// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_GetSingleUserAccessibleGroupsSendingData } from "../../../Models/sendingDataSchema";
import { I_AccessibleUserGroups } from "../../../Models/interfaces";
// Models

export const getSingleUserAccessibleGroupsService = async (
  userToken: string,
  username: string,
  searchData: I_GetSingleUserAccessibleGroupsSendingData
): Promise<
  AxiosResponse<{
    error: number;
    messages: {
      message: string;
      extras: {
        informationType: string;
        information: string;
      }[];
    };
    result: I_AccessibleUserGroups;
  }>
> => {
  return _axios.get(
    `/users/${username}/accessible-groups?page=${searchData.page}&size=${searchData.size}&search=${searchData.search}&tenant=${searchData.tenantId}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
