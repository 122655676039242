// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./SingleUser.module.css";
// CSS

// Modules
import { useParams } from "react-router";
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
// Modules

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncSingleUserAccessibleData,
  singleAccessibleUserSlice,
} from "../../../Features/SingleAccessibleUserSlice/singleAccessibleUserSlice";
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
// Redux

// Utils
import Spinner from "../../../Utils/Spinner/Spinner";
// Utils

// Components
import PersonalTab from "./PersonalTab/PersonalTab";
import PermitionTab from "./UserGroupsTab/UserGroupsTab";
import AccessibleProcessTab from "./AccessibleProcessTab/AccessibleProcessTab";
import AccessibleGroups from "./AccessibleGroups/AccessibleGroups";
// Components

// Contexts
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

const SingleUser = () => {
  // States
  const [selectedTab, setSelectedTab] = useState<
    "personal" | "groups" | "processes" | "accessibleGroups"
  >("personal");
  //
  const [externalUserInformation, setExternalUserInformation] = useState<{
    password?: string;
    externalIdentitiyInformation: {
      [key: string]: string | number;
    };
  }>({
    password: "",
    externalIdentitiyInformation: {},
  });
  /***************************** */
  /***************************** */
  /***************************** */
  /***************************** */
  /***************************** */
  // constants
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightOn = useContext(IsNightModeOnContext);
  // constants
  /***************************** */
  /***************************** */
  /***************************** */
  /***************************** */
  /***************************** */
  // Redux States
  const singleUser = useAppSelector((state) => state.singleAccessibleUser);
  //
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  //
  const allGroups = useContext(UserGroupsContext);
  // Redux States
  //
  //
  //
  //
  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("ویرایش کاربران"));
  }, [dispatch]);
  //
  // THIS EFFECT IS FOR CHANGE THE ADDITIONAL IDENTITIY OBJECT TO A SIMPLER OBJECT FOR SENDING TO SERVER !
  useEffect(() => {
    const _data = Object.values(singleUser.extraIdentityInformation).map(
      (item, index) => {
        const keys = Object.keys(singleUser.extraIdentityInformation);

        const selectedItemIndex = keys.findIndex(
          (_item, _index) => _index === index
        );
        console.log("selectedItemIndex", selectedItemIndex);

        const selectedItemKey = keys[selectedItemIndex];
        const selectedItemValue = { ...item };

        return { [selectedItemKey]: selectedItemValue.value };
      }
    );

    const keys: any = Object.keys(_data);
    const values: any = Object.values(_data);

    const result: { [key: string | number]: string | number } = {};

    for (let i: number = 0; i <= values.length - 1; i++) {
      result[keys[i]] = values[i];
    }

    const transformedObject: { [key: string | number]: string | number } =
      Object.values(result).reduce((acc: any, item) => {
        const key = Object.keys(item)[0];
        const value = Object.values(item)[0];
        acc[key] = value;
        return acc;
      }, {});

    console.log("result", transformedObject);

    setExternalUserInformation((prevState) => ({
      ...prevState,
      externalIdentitiyInformation: transformedObject,
    }));
    //
  }, [singleUser.extraIdentityInformation]);
  // THIS EFFECT IS FOR CHANGE THE ADDITIONAL IDENTITIY OBJECT TO A SIMPLER OBJECT FOR SENDING TO SERVER !
  //
  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncSingleUserAccessibleData({
          userName: String(userId),
          userToken,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [userToken, userId, dispatch, allGroups, isFullRenderd]);
  //
  useEffect(() => {
    return () => {
      dispatch(singleAccessibleUserSlice.actions.resetRecevedUserData());
    };
  }, [dispatch]);
  //
  //
  //
  //
  //
  //
  //
  return (
    <>
      {singleUser.isGettingSingleUserPending ? (
        <div
          className={`d-flex flex-column align-items-center justify-content-center ${
            isNightOn ? "nightBg1" : ""
          }`}
        >
          <p
            style={{
              fontSize: "1.3rem",
            }}
          >
            در حال بارگیری
          </p>
          <Spinner
            style={{
              width: "24px",
              height: "24px",
            }}
          />
        </div>
      ) : (
        <div
          className={`w-100 d-flex flex-row flex-column align-content-center justify-content-center ${styles.singleUserContainer} `}
        >
          {/* Tab Selector  */}
          <div
            className={`${
              styles.topButtons
            } d-flex flex-row flex-row-reverse align-items-center justify-content-start my-4 align-self-end flex-wrap w-100 
            
            ${isNightOn ? "nightBg2" : ""}`}
            style={{
              gap: "6px",
              background: isNightOn ? "transparent !important" : "",
            }}
          >
            {permitionCheckerWithoutToast(
              allGroups,
              [
                "Full_Manager",
                "Tenant_Full_Manager",
                "Tenant_Basic_Manager",
                "User_Editor",
              ],
              isFullRenderd
            ) && (
              <button
                className={`${isNightOn ? "nightBg1" : ""}`}
                onClick={() => {
                  setSelectedTab("personal");
                }}
                style={{
                  boxShadow:
                    selectedTab === "personal"
                      ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                      : "",
                  padding: "8px 24px",
                  border:
                    selectedTab === "personal" && isNightOn
                      ? "1px solid #fff"
                      : "",
                }}
              >
                مشخصات کاربر
              </button>
            )}

            {permitionCheckerWithoutToast(
              allGroups,
              [
                "Full_Manager",
                "Tenant_Full_Manager",
                "Tenant_Basic_Manager",
                "User_Editor",
              ],
              isFullRenderd
            ) && (
              <button
                className={`${isNightOn ? "nightBg1" : ""}`}
                onClick={() => {
                  setSelectedTab("groups");
                }}
                style={{
                  boxShadow:
                    selectedTab === "groups"
                      ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                      : "",
                  padding: "8px 24px",
                  border:
                    selectedTab === "groups" && isNightOn
                      ? "1px solid #fff"
                      : "",
                }}
              >
                ویرایش دسترسی ها
              </button>
            )}

            {(permitionCheckerWithoutToast(
              allGroups,
              ["Tenant_Full_Manager", "Tenant_Basic_Manager"],
              isFullRenderd
            ) === true ||
              permitionCheckerWithoutToast(
                allGroups,
                ["Process_Full_Manager", "Process_Staff_Editor"],
                isFullRenderd
              ) === true) &&
              permitionCheckerWithoutToast(
                allGroups,
                ["Full_Manager", "User_Editor"],
                isFullRenderd
              ) === true &&
              singleUser.accessingProcessChangeable === true && (
                <button
                  className={`${isNightOn ? "nightBg1" : ""}`}
                  onClick={() => {
                    setSelectedTab("processes");
                  }}
                  style={{
                    boxShadow:
                      selectedTab === "processes"
                        ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                        : "",
                    padding: "8px 24px",
                    border:
                      selectedTab === "processes" && isNightOn
                        ? "1px solid #fff"
                        : "",
                  }}
                >
                  مشاهده فرآیندهای کاربر
                </button>
              )}

            {permitionCheckerWithoutToast(
              allGroups,
              [
                "Full_Manager",
                "Tenant_Full_Manager",
                "Tenant_Basic_Manager",
                "User_Editor",
              ],
              isFullRenderd
            ) &&
              singleUser.accessingGroupsChangeable && (
                <button
                  className={`${isNightOn ? "nightBg1" : ""}`}
                  onClick={() => {
                    setSelectedTab("accessibleGroups");
                  }}
                  style={{
                    boxShadow:
                      selectedTab === "accessibleGroups"
                        ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                        : "",
                    padding: "8px 24px",
                    border:
                      selectedTab === "accessibleGroups" && isNightOn
                        ? "1px solid #fff"
                        : "",
                  }}
                >
                  مشاهده گروه های دارای مدیریت کاربر
                </button>
              )}
          </div>
          {/* Tab Selector  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {selectedTab === "personal" &&
          permitionCheckerWithoutToast(
            allGroups,
            [
              "Full_Manager",
              "Tenant_Full_Manager",
              "Tenant_Basic_Manager",
              "User_Editor",
            ],
            isFullRenderd
          ) ? (
            <PersonalTab
              externalUserInformation={externalUserInformation}
              setExternalUserInformation={setExternalUserInformation}
            />
          ) : null}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {selectedTab === "groups" &&
          permitionCheckerWithoutToast(
            allGroups,
            [
              "Full_Manager",
              "Tenant_Full_Manager",
              "Tenant_Basic_Manager",
              "User_Editor",
            ],
            isFullRenderd
          ) ? (
            <PermitionTab />
          ) : null}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {selectedTab === "processes" &&
          (permitionCheckerWithoutToast(
            allGroups,
            ["Tenant_Full_Manager", "Tenant_Basic_Manager"],
            isFullRenderd
          ) === true ||
            permitionCheckerWithoutToast(
              allGroups,
              ["Process_Full_Manager", "Process_Staff_Editor"],
              isFullRenderd
            ) === true) &&
          permitionCheckerWithoutToast(
            allGroups,
            ["Full_Manager", "User_Editor"],
            isFullRenderd
          ) === true ? (
            <AccessibleProcessTab />
          ) : null}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {selectedTab === "accessibleGroups" &&
          permitionCheckerWithoutToast(
            allGroups,
            [
              "Full_Manager",
              "Tenant_Full_Manager",
              "Tenant_Basic_Manager",
              "User_Editor",
            ],
            isFullRenderd
          ) ? (
            <AccessibleGroups />
          ) : null}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
        </div>
      )}
    </>
  );
};

export default SingleUser;
