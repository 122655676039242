// React
import React, { useEffect, useRef, useState } from "react";
// React

// CSS
import styles from "./TaskFormAndSubmitTaskFormByTasks.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
import {
  postAsyncTaskForm,
  taskSlice,
} from "../../../Features/TasksSlice/tasksSlice";
// Redux

// Modules
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// Modules

// Components
import { Form } from "@formio/react";
import { Spinner } from "reactstrap";
// Components

// Urls
import { mainUrl } from "../../../Services/urls";
// Urls

// Hooks
import usePersianDatePickerEventHandler from "../../../Hooks/usePersianDatePickerEventHandler";
import useReRenderMap from "../../../Hooks/useReRenderMap";
// Hooks

// Utils
import { resetReferenceOfObject } from "../../../Utils/resetReferenceOfObject";
import { convertToGeorgianDateFormPersianDateInString } from "../../../Utils/FormioUtils/convertToGeorgianDateFormPersianDateInString";
import { convertToPersianDateFromGeorgianDateInString } from "../../../Utils/FormioUtils/convertToPersianDateFromGeorgianDateInString";
import { useMapRenderInLittleModal } from "../../../Hooks/MapHooks/useMapRenderInLittleModal";
// Utils

const TaskFormAndSubmitTaskFormByTasks = () => {
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const currFormStatus = useAppSelector(
    (state) => state.tasks.submitTaskForm.isFormSubmited
  );
  const { formData: recevedFormDataFromBackend }: any = useAppSelector(
    (state) => state.tasks.singleTaskDetail.singleTaskDetail.form
  );

  const { taskId } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const [nForm, setNForm] = useState<any>({});
  const [form, setForm] = useState<any>({});
  const _formData = useRef<any>();
  const [isFormShowPending, setIsFormShowPending] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const formData = useRef<any>({});

  const { processPage } = useAppSelector((state) => state.searchAndBack);

  useEffect(() => {
    fetch(`${mainUrl}${searchParams.get("formUrl")}`)
      .then((res) => res.json())
      .then((data) => setForm(data))
      .then(() => setIsFormShowPending(false))
      .catch((err) => {
        console.log(err);
        setIsFormShowPending(false);
        setHasError(true);
      });
  }, [navigate, processPage, searchParams]);

  useEffect(() => {
    dispatch(
      searchAndBackSlice.actions.changeCurrLocation("ارسال فرم شروع با وظیفه")
    );
  }, [dispatch]);

  useEffect(() => {
    if (!currFormStatus) return;
    navigate("/tasks");
    return () => {
      dispatch(taskSlice.actions.resetFormSubmitStatus());
    };
  }, [dispatch, navigate, currFormStatus]);

  usePersianDatePickerEventHandler(isFormShowPending);

  useReRenderMap({
    forceReRender: true,
    form,
    isPending: isFormShowPending,
  });

  useMapRenderInLittleModal(isFormShowPending);

  return (
    <div
      className={`${styles.taskFormFromTasksContainer} w-100 d-flex flex-column`}
    >
      <p
        className="w-100 d-flex align-items-center justify-content-end"
        style={{
          color: "#111D64",
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        برای ادامه عملیات ، فرم زیر را تکمیل کنید
      </p>
      {isFormShowPending ? (
        <div className="w-100 d-flex align-items-center justify-content-center flex-column">
          <p>در حال بارگیری</p>
          <Spinner />
        </div>
      ) : (
        <>
          {hasError ? (
            <>مشکلی در نمایش فرم پیش آمده</>
          ) : (
            <>
              {Object.keys(recevedFormDataFromBackend).length > 0 ? (
                <Form
                  src={form}
                  onChange={(data: any) => {
                    _formData.current = data;
                  }}
                  onFormLoad={() => {
                    console.log("loaded");
                  }}
                  onSubmit={(data: any) => {
                    console.log(nForm.data);
                    dispatch(
                      postAsyncTaskForm({
                        formData: JSON.parse(
                          convertToGeorgianDateFormPersianDateInString(
                            JSON.stringify(_formData.current.data)
                          )
                        )
                          ? JSON.parse(
                              convertToGeorgianDateFormPersianDateInString(
                                JSON.stringify(_formData.current.data)
                              )
                            )
                          : _formData.current.data,
                        taskId: String(taskId),
                        userToken,
                      })
                    );
                    console.log("submit");
                  }}
                  onError={() => {
                    console.log("errrrrrrrrrrrrrrrrrr");
                  }}
                  submission={{
                    data: convertToPersianDateFromGeorgianDateInString(
                      resetReferenceOfObject(recevedFormDataFromBackend)
                    )
                      ? resetReferenceOfObject(
                          convertToPersianDateFromGeorgianDateInString(
                            resetReferenceOfObject(recevedFormDataFromBackend)
                          )
                        )
                      : recevedFormDataFromBackend,
                  }}
                />
              ) : (
                <Form
                  src={form}
                  onChange={(data: any) => {
                    setNForm(data);
                    formData.current = data;
                  }}
                  onFormLoad={() => {
                    console.log("loaded");
                  }}
                  onSubmit={(data: any) => {
                    console.log(nForm.data);
                    dispatch(
                      postAsyncTaskForm({
                        formData: JSON.parse(
                          convertToGeorgianDateFormPersianDateInString(
                            JSON.stringify(_formData.current.data)
                          )
                        )
                          ? JSON.parse(
                              convertToGeorgianDateFormPersianDateInString(
                                JSON.stringify(_formData.current.data)
                              )
                            )
                          : _formData.current.data,

                        taskId: String(taskId),
                        userToken,
                      })
                    );
                    console.log("submit");
                  }}
                  onError={() => {
                    console.log("errrrrrrrrrrrrrrrrrr");
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TaskFormAndSubmitTaskFormByTasks;
