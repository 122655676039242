// React
import React, { useContext, useState } from "react";
// React

// CSS
import styles from "./CustomFormioComponents.module.css";
// CSS

// Types
import { formioCustomDataTypes } from "./Data/formioCustomDataTypes";
// Types

// CustomComponents
import CustomFormioComponentDataReceiverModal from "./CustomFormioComponentDataReceverModal/CustomFormioComponentDataReceverModal";
// CustomComponents

// Contexts
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Redux
import { useAppSelector } from "../../app/store";
import CustomSelectOption2 from "../CustomSelectOption2/CustomSelectOption2";
// Redux

type CustomFormioComponentsProps = {
  dataSetter: (data: any) => void;
  containerClassName: string;
};

const CustomFormioComponents: React.FunctionComponent<
  CustomFormioComponentsProps
> = ({ dataSetter, containerClassName }) => {
  const [selectedComponentType, setSelectedComponent] = useState<string>("");
  const [isModalInScreen, setIsModalInScreen] = useState<boolean>(false);

  const isNightModeOn = useContext(IsNightModeOnContext);

  const language = useAppSelector((state) => state.currLanguage.language);

  return (
    <div
      className={`${
        styles.customComponentContainer
      } d-flex flex-row-reverse align-items-center justify-content-between ${containerClassName} ${
        styles.biggerx
      } ${isNightModeOn ? "nightBg1" : ""}
      `}
    >
      <div
        className={` d-flex flex-column align-items-center justify-content-center ${
          window.innerWidth > 1000 ? "" : "w-100"
        }`}
      >
        <p
          style={{
            marginBottom: "unset",
          }}
        >
          فیلدهای اختصاصی
        </p>

        <CustomSelectOption2
          getSelectedOption={(data) => {
            setSelectedComponent(String(data));
          }}
          initialTitle="انتخاب فیلد"
          initialValue={""}
          options={formioCustomDataTypes.map((item) => ({
            title: item.title,
            value: item.type,
          }))}
          containerStyle={{
            zIndex: "9000",
          }}
          containerClassName={window.innerWidth < 1000 ? "w-100" : ""}
        />
      </div>

      {selectedComponentType !== "" ? (
        <button
          className="operationEvent editBtn mt-4 "
          data-tooltip-content={language.thisWillAddedOnBottomOfForm}
          data-tooltip-id="my-tooltip"
          onClick={() => {
            if (selectedComponentType === "CUSTOM_PERSIAN_DATE_PICKER") {
              dataSetter({ modalType: selectedComponentType });
              return;
            }
            if (selectedComponentType === "CUSTOM_EMAIL_COMPONENT") {
              dataSetter({ modalType: selectedComponentType });
              return;
            }
            if (selectedComponentType === "CUSTOM_MAP_COMPONENT") {
              setIsModalInScreen(true);
              return;
            }
            if (selectedComponentType === "CUSTOM_MAP_COMPONENT_WITH_INPUT") {
              dataSetter({ modalType: selectedComponentType });
              return;
            }
            setIsModalInScreen(true);
          }}
        >
          افزودن
        </button>
      ) : null}

      {isModalInScreen ? (
        <CustomFormioComponentDataReceiverModal
          setIsModalInScreen={setIsModalInScreen}
          modalType={selectedComponentType}
          dataSetter={dataSetter}
        />
      ) : null}
    </div>
  );
};

export default CustomFormioComponents;
