export const removeDuplicateItemsInArray__NO_MAIN_ARRAY_MUTATE = <
  T extends any[]
>(
  x: T
): any[] => {
  const uniqueArray: T[] = [];

  for (const item of x) {
    if (!uniqueArray.includes(item)) {
      uniqueArray.push(item);
    }
  }

  return uniqueArray;
};
