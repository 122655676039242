// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Models
import { I_UsersListResult } from "../../Models/interfaces";
import {
  I_AddUser,
  I_EditUserAccessibleProcess,
  I_GroupAccessModifying,
} from "../../Models/sendingDataSchema";
// Models

// Services
import { getAccessableUsersService } from "../../Services/Users/get/getAccessableUsersService";
import { addUserService } from "../../Services/Users/post/addUserService";
import { changeUsersAccessingGroupsService } from "../../Services/Users/post/changeUsersAccessingGroupsService";
import { editUserAccessibleProcessService } from "../../Services/Users/post/editUserAccessibleProcessService";
// Services

// Utils
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Utils

const initialState: I_UsersListResult & {
  isUserAddingPending: boolean;
  isPending: boolean;
  isEditUserGroupsPending: boolean;
  isProcessEditPending: boolean;
  isUserDataChangePending: boolean;
  isUserCreated: boolean;
  isError: boolean;
} = {
  content: [],
  hasNextPage: false,
  pageNumber: 0,
  pageSize: 0,
  totalElements: 0,
  totalPages: 0,
  // Done by Developer
  isPending: true,
  isUserAddingPending: false,
  isEditUserGroupsPending: false,
  isProcessEditPending: false,
  isUserDataChangePending: false,
  isUserCreated: false,
  isError: false,
  // Done by Developer
};

export const getAsyncAccessibleUsers = createAsyncThunk(
  "accessableUsers/getAccessableUsers",
  async (serchParams: {
    page: number;
    size: number;
    editing: boolean;
    search: string;
    group: string;
    tenant: string;
    usernames: string[];
    userToken: string;
  }) => {
    try {
      const { data } = await getAccessableUsersService(serchParams);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);
/************************************ */
/************************************ */
/************************************ */
/************************************ */
export const addNewAsyncUser = createAsyncThunk(
  "accessableUsers/addNewUser",
  async (_data: { userToken: string; _data: I_AddUser }) => {
    try {
      const { data, status } = await addUserService(_data);
      if (String(status)[0] === "4") return Promise.reject();
      return { data: data.messages, status };
      //
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);
/************************************ */
/************************************ */
/************************************ */
/************************************ */
export const editAsyncUserAccessingGroups = createAsyncThunk(
  "accessableUsers/editUserAccessingGroups",
  async (_data: {
    groupAccessingListData: I_GroupAccessModifying;
    userToken: string;
    userName: string;
  }) => {
    try {
      const { data } = await changeUsersAccessingGroupsService(
        _data.groupAccessingListData,
        _data.userToken,
        _data.userName
      );

      return data.messages;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);
/************************************ */
/************************************ */
/************************************ */
/************************************ */
export const editAsyncAccessibleUserProcess = createAsyncThunk(
  "accessibleUsers/editAccessProcess",
  async (receivedDataByDispatch: {
    _data: I_EditUserAccessibleProcess;
    userToken: string;
    userName: string;
  }) => {
    try {
      const { data } = await editUserAccessibleProcessService(
        receivedDataByDispatch.userToken,
        receivedDataByDispatch.userName,
        receivedDataByDispatch._data
      );
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);
/************************************ */
/************************************ */
/************************************ */
/************************************ */

/*********************************************************************************** */
/*********************************************************************************** */
/*********************************************************************************** */
/*********************************************************************************** */
/*********************************************************************************** */

export const accessibleUsersListSlice = createSlice({
  name: "accessibleUsers",
  initialState,
  reducers: {
    setUsersLoadingPending: (state) => {
      state.isPending = true;
      state.content = [];
    },
    resetIsUserCreated: (state) => {
      state.isUserCreated = false;
    },
    resetAccessibleUserPending: (state) => {
      state.isPending = true;
    },
  },
  extraReducers: (builder) => {
    /************************* */
    /************************* */
    /************************* */
    /************************* */
    builder.addCase(getAsyncAccessibleUsers.pending, (state) => {
      state.isPending = true;
      state.isError = false;
    });
    //
    builder.addCase(getAsyncAccessibleUsers.fulfilled, (state, action) => {
      state.isPending = false;

      const err: any = action.payload?.result;

      if (err?.response?.result?.data?.messages) {
        for (
          let i: number = 0;
          i <= err?.response.data.messages.length - 1;
          i++
        ) {
          customizedToast(err?.response.data.messages[i].message, "error");
        }
        state.content = [];
        return;
      } else {
        state.content = action.payload!.result.content;
        state.hasNextPage = action.payload!.result.hasNextPage;
        state.pageNumber = action.payload!.result.pageNumber;
        state.pageSize = action.payload!.result.pageSize;
        state.totalElements = action.payload!.result.totalElements;
        state.totalPages = action.payload!.result.totalPages;
        state.isError = false;
      }
    });
    //
    builder.addCase(getAsyncAccessibleUsers.rejected, (state) => {
      state.isPending = false;
      state.isError = true;
    });
    /************************* */
    /************************* */
    /************************* */
    /************************* */
    builder.addCase(addNewAsyncUser.pending, (state) => {
      state.isUserAddingPending = true;
      state.isUserCreated = false;
    });
    //
    builder.addCase(addNewAsyncUser.fulfilled, (state, action) => {
      state.isUserAddingPending = false;
      if (!action.payload?.data) return;
      for (let i: number = 0; i <= action.payload?.data.length - 1; i++) {
        customizedToast(
          action.payload?.data[i].message,
          String(action.payload?.status).startsWith("4") ? "error" : "success"
        );
        state.isUserCreated = true;
      }
    });
    //
    builder.addCase(addNewAsyncUser.rejected, (state, action) => {
      state.isUserAddingPending = false;
      state.isUserCreated = false;
    });
    /************************* */
    /************************* */
    /************************* */
    /************************* */
    builder.addCase(editAsyncUserAccessingGroups.pending, (state, action) => {
      state.isEditUserGroupsPending = true;
    });
    builder.addCase(editAsyncUserAccessingGroups.fulfilled, (state, action) => {
      state.isEditUserGroupsPending = false;
    });
    builder.addCase(editAsyncUserAccessingGroups.rejected, (state, action) => {
      state.isEditUserGroupsPending = false;
    });
    /************************* */
    /************************* */
    /************************* */
    /************************* */
    builder.addCase(editAsyncAccessibleUserProcess.pending, (state) => {
      state.isProcessEditPending = true;
    });
    builder.addCase(
      editAsyncAccessibleUserProcess.fulfilled,
      (state, action) => {
        state.isProcessEditPending = false;
      }
    );
    builder.addCase(editAsyncAccessibleUserProcess.rejected, (state) => {
      state.isProcessEditPending = false;
    });
    /************************* */
    /************************* */
    /************************* */
    /************************* */
  },
});

const { setUsersLoadingPending } = accessibleUsersListSlice.actions;

export { setUsersLoadingPending };
