// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_CandidateUsers, I_Message } from "../../../../Models/interfaces";
// Models

export const getCandidateUsersOfActivityHistoryByProcessInstanceIdService =
  (_data: {
    userToken: string;
    _data: {
      processInstanceId: string | number;
      historyId: string | number;
      search: string;
      page: string | number;
      size: string | number;
    };
  }): Promise<
    AxiosResponse<{
      error: number;
      messages: I_Message[];
      result: I_CandidateUsers;
    }>
  > => {
    return _axios.get(
      `/processes/running-instances/${_data._data.processInstanceId}/history/activities/${_data._data.historyId}/candidate-users?search=${_data._data.search}&page=${_data._data.page}&size=${_data._data.size}`,
      {
        headers: {
          Authorization: `Bearer ${_data.userToken}`,
        },
      }
    );
  };
