import { _axios } from "../../Base_Axios_Config/_axios";

export const editSelectedFormService = async (_data: {
  _data: {
    processKey: string;
    formId: string;
    formBody: {
      title: string;
      formData: any;
    };
  };
  userToken: string;
}) => {
  return _axios.post(
    `/processes/${_data._data.processKey}/forms/${_data._data.formId}/edit`,
    _data._data.formBody,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};

