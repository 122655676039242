// React
import React from "react";
// React

// CSS
import styles from "./../Home.module.css";
// CSS

// Modules
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
// Modules

// Utils
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
// Utils

// Redux
import { useAppSelector } from "../../../app/store";
// Redux

type HomeWidgetsProps = {
  isNightModeOn: boolean;
  allGroups: string[];
  isFullRenderd: boolean;
  isFull: boolean;
};

const HomeWidgets: React.FunctionComponent<HomeWidgetsProps> = ({
  isNightModeOn,
  allGroups,
  isFullRenderd,
  isFull,
}) => {
  const tasksData = useAppSelector((state) => state.tasks.taskData);
  const groupsData = useAppSelector((state) => state.groups.allGroupsData);
  const accessbileUserData = useAppSelector((state) => state.accessibleUsers);
  const processesRecevedData = useAppSelector((state) => state.processes);
  const serverData = useAppSelector((state) => state.servers);
  const { data: eventData, isPending: eventDataPending } = useAppSelector(
    (state) => state.securityEvent.securityEventData
  );

  return (
    <div
      className={`${styles.widgetsContainer} `}
      style={{
        width: isFull ? "100% !important" : "",
      }}
    >
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      <Link
        to={"/tasks"}
        className={`${styles.card} ${isNightModeOn ? styles.dark : ""} ${
          styles.bigOnHover
        } `}
      >
        <span className={`${styles.icon} ${styles.taskBtn} icon-tasks`}></span>

        <div
          className={`${styles.dataContainer} mt-3 w-100 d-flex flex-row-reverse align-items-center justify-content-between`}
        >
          <p>وظایف محوله</p>
          <span>
            {tasksData.content.length === 0 ? 0 : tasksData.content.length}
          </span>
        </div>
      </Link>
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {permitionCheckerWithoutToast(
        allGroups,
        [
          "Full_Manager",
          "Tenant_Full_Manager",
          "Tenant_Basic_Manager",
          "User_Editor",
          "User_Information_Reader",
        ],
        isFullRenderd
      ) === true && (
        <Link
          to={"/users"}
          className={`${styles.card} ${isNightModeOn ? styles.dark : ""} ${
            styles.bigOnHover
          } `}
        >
          <span className={`${styles.icon} ${styles.users} icon-users`}></span>

          <div
            className={`${styles.dataContainer} mt-3 w-100 d-flex flex-row-reverse  justify-content-between`}
          >
            <p>کاربران زیردست</p>

            {accessbileUserData.isPending ? (
              <Spinner />
            ) : (
              <span>{accessbileUserData.content.length}</span>
            )}
          </div>
        </Link>
      )}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {permitionCheckerWithoutToast(
        allGroups,
        [
          "Tenant_Full_Manager",
          "Tenant_Basic_Manager",
          "Process_Full_Manager",
          "Process_Staff_Editor",
          "Process_Editor",
          "Process_Starter",
          "Process_Schema_Analyzer",
          "Process_Form_Editor",
        ],
        isFullRenderd
      ) === true && (
        <Link
          to={"/processes"}
          className={`${styles.card} ${isNightModeOn ? styles.dark : ""} ${
            styles.bigOnHover
          } `}
        >
          <span
            className={`${styles.icon} ${styles.processes} icon-flow`}
          ></span>

          <div
            className={`${styles.dataContainer} mt-3 w-100 d-flex flex-row-reverse align-items-center justify-content-between`}
          >
            <p>فرآیند های ثبت شده</p>

            {processesRecevedData.isGettingProcessesPending ? (
              <Spinner />
            ) : (
              <span>{processesRecevedData.content.length}</span>
            )}
          </div>
        </Link>
      )}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {permitionCheckerWithoutToast(
        allGroups,
        ["Tenant_Full_Manager", "Tenant_Basic_Manager", "Settings_Editor"],
        isFullRenderd
      ) === true && (
        <Link
          className={`${styles.card} ${isNightModeOn ? styles.dark : ""} ${
            styles.bigOnHover
          } `}
          to={"/servers"}
        >
          <span
            className={`${styles.icon} ${styles.dataBase} icon-data-base`}
          ></span>

          <div
            className={`${styles.dataContainer} mt-3 w-100 d-flex flex-row-reverse align-items-center justify-content-between`}
          >
            <p>پایگاه های داده</p>

            {serverData.isPending ? (
              <Spinner />
            ) : (
              <span>{serverData.content.length}</span>
            )}
          </div>
        </Link>
      )}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {permitionCheckerWithoutToast(
        allGroups,
        ["Tenant_Full_Manager", "Tenant_Basic_Manager", "Settings_Editor"],
        isFullRenderd
      ) === true && (
        <Link
          to={"/groups"}
          className={`${styles.card} ${isNightModeOn ? styles.dark : ""} ${
            styles.bigOnHover
          } `}
        >
          <span className={`${styles.icon} ${styles.groups} icon-group`}></span>

          <div
            className={`${styles.dataContainer} mt-3 w-100 d-flex flex-row-reverse align-items-center justify-content-between`}
          >
            <p>گروه‌های قابل مشاهده</p>

            {groupsData.isRecevedGroupsPending ? (
              <Spinner />
            ) : (
              <span>{groupsData.content.length}</span>
            )}
          </div>
        </Link>
      )}

      {permitionCheckerWithoutToast(
        allGroups,
        ["Tenant_Full_Manager", "Tenant_Basic_Manager", "Settings_Editor"],
        isFullRenderd
      ) === true && (
        <Link
          to={"/security-events"}
          className={`${styles.card} ${isNightModeOn ? styles.dark : ""} ${
            styles.bigOnHover
          } `}
        >
          <span
            className={`${styles.icon} ${styles.securityEvents} icon-namesecurity-events`}
          ></span>

          <div
            className={`${styles.dataContainer} mt-3 w-100 d-flex flex-row-reverse align-items-center justify-content-between`}
          >
            <p>رویداد های امنیتی</p>

            {eventDataPending ? (
              <Spinner />
            ) : (
              <span>{eventData.content.length}</span>
            )}
          </div>
        </Link>
      )}
    </div>
  );
};

export default HomeWidgets;
