// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Service
import { getFormsByProcessKeyService } from "../../Services/FormManagement/get/getFormsByProcessKeyService";
import { addNewFormService } from "../../Services/FormManagement/post/addNewFormService";
import { deleteFormService } from "../../Services/FormManagement/post/deleteFormService";
import { editSelectedFormService } from "../../Services/FormManagement/post/editSelectedFormService";
// Service

// Models
import { I_Forms } from "../../Models/interfaces";
// Models

// Utils
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { resetReferenceOfObject } from "../../Utils/resetReferenceOfObject";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Utils

const initialState: I_Forms & {
  isGettingFormPending: boolean;
  isAddingFormPending: boolean;
  isRemoveFormPending: boolean;
  isFormEditPending: boolean;
  isEditFormDone: boolean;
  isFormAdded: boolean;
  isError: boolean;

  singleForm: {
    url: string;
    isPending: boolean;
    isError: boolean;
    isDone: boolean;
  };
} = {
  pageNumber: 1,
  pageSize: 10,
  totalPages: 0,
  totalElements: 0,
  hasNextPage: false,
  content: [],

  // added by developer
  isGettingFormPending: true,
  isAddingFormPending: false,
  isRemoveFormPending: false,
  isFormEditPending: false,
  isEditFormDone: false,
  isFormAdded: false,
  isError: false,
  // added by developer

  singleForm: {
    isPending: true,
    isDone: false,
    isError: false,
    url: "",
  },
};

export const getAsyncForms = createAsyncThunk(
  "forms/getForms",
  async (_data: {
    userToken: string;
    processKey: string;
    page: number | string;
    size: number | string;
    search: string;
    tenant: number | string;
    id: string | number;
  }) => {
    try {
      const { data } = await getFormsByProcessKeyService(_data);

      return data.result;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncSingleFormById = createAsyncThunk(
  "forms/getSingleForm",
  async (_data: {
    userToken: string;
    processKey: string;
    id: string | number;
    tenant: string;
  }) => {
    try {
      const { data } = await getFormsByProcessKeyService({
        ..._data,
        page: 1,
        search: "",
        size: 1,
      });

      return data.result;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const addAsyncForm = createAsyncThunk(
  "forms/addForm",
  async (_data: {
    _data: {
      tenantId: string;
      title: string;
      formType: "Form_Io";
      key: string;
      formData: {};
      processKey: string;
    };
    userToken: string;
  }) => {
    try {
      const { data } = await addNewFormService(_data);

      return data.result;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const deleteAsyncForm = createAsyncThunk(
  "forms/deleteCurrentForm",
  async (_data: {
    _data: {
      processKey: string;
      formId: string;
    };
    userToken: string;
  }) => {
    try {
      const { data } = await deleteFormService(_data);
      return data.result;
      //
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const editAsyncForm = createAsyncThunk(
  "forms/editForm",
  async (_data: {
    _data: {
      processKey: string;
      formId: string;
      formBody: {
        title: string;
        formData: any;
      };
    };
    userToken: string;
  }) => {
    try {
      const { data } = await editSelectedFormService(_data);

      return data.result;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const formsSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    resetFormPending: (state) => {
      state.isGettingFormPending = true;
      state.content = resetReferenceOfObject(initialState.content);
    },
    resetFormEditDone: (state) => {
      state.isEditFormDone = false;
    },
    resetAddingStateOfForm: (state) => {
      state.isFormAdded = false;
    },
    resetSingleForm: (state) => {
      state.singleForm.isPending = true;
      state.singleForm.url = "";
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAsyncForms.pending, (state, action) => {
      state.isGettingFormPending = true;
      state.isError = false;
    });
    builder.addCase(getAsyncForms.fulfilled, (state, action) => {
      state.isGettingFormPending = false;
      state.isError = false;

      if (!action.payload) {
        state.pageNumber = 1;
        state.pageSize = 10;
        state.totalPages = 1;
        state.totalElements = 0;
        state.hasNextPage = false;
        state.content = [];
        customizedToast("موردی مطابق خواسته ی شما پیدا نشد", "error");
        return;
      }

      state.pageNumber = action.payload!.pageNumber;
      state.pageSize = action.payload!.pageSize;
      state.totalPages = action.payload!.totalPages;
      state.totalElements = action.payload!.totalElements;
      state.hasNextPage = action.payload!.hasNextPage;
      state.content = action.payload!.content;
    });
    builder.addCase(getAsyncForms.rejected, (state, action) => {
      state.isGettingFormPending = false;
      state.isError = true;
    });
    //
    //
    //
    //
    //
    builder.addCase(addAsyncForm.pending, (state, action) => {
      state.isAddingFormPending = true;
      state.isFormAdded = false;
    });
    builder.addCase(addAsyncForm.fulfilled, (state, action) => {
      state.isAddingFormPending = false;
      state.isFormAdded = true;
      if (!action.payload) return;

      customizedToast("فرم با موفقیت آپلود شد", "success");
    });
    builder.addCase(addAsyncForm.rejected, (state, action) => {
      state.isAddingFormPending = false;
      state.isFormAdded = false;
    });
    //
    //
    //
    //
    //
    builder.addCase(deleteAsyncForm.pending, (state, action) => {
      state.isRemoveFormPending = true;
    });
    builder.addCase(deleteAsyncForm.fulfilled, (state, action) => {
      state.isRemoveFormPending = true;
    });
    builder.addCase(deleteAsyncForm.rejected, (state, action) => {
      state.isRemoveFormPending = true;
    });
    //
    //
    //
    //
    //
    builder.addCase(editAsyncForm.pending, (state, action) => {
      state.isFormEditPending = true;
      state.isEditFormDone = false;
    });
    builder.addCase(editAsyncForm.fulfilled, (state, action) => {
      state.isFormEditPending = false;
      state.isEditFormDone = true;
      if (!action.payload?.title) return;
      customizedToast(
        ` ${action.payload?.title} با موفقیت ویرایش شد`,
        "success"
      );
    });
    builder.addCase(editAsyncForm.rejected, (state, action) => {
      state.isFormEditPending = false;
      state.isEditFormDone = false;
    });
    //
    //
    //
    //
    //
    builder.addCase(getAsyncSingleFormById.pending, (state) => {
      state.singleForm.isPending = true;
    });
    builder.addCase(getAsyncSingleFormById.fulfilled, (state, action) => {
      state.singleForm.isPending = false;
      state.singleForm.isError = false;
      state.singleForm.url = action.payload!.content[0].url;
    });
    builder.addCase(getAsyncSingleFormById.rejected, (state) => {
      state.singleForm.isPending = false;
      state.singleForm.isError = true;
    });
  },
});
