// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_Message, I_SingleInstanceSchema } from "../../../Models/interfaces";
// Models

export const getInstanceProcessSchemaService = async (
  userToken: string,
  processInstanceId: string
): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message;
    result: I_SingleInstanceSchema;
  }>
> => {
  return _axios.get(
    `/processes/running-instances/${processInstanceId}/schema`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
