// React
import React, { useContext, useEffect, useRef, useState } from "react";
// React

// CSS
import styles from "./CustomFormioComponentDataReceiverModal.module.css";
// CSS

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Utils
import CustomInput1 from "../../CustomInput1/CustomInput1";
import CustomCheckbox from "../../CustomCheckbox/CustomCheckbox";
// Utils

// Components
import MapModal from "./MapModal/MapModal";
import { defaultLat, defaultLon } from "../../../Constants/constants";
// Components

type CustomFormioComponentDataReceiverModalProps = {
  setIsModalInScreen: React.Dispatch<React.SetStateAction<boolean>>;
  dataSetter: (data: any) => void;
  modalType: string;
};

const CustomFormioComponentDataReceiverModal: React.FunctionComponent<
  CustomFormioComponentDataReceiverModalProps
> = ({ setIsModalInScreen, modalType, dataSetter }) => {
  const bgRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const isNightModeOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!bgRef.current) return;
      if (!contentRef.current) return;

      bgRef.current.style.opacity = "0.7";
      contentRef.current.style.top = "0px";
    }, 50);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  const closeModal = () => {
    if (!bgRef.current) return;
    if (!contentRef.current) return;

    bgRef.current.style.opacity = "0";
    contentRef.current.style.top = "100vh";

    setTimeout(() => {
      setIsModalInScreen(false);
    }, 500);
  };

  const [customData, setCostomData] = useState<{
    data: string;
    className: string;
  }>({ data: "", className: "" });

  const [mapDetails, setMapDetails] = useState<{
    circleData: {
      isActive: boolean;
      lat: string;
      lon: string;
      circleRadius: number;
    };
    mapData: {
      lat: string;
      lon: string;
      markerText: string;
    };
  }>({
    circleData: {
      isActive: false,
      lat: String(defaultLat),
      lon: String(defaultLon),
      circleRadius: 500,
    },
    mapData: {
      lat: String(defaultLat),
      lon: String(defaultLon),
      markerText: "تست مارکر",
    },
  });

  const [complexPersianDate, setComplexPersianDate] = useState<{
    maxDate: string;
    minDate: string;
  }>({
    maxDate: "",
    minDate: "",
  });

  const [isMapModalOpen, setIsMapModalOpen] = useState<boolean>(false);

  return (
    <>
      {isMapModalOpen ? (
        <MapModal
          setThisModalInScreen={setIsMapModalOpen}
          parentCloseModal={closeModal}
          setMapDetails={setMapDetails}
          mapDetails={mapDetails}
        />
      ) : null}

      <div
        className={`${styles.customFormIoComponentDataReceiverModalContainer} 
      w-100 d-flex align-items-center justify-content-center`}
      >
        <div
          className={`${styles.bgRef}`}
          ref={bgRef}
          onClick={() => {
            closeModal();
          }}
        ></div>
        <div
          className={`${styles.content} ${isNightModeOn ? "nightBg2" : ""}`}
          ref={contentRef}
        >
          <div
            className={`${styles.dataReceiver} 
           w-100 d-flex align-items-center justify-content-between flex-column`}
          >
            {/* <CustomInput1
            inputType="text"
            onBlur={() => {}}
            onChange={(e) => {
              setCostomData((prevState) => ({
                ...prevState,
                className: e.target.value,
              }));
            }}
            placeHolder="نام کلاس مد نظر خود را وارد کنید"
            value={customData.className}
            dontShowSearchIcon
          /> */}

            <p className="mb-2">این کامپوننت در آخر فرم افزوده میشود</p>
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {modalType === "CUSTOM_IMAGE_COMPONENT" ? (
              <CustomInput1
                inputType="text"
                onBlur={() => {}}
                onChange={(e) => {
                  setCostomData((prevState) => ({
                    ...prevState,
                    data: e.target.value,
                  }));
                }}
                placeHolder="آدرس / دیتا -- را وارد کنید"
                value={customData.data}
                defaultValue={customData.data}
                dontShowSearchIcon
                containerClassName="mt-3"
              />
            ) : null}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {modalType === "CUSTOM_MAP_COMPONENT" ? (
              <>
                <CustomInput1
                  defaultValue={mapDetails.mapData.lat}
                  inputType="text"
                  onBlur={() => {}}
                  onChange={(e) => {
                    setMapDetails((prevState) => ({
                      ...prevState,
                      mapData: {
                        ...prevState.mapData,
                        lat: e.target.value,
                      },
                    }));
                  }}
                  placeHolder="lat"
                  value={mapDetails.mapData.lat}
                  dontShowSearchIcon
                />
                <CustomInput1
                  defaultValue={mapDetails.mapData.lon}
                  inputType="text"
                  onBlur={() => {}}
                  onChange={(e) => {
                    setMapDetails((prevState) => ({
                      ...prevState,
                      mapData: {
                        ...prevState.mapData,
                        lon: e.target.value,
                      },
                    }));
                  }}
                  placeHolder="lon"
                  value={mapDetails.mapData.lon}
                  dontShowSearchIcon
                  containerClassName="mt-2"
                />

                {/* {mapDetails.circleData.isActive ? (
                  <>
                    <CustomInput1
                      defaultValue={mapDetails.circleData.lat}
                      inputType="text"
                      onBlur={() => {}}
                      onChange={(e) => {
                        setMapDetails((prevState) => ({
                          ...prevState,
                          circleData: {
                            ...prevState.circleData,
                            lat: e.target.value,
                          },
                        }));
                      }}
                      placeHolder="circleLat"
                      value={mapDetails.circleData.lat}
                      dontShowSearchIcon
                      containerClassName="mt-2"
                    />
                    <CustomInput1
                      defaultValue={mapDetails.circleData.lon}
                      inputType="text"
                      onBlur={() => {}}
                      onChange={(e) => {
                        setMapDetails((prevState) => ({
                          ...prevState,
                          circleData: {
                            ...prevState.circleData,
                            lon: e.target.value,
                          },
                        }));
                      }}
                      placeHolder="circleLon"
                      value={mapDetails.circleData.lon}
                      dontShowSearchIcon
                      containerClassName="mt-2"
                    />
                  </>
                ) : null} */}

                <CustomCheckbox
                  isChecked={mapDetails.circleData.isActive}
                  onChange={() => {}}
                  onClick={() => {
                    setMapDetails((prevState) => ({
                      ...prevState,
                      circleData: {
                        ...prevState.circleData,
                        isActive: !prevState.circleData.isActive,
                      },
                    }));
                  }}
                  title="نمایش حیطه"
                  containerClassName="w-100 my-4"
                />
              </>
            ) : null}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {modalType === "CUSTOM_FORMIO_COMPLEX_PERSIAN_DATE_PICKER" ? (
              <>
                <CustomInput1
                  defaultValue=""
                  inputType="text"
                  onBlur={() => {}}
                  onChange={(e) => {
                    setComplexPersianDate((prevState) => ({
                      ...prevState,
                      minDate: e.target.value,
                    }));
                  }}
                  placeHolder="حداقل تاریخ : YYYY/MM/DD"
                  dontShowSearchIcon
                  value={complexPersianDate.minDate}
                />
                <CustomInput1
                  defaultValue=""
                  inputType="text"
                  onBlur={() => {}}
                  onChange={(e) => {
                    setComplexPersianDate((prevState) => ({
                      ...prevState,
                      maxDate: e.target.value,
                    }));
                  }}
                  placeHolder="حداکثر تاریخ : YYYY/MM/DD"
                  value={complexPersianDate.maxDate}
                  dontShowSearchIcon
                  containerClassName="mt-2"
                />
              </>
            ) : null}

            <div className="w-100 d-flex flex-row align-items-center justify-content-between mt-4">
              <button
                className={`${
                  modalType !== "CUSTOM_MAP_COMPONENT" ? "w-100 " : "w-25 "
                }  operationEvent d-flex flex-row align-items-center justify-content-center submitBtn `}
                onClick={() => {
                  const P = Promise.resolve(
                    dataSetter({
                      ...customData,
                      modalType,
                      moreData: {
                        mapDetails: {
                          ...mapDetails.mapData,
                          ...mapDetails.circleData,
                        },
                        circleData: {
                          ...mapDetails.circleData,
                        },
                      },
                      data: complexPersianDate,
                    })
                  );

                  P.then(() => {
                    closeModal();
                  });
                }}
              >
                تایید
              </button>

              {modalType === "CUSTOM_MAP_COMPONENT" ? (
                <button
                  className="operationEvent submitBtn"
                  onClick={() => {
                    setIsMapModalOpen(true);
                  }}
                >
                  نمایش نقشه
                </button>
              ) : null}
            </div>
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomFormioComponentDataReceiverModal;
