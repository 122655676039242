// Redux
import { configureStore } from "@reduxjs/toolkit";
import { Middleware } from "@reduxjs/toolkit";
// Redux

// React_Redux_Connector
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
// React_Redux_Connector

// Slices
import { loginSlice } from "../Features/LoginSlice/loginSlice";
import { rolesSlice } from "../Features/RolesSlice/RolesSlice";
import { currLanguageSlice } from "../Features/CurrLanguageSlice/currLanguageSlice";
import { userInformationSlice } from "../Features/UserInformationSlice/userInformationSlice";
import { accessibleUsersListSlice } from "../Features/AccessibleUsersListSlice/AccessibleUsersListSlice";
import { singleAccessibleUserSlice } from "../Features/SingleAccessibleUserSlice/singleAccessibleUserSlice";
import { singleUserAccessibleProcessSlice } from "../Features/SingleUserAccessibleProcessSlice/SingleUserAccessibleProcessSlice";
import { singleUserAccessibleGroupSlice } from "../Features/SingleUserAccessibleGroupSlice/singleUserAccessibleGroupSlice";
import { possibleAccessibleGroupsForSingleUserSlice } from "../Features/PossibleAccessibleGroupsForSingleUserSlice/possibleAccessibleGroupsForSingleUserSlice";
import { processesSlice } from "../Features/ProcessesSlice/ProcessesSlice";
import { formsSlice } from "../Features/FormsSlice/FormsSlice";
import { groupSlice } from "../Features/GroupSlice/groupSlice";
import { searchAndBackSlice } from "../Features/SearchAndBackSlice/searchAndBackSlice";
import { possibleGroupMemberShipsSlice } from "../Features/PossibleGroupMemberShipsSlice/possibleGroupMemberShipsSlice";
import { taskSlice } from "../Features/TasksSlice/tasksSlice";
import { serverManagementSlice } from "../Features/ServerManagementSlice/ServerManagementSlice";
import { reportManagementSlice } from "../Features/ReportManagementSlice/reportManagementSlice";
import { tenantsSlice } from "../Features/TenantsSlice/tenantsSlice";
import { userIdentitiesSlice } from "../Features/userIdentitiesSlice/userIdentitiesSlice";
import { callOfRegistrationSlice } from "../Features/CallOfRegistrationSlice/callOfRegistrationSlice";
import { loadingSlice } from "../Features/LoadingSlice/loadingSlice";
import { postsSlice } from "../Features/PostsSlice/postsSlice";
import { securityEventSlice } from "../Features/SecurityEvents/securityEventsSlice";
import { formSamplesSlice } from "../Features/FormSamples/formSamplesSlice";
import { allocatedProcessesSlice } from "../Features/AllocatedProcessesSlice/allocatedProcessesSlice";
import { processInstanceHistorySlice } from "../Features/ProcessesSlice/ProcessInstanceHistorySlice/processInstanceHistorySlice";
// Slices

// Utils
import { customizedToast } from "../Utils/CustomizedToast/customizedToast";
// Utils

// Middlewares
const scrollMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action);

  const masterContainerChildrenContainer = document.getElementById(
    "perfectScrollbarCustomId"
  );
  if (masterContainerChildrenContainer) {
    masterContainerChildrenContainer.style.scrollBehavior = "smooth";
    masterContainerChildrenContainer.scrollTo({ top: 0 });
  }

  return result;
};
//
const ngProgressMiddleware: Middleware =
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type.endsWith("/pending")) {
      dispatch(loadingSlice.actions.setLoadingTrue());
    } else if (
      action.type.endsWith("/fulfilled") ||
      action.type.endsWith("/rejected")
    ) {
      dispatch(loadingSlice.actions.setLoadingFalse());
    }

    return next(action);
  };
//
export const errorHandling: Middleware =
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    if (action.type.endsWith("/rejected")) {
      if (action.error) {
        if (
          action.error.code === "ERR_BAD_RESPONSE" ||
          (action.error.message.includes("500") &&
            !window.location.href.includes("login"))
        ) {
          customizedToast(
            "برای اجرای درخواست شما مشکلی سمت سرور رخ داده است",
            "error"
          );
        }
        if (
          action.error.code === "ERR_BAD_REQUEST" ||
          (action.error.message.includes("400") &&
            !window.location.href.includes("login"))
        ) {
          customizedToast("لطفا ورودی های خود را کنترل کنید", "error");
        }
      }
    }
    return next(action);
  };
// Middlewares

// Store
export const store = configureStore({
  reducer: {
    loading: loadingSlice.reducer,
    loginData: loginSlice.reducer,
    allRoles: rolesSlice.reducer,
    currLanguage: currLanguageSlice.reducer,
    userInformation: userInformationSlice.reducer,
    accessibleUsers: accessibleUsersListSlice.reducer,
    possibleGroupMembership: possibleGroupMemberShipsSlice.reducer,
    singleAccessibleUser: singleAccessibleUserSlice.reducer,
    singleUserAccessibleProcess: singleUserAccessibleProcessSlice.reducer,
    singleUserAccessibleGroup: singleUserAccessibleGroupSlice.reducer,
    possibleAccessibleGroupsForSingleUser:
      possibleAccessibleGroupsForSingleUserSlice.reducer,
    forms: formsSlice.reducer,
    processes: processesSlice.reducer,
    groups: groupSlice.reducer,
    searchAndBack: searchAndBackSlice.reducer,
    tasks: taskSlice.reducer,
    servers: serverManagementSlice.reducer,
    reportManagement: reportManagementSlice.reducer,
    tenants: tenantsSlice.reducer,
    userIdentities: userIdentitiesSlice.reducer,
    callOfRegistration: callOfRegistrationSlice.reducer,
    posts: postsSlice.reducer,
    securityEvent: securityEventSlice.reducer,
    formSamples: formSamplesSlice.reducer,
    allocatedProcesses: allocatedProcessesSlice.reducer,
    processInstanceHistorySlice: processInstanceHistorySlice.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(scrollMiddleware)
      .concat(ngProgressMiddleware)
      .concat(errorHandling),
});
// Store

// CUSTOM STORE HOOKS FOR BETTER NAME CONVENTION ;)
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;
// CUSTOM STORE HOOKS FOR BETTER NAME CONVENTION (;
