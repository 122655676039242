// React
import React, { useCallback, useContext, useEffect, useRef } from "react";
// React

// CSS
import styles from "./ProcessSelectModal.module.css";
// CSS

// Models
import { IsNightModeOnContext } from "../../../../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import { Link, useNavigate } from "react-router-dom";
import {
  initialProcessHistoryContextData,
  SetProcessHistoryContext,
} from "../../../../../../../Contexts/ProcessHistoryContext/ProcessHistoryContext";
import {
  nextAndPrevButtonClickEventInhProcessHistory,
  openProcessInThisTabEvent,
  processHistoryResetEvent,
} from "../../../../../../../App";
import { processInstanceHistorySlice } from "../../../../../../../Features/ProcessesSlice/ProcessInstanceHistorySlice/processInstanceHistorySlice";
import { useAppDispatch } from "../../../../../../../app/store";
import { ProcessSelectModalData } from "../../HistoryTabItem";
// Models

type ProcessSelectModalProps = {
  modalData: ProcessSelectModalData;
  setModalData: React.Dispatch<React.SetStateAction<ProcessSelectModalData>>;
};

const ProcessSelectModal: React.FunctionComponent<ProcessSelectModalProps> = ({
  modalData,
  setModalData,
}) => {
  const dispatch = useAppDispatch();

  const outerContentRef = useRef<HTMLDivElement>(null);
  const innerContentRef = useRef<HTMLDivElement>(null);

  const isNightOn = useContext(IsNightModeOnContext);

  const setProcessData = useContext(SetProcessHistoryContext);

  const navigate = useNavigate();

  const closeModal = useCallback(() => {
    if (!outerContentRef.current) return;
    if (!innerContentRef.current) return;

    outerContentRef.current.style.opacity = "0";
    innerContentRef.current.style.top = "100vh";

    setTimeout(() => {
      setModalData({
        isModalInScreen: false,
        processId: "",
      });
    }, 500);
  }, [setModalData]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!outerContentRef.current) return;
      if (!innerContentRef.current) return;

      outerContentRef.current.style.opacity = "0.75";
      innerContentRef.current.style.top = "0px";
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  const openInThisTab = useCallback(() => {
    setProcessData(initialProcessHistoryContextData);
    dispatchEvent(processHistoryResetEvent);
    dispatchEvent(openProcessInThisTabEvent);
    dispatchEvent(nextAndPrevButtonClickEventInhProcessHistory);
    dispatch(processInstanceHistorySlice.actions.resetStepsTab());
    dispatch(processInstanceHistorySlice.actions.resetActivityInformation());
    dispatch(processInstanceHistorySlice.actions.resetHistoryDetail());

    navigate(
      `/processes/show-instance-data-by-instance-id/${modalData.processId}`
    );
  }, [navigate, dispatch, setProcessData, modalData.processId]);

  useEffect(() => {
    window.addEventListener("popstate", openInThisTab);

    return () => {
      window.removeEventListener("popstate", openInThisTab);
    };
  }, [openInThisTab]);

  return (
    <div className={`${styles.processSelectModalContainer} `}>
      <div
        className={`${styles.outerContent}`}
        ref={outerContentRef}
        onClick={closeModal}
      ></div>
      <div
        className={`${
          styles.innerContent
        } d-flex flex-column align-items-center ${
          isNightOn ? "nightBg1" : ""
        } `}
        ref={innerContentRef}
      >
        <p className={`${styles.topName}`}>
          فرآیند انتخاب شده یک زیرفرآیند می‌باشد
        </p>
        <span>
          تمایل دارید که این زیرفرآیند در این صفحه یا در تب جدید بازشود؟
        </span>

        <div
          className={`${styles.btns} d-flex flex-row-reverse align-items-center mt-4 gap-2`}
        >
          <button className="submitBtn_important" onClick={openInThisTab}>
            بازکردن در این صفحه
          </button>
          <Link
            className={`${styles.newTabBtn}`}
            to={`/processes/show-instance-data-by-instance-id/${modalData.processId}`}
            target="_blank"
          >
            بازکردن در تب جدید
          </Link>
          <button onClick={closeModal}>انصراف</button>
        </div>
      </div>
    </div>
  );
};

export default ProcessSelectModal;
