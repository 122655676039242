// React
import React, { useContext } from "react";
// React

// CSS
import styles from "./../Home.module.css";
// CSS

// Utils
import { Spinner } from "reactstrap";
// Utils

// Icons
import { CgDetailsMore } from "react-icons/cg";
import { MdDone } from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
// Icons

// Redux
import { useAppSelector } from "../../../app/store";
// Redux

// RRD
import { useNavigate } from "react-router-dom";
// RRD

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

type HomePageTasksProps = {
  containerClassName?: string;
};

const HomePageTasks: React.FunctionComponent<HomePageTasksProps> = ({
  containerClassName,
}) => {
  const currLanguage = useAppSelector((state) => state.currLanguage.language);

  const isNightModeOn = useContext(IsNightModeOnContext);

  const tasksData = useAppSelector((state) => state.tasks.taskData);

  const navigate = useNavigate();

  return (
    <div className={`${containerClassName}`}>
      {!tasksData.isPending && (
        <p className="w-100 d-flex align-items-center justify-content-end mb-4 bold ">
          وظایف
        </p>
      )}

      {tasksData.isPending ? (
        <div className="w-100 d-flex flex-column align-items-center mt-2 justify-content-center">
          <p>در حال بارگیری</p>
          <Spinner />
        </div>
      ) : (
        <div
          className={`${styles.myTable} ${
            isNightModeOn ? "nightBg2 pcNight" : ""
          }  w-100 d-flex flex-column align-items-center align-items-center`}
        >
          <div
            className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
          >
            <p className={`${styles.operationTab}`}>عملیات</p>
            <p className={`${styles.priorityTab}`}>اولویت</p>
            <p className={`${styles.description}`}>توضیحات</p>
            <p className={`${styles.followUpdate}`}>بروزرسانی</p>
            <p className={`${styles.taskName}`}>نام وظیفه</p>
          </div>
          <div
            className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
          >
            {tasksData.content.length === 0 ? (
              <p className="mt-4">موردی وجود ندارد</p>
            ) : (
              <>
                {tasksData.content
                  .filter((item, index) => index <= 3)
                  .map((item) => (
                    <div
                      key={item.taskId}
                      className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                    >
                      <p className={`${styles.operationTab}`}>
                        <button
                          className={` operationEvent editBtn`}
                          onClick={() => {
                            navigate(`/tasks/task-detail/${item.taskId}`);
                          }}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={currLanguage.more}
                        >
                          <CgDetailsMore />
                        </button>
                      </p>
                      <p
                        className={`${styles.priorityTab}`}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={
                          item.priorityType.title.length >= 20
                            ? item.priorityType.title
                            : ""
                        }
                      >
                        {item.priorityType.title.length >= 20
                          ? "..." + item.priorityType.title.substring(0, 19)
                          : item.priorityType.title}
                      </p>
                      <p className={`${styles.description}`}>
                        {item.description || "ندارد"}
                      </p>
                      <p className={`${styles.followUpdate}`}>
                        {item.followUpDate ? (
                          <MdDone
                            style={{
                              fontSize: "2rem",
                            }}
                          />
                        ) : (
                          <BsDashLg
                            style={{
                              fontSize: "2rem",
                            }}
                          />
                        )}
                      </p>
                      <p
                        className={`${styles.taskName}`}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={
                          item.taskTitle.length >= 20 ? item.taskTitle : ""
                        }
                      >
                        {item.taskTitle.length >= 20
                          ? "..." + item.taskTitle.substring(0, 19)
                          : item.taskTitle}
                      </p>
                    </div>
                  ))}
              </>
            )}
          </div>
          <div className={`${styles.tableFooter}`}></div>
        </div>
      )}
    </div>
  );
};

export default HomePageTasks;
