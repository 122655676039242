import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
import { I_Message } from "../../../Models/interfaces";

export const dataServerGenerateNewTokenService = (
  userToken: string,
  dataServerId: string
): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {
      passcode: string;
    };
  }>
> => {
  return _axios.post(
    `/data-servers/${dataServerId}/regenerate-passcode`,
    { dataServerId },
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
