// React
import React, { useRef } from "react";
// React

// CSS
import styles from "./LoadFormFROMsampleOrAddToCurrentForm.module.css";
// CSS

// Contexts
// Contexts

// Utils
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import CustomSelectOption2 from "../../Utils/CustomSelectOption2/CustomSelectOption2";
// Utils

// Redux
import { useAppSelector } from "../../app/store";
// Redux

type LoadFormFROMsampleOrAddToCurrentFormProps = {
  setIsReadInputPending: React.Dispatch<React.SetStateAction<boolean>>;
  setForm: React.Dispatch<React.SetStateAction<any>>;
  form: any;
  setIsModalInScreen: React.Dispatch<React.SetStateAction<boolean>>;
  modalData: {
    selectOptionValue: string;
    formUrl: string;
  };
  setModalData: React.Dispatch<
    React.SetStateAction<{
      selectOptionValue: string;
      formUrl: string;
    }>
  >;
};

const LoadFormFROMsampleOrAddToCurrentForm: React.FunctionComponent<
  LoadFormFROMsampleOrAddToCurrentFormProps
> = ({
  setIsReadInputPending,
  setForm,
  form,
  setIsModalInScreen,
  modalData,
  setModalData,
}) => {
  // constants
  // constants

  //   Refs
  const inputRef = useRef<HTMLInputElement>(null);
  //   Refs

  // Redux Store Data
  const {
    data,
    isError: isFormSamplesError,
    isPending: isFormSamplePending,
  } = useAppSelector((state) => state.formSamples.formSamples);
  // Redux Store Data

  // Methods
  const fileReader = (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      customizedToast("فایل درست بارگذاری نشد", "error");
      return;
    }
    setIsReadInputPending(true);
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const content = e.target.result;
      try {
        const parsedJsonData = JSON.parse(content);
        if (parsedJsonData.display !== "form") {
          setIsReadInputPending(false);
          throw new Error("");
        }
        setForm(parsedJsonData);
        setIsReadInputPending(false);
      } catch (error) {
        setIsReadInputPending(false);
        customizedToast("فایل بارگذاری شده با فرمت ما مطابقت ندارد", "error");
        console.error("Error parsing JSON file:", error);
      }
    };
    reader.readAsText(file);
  };
  // Methods

  // Contexts
  // Contexts

  return (
    <div
      className={`${styles.loadFormFromSampleOrAddToCurrentFormContainer} w-100`}
    >
      <div
        className={`d-flex flex-row-reverse align-items-center justify-content-between my-2 ${styles.jsonInputContainer} `}
      >
        <input
          type="file"
          accept=".json,application/json"
          onChange={(e) => {
            fileReader(e);
          }}
          ref={inputRef}
          style={{
            display: "none",
          }}
        />
        <button
          onClick={() => {
            if (!inputRef.current) return;
            inputRef.current.click();
          }}
          className={` operationEvent submitBtn  ${styles.loadFormBtn}  ${
            form?.display === "form" ? "disabled" : ""
          } `}
        >
          JSON آپلود
        </button>
        <p
          style={{
            margin: "unset",
          }}
        >
          فرم آماده برای آپلود دارید؟
        </p>
      </div>

      <div
        className={`${styles.templateSelectorContainer}  d-flex flex-row align-items-center justify-content-between `}
      >
        {/* <div className="d-flex flex-row-reverse align-items-center justify-content-start">
          <CustomSelectOption2
            getSelectedOption={(data) => {
              setModalData({
                formUrl: String(data),
                selectOptionValue: String(data),
              });
            }}
            initialTitle="لطفا یکی از تمپلیت ها را انتخاب کنید"
            initialValue={""}
            options={(() => {
              if (!isFormSamplePending && !isFormSamplesError) {
                return data.content.map((item) => ({
                  title: item.title,
                  value: item.url,
                }));
              } else if (isFormSamplePending && !isFormSamplesError) {
                return [
                  {
                    title: "در حال بارگذاری",
                    value: "",
                  },
                ];
              } else {
                return [
                  {
                    title: "دوباره تلاش کنید",
                    value: "",
                  },
                ];
              }
            })()}
          />

          <button
            className="operationEvent submitBtn"
            style={{
              marginRight: "8px",
            }}
            onClick={() => {
              setModalData({
                formUrl: "",
                selectOptionValue: "",
              });
              dispatch(
                getAsyncFormSamples({
                  userToken,
                  _data: {
                    page: 1,
                    search: "",
                    size: 999,
                    type: "Form-IO",
                    tenant: selectedTenant.tenantId,
                  },
                })
              );
            }}
          >
            بارگذاری مجدد گزینه ها
          </button>
        </div> */}

        <CustomSelectOption2
          getSelectedOption={(data) => {
            setModalData({
              formUrl: String(data),
              selectOptionValue: String(data),
            });
          }}
          initialTitle="انتخاب فرم پیش ساخته"
          initialValue={""}
          options={(() => {
            if (!isFormSamplePending && !isFormSamplesError) {
              return data.content.map((item) => ({
                title: item.title,
                value: item.url,
              }));
            } else if (isFormSamplePending && !isFormSamplesError) {
              return [
                {
                  title: "در حال بارگذاری",
                  value: "",
                },
              ];
            } else {
              return [
                {
                  title: "دوباره تلاش کنید",
                  value: "",
                },
              ];
            }
          })()}
          containerStyle={{
            direction: "ltr",
            zIndex: "3000",
          }}
        />
        <button
          className="operationEvent submitBtn"
          onClick={() => {
            if (modalData.selectOptionValue === "") return;
            setIsModalInScreen(true);
          }}
        >
          افزودن به فرم فعلی
        </button>
      </div>
    </div>
  );
};

export default LoadFormFROMsampleOrAddToCurrentForm;
