// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./AddUser.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { accessibleUsersListSlice } from "../../../Features/AccessibleUsersListSlice/AccessibleUsersListSlice";
// Redux

// Modules
import { useNavigate } from "react-router-dom";
// Modules

// Utils
// Utils

// Components
import PersonalTab from "./PersonalTab/PersonalTab";
import GroupsTab from "./GroupsTab/GroupsTab";
// Components

// Redux
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
// Redux

// Utils
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
// Utils

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Formats
import { mailformat } from "../../../Formats/formats";
import ExtraIdentityTabInAddUser from "./ExtraIdentityTabInAddUser/ExtraIdentityTabInAddUser";
// Formats

const AddUser = () => {
  const isNightModeOn = useContext(IsNightModeOnContext);

  const [newUserData, setNewUserData] = useState<{
    tenantId: string;
    firstname: string;
    lastname: string;
    email: string;
    username: string;
    password: string;
    retypePassword?: string;
    extraIdentityInformation: {
      [key: string]: any;
    };
    groupMembershipToAdd: {
      groupId: string;
    }[];
  }>({
    tenantId: "",
    firstname: "",
    lastname: "",
    email: "",
    username: "",
    password: "",
    extraIdentityInformation: {},
    groupMembershipToAdd: [],
  });
  //
  const [selectedTab, setSelectedTab] = useState<
    "personal" | "groups" | "extraIdentity"
  >("personal");
  //
  const [paginationData, setPaginationData] = useState<{
    currPage: number;
    pageSize: number;
    tenantId: string;
    search: string;
  }>({
    currPage: 1,
    pageSize: 10,
    search: "",
    tenantId: "",
  });
  //
  //
  //
  //
  //

  const dispatch = useAppDispatch();
  const { isUserCreated } = useAppSelector((state) => state.accessibleUsers);
  const navigate = useNavigate();

  const [groupsForChecking, setGroupsForChecking] = useState<string[]>([
    "Active_Users",
  ]);

  const handleChangeValue = (key: string, value: string) => {
    setNewUserData((prevState) => ({
      ...prevState,
      extraIdentityInformation: {
        ...prevState.extraIdentityInformation,
        [key]: value,
      },
    }));
  };

  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("افزودن کاربر"));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(accessibleUsersListSlice.actions.resetIsUserCreated());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isUserCreated === false) return;
    navigate("/users");
  }, [isUserCreated, navigate]);

  return (
    <div className={`${styles.profileContainer} w-100 d-flex flex-column`}>
      <div
        className={`${styles.topButtons}  ${
          isNightModeOn ? "nightBg2" : ""
        } d-flex flex-row align-items-center justify-content-around my-4 align-self-end flex-wrap`}
        style={{
          width: "max-content",
        }}
      >
        <button
          className={` ${isNightModeOn ? "nightBg1" : ""}`}
          onClick={() => {
            if (newUserData.firstname.trim().length < 2) {
              customizedToast("طول نام از حد مجاز کمتر است", "error");
              return;
            }
            if (newUserData.lastname.trim().length < 2) {
              customizedToast("طول نام خانوادگی از حد مجاز کمتر است", "error");
              return;
            }
            if (!mailformat.test(newUserData.email)) {
              customizedToast("ایمیل ورودی صحیح نیست", "error");
              return;
            }
            if (newUserData.username.trim().length < 5) {
              customizedToast("طول نام کاربری از حد مجاز کمتر است", "error");
              return;
            }
            if (newUserData.password !== newUserData.retypePassword) {
              customizedToast("رمز عبور و تکرار آن یکسان نیست", "error");
              return;
            }
            setSelectedTab("extraIdentity");
          }}
          style={{
            boxShadow:
              selectedTab === "extraIdentity"
                ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                : "",
            padding: "8px 24px",
            border:
              selectedTab === "extraIdentity" && isNightModeOn
                ? "1px solid #fff"
                : "",
          }}
        >
          فیلد های هویتی
        </button>
        <button
          className={`  ${isNightModeOn ? "nightBg1" : ""}`}
          onClick={() => {
            if (newUserData.firstname.trim().length < 2) {
              customizedToast("طول نام از حد مجاز کمتر است", "error");
              return;
            }
            if (newUserData.lastname.trim().length < 2) {
              customizedToast("طول نام خانوادگی از حد مجاز کمتر است", "error");
              return;
            }
            if (!mailformat.test(newUserData.email)) {
              customizedToast("ایمیل ورودی صحیح نیست", "error");
              return;
            }
            if (newUserData.username.trim().length < 5) {
              customizedToast("طول نام کاربری از حد مجاز کمتر است", "error");
              return;
            }
            if (newUserData.password !== newUserData.retypePassword) {
              customizedToast("رمز عبور و تکرار آن یکسان نیست", "error");
              return;
            }
            setSelectedTab("groups");
          }}
          style={{
            boxShadow:
              selectedTab === "groups"
                ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                : "",
            padding: "8px 24px",
            border:
              selectedTab === "groups" && isNightModeOn ? "1px solid #fff" : "",
          }}
        >
          انتخاب گروه ها
        </button>
        <button
          className={` ${isNightModeOn ? "nightBg1" : ""}`}
          onClick={() => {
            setSelectedTab("personal");
          }}
          style={{
            boxShadow:
              selectedTab === "personal"
                ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                : "",
            padding: "8px 24px",
            border:
              selectedTab === "personal" && isNightModeOn
                ? "1px solid #fff"
                : "",
          }}
        >
          مشخصات کاربر
        </button>
      </div>

      <div className={`w-100 d-flex flex-column ${styles.tabContainers}`}>
        {selectedTab === "personal" ? (
          <PersonalTab
            handleChangeValue={handleChangeValue}
            newUserData={newUserData}
            setNewUserData={setNewUserData}
            setSelectedTab={setSelectedTab}
          />
        ) : null}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {selectedTab === "groups" ? (
          <GroupsTab
            groupsForChecking={groupsForChecking}
            paginationData={paginationData}
            setGroupsForChecking={setGroupsForChecking}
            setPaginationData={setPaginationData}
            setSelectedTab={setSelectedTab}
          />
        ) : null}

        {selectedTab === "extraIdentity" ? (
          <ExtraIdentityTabInAddUser
            setNewUserData={setNewUserData}
            userData={newUserData}
            groupsForChecking={groupsForChecking}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AddUser;
