// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../Base_Axios_Config/_axios";
// Axios

// Models
import { I_Message } from "../../Models/interfaces";
// Models

export const addNewOptionToSingleIdentityAttributeService = async (_data: {
  userToken: string;
  attributeIdOrKey: string;
  _data: string;
  tenant: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {
      id: 0;
      title: "string";
    };
  }>
> => {
  return _axios.post(
    `/users/identity/attributes/${_data.attributeIdOrKey}/options/add?tenant=${_data.tenant}`,
    { title: _data._data },
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
