import { createContext } from "react";
import {
  I_ActivityInformationContent,
  processActivityStep,
} from "../../Models/interfaces";

export type T_HistoryType = "userTask" | "callActivity" | "";

export type T_ProcessHistoryContext = {
  allHistory: I_ActivityInformationContent[];
  selectedHistoryId: string;
  selectedHistoryType: T_HistoryType;
  allSteps: processActivityStep;
  selectedDiagramId: string;
  bpmnReference: any;
  clickedElementId: string;
  _stepNumber: number;
  isTopItemsDisabled: boolean;
  // for reset this context use || processHistoryReset || event
};

export const initialProcessHistoryContextData: T_ProcessHistoryContext = {
  allHistory: [],
  selectedHistoryId: "",
  selectedHistoryType: "",
  allSteps: [],
  selectedDiagramId: "",
  bpmnReference: null,
  clickedElementId: "",
  _stepNumber: 1,
  isTopItemsDisabled: false,
};

export const ProcessHistoryContext = createContext<T_ProcessHistoryContext>(
  initialProcessHistoryContextData
);

export const SetProcessHistoryContext = createContext<
  React.Dispatch<React.SetStateAction<T_ProcessHistoryContext>>
>(() => {});
