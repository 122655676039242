// React
import React, { useContext, useEffect } from "react";
// React

// CSS
import styles from "./Home.module.css";
import "./swiperStyles.css";
// CSS

// Redux
import { searchAndBackSlice } from "../../Features/SearchAndBackSlice/searchAndBackSlice";
import { getAsyncTasks, taskSlice } from "../../Features/TasksSlice/tasksSlice";
import {
  getAsyncAllGroups,
  groupSlice,
} from "../../Features/GroupSlice/groupSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  accessibleUsersListSlice,
  getAsyncAccessibleUsers,
} from "../../Features/AccessibleUsersListSlice/AccessibleUsersListSlice";
import {
  getAsyncProcessesList,
  processesSlice,
} from "../../Features/ProcessesSlice/ProcessesSlice";
import {
  getAsyncServerData,
  serverManagementSlice,
} from "../../Features/ServerManagementSlice/ServerManagementSlice";
// Redux

// Modules
import { permitionCheckerWithoutToast } from "../../Modules/permitionCheckerWithoutToast";
// Modules

// Contexts
import {
  SetUserGroupsContext,
  UserGroupsContext,
} from "../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Utils
import { Spinner } from "reactstrap";
import { MdNavigateNext } from "react-icons/md";
// Utils

// Components
import HomeWidgets from "./HomeWidgets/HomeWidgets";
// Components

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  getAsyncAllPostsData,
  postsSlice,
} from "../../Features/PostsSlice/postsSlice";
import HTMLReactParser from "html-react-parser";
import {
  getAsyncSecurityEvent,
  securityEventSlice,
} from "../../Features/SecurityEvents/securityEventsSlice";
import HomePageTasks from "./HomePageTasks/HomePageTasks";
import HomePageAllocatedProcesses from "./HomePageAllocatedProcesses/HomePageAllocatedProcesses";
// Swiper

const Home = () => {
  const dispatch = useAppDispatch();

  const allGroups = useContext(UserGroupsContext);
  const setUserGroups = useContext(SetUserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightModeOn = useContext(IsNightModeOnContext);

  const { postsData } = useAppSelector((state) => state.posts);

  const userGroups = useAppSelector((state) => state.userInformation.allGroups);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  useEffect(() => {
    if (!userGroups) return;
    setUserGroups(userGroups);
  }, [setUserGroups, userGroups]);

  useEffect(() => {
    if (!isFullRenderd) return;
    dispatch(searchAndBackSlice.actions.changeCurrLocation("خانه"));
  }, [dispatch, isFullRenderd]);

  useEffect(() => {
    if (!userGroups.includes("Active_Users")) return;
    dispatch(
      getAsyncTasks({
        _data: {
          page: 1,
          size: 9999,
          descendingSort: false,
          orderByDueDate: true,
          orderByFollowUpDate: false,
          orderByPriority: true,
          search: "",
          orderByCreateDate: false,
        },
        userToken,
      })
    );
  }, [dispatch, userToken, isFullRenderd, userGroups]);

  useEffect(() => {
    if (!isFullRenderd) return;
    if (!userGroups.includes("Active_Users")) return;

    dispatch(
      getAsyncAllGroups({
        userToken,
        _data: {
          page: 1,
          size: 999,
          search: "",
          tenant: "",
        },
      })
    );
  }, [dispatch, userToken, isFullRenderd, userGroups]);

  useEffect(() => {
    if (!isFullRenderd) return;
    if (
      permitionCheckerWithoutToast(
        allGroups,
        [
          "Full_Manager",
          "Tenant_Full_Manager",
          "Tenant_Basic_Manager",
          "User_Editor",
          "User_Information_Reader",
        ],
        isFullRenderd
      ) === true
    ) {
      dispatch(
        getAsyncAccessibleUsers({
          usernames: [],
          userToken,
          editing: false,
          group: "",
          page: 1,
          search: "",
          size: 999,
          tenant: "",
        })
      );
    }
  }, [dispatch, userToken, isFullRenderd, allGroups]);

  useEffect(() => {
    if (!isFullRenderd) return;
    if (
      permitionCheckerWithoutToast(
        allGroups,
        [
          "Tenant_Full_Manager",
          "Tenant_Basic_Manager",
          "Process_Full_Manager",
          "Process_Staff_Editor",
          "Process_Editor",
          "Process_Starter",
          "Process_Schema_Analyzer",
          "Process_Form_Editor",
        ],
        isFullRenderd
      ) === true
    ) {
      dispatch(
        getAsyncProcessesList({
          _data: {
            page: 1,
            search: "",
            size: 999,
            startable: false,
            tenant: "",
          },
          userToken,
        })
      );
    }
  }, [dispatch, userToken, allGroups, isFullRenderd]);

  useEffect(() => {
    if (!isFullRenderd) return;
    if (
      permitionCheckerWithoutToast(
        allGroups,
        ["Tenant_Full_Manager", "Tenant_Basic_Manager", "Settings_Editor"],
        isFullRenderd
      ) === true
    ) {
      dispatch(
        getAsyncServerData({
          userToken,
          _data: {
            page: 1,
            size: 999,
            tenant: "",
          },
        })
      );
    }
  }, [dispatch, userToken, allGroups, isFullRenderd]);

  useEffect(() => {
    if (!isFullRenderd) return;
    dispatch(
      getAsyncAllPostsData({
        userToken: "",
        _data: {
          active: true,
          page: 1,
          placement: "DASHBOARD_NOTIFICATION",
          search: "",
          size: 999,
          tenant: "",
        },
      })
    );
  }, [dispatch, isFullRenderd]);

  useEffect(() => {
    if (!userGroups.includes("Active_Users")) return;
    return () => {
      dispatch(taskSlice.actions.resetTaskPending());
      dispatch(groupSlice.actions.resetAllGroups());

      if (
        permitionCheckerWithoutToast(
          allGroups,
          ["Tenant_Full_Manager", "Tenant_Basic_Manager", "Settings_Editor"],
          isFullRenderd
        ) === true
      ) {
        dispatch(serverManagementSlice.actions.resetServerData());
      }
      //////////////////////
      //////////////////////
      //////////////////////
      //////////////////////
      //////////////////////
      //////////////////////
      //////////////////////
      //////////////////////
      //////////////////////
      if (
        permitionCheckerWithoutToast(
          allGroups,
          [
            "Tenant_Full_Manager",
            "Tenant_Basic_Manager",
            "Process_Full_Manager",
            "Process_Staff_Editor",
            "Process_Editor",
            "Process_Starter",
            "Process_Schema_Analyzer",
            "Process_Form_Editor",
          ],
          isFullRenderd
        ) === true
      ) {
        dispatch(processesSlice.actions.resetProcessListPending());
      }
      //////////////////////
      //////////////////////
      //////////////////////
      //////////////////////
      //////////////////////
      //////////////////////
      //////////////////////
      //////////////////////
      //////////////////////
      if (
        permitionCheckerWithoutToast(
          allGroups,
          [
            "Full_Manager",
            "Tenant_Full_Manager",
            "Tenant_Basic_Manager",
            "User_Editor",
            "User_Information_Reader",
          ],
          isFullRenderd
        ) === true
      ) {
        dispatch(accessibleUsersListSlice.actions.resetAccessibleUserPending());
      }

      dispatch(postsSlice.actions.resetRecevedPostsDataState());
      dispatch(securityEventSlice.actions.resetSecurityEvent());
    };
  }, [allGroups, isFullRenderd, dispatch, userGroups]);

  useEffect(() => {
    if (!isFullRenderd) return;
    if (
      !permitionCheckerWithoutToast(
        allGroups,
        ["Security_Event_Reader", "Security_Event_Manager"],
        isFullRenderd
      )
    )
      return;
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncSecurityEvent({
          userToken,
          _data: {
            effect: "",
            from: "",
            page: 1,
            risk: 1,
            search: "",
            size: 999,
            to: "",
            type: "",
          },
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, allGroups, isFullRenderd, userToken]);

  return (
    <div className={`${styles.homeContaioner} `}>
      {postsData.data.content.length === 0 &&
      !postsData.isError &&
      !postsData.isPending ? (
        <HomeWidgets
          allGroups={allGroups}
          isFullRenderd={isFullRenderd}
          isNightModeOn={isNightModeOn}
          isFull
        />
      ) : (
        <div
          className={`${styles.homeTopItems} w-100 d-flex  align-items-center justify-content-between`}
        >
          <div
            className={`${styles.postAndImageContainer} ${
              isNightModeOn ? "nightBg2" : ""
            } d-flex flex-row align-items-center justify-content-center`}
          >
            {postsData.isPending && !postsData.isError ? (
              <div
                className={`d-flex flex-column align-items-center justify-content-center p-3 ${
                  isNightModeOn ? "nightBg1" : ""
                }`}
                style={{
                  borderRadius: "8px",
                }}
              >
                <p className="">در حال بارگذاری</p>
                <Spinner />
              </div>
            ) : null}
            {!postsData.isPending && postsData.isError ? (
              <div
                className={`${isNightModeOn ? "nightBg1" : ""}`}
                onClick={() => {
                  dispatch(
                    getAsyncAllPostsData({
                      userToken: "",
                      _data: {
                        active: true,
                        page: 1,
                        placement: "LOGIN_PAGE",
                        search: "",
                        size: 999,
                        tenant: "",
                      },
                    })
                  );
                }}
              >
                مشکلی پیش آمده
              </div>
            ) : null}

            {!postsData.isError &&
            !postsData.isPending &&
            postsData.data.content.length !== 0 ? (
              <>
                <div
                  className={`${styles.postContainer} ${
                    isNightModeOn ? "nightBg1" : ""
                  }`}
                >
                  <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    initialSlide={0}
                    className={`${styles.swiperClass}`}
                    direction="horizontal"
                    speed={500}
                    // autoplay={{
                    //   delay: 2500,
                    //   disableOnInteraction: false,
                    // }}
                    loop
                    simulateTouch
                    modules={[
                      Mousewheel,
                      Keyboard,
                      Autoplay,
                      Navigation,
                      Pagination,
                    ]}
                    pagination={{
                      clickable: true,
                      el: `.${styles.swiperPagination}`,
                      bulletActiveClass: styles.activePostBtn,
                      bulletClass: styles.notActivePostBtn,
                    }}
                    navigation={{
                      nextEl: "#nextBtn",
                      prevEl: "#prevBtn",
                    }}
                  >
                    {postsData.data.content.map((item) => (
                      <SwiperSlide
                        key={item.id}
                        className={`${styles.postContainer} ${
                          isNightModeOn ? "nightBg2" : ""
                        } ${styles.swiperSlideCustom}`}
                      >
                        {HTMLReactParser(item.data)}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div
                    className={`${styles.paginationContainer} ${
                      isNightModeOn ? "transparentBg" : ""
                    }`}
                  >
                    <MdNavigateNext
                      id="prevBtn"
                      style={{
                        transform: "rotate(-180deg)",
                      }}
                      fill={isNightModeOn ? "#fff" : "#8392AB"}
                      className={isNightModeOn ? "transparentBg" : ""}
                    />
                    <div
                      className={`${styles.swiperPagination} d-flex flex-row align-items-center justify-content-center`}
                    ></div>
                    <MdNavigateNext
                      id="nextBtn"
                      fill={isNightModeOn ? "#fff" : "#8392AB"}
                      className={isNightModeOn ? "transparentBg" : ""}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>

          <HomeWidgets
            allGroups={allGroups}
            isFullRenderd={isFullRenderd}
            isNightModeOn={isNightModeOn}
            isFull={false}
          />
        </div>
      )}

      <div
        className={`${styles.aboutSystem} ${
          styles.tasks
        } d-flex flex-row align-items-center pb-2 justify-content-between mt-4  ${
          isNightModeOn ? "nightBg1 pcNight" : ""
        }`}
      >
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/* TASKS */}
        <HomePageTasks containerClassName="w-75" />
        {/* TASKS */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/* Allocated Processes  */}
        <HomePageAllocatedProcesses containerClassName="w-25" />
        {/* Allocated Processes  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
      </div>
    </div>
  );
};

export default Home;
