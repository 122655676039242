// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./GroupOnGroupAccess.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  editAsyncGroupOnGroup,
  getAsyncGroupOnGroupAccess,
  groupSlice,
} from "../../../Features/GroupSlice/groupSlice";
// Redux

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import { CSVLink } from "react-csv";
import Pagination from "../../../Utils/Pagination/Pagination";
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
import { Spinner } from "reactstrap";
import Swal from "sweetalert2";
// Utils

// Components
// Components

// Contexts
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
// Contexts

// Modules
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
import { useNavigate } from "react-router-dom";
// Modules

// Icons
import { MdDone } from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
// Icons

// Contexts
import { ExecutingPermissionsContext } from "../../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

const GroupOnGroupAccess = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const language = useAppSelector((state) => state.currLanguage.language);
  const groupOnGroupData = useAppSelector(
    (state) => state.groups.groupOnGroupAccess
  );

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const allGroups = useContext(UserGroupsContext);
  const isFullRendered = useContext(IsFullRenderdContext);
  const permitions = useContext(ExecutingPermissionsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightModeOn = useContext(IsNightModeOnContext);

  const [paginationData, setPaginationData] = useState<{
    page: number;
    size: number;
    search: string;
    tenant: string;
  }>({ page: 1, size: 10, search: "", tenant: selectedTenant.tenantId });

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (
        !permitionCheckerWithoutToast(
          allGroups,
          ["Tenant_Full_Manager", "Tenant_Basic_Manager"],
          isFullRendered
        ) ||
        (allGroups.includes("Full_Manager") === false &&
          allGroups.includes("All_Group_Manager") === false)
      ) {
        customizedToast("شما دسترسی لازم به این بخش را ندارید", "info");
        return;
      }
      dispatch(
        getAsyncGroupOnGroupAccess({
          _data: paginationData,
          userToken,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, paginationData, userToken, allGroups, isFullRendered]);

  useEffect(() => {
    return () => {
      dispatch(groupSlice.actions.resetGroupOnGroupData());
    };
  }, [dispatch]);

  const csvReport = {
    headers: [
      {
        label: "شمارنده",
        key: "id",
      },
      {
        label: "از گروه",
        key: "fromGroup",
      },
      {
        label: "به گروه",
        key: "toGroup",
      },
    ],
    data: groupOnGroupData.content.map((item) => ({
      id: item.id,
      fromGroup:
        item.fromGroup.categoryType.title + " : " + item.fromGroup.title,
      toGroup: item.toGroup.categoryType.title + " : " + item.toGroup.title,
    })),
    filename: "groupOnGroupMembership.csv",
  };

  return (
    <div
      className={`${styles.groupOnGroupAccessContainer} w-100 ${
        isNightModeOn ? "nightBg1" : ""
      }`}
    >
      <div className="w-100 d-flex flex-row-reverse align-items-center justify-content-between">
        <p
          style={{
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          گروه ها
        </p>
        <div className="d-flex flex-col align-items-center">
          <button
            className="operationEvent submitBtn"
            onClick={() => {
              navigate(`/groups/new-group/group-on-group`);
            }}
          >
            افزودن
            <span className="icon-Plus"></span>
          </button>
          <CSVLink {...csvReport} className={"operationEvent csvBtn"}>
            csv خروجی
          </CSVLink>
        </div>
      </div>

      <div
        className={`${styles.inputsContainer} w-100 d-flex flex-row mt-2 align-items-center justify-content-end flex-wrap `}
      >
        {permitionCheckerWithoutToast(
          allGroups,
          permitions.canSeeTenantTextBox,
          isFullRenderd
        ) && (
          <CustomInput1
            inputType="text"
            onBlur={() => {
              return false;
            }}
            onChange={(e) => {
              return false;
            }}
            placeHolder="  نام سازمان"
            //   keyForShowOnTop="  نام سازمان"
            containerStyle={{
              width: "45%",
            }}
            value={selectedTenant.tenantTitle}
            defaultValue={selectedTenant.tenantTitle}
            dontShowSearchIcon
            disabled
          />
        )}
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={paginationData.search}
          defaultValue={paginationData.search}
          onChange={(e) => {
            setPaginationData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          placeHolder="نام گروه"
          //   keyForShowOnTop="نام گروه"
          containerStyle={{
            width: "45%",
          }}
        />
      </div>

      {groupOnGroupData.isPending ? (
        <div className="w-100 d-flex align-items-center justify-content-center flex-column mt-4">
          <p>در حال بارگیری</p>
          <Spinner />
        </div>
      ) : (
        <>
          {groupOnGroupData.content.length === 0 ? (
            <div className="w-100 d-flex align-items-center justify-content-center flex-column mt-4">
              <p>موردی وجود ندارد</p>
            </div>
          ) : (
            <>
              {window.innerWidth > 1000 ? (
                <div
                  className={`${styles.myTable} ${
                    isNightModeOn ? "nightBg2 pcNight" : ""
                  } w-100 d-flex flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    <p className={`${styles.isHegemony}`}>دسترسی سلسه مراتب</p>
                    <p className={`${styles.fromCategoryTab}`}>گروه مبدا</p>
                    <p className={`${styles.toGroupTab}`}>گروه مقصد</p>
                    <p className={`${styles.id}`}> شمارنده </p>
                  </div>

                  <div
                    className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                  >
                    {groupOnGroupData.content.map((item) => (
                      <div
                        key={item.id}
                        className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                      >
                        <div className={`${styles.operationTab}`}>
                          <button
                            className={"operationEvent deleteBtn"}
                            onClick={() => {
                              Swal.fire({
                                icon: "question",
                                title: "آیا این گروه حذف شود ؟",
                                text: "عملیات غیر قابل بازگشت میباشد",
                                showCancelButton: true,
                                cancelButtonText: "لغو",
                                confirmButtonText: "حذف",
                                customClass: isNightModeOn ? "swalNight" : "",
                              }).then(({ isConfirmed }) => {
                                if (!isConfirmed) return;
                                dispatch(
                                  editAsyncGroupOnGroup({
                                    userToken,
                                    _data: {
                                      tenantId: selectedTenant.tenantId,
                                      addList: [],
                                      deleteList: [
                                        {
                                          id: item.id,
                                        },
                                      ],
                                      revertCheckingGroupHegemonyList: [],
                                    },
                                  })
                                ).then(() => {
                                  dispatch(
                                    getAsyncGroupOnGroupAccess({
                                      _data: paginationData,
                                      userToken,
                                    })
                                  );
                                });
                              });
                            }}
                            data-tooltip-content={language.delete}
                            data-tooltip-id="my-tooltip"
                          >
                            <span className="icon-trash"></span>
                          </button>
                        </div>
                        <p className={`${styles.isHegemony}`}>
                          {item.checkingGroupHegemony ? (
                            <MdDone
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          ) : (
                            <BsDashLg
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          )}
                        </p>
                        <p
                          className={`${styles.fromCategoryTab}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            item.fromGroup.title.length >= 16
                              ? item.fromGroup.title
                              : ""
                          }
                        >
                          {item.fromGroup.title.length >= 16
                            ? "..." + item.fromGroup.title.substring(0, 15)
                            : item.fromGroup.title}
                        </p>
                        <p
                          className={`${styles.toGroupTab}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            item.toGroup.title.length >= 16
                              ? item.toGroup.title
                              : ""
                          }
                        >
                          {item.toGroup.title.length >= 16
                            ? "..." + item.toGroup.title.substring(0, 15)
                            : item.toGroup.title}
                        </p>

                        <p
                          className={`${styles.id}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            String(item.id).length >= 16 ? String(item.id) : ""
                          }
                        >
                          {String(item.id).length >= 16
                            ? "..." + String(item.id).substring(0, 15)
                            : String(item.id)}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              ) : (
                <div
                  className={`${styles.cards} mt-4 w-100 d-flex flex-column align-items-center`}
                >
                  {groupOnGroupData.content.map((item) => (
                    <div key={item.id} className="w-100">
                      <div
                        className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>{item.id}</p>
                        <p>شمارنده</p>
                      </div>
                      <div
                        className={`${styles.tableRow} ${
                          isNightModeOn ? "nightBg2" : ""
                        } w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>
                          <span> نام گروه مبدا</span>
                          <span>{item.fromGroup.title}</span>
                        </p>
                        <p>
                          <span>نام گروه مقصد</span>
                          <span>{item.toGroup.title}</span>
                        </p>
                        <div className={`${styles.operationTab}`}>
                          <button
                            className={`operationEvent deleteBtn`}
                            data-tooltip-content={language.delete}
                            data-tooltip-id="my-tooltip"
                          >
                            <span className="icon-trash"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}

      <Pagination
        dataForPagination={{
          currPage: paginationData.page,
          size: paginationData.size,
          totalPages: groupOnGroupData.totalPages,
        }}
        onPageNumberChange={(page) => {
          setPaginationData((prevState) => ({
            ...prevState,
            page,
          }));
        }}
        onPageNumbersClick={(page) => {
          setPaginationData((prevState) => ({
            ...prevState,
            page,
          }));
        }}
        onPageSizeChange={(size) => {
          setPaginationData((prevState) => ({
            ...prevState,
            size,
          }));
          setPaginationData((prevState) => ({ ...prevState, page: 1 }));
        }}
        search={paginationData.search}
      />
    </div>
  );
};

export default GroupOnGroupAccess;
