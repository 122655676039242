// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Models
import { I_ReportData } from "../../Models/interfaces";
import {
  I_AddingNewReportSendingData,
  I_GetAllReportsSendingData,
} from "../../Models/sendingDataSchema";
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { getAllProcessReportService } from "../../Services/ReportManagement/get/getAllProcessReportService";
import { addReportToProcessService } from "../../Services/ReportManagement/post/addReportToProcessService";
import { deleteSingleReportService } from "../../Services/ReportManagement/post/deleteSingleReportService";
import { revertReportStateService } from "../../Services/ReportManagement/post/revertReportStateService";
import { resetReferenceOfObject } from "../../Utils/resetReferenceOfObject";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Models

const initialState: I_ReportData & {
  isPending: boolean;
  isError: boolean;
} & {
  addingReportData: {
    isPending: boolean;
    isDone: boolean;
  };
} = {
  pageNumber: 1,
  pageSize: 10,
  totalPages: 0,
  totalElements: 0,
  hasNextPage: false,
  content: [],

  // added by Developer
  isPending: true,
  isError: false,
  // added by Developer

  addingReportData: {
    // added by Developer
    isPending: false,
    isDone: false,
    // added by Developer
  },
};

export const getAsyncProcessReportData = createAsyncThunk(
  "reportManagement/getAllData",
  async (_data: {
    _data: I_GetAllReportsSendingData;
    userToken: string;
    processKey: string;
  }) => {
    try {
      //
      const { data } = await getAllProcessReportService(_data);
      return data;
      //
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const addAsyncNewReport = createAsyncThunk(
  "reportManagement/addNewReport",
  async (_data: {
    _data: I_AddingNewReportSendingData;
    userToken: string;
    processKey: string;
  }) => {
    try {
      const { data } = await addReportToProcessService(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const deleteAsyncSingleReport = createAsyncThunk(
  "reportManagement/deleteReport",
  async (_data: {
    userToken: string;
    processKey: string;
    reportId: string;
  }) => {
    try {
      const { data } = await deleteSingleReportService(
        _data.userToken,
        _data.processKey,
        _data.reportId
      );

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const revertAsyncReportState = createAsyncThunk(
  "reportManagement/revertState",
  async (_data: {
    userToken: string;
    processKey: string;
    reportId: string;
  }) => {
    try {
      const { data } = await revertReportStateService(
        _data.userToken,
        _data.processKey,
        _data.reportId
      );

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const reportManagementSlice = createSlice({
  name: "reportManagement",
  initialState,
  reducers: {
    resetAddingStatus: (state) => {
      state.addingReportData.isDone = false;
      state.addingReportData.isPending = false;
    },
    resetReportData: (state) => {
      state.content = resetReferenceOfObject(initialState.content);
      state.isPending = true;
      state.pageSize = 5;
      state.pageNumber = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAsyncProcessReportData.pending, (state) => {
      state.isPending = true;
      state.isError = false;
    });
    builder.addCase(getAsyncProcessReportData.fulfilled, (state, action) => {
      state.isPending = false;

      if (!action.payload) return;
      state.pageNumber = action.payload.result.pageNumber;
      state.pageSize = action.payload.result.pageSize;
      state.totalPages = action.payload.result.totalPages;
      state.totalElements = action.payload.result.totalElements;
      state.hasNextPage = action.payload.result.hasNextPage;
      state.content = action.payload.result.content;
      state.isError = false;
    });

    builder.addCase(getAsyncProcessReportData.rejected, (state) => {
      state.isPending = false;
      state.isError = true;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(addAsyncNewReport.pending, (state, aciton) => {
      state.addingReportData.isPending = true;
      state.addingReportData.isDone = false;
    });
    builder.addCase(addAsyncNewReport.fulfilled, (state, action) => {
      state.addingReportData.isPending = false;

      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        customizedToast("گزارش با موفقیت اضافه شد", "success");
        state.addingReportData.isDone = true;
        return;
      }
    });
    builder.addCase(addAsyncNewReport.rejected, (state, aciton) => {
      state.addingReportData.isPending = false;
      state.addingReportData.isDone = false;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(deleteAsyncSingleReport.pending, () => {});
    builder.addCase(deleteAsyncSingleReport.fulfilled, (_, action) => {
      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        // customizedToast("گزارش با موفقیت حذف شد", "success");
        return;
      }
    });
    builder.addCase(deleteAsyncSingleReport.rejected, () => {});
    //
    //
    //
    //
    //
    //
    builder.addCase(revertAsyncReportState.pending, () => {});
    builder.addCase(revertAsyncReportState.fulfilled, (_, action) => {
      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        customizedToast("گزارش با موفقیت عوض شد", "success");
        return;
      }
    });
    builder.addCase(revertAsyncReportState.rejected, () => {});
  },
});
