// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./ShowAllRequestsForSingleCAllOfRegistration.module.css";
// CSS

// Modules
import { useParams, useNavigate } from "react-router-dom";
// Modules

// Models
import { I_SendingCallOfRegistrationRequest } from "../../../Models/sendingDataSchema";
// Models

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  callOfRegistrationSlice,
  getAsyncAllRelatedCallOfRegistrationRequests,
} from "../../../Features/CallOfRegistrationSlice/callOfRegistrationSlice";
// Redux

// Utils
import Pagination from "../../../Utils/Pagination/Pagination";
// Utils

// Urls
// Urls

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import CustomSelectOption2 from "../../../Utils/CustomSelectOption2/CustomSelectOption2";
import Pending from "../../../Components/Pending/Pending";
// Contexts

const ShowAllRequestsForSingleCAllOfRegistration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { callOfRegistrationId } = useParams();

  const isNightOn = useContext(IsNightModeOnContext);

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { registrationRequestState: state } = useAppSelector(
    (state) => state.allRoles.data
  );
  const { singleCallOfRegistrationRequests } = useAppSelector(
    (state) => state.callOfRegistration
  );
  const currLang = useAppSelector((state) => state.currLanguage.language);

  const [formData, setFormData] = useState<I_SendingCallOfRegistrationRequest>({
    callOfRegistrationId: String(callOfRegistrationId),
    page: 1,
    search: "",
    size: 10,
    state: state.waiting.value,
    tenant: "",
  });

  //
  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncAllRelatedCallOfRegistrationRequests({
          _data: formData,
          userToken,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, formData, userToken]);
  //
  useEffect(() => {
    return () => {
      dispatch(
        callOfRegistrationSlice.actions.resetRecevedSingleCallOfRegistrationRequests()
      );
    };
  }, [dispatch]);
  //
  return (
    <div
      className={`${styles.singleCallOfRegistrationRequestsContainer}  ${
        isNightOn ? "nightBg1" : ""
      }`}
    >
      <p className="w-100 d-flex align-items-center justify-content-end">
        درخواست های فراخوان شماره {callOfRegistrationId}
      </p>

      <CustomSelectOption2
        getSelectedOption={(data) => {
          setFormData((prevState) => ({
            ...prevState,
            state: String(data),
          }));
        }}
        initialTitle="انتخاب وضعیت "
        initialValue={""}
        options={Object.keys(state).map((item) => ({
          title: state[item].title,
          value: state[item].value,
        }))}
        containerClassName="w-100"
      />

      {singleCallOfRegistrationRequests.isPending ? (
        <Pending className="mt-4" />
      ) : (
        <>
          {singleCallOfRegistrationRequests.data.content.length === 0 ? (
            <div
              className={` mt-4 d-flex flex-column align-items-center justify-content-center`}
            >
              موردی وجود ندارد
            </div>
          ) : (
            <>
              {window.innerWidth > 1000 ? (
                <div
                  className={`${styles.myTable} my-4 ${
                    isNightOn ? "nightBg2 pcNight" : ""
                  }
             w-100 d-flex flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead}  w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    <p className={`${styles.trackingCode}`}> کد رهگیری</p>
                    <p className={`${styles.status}`}>وضعیت</p>
                    <p className={`${styles.email}`}>ایمیل</p>
                    <p className={`${styles.lastname}`}>نام خانوادگی</p>
                    <p className={`${styles.username}`}>نام کاربر</p>
                    <p className={`${styles.id}`}>شمارنده</p>
                  </div>
                  <div
                    className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                  >
                    {singleCallOfRegistrationRequests.data.content.map(
                      (item) => (
                        <div
                          key={item.id}
                          className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                        >
                          <p className={`${styles.operationTab}`}>
                            <button
                              className="operationEvent editBtn"
                              onClick={() => {
                                navigate(
                                  `/call-of-registration/requests/edit-single-request/${item.id}`,
                                  {
                                    state: item,
                                  }
                                );
                              }}
                            >
                              <span
                                className="icon-edit"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={currLang.edit}
                              ></span>
                            </button>
                          </p>
                          <p className={`${styles.trackingCode}`}>
                            {item.trackingCode}
                          </p>
                          <p className={`${styles.status}`}>
                            {item.state.title}
                          </p>
                          <p
                            className={`${styles.email}`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              String(item.email).length >= 14
                                ? String(item.email)
                                : ""
                            }
                          >
                            {String(item.email).length >= 14
                              ? "..." + String(item.email).substring(0, 14)
                              : String(item.email)}
                          </p>
                          <p
                            className={`${styles.lastname}`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              String(item.lastname).length >= 14
                                ? String(item.lastname)
                                : ""
                            }
                          >
                            {String(item.lastname).length >= 14
                              ? "..." + String(item.lastname).substring(0, 14)
                              : String(item.lastname)}
                          </p>
                          <p
                            className={`${styles.username}`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              String(item.username).length >= 14
                                ? String(item.username)
                                : ""
                            }
                          >
                            {String(item.username).length >= 14
                              ? "..." + String(item.username).substring(0, 14)
                              : String(item.username)}
                          </p>
                          <p className={`${styles.id}`}>{item.id}</p>
                        </div>
                      )
                    )}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              ) : (
                <div
                  className={`${styles.cards} w-100 mt-5 d-flex flex-column align-items-center`}
                >
                  {singleCallOfRegistrationRequests.data.content.map((item) => (
                    <div key={item.id} className="w-100">
                      <div
                        className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>{item.id}</p>
                        <p> شمارنده </p>
                      </div>
                      <div
                        className={`${styles.tableRow} ${
                          isNightOn ? "nightBg2" : ""
                        } w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>
                          <span>کاربر</span>
                          <span>{item.username}</span>
                        </p>
                        <p>
                          <span>نام</span>
                          <span>{item.firstname}</span>
                        </p>
                        <p>
                          <span>نام خانوادگی</span>
                          <span>{item.lastname}</span>
                        </p>
                        <p>
                          <span> ایمیل</span>
                          <span>{item.lastname}</span>
                        </p>
                        <p>
                          <span>وضعیت </span>
                          <span>{item.lastname}</span>
                        </p>
                        <p>
                          <span>کد رهگیری</span>
                          <span>{item.lastname}</span>
                        </p>

                        <p>
                          <span>عملیات</span>
                          <button
                            className="operationEvent editBtn"
                            onClick={() => {
                              navigate(
                                `/call-of-registration/requests/edit-single-request/${item.id}`,
                                {
                                  state: item,
                                }
                              );
                            }}
                          >
                            <span
                              className="icon-edit"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={currLang.edit}
                            ></span>
                          </button>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}

      <Pagination
        dataForPagination={{
          currPage: formData.page,
          size: formData.size,
          totalPages: singleCallOfRegistrationRequests.data.totalPages,
        }}
        onPageNumberChange={(page) => {
          setFormData((prevState) => ({ ...prevState, page }));
        }}
        onPageNumbersClick={(page) => {
          setFormData((prevState) => ({ ...prevState, page }));
        }}
        onPageSizeChange={(size) => {
          setFormData((prevState) => ({ ...prevState, size }));
          setFormData((prevState) => ({ ...prevState, page: 1 }));
        }}
        search={formData.search}
      />
    </div>
  );
};

export default ShowAllRequestsForSingleCAllOfRegistration;
