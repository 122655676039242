// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Services
import { getProcessesService } from "../../Services/Processes/get/getProcessesService";
import { getProcessTasksServices } from "../../Services/Processes/get/getProcessTasksServices";
import { addNewProcessService } from "../../Services/Processes/post/addNewProcessService";
import {
  I_EditExistingProcessSendingSchema,
  I_RemoveProcessByVersionSendingData,
  I_SendingDataForGettingProcessVersion,
  I_SendingDataForRemoveSingleProcessInstance,
  I_SendingNewProcessData,
  I_SendingRunningInstanceData,
} from "../../Models/sendingDataSchema";
import { editExistingProcessService } from "../../Services/Processes/post/editExistingProcessService";
import { removeSingleProcessService } from "../../Services/Processes/post/removeSingleProcessService";
import { startSingleInstanceProcessService } from "../../Services/Processes/post/startSingleInstanceProcessService";
import { getStartingFormService } from "../../Services/Processes/get/getStartingFormService";
import { getProcessSchemaAs_XML_Service } from "../../Services/Processes/get/getProcessSchemaAs_XML_Service";
import { getTaskDetailsFromProcessesService } from "../../Services/Processes/get/getTaskDetailsFromProcessesService";
import { getRunningInsatncesService } from "../../Services/Processes/get/getRunningInsatncesService";
// Services

// Models
import {
  I_GetProcessResponseResult,
  I_ProcessInstaceData,
  I_RecevedTasksByProcessKey,
  I_SingleInstanceSchema,
  I_StartingFormRecevedData,
  I_TaskDetailByProcesses,
  T_ProcessVersion,
} from "../../Models/interfaces";
// Models

// Utils
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { getInstanceProcessSchemaService } from "../../Services/Processes/get/getInstaceProcessSchemaService";
import { submitFormFromSpecificTask } from "../../Services/Processes/post/submitStartProcessStartFormFromTaskManagerService";
import { resetReferenceOfObject } from "../../Utils/resetReferenceOfObject";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
import { removeSingleProcessInstanceService } from "../../Services/Processes/delete/removeSingleProcessInstanceService";
import { removeProcessByVersionService } from "../../Services/Processes/delete/removeProcessByVersionService";
import { getSingleProcessVersion } from "../../Services/Processes/get/getSingleProcessVersion";
// Utils

const initialState: I_GetProcessResponseResult & {
  isGettingProcessesPending: boolean;
} & {
  processTasks: I_RecevedTasksByProcessKey;
} & {
  isSendingProcessPending: boolean;
  isAddingProcessDone: boolean;
  isEditPending: boolean;
  isEditDone: boolean;
  isError: boolean;
} & {
  isStartNewInstancePending: boolean;
  isGetProcessXML_Error: boolean;
} & {
  startingForm: {
    result: I_StartingFormRecevedData;
    // added by Developer
    isPending: boolean;
    isDone: boolean;
    isError: boolean;
    // added by Developer
  };
} & {
  isgetProcessXmlPending: boolean;
  processXmlData: string;
} & {
  taskDetailByProcesses: I_TaskDetailByProcesses & {
    isTaskDetailsPending: boolean;
    isError: boolean;
  };
} & {
  processInstanceData: I_ProcessInstaceData & {
    isProcessInstancePending: boolean;
    isProcessInstanceError: boolean;
  } & {
    processInstanceSchema: I_SingleInstanceSchema;
    processInstanceXmlDataPending: boolean;
    isError: boolean;
  };
} & {
  submitSingleSpecificForm: {
    isPending: boolean;
    isDone: boolean;
  };
} & {
  removeSingleInstanceStatus: {
    isPending: boolean;
    isDone: boolean;
    isError: boolean;
  };
} & {
  processVersionRemovingStatus: {
    isPending: boolean;
    isDone: boolean;
    isError: boolean;
  };
} & {
  processVersion: {
    isPending: boolean;
    isDone: boolean;
    isError: boolean;
    data: T_ProcessVersion;
  };
} = {
  pageNumber: 1,
  pageSize: 10,
  totalPages: 1,
  totalElements: 1,
  hasNextPage: false,
  content: [],

  // processTasks
  processTasks: {
    isPending: true,
    pageNumber: 10,
    pageSize: 10,
    totalPages: 1,
    totalElements: 0,
    hasNextPage: false,
    content: [],
    isError: false,
  },
  // processTasks

  // startingForm
  startingForm: {
    result: {
      id: 0,
      title: "",
      formType: {
        value: "",
        title: "",
      },
      key: "",
      processKey: "",
      url: "",
    },

    // added by developer
    isPending: false,
    isDone: false,
    isError: false,
    // added by developer
  },
  // startingForm

  // taskDetai By Processes
  taskDetailByProcesses: {
    taskId: "",
    processId: "",
    processInstanceId: "",
    taskTitle: "",
    dueDate: {
      gregorianDate: "",
      jalaliDate: "",
    },
    followUpDate: {
      gregorianDate: "",
      jalaliDate: "",
    },
    priority: 0,
    priorityType: {
      minValue: 0,
      maxValue: 0,
      title: "",
    },
    schemaId: "",
    description: "",
    form: {
      id: 0,
      title: "",
      formType: {
        value: "",
        title: "",
      },
      key: "",
      processKey: "",
      url: "",
      formData: {},
    },
    //
    // added By Developer
    isTaskDetailsPending: true,
    isError: false,
    // added By Developer
    //
  },
  // taskDetai By Processes

  // processInstancesData
  processInstanceData: {
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
    totalElements: 0,
    hasNextPage: false,
    content: [],
    isProcessInstanceError: false,
    // added by developer
    isProcessInstancePending: true,
    isError: false,
    // added by developer
    processInstanceSchema: {
      bpmnXml: "",
      // activeTask: {
      //   taskId: "",
      //   processId: "",
      //   processInstanceId: "",
      //   taskTitle: "",
      //   dueDate: {
      //     gregorianDate: "",
      //     jalaliDate: "",
      //   },
      //   followUpDate: {
      //     gregorianDate: "",
      //     jalaliDate: "",
      //   },
      //   priority: 0,
      //   priorityType: {
      //     minValue: 0,
      //     maxValue: 0,
      //     title: "",
      //   },
      //   schemaId: "",
      //   description: "",
      // },
      validSchemas: [],
    },
    processInstanceXmlDataPending: false,
    // added by developer
  },
  // processInstancesData

  // added by developer
  isGettingProcessesPending: true,
  isSendingProcessPending: false,
  isAddingProcessDone: false,
  isEditPending: false,
  isEditDone: false,
  isStartNewInstancePending: false,
  isgetProcessXmlPending: false,
  isError: false,
  isGetProcessXML_Error: false,
  // // files
  processXmlData: "",
  // // files
  // added by developer

  submitSingleSpecificForm: {
    isDone: false,
    isPending: false,
  },
  removeSingleInstanceStatus: {
    isDone: false,
    isPending: false,
    isError: false,
  },
  processVersionRemovingStatus: {
    isDone: false,
    isPending: false,
    isError: false,
  },
  processVersion: {
    isDone: false,
    isPending: true,
    isError: false,
    data: {
      result: [],
    },
  },
};

export const getAsyncProcessesList = createAsyncThunk(
  "processes/getAllProcesses",
  async (_data: {
    _data: {
      page: number;
      size: number;
      tenant: string;
      search: string;
      startable: boolean;
    };
    userToken: string;
  }) => {
    try {
      const { data } = await getProcessesService(_data.userToken, _data._data);

      return data.result;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncProcessTasks = createAsyncThunk(
  "processes/getProcessTasks",
  async (_data: {
    _data: {
      page: number;
      size: number;
      orderByDueDate: boolean;
      orderByFollowUpDate: boolean;
      orderByPriority: boolean;
      descendingSort: boolean;
      tenant: string;
      userName: string;
      processKey: string;
      search: string;
    };
    userToken: string;
  }) => {
    try {
      const { data } = await getProcessTasksServices(_data);

      return data.result;
    } catch (err: any) {
      for (let i: number = 0; i <= err?.response.data.message.length - 1; i++) {
        customizedToast(err?.response.data.message[i], "error");
      }
    }
  }
);

export const addAsyncSingleProcess = createAsyncThunk(
  "processes/addSingleProcess",
  async (_data: I_SendingNewProcessData) => {
    try {
      const { data, status } = await addNewProcessService(_data);

      if (String(status).startsWith("4")) {
        console.log("data", data);
        return;
      }

      return { ...data, status };
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const editAsyncSingleProcess = createAsyncThunk(
  "processes/editProcess",
  async (_data: {
    userToken: string;
    processKey: string;
    _data: I_EditExistingProcessSendingSchema;
  }) => {
    try {
      const { data, status } = await editExistingProcessService(
        _data.userToken,
        _data.processKey,
        _data._data
      );

      if (String(status).startsWith("4")) {
        console.log("data", data);
        return;
      }

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const deleteAsyncSingleProcess = createAsyncThunk(
  "processes/deleteProcess",
  async (_data: {
    userToken: string;
    tenantId?: string;
    processKey: string;
  }) => {
    try {
      const { data } = await removeSingleProcessService(
        _data.userToken,
        _data.processKey,
        _data.tenantId
      );

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const startAsyncSingleProcessInstance = createAsyncThunk(
  "processes/startNewProcessInstance",
  async (_data: {
    userToken: string;
    _data: {
      processKey: string;
      tenant?: string;
      _data: {
        title: string;
        formData: any;
        businessKey: string;
      };
    };
  }) => {
    try {
      console.log("gagg");
      const { data } = await startSingleInstanceProcessService(_data);

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncStartingForm = createAsyncThunk(
  "processes/getStartingForm",
  async (_data: { userToken: string; processKey: string; tenant?: string }) => {
    try {
      const { data } = await getStartingFormService(
        _data.userToken,
        _data.processKey,
        _data.tenant
      );
      return data;
    } catch (err: any) {
      console.log("err", err);
      if (!err?.response.data.message) return;
      for (let i: number = 0; i <= err?.response.data.message.length - 1; i++) {
        customizedToast(err?.response.data.message[i], "error");
      }
    }
  }
);

export const getAsyncProcessXML = createAsyncThunk(
  "processes/getProcessXML",
  async (_data: {
    userToken: string;
    processKey: string;
    tenantId?: string;
    onDone?: (data: string) => void;
  }) => {
    try {
      const { data } = await getProcessSchemaAs_XML_Service(_data);

      if (_data.onDone) {
        _data.onDone(data.result.bpmnXml);
      }
      return data.result;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncTaskDataFromProcesses = createAsyncThunk(
  "processes/getTaskDetailFromProcesses",
  async (_data: { userToken: string; taskId: string; onError: () => void }) => {
    try {
      const { data } = await getTaskDetailsFromProcessesService(
        _data.userToken,
        _data.taskId
      );

      return data.result;
    } catch (err: any) {
      console.log(err);
      if (_data.onError) {
        _data.onError();
      }
      if (err?.response.data.messages) {
        for (
          let i: number = 0;
          i <= err?.response.data.messages.length - 1;
          i++
        ) {
          customizedToast(err?.response.data.messages[i].message, "error");
        }
        throw err;
      }
      throw err;
    }
  }
);

export const getAsyncRunningInstancesOfSingleProcess = createAsyncThunk(
  "processes/getRunningInstances",
  async (_data: { userToken: string; _data: I_SendingRunningInstanceData }) => {
    try {
      const { data } = await getRunningInsatncesService(_data);

      return data.result;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncInstanceProcessXmlData = createAsyncThunk(
  "processes/getSingleInstanceXml",
  async (_data: {
    userToken: string;
    instanceId: string;
    onError?: () => void;
    onDone?: (data: string) => void;
  }) => {
    try {
      const { data } = await getInstanceProcessSchemaService(
        _data.userToken,
        _data.instanceId
      );

      if (_data.onDone) {
        _data.onDone(data.result.bpmnXml);
      }

      return data.result;
    } catch (err: any) {
      console.log(err);
      if (_data.onError) {
        _data.onError();
      }
      if (err?.response.data.messages) {
        for (
          let i: number = 0;
          i <= err?.response.data.messages.length - 1;
          i++
        ) {
          customizedToast(err?.response.data.messages[i].message, "error");
        }
        throw err;
      }
      throw err;
    }
  }
);

export const postAsyncFormFromSpecificTaskFromProcesses = createAsyncThunk(
  "processes/submitFormFromSpecificTask",
  async (_data: { userToken: string; taskId: string; formData: any }) => {
    try {
      const { data } = await submitFormFromSpecificTask(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const removeAsyncSingleRunningProcessInstance = createAsyncThunk(
  "processes/removeSingleInstance",
  async (_data: {
    userToken: string;
    _data: I_SendingDataForRemoveSingleProcessInstance;
    onDone: () => void;
  }) => {
    try {
      const { data } = await removeSingleProcessInstanceService(_data);
      if (typeof _data.onDone === "function") {
        _data.onDone();
      }
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
      throw err;
    }
  }
);

export const removeAsyncVersionOfProcess = createAsyncThunk(
  "processes/removeAsyncVersion",
  async (_data: {
    userToken: string;
    _data: I_RemoveProcessByVersionSendingData;
    onDone: () => void;
  }) => {
    try {
      const { data } = await removeProcessByVersionService(_data);
      if (typeof _data.onDone === "function") {
        _data.onDone();
      }
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
      throw err;
    }
  }
);

export const getAsyncSingleProcessVersions = createAsyncThunk(
  "processes/getProcessVersion",
  async (_data: I_SendingDataForGettingProcessVersion) => {
    try {
      const { data } = await getSingleProcessVersion(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
      throw err;
    }
  }
);

export const processesSlice = createSlice({
  name: "processes",
  initialState,
  reducers: {
    resetPendingState: (state) => {
      state.isGettingProcessesPending = true;
    },
    resetPendingStateOfProcessTasks: (state) => {
      state.processTasks.isPending = true;
    },
    resetIsAddingProcessDone: (state) => {
      state.isAddingProcessDone = false;
      state.isSendingProcessPending = false;
    },
    resetPerviousLoadedData: (state) => {
      state.content = resetReferenceOfObject(initialState.content);
      state.pageNumber = 1;
      state.pageSize = 10;
      state.totalPages = 1;
      state.totalElements = 1;
      state.hasNextPage = false;
    },
    resetXmlData: (state) => {
      state.processXmlData = "";
    },
    resetProcessTaskPendingState: (state) => {
      state.processTasks.isPending = true;
    },
    resetRunningInstancesData: (state) => {
      state.processInstanceData = resetReferenceOfObject(
        initialState.processInstanceData
      );
      state.processInstanceData.isProcessInstancePending = true;
      state.processInstanceData.content = [];
      state.processInstanceData.totalPages = 1;
    },
    resetProcessInstanceXmlData: (state) => {
      state.processInstanceData.processInstanceSchema.bpmnXml = "";
      state.processInstanceData.processInstanceXmlDataPending = true;
    },
    resetSendingFormStatus: (state) => {
      state.startingForm.isDone = false;
    },
    resetTaskData: (state) => {
      state.taskDetailByProcesses.isTaskDetailsPending = true;
      state.taskDetailByProcesses.isError = false;
      state.taskDetailByProcesses.form =
        initialState.taskDetailByProcesses.form;
    },
    resetTasks: (state) => {
      state.processTasks.isPending = true;
      state.processTasks.totalElements = 1;
      state.processTasks.content = resetReferenceOfObject(
        initialState.processTasks.content
      );
      state.processTasks.content = [];
      state.processTasks.pageNumber = 1;
      state.processTasks.pageSize = 10;
      state.processTasks.totalPages = 1;
    },
    resetProcessInstanceData: (state) => {
      state.processInstanceData.processInstanceXmlDataPending = true;
      state.processInstanceData.processInstanceSchema.bpmnXml = "";
    },
    changeSortOfProcessTasks: (state, action) => {
      state.processTasks.content = action.payload;
    },
    resetProcessListPending: (state) => {
      state.isGettingProcessesPending = true;
    },
    resetProcessEditState: (state) => {
      state.isEditPending = false;
      state.isEditDone = false;
    },
    resetSubmissionSingleSpecificForm: (state) => {
      state.submitSingleSpecificForm.isDone = false;
      state.submitSingleSpecificForm.isPending = false;
    },
    resetReceivedProcessInstancesData: (state) => {
      state.processInstanceData = resetReferenceOfObject(
        initialState.processInstanceData
      );
    },
    resetProcessVersions: (state) => {
      state.processVersion = resetReferenceOfObject(
        initialState.processVersion
      );
    },
  },

  extraReducers: (builder) => {
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncProcessesList.pending, (state, action) => {
      state.isGettingProcessesPending = true;
      state.isError = false;
    });
    builder.addCase(getAsyncProcessesList.fulfilled, (state, action) => {
      state.isGettingProcessesPending = false;

      state.pageNumber = action.payload!.pageNumber;
      state.pageSize = action.payload!.pageSize;
      state.totalPages = action.payload!.totalPages;
      state.totalElements = action.payload!.totalElements;
      state.hasNextPage = action.payload!.hasNextPage;
      state.content = action.payload!.content;
      state.isError = false;
    });
    builder.addCase(getAsyncProcessesList.rejected, (state, action) => {
      state.isGettingProcessesPending = false;
      state.isError = true;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncProcessTasks.pending, (state, action) => {
      state.processTasks.isPending = true;
      state.processTasks.isError = false;
    });
    builder.addCase(getAsyncProcessTasks.fulfilled, (state, action) => {
      state.processTasks.isPending = false;
      state.processTasks.pageNumber = action.payload!.pageNumber;
      state.processTasks.pageSize = action.payload!.pageSize;
      state.processTasks.totalPages = action.payload!.totalPages;
      state.processTasks.totalElements = action.payload!.totalElements;
      state.processTasks.hasNextPage = action.payload!.hasNextPage;
      state.processTasks.content = action.payload!.content;
      state.processTasks.isError = false;
    });
    builder.addCase(getAsyncProcessTasks.rejected, (state, action) => {
      state.processTasks.isPending = false;
      state.processTasks.isError = true;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(addAsyncSingleProcess.pending, (state) => {
      state.isSendingProcessPending = true;
    });
    builder.addCase(addAsyncSingleProcess.fulfilled, (state, action) => {
      state.isSendingProcessPending = false;

      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        state.isAddingProcessDone = true;
        return;
      }
    });
    builder.addCase(addAsyncSingleProcess.rejected, (state, action) => {
      state.isSendingProcessPending = false;
      console.log(action);
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(editAsyncSingleProcess.pending, (state, action) => {
      state.isEditPending = true;
      state.isEditDone = false;
    });
    builder.addCase(editAsyncSingleProcess.fulfilled, (state, action) => {
      state.isEditPending = false;

      const err: any = action.payload;

      if (err?.response?.data?.messages) {
        for (
          let i: number = 0;
          i <= err?.response.data.messages.length - 1;
          i++
        ) {
          customizedToast(err?.response.data.messages[i].message, "error");
        }

        return;
      }
      state.isEditDone = true;
      state.isAddingProcessDone = true;

      console.log(action.payload);

      for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
        customizedToast(action.payload!.messages[i].message, "success");
      }
    });
    builder.addCase(editAsyncSingleProcess.rejected, (state, action) => {
      state.isEditPending = false;
      state.isEditDone = false;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(deleteAsyncSingleProcess.pending, (state, action) => {});
    builder.addCase(deleteAsyncSingleProcess.fulfilled, (state, action) => {
      const err: any = action.payload;

      if (err?.response?.data?.messages) {
        for (
          let i: number = 0;
          i <= err?.response.data.messages.length - 1;
          i++
        ) {
          customizedToast(err?.response.data.messages[i].message, "error");
        }

        return;
      } else {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
      }
    });
    builder.addCase(deleteAsyncSingleProcess.rejected, (state, action) => {});
    //
    //
    //
    //
    //
    //
    builder.addCase(
      startAsyncSingleProcessInstance.pending,
      (state, action) => {
        state.isStartNewInstancePending = true;
        state.startingForm.isDone = false;
      }
    );
    builder.addCase(
      startAsyncSingleProcessInstance.fulfilled,
      (state, action) => {
        state.isStartNewInstancePending = false;
        const err: any = action.payload;

        if (err?.response?.data?.messages) {
          for (
            let i: number = 0;
            i <= err?.response.data.messages.length - 1;
            i++
          ) {
            customizedToast(err?.response.data.messages[i].message, "error");
          }

          return;
        } else {
          for (
            let i: number = 0;
            i <= action.payload.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload.messages[i].message, "success");
          }
          state.startingForm.isDone = true;
        }
      }
    );
    builder.addCase(
      startAsyncSingleProcessInstance.rejected,
      (state, action) => {
        state.isStartNewInstancePending = false;
        state.startingForm.isDone = false;
      }
    );
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncStartingForm.pending, (state, action) => {
      state.startingForm.isPending = true;
      state.startingForm.isError = false;
    });
    builder.addCase(getAsyncStartingForm.fulfilled, (state, action) => {
      state.startingForm.isPending = false;
      state.startingForm.isError = false;

      const err: any = action.payload;

      if (err?.response?.data?.messages) {
        for (
          let i: number = 0;
          i <= err?.response.data.messages.length - 1;
          i++
        ) {
          customizedToast(err?.response.data.messages[i].message, "error");
        }

        return;
      }
    });
    builder.addCase(getAsyncStartingForm.rejected, (state, action) => {
      state.startingForm.isPending = false;
      state.startingForm.isError = true;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncProcessXML.pending, (state, action) => {
      state.isgetProcessXmlPending = true;
      state.isGetProcessXML_Error = false;
    });
    builder.addCase(getAsyncProcessXML.fulfilled, (state, action) => {
      state.isgetProcessXmlPending = false;

      const err: any = action.payload;
      if (err?.response?.data?.messages) {
        for (
          let i: number = 0;
          i <= err?.response.data.messages.length - 1;
          i++
        ) {
          customizedToast(err?.response.data.messages[i].message, "error");
        }

        return;
      } else {
        state.processXmlData = action.payload!.bpmnXml;
        state.isGetProcessXML_Error = false;
      }
    });
    builder.addCase(getAsyncProcessXML.rejected, (state, action) => {
      state.isgetProcessXmlPending = false;
      state.isGetProcessXML_Error = true;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncTaskDataFromProcesses.pending, (state) => {
      state.taskDetailByProcesses.isTaskDetailsPending = true;
      state.taskDetailByProcesses.isError = false;
    });
    builder.addCase(
      getAsyncTaskDataFromProcesses.fulfilled,
      (state, action) => {
        state.taskDetailByProcesses.isTaskDetailsPending = false;
        state.taskDetailByProcesses.isError = false;
        const err: any = action.payload;
        if (err?.response?.data?.messages) {
          for (
            let i: number = 0;
            i <= err?.response.data.messages.length - 1;
            i++
          ) {
            customizedToast(err?.response.data.messages[i].message, "error");
          }

          return;
        } else {
          state.taskDetailByProcesses.taskId = action.payload!.taskId;
          state.taskDetailByProcesses.processId = action.payload!.processId;
          state.taskDetailByProcesses.processInstanceId =
            action.payload!.processInstanceId;
          state.taskDetailByProcesses.taskTitle = action.payload!.taskTitle;
          state.taskDetailByProcesses.dueDate = action.payload!.dueDate;
          state.taskDetailByProcesses.followUpDate =
            action.payload!.followUpDate;
          state.taskDetailByProcesses.priority = action.payload!.priority;
          state.taskDetailByProcesses.priorityType =
            action.payload!.priorityType;

          state.taskDetailByProcesses.schemaId = action.payload!.schemaId;
          state.taskDetailByProcesses.description = action.payload!.description;
          state.taskDetailByProcesses.form = action.payload!.form;
        }
      }
    );
    builder.addCase(getAsyncTaskDataFromProcesses.rejected, (state) => {
      state.taskDetailByProcesses.isTaskDetailsPending = false;
      state.taskDetailByProcesses.isError = true;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(
      getAsyncRunningInstancesOfSingleProcess.pending,
      (state) => {
        state.processInstanceData.isProcessInstancePending = true;
        state.processInstanceData.isError = false;
      }
    );
    builder.addCase(
      getAsyncRunningInstancesOfSingleProcess.fulfilled,
      (state, action) => {
        state.processInstanceData.isProcessInstancePending = false;
        const err: any = action.payload;
        if (err?.response?.data?.messages) {
          for (
            let i: number = 0;
            i <= err?.response.data.messages.length - 1;
            i++
          ) {
            customizedToast(err?.response.data.messages[i].message, "error");
          }

          return;
        } else {
          state.processInstanceData.pageNumber = action.payload!.pageNumber;
          state.processInstanceData.pageSize = action.payload!.pageSize;
          state.processInstanceData.totalPages = action.payload!.totalPages;
          state.processInstanceData.totalElements =
            action.payload!.totalElements;
          state.processInstanceData.hasNextPage = action.payload!.hasNextPage;
          state.processInstanceData.content = action.payload!.content;
          state.processInstanceData.isError = false;
        }
      }
    );
    builder.addCase(
      getAsyncRunningInstancesOfSingleProcess.rejected,
      (state) => {
        state.processInstanceData.isProcessInstancePending = false;
      }
    );
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncInstanceProcessXmlData.pending, (state, action) => {
      state.processInstanceData.processInstanceXmlDataPending = true;
      state.processInstanceData.isProcessInstanceError = false;
    });
    builder.addCase(
      getAsyncInstanceProcessXmlData.fulfilled,
      (state, action) => {
        state.processInstanceData.processInstanceXmlDataPending = false;

        const err: any = action.payload;
        if (err?.response?.data?.messages) {
          for (
            let i: number = 0;
            i <= err?.response.data.messages.length - 1;
            i++
          ) {
            customizedToast(err?.response.data.messages[i].message, "error");
          }

          return;
        } else {
          // state.processInstanceData.processInstanceSchema.bpmnXml =
          //   action.payload!.bpmnXml;
          // state.processInstanceData.processInstanceSchema.activeTask.description =
          //   action.payload?.activeTask?.description;
          // state.processInstanceData.processInstanceSchema.activeTask.dueDate =
          //   action.payload?.activeTask?.dueDate;
          // state.processInstanceData.processInstanceSchema.activeTask.followUpDate =
          //   action.payload?.activeTask?.followUpDate;
          // state.processInstanceData.processInstanceSchema.activeTask.priority =
          //   action.payload?.activeTask?.priority;
          // state.processInstanceData.processInstanceSchema.activeTask.priorityType =
          //   action.payload?.activeTask?.priorityType;
          // state.processInstanceData.processInstanceSchema.activeTask.processId =
          //   action.payload?.activeTask?.processId;
          // state.processInstanceData.processInstanceSchema.activeTask.processInstanceId =
          //   action.payload?.activeTask?.processInstanceId;
          // state.processInstanceData.processInstanceSchema.activeTask.schemaId =
          //   action.payload?.activeTask?.schemaId;
          // state.processInstanceData.processInstanceSchema.activeTask.taskId =
          //   action.payload?.activeTask?.taskId;
          // state.processInstanceData.processInstanceSchema.activeTask.taskTitle =
          //   action.payload?.activeTask?.taskTitle;
          state.processInstanceData.processInstanceSchema = action.payload;
          state.processInstanceData.isProcessInstanceError = false;
          customizedToast("دریافت اطلاعات با موفقیت انجام شد", "success");
        }
      }
    );
    builder.addCase(
      getAsyncInstanceProcessXmlData.rejected,
      (state, action) => {
        state.processInstanceData.processInstanceXmlDataPending = false;
        state.processInstanceData.isProcessInstanceError = true;
      }
    );
    //
    //
    //
    //
    //
    //
    builder.addCase(
      postAsyncFormFromSpecificTaskFromProcesses.pending,
      (state, action) => {
        state.submitSingleSpecificForm.isDone = false;
        state.submitSingleSpecificForm.isPending = false;
      }
    );
    builder.addCase(
      postAsyncFormFromSpecificTaskFromProcesses.fulfilled,
      (state, action) => {
        state.submitSingleSpecificForm.isPending = false;

        const err: any = action.payload;
        if (err?.response?.data?.messages) {
          for (
            let i: number = 0;
            i <= err?.response.data.messages.length - 1;
            i++
          ) {
            customizedToast(err?.response.data.messages[i].message, "error");
          }

          return;
        } else {
          for (
            let i: number = 0;
            i <= action.payload!.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload!.messages[i]?.message!, "success");
          }
          state.submitSingleSpecificForm.isDone = true;
        }
      }
    );
    builder.addCase(
      postAsyncFormFromSpecificTaskFromProcesses.rejected,
      (state, action) => {
        state.submitSingleSpecificForm.isDone = false;
        state.submitSingleSpecificForm.isPending = false;
      }
    );
    //
    //
    //
    //
    //
    //
    builder.addCase(
      removeAsyncSingleRunningProcessInstance.pending,
      (state, action) => {
        state.removeSingleInstanceStatus.isPending = true;
        state.removeSingleInstanceStatus.isDone = false;
        state.removeSingleInstanceStatus.isError = false;
      }
    );
    builder.addCase(
      removeAsyncSingleRunningProcessInstance.fulfilled,
      (state, action) => {
        state.removeSingleInstanceStatus.isPending = false;
        state.removeSingleInstanceStatus.isDone = true;
        state.removeSingleInstanceStatus.isError = false;

        if (Array.isArray(action.payload.messages)) {
          action.payload.messages.forEach((item) => {
            customizedToast(item.message, "success");
          });
        }
      }
    );
    builder.addCase(
      removeAsyncSingleRunningProcessInstance.rejected,
      (state, action) => {
        state.removeSingleInstanceStatus.isPending = false;
        state.removeSingleInstanceStatus.isDone = false;
        state.removeSingleInstanceStatus.isError = true;
      }
    );
    //
    //
    //
    //
    //
    //
    builder.addCase(removeAsyncVersionOfProcess.pending, (state, action) => {
      state.processVersionRemovingStatus.isPending = true;
      state.processVersionRemovingStatus.isDone = false;
      state.processVersionRemovingStatus.isError = false;
    });
    builder.addCase(removeAsyncVersionOfProcess.fulfilled, (state, action) => {
      state.processVersionRemovingStatus.isPending = false;
      state.processVersionRemovingStatus.isDone = true;
      state.processVersionRemovingStatus.isError = false;

      if (Array.isArray(action.payload.messages)) {
        action.payload.messages.forEach((item) => {
          customizedToast(item.message, "success");
        });
      }
    });
    builder.addCase(removeAsyncVersionOfProcess.rejected, (state, action) => {
      state.processVersionRemovingStatus.isPending = false;
      state.processVersionRemovingStatus.isDone = false;
      state.processVersionRemovingStatus.isError = true;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncSingleProcessVersions.pending, (state, action) => {
      state.processVersion.isPending = true;
      state.processVersion.isDone = false;
      state.processVersion.isError = false;
    });
    builder.addCase(
      getAsyncSingleProcessVersions.fulfilled,
      (state, action) => {
        state.processVersion.isPending = false;
        state.processVersion.isDone = true;
        state.processVersion.isError = false;

        state.processVersion.data = action.payload;
      }
    );
    builder.addCase(getAsyncSingleProcessVersions.rejected, (state, action) => {
      state.processVersion.isPending = false;
      state.processVersion.isDone = false;
      state.processVersion.isError = true;
    });
    //
    //
    //
    //
    //
    //
  },
});
