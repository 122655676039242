// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Models
import { I_SingleUserData } from "../../Models/interfaces";
import { I_EditUserInformations } from "../../Models/sendingDataSchema";
// Models

// Services
import { getSingleAccessibleUserDataService } from "../../Services/Users/get/getSingleAccessibleUserDataService";
import { editUserInformationService } from "../../Services/Users/post/editUserInformationService";
// Services

// Utils
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { resetReferenceOfObject } from "../../Utils/resetReferenceOfObject";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Utils

const initialState: I_SingleUserData & {
  isGettingSingleUserPending: boolean;
  isUserDataChangePending: boolean;
  isEditingGroupsPending: boolean;
  isUserChangeDataDone: boolean;
} = {
  username: "",
  firstname: "",
  lastname: "",
  email: "",
  extraIdentityInformation: {
    // nationalNumber: {
    //   value: "شماره شناسنامه تستی",
    //   identityAttribute: {
    //     id: 0,
    //     key: "",
    //     title: "",
    //     type: {
    //       value: "",
    //       title: "",
    //     },
    //     required: false,
    //     unique: false,
    //     searchable: false,
    //     availableOnAccessList: false,
    //     minimumValue: 0,
    //     maximumValue: 0,
    //   },
    //   identityOption: {
    //     id: 0,
    //     title: "",
    //   },
    // },
    // nationalNumber2: {
    //   value: "شماره شناسنامه تست222ی",
    //   identityAttribute: {
    //     id: 0,
    //     key: "",
    //     title: "",
    //     type: {
    //       value: "",
    //       title: "",
    //     },
    //     required: false,
    //     unique: false,
    //     searchable: false,
    //     availableOnAccessList: false,
    //     minimumValue: 0,
    //     maximumValue: 0,
    //   },
    //   identityOption: {
    //     id: 0,
    //     title: "",
    //   },
    // },
  },
  accessingGroupsChangeable: false,
  accessingProcessChangeable: false,
  groupMembership: [],

  // added by Developer !
  isGettingSingleUserPending: true,
  isUserDataChangePending: false,
  isEditingGroupsPending: false,
  isUserChangeDataDone: false,
  // added by Developer !
};

export const getAsyncSingleUserAccessibleData = createAsyncThunk(
  "singleAccessibleUser/getSingleUser",
  async (_data: { userToken: string; userName: string }) => {
    try {
      const { data } = await getSingleAccessibleUserDataService(
        _data.userToken,
        _data.userName
      );

      return data.result;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncSingleUserAccessibleDataWithoutPending = createAsyncThunk(
  "singleAccessibleUser/getSingleUserWhitoutPending",
  async (_data: { userToken: string; userName: string }) => {
    try {
      const { data } = await getSingleAccessibleUserDataService(
        _data.userToken,
        _data.userName
      );

      return data.result;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const editAsyncUserInformation = createAsyncThunk(
  "accessibleUsers/editUserInformation",
  async (_data: {
    userName: string;
    userToken: string;
    newData: I_EditUserInformations;
  }) => {
    try {
      const { data } = await editUserInformationService(
        _data.userToken,
        _data.userName,
        _data.newData
      );
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const singleAccessibleUserSlice = createSlice({
  name: "singleAccessibleUser",
  initialState,
  reducers: {
    resetGetUserDataPending: (state) => {
      state = resetReferenceOfObject(initialState);
      state.isGettingSingleUserPending = true;
    },
    resetEditingUserData: (state) => {
      state.isUserChangeDataDone = false;
      state.isGettingSingleUserPending = false;
    },
    resetRecevedUserData: (state) => {
      state.isGettingSingleUserPending = true;
      state.extraIdentityInformation = {};
      state.email = "";
      state.username = "";
      state.firstname = "";
      state.lastname = "";
      state.email = "";
    },
  },
  extraReducers: (builder) => {
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncSingleUserAccessibleData.pending, (state) => {
      state.isGettingSingleUserPending = true;
    });
    builder.addCase(
      getAsyncSingleUserAccessibleData.fulfilled,
      (state, action) => {
        state.isGettingSingleUserPending = false;

        //
        state.accessingGroupsChangeable =
          action.payload!.accessingGroupsChangeable;
        //
        state.accessingProcessChangeable =
          action.payload!.accessingProcessChangeable;
        //
        state.email = action.payload!.email;
        //
        state.extraIdentityInformation =
          action.payload!.extraIdentityInformation;
        //
        state.firstname = action.payload!.firstname;
        //
        state.groupMembership = action.payload!.groupMembership;
        //
        state.lastname = action.payload!.lastname;
        //
        state.username = action.payload!.username;
      }
    );
    builder.addCase(getAsyncSingleUserAccessibleData.rejected, (state) => {
      state.isGettingSingleUserPending = false;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(
      getAsyncSingleUserAccessibleDataWithoutPending.pending,
      (state) => {}
    );
    builder.addCase(
      getAsyncSingleUserAccessibleDataWithoutPending.fulfilled,
      (state, action) => {
        state.accessingGroupsChangeable =
          action.payload!.accessingGroupsChangeable;
        //
        state.accessingProcessChangeable =
          action.payload!.accessingProcessChangeable;
        //
        state.email = action.payload!.email;
        //
        state.extraIdentityInformation =
          action.payload!.extraIdentityInformation;
        //
        state.firstname = action.payload!.firstname;
        //
        state.groupMembership = action.payload!.groupMembership;
        //
        state.lastname = action.payload!.lastname;
        //
        state.username = action.payload!.username;
      }
    );
    builder.addCase(
      getAsyncSingleUserAccessibleDataWithoutPending.rejected,
      (state) => {}
    );
    //
    //
    //
    //
    //
    //
    builder.addCase(editAsyncUserInformation.pending, (state, action) => {
      state.isUserDataChangePending = true;
      state.isUserChangeDataDone = false;
    });
    builder.addCase(editAsyncUserInformation.fulfilled, (state, action) => {
      state.isUserDataChangePending = false;
      const err: any = action.payload;

      if (err?.response?.data?.messages) {
        for (
          let i: number = 0;
          i <= err?.response.data.messages.length - 1;
          i++
        ) {
          customizedToast(err?.response.data.messages[i].message, "error");
        }
        return;
      } else {
        console.log(action.payload);
        for (let i: number = 0; i <= action.payload.messages.length - 1; i++) {
          customizedToast(action.payload.messages[i].message, "success");
        }
        state.isUserChangeDataDone = true;
      }
    });
    builder.addCase(editAsyncUserInformation.rejected, (state, action) => {
      state.isUserDataChangePending = false;
      state.isUserChangeDataDone = false;
    });
    //
    //
    //
    //
    //
    //
  },
});

export const singleAccessibleUserSliceActions = {
  singleAccessibleUserSliceActions: singleAccessibleUserSlice.actions,
};
