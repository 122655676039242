// React
import React from "react";
// React

// CSS
import styles from "./PendingManager.module.css";
import { Spinner } from "reactstrap";
// CSS

type PendingManagerProps = {
  isPending: boolean;
  isError: boolean;
  isDone: boolean;

  onError: () => void | any;

  showAfterDone: React.ReactElement;

  pendingText?: string;
};

const PendingManager: React.FunctionComponent<PendingManagerProps> = ({
  isDone,
  isError,
  isPending,
  onError,
  showAfterDone,
  pendingText,
}) => {
  return (
    <>
      {!isDone && (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          {isPending && !isError && !isDone ? (
            <div className="d-flex flex-column align-items-center justify-content-center mt-5 w-100">
              <p className="w-100 text-center text-sm rtl">
                در حال بارگذاری {pendingText}
              </p>
              <Spinner />
            </div>
          ) : null}

          {!isPending && isError && !isDone ? (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <p className="w-100 text-center text-sm "></p>
              <button
                className={`${styles.errorBtn} px-6 py-1 text-sm  text-white rounded-lg`}
                onClick={onError}
              >
                تلاش دوباره
              </button>
            </div>
          ) : null}
        </div>
      )}
      {isDone && !isPending && !isError ? showAfterDone : null}
    </>
  );
};

export default PendingManager;
