// React
import React, { useContext, useRef, useState } from "react";
// React

// CSS
import styles from "./CustomInput1.module.css";
// CSS

// Contexts
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

type CustomInput1Props = {
  // keyForShowOnTop?: string;
  placeHolder: string;
  inputType: "number" | "text" | "password" | "email";
  defaultValue: string;

  onChange: (data: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (data: React.FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: (data: any) => void;

  keyStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;

  keyClassName?: string;
  inputClassName?: string;
  containerClassName?: string;

  value: string;

  dontShowSearchIcon?: boolean;

  disabled?: boolean;

  attributes?: { [key: string]: string | boolean };

  id?: string;

  required?: boolean;

  onContainerClick?: (e: any) => void;

  additionalElement?: React.ReactNode;
  refReturner?: (ref: React.RefObject<HTMLInputElement>) => void;

  placeHolderClassName?: string;
};

const CustomInput1: React.FunctionComponent<CustomInput1Props> = ({
  // keyForShowOnTop,
  inputType,
  onBlur,
  onChange,
  placeHolder,
  containerClassName,
  containerStyle,
  inputClassName,
  inputStyle,
  keyClassName,
  keyStyle,
  defaultValue,
  value,
  dontShowSearchIcon,
  disabled,
  onKeyPress,
  attributes,
  id,
  required,
  onContainerClick,
  additionalElement,
  refReturner,
  placeHolderClassName,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const isNightMode = useContext(IsNightModeOnContext);

  if (refReturner) {
    if (inputRef) {
      refReturner(inputRef);
    }
  }

  if (disabled) return <></>;

  return (
    <div
      className={`${styles.customInput1Container} xxfw d-flex flex-column align-items-end ${containerClassName}`}
      style={containerStyle}
      onClick={(e) => {
        inputRef.current?.focus();
        if (onContainerClick) {
          onContainerClick(e);
        }
      }}
    >
      {/* {keyForShowOnTop ? (
        <p
          className={`${styles.keyClassName} ${keyClassName}`}
          style={keyStyle}
        >
          {keyForShowOnTop}
          {required && <span style={{ color: "red" }}>*</span>}
        </p>
      ) : null} */}

      {!disabled && (
        <span
          className={`${styles.placeHolderSpan}  ${
            value.length !== 0 ||
            String(defaultValue).length !== 0 ||
            isFocused === true
              ? `${styles.focused} ${isNightMode ? styles.nightFocus : ""} `
              : ` ${isNightMode ? styles.nightBlur : ""}`
          } ${placeHolderClassName}`}
        >{`${required ? `* ${placeHolder}` : placeHolder} `}</span>
      )}
      <div className={`${styles.inputContainer} `}>
        <input
          type={inputType}
          onChange={onChange}
          ref={inputRef}
          onBlur={(e) => {
            setIsFocused(false);
            if (onBlur) {
              onBlur(e);
            }
          }}
          onKeyUp={onKeyPress}
          onFocus={() => {
            if (disabled) return;
            setIsFocused(true);
          }}
          // placeholder={`${required ? `* ${placeHolder}` : placeHolder}`}
          className={`${styles.inputClassName} ${inputClassName}`}
          style={{
            ...inputStyle,
            color: disabled ? "gray" : "",
            direction: inputType === "email" ? "ltr" : "rtl",
          }}
          defaultValue={defaultValue}
          value={value}
          {...attributes}
          id={id}
          data-disabled={disabled}
        />
        {dontShowSearchIcon ? null : (
          <span className={`${styles.mySvg} icon-search`}></span>
        )}
        {additionalElement ? additionalElement : null}
      </div>
    </div>
  );
};

export default CustomInput1;
