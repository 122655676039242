// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./Tasks.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../app/store";
import { getAsyncTasks, taskSlice } from "../../Features/TasksSlice/tasksSlice";
// Redux

// Modules
import { Link, useSearchParams } from "react-router-dom";
// Modules

// Utils
import Pagination from "../../Utils/Pagination/Pagination";
import { Spinner } from "reactstrap";
import { CSVLink } from "react-csv";
// Utils

// Components
// Components

// Icons
import { CgDetailsMore } from "react-icons/cg";
// Icons

// Contexts
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import { MdDone } from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
import { searchAndBackSlice } from "../../Features/SearchAndBackSlice/searchAndBackSlice";
import { FaAngleDown } from "react-icons/fa6";
import CustomInput1 from "../../Utils/CustomInput1/CustomInput1";
// Contexts

const Tasks = () => {
  const dispatch = useAppDispatch();

  const isNightOn = useContext(IsNightModeOnContext);

  const [searchParam, setSearchParam] = useSearchParams();

  const tasks = useAppSelector((state) => state.tasks.taskData);
  const currLanguage = useAppSelector((state) => state.currLanguage.language);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const {
    tasksPage: { pageNumber, size },
  } = useAppSelector((state) => state.searchAndBack);

  const [formsData, setFormsData] = useState<{
    page: number;
    size: number;
    orderByDueDate: boolean;
    orderByFollowUpDate: boolean;
    orderByPriority: boolean;
    descendingSort: boolean;
    orderByCreateDate: boolean;
    search: string;
  }>({
    page: Number(searchParam.get("page")) || pageNumber || 1,
    size: Number(searchParam.get("size")) || size || 10,
    orderByDueDate: false,
    orderByFollowUpDate: false,
    orderByPriority: false,
    descendingSort: false,
    search: searchParam.get("search") || "",
    orderByCreateDate: false,
  });

  const [isMounted, setIsMounted] = useState<boolean>(false);
  //
  const [sortShowArrowStatusOptions, setShowSortArrowStatusOptions] = useState<{
    forPriority: boolean;
    forDueDate: boolean;
    forFollowUpdate: boolean;
    orderByCreateDate: boolean;
  }>({
    forPriority: false,
    forDueDate: false,
    forFollowUpdate: false,
    orderByCreateDate: false,
  });

  //
  useEffect(() => {
    setIsMounted(true);
  }, []);
  //
  useEffect(() => {
    if (!isMounted) return;
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncTasks({
          _data: formsData,
          userToken,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, formsData, userToken, isMounted]);

  useEffect(() => {
    return () => {
      dispatch(taskSlice.actions.resetTasks());
    };
  }, [dispatch]);
  //
  useEffect(() => {
    setSearchParam(
      {
        page: String(formsData.page) || 1 + "",
        size: String(formsData.size) || 10 + "",
        search: String(formsData.search) || "",
      },
      {
        replace: true,
      }
    );
  }, [formsData, setSearchParam, searchParam]);

  const csvReport = {
    headers: [
      {
        label: "شمارنده",
        key: "id",
      },
      {
        label: "نام",
        key: "title",
      },
      {
        label: "شمارنده اجرا",
        key: "proceesInstanceId",
      },
      {
        label: "شمارنده فرآیند",
        key: "processId",
      },
      {
        label: "سر رسید",
        key: "dueDate",
      },
      {
        label: "اهمیت",
        key: "priority",
      },
      {
        label: "کد ساختار",
        key: "schemaId",
      },
    ],
    data: tasks.content.map((item, index) => ({
      id: item.taskId,
      title: item.taskTitle,
      proceesInstanceId: item.processInstanceId,
      processId: item.processId,
      dueDate: item.dueDate,
      priority: item.priorityType.title,
      schemaId: item.schemaId,
    })),
    filename: "tasks.csv",
  };

  return (
    <div
      className={`${styles.tasksContainer} ${
        isNightOn ? "nightBg1" : ""
      } w-100 d-flex align-items-center flex-column`}
    >
      <div className="w-100 d-flex flex-row-reverse align-items-center justify-content-between">
        <p
          style={{
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          لیست وظایف
        </p>
        <div className="d-flex flex-col align-items-center">
          <CSVLink
            {...csvReport}
            style={{
              textDecoration: "none",
              color: "#fff",
              background: "#3AC150",
              marginBottom: "1rem",
              padding: "6px 10px",
              marginLeft: "8px",
              borderRadius: "8px",
            }}
          >
            csv خروجی
          </CSVLink>
        </div>
      </div>
      <div className="w-100 d-flex flex-row justify-content-end my-2">
        <CustomInput1
          defaultValue={String(formsData.search)}
          inputType="text"
          onBlur={() => {}}
          onChange={(e) => {
            e.stopPropagation();
            setFormsData((prevState) => ({
              ...prevState,
              search: e.target.value,
              page: 1,
            }));
          }}
          placeHolder={"جستجو"}
          value={String(formsData.search)}
          onContainerClick={(e) => {
            e.stopPropagation();
          }}
        />
      </div>
      {tasks.isPending ? (
        <div
          className={`${styles.loadingContainer} w-100 d-flex flex-column align-items-center justify-content-center mt-4`}
        >
          <p>در حال بارگذاری</p>
          <Spinner />
        </div>
      ) : (
        <>
          {window.innerWidth > 1000 ? (
            <>
              {tasks.content.length === 0 ? (
                <div
                  className={`${styles.loadingContainer} w-100 d-flex flex-column align-items-center justify-content-center mt-4`}
                >
                  موردی وجود ندارد
                </div>
              ) : (
                <div
                  className={`${styles.myTable} ${
                    isNightOn ? "nightBg2 pcNight" : ""
                  }  w-100 d-flex mt-3 flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    {/* <p
                      className={`${styles.priorityTab} `}
                      onClick={() => {
                        if (formsData.orderByPriority === true) {
                          setFormsData((prevState) => ({
                            ...prevState,
                            descendingSort: !prevState.descendingSort,
                          }));
                          return;
                        }
                        setFormsData((prevState) => ({
                          ...prevState,
                          orderByDueDate: false,
                          orderByFollowUpDate: false,
                          orderByPriority: true,
                          descendingSort: false,
                          orderByCreateDate: false,
                        }));
                      }}
                      onMouseEnter={() => {
                        setShowSortArrowStatusOptions({
                          forDueDate: false,
                          forFollowUpdate: false,
                          forPriority: true,
                          orderByCreateDate: false,
                        });
                      }}
                      onMouseLeave={() => {
                        setShowSortArrowStatusOptions({
                          forDueDate: false,
                          forFollowUpdate: false,
                          forPriority: false,
                          orderByCreateDate: false,
                        });
                      }}
                    >
                      {!formsData.orderByPriority &&
                        sortShowArrowStatusOptions.forPriority && (
                          <FaAngleDown
                            style={{
                              transform: "rotate(180deg)",
                            }}
                          />
                        )}
                      {formsData.orderByPriority && (
                        <FaAngleDown
                          style={{
                            transform: !formsData.descendingSort
                              ? "rotate(180deg)"
                              : "",
                          }}
                        />
                      )}
                      اولویت
                    </p> */}
                    <p
                      className={`${styles.dueDate}`}
                      onClick={() => {
                        if (formsData.orderByDueDate === true) {
                          setFormsData((prevState) => ({
                            ...prevState,
                            descendingSort: !prevState.descendingSort,
                          }));
                          return;
                        }
                        setFormsData((prevState) => ({
                          ...prevState,
                          orderByDueDate: true,
                          orderByFollowUpDate: false,
                          orderByPriority: false,
                          descendingSort: false,
                          orderByCreateDate: false,
                        }));
                      }}
                      onMouseEnter={() => {
                        setShowSortArrowStatusOptions({
                          forDueDate: true,
                          forFollowUpdate: false,
                          forPriority: false,
                          orderByCreateDate: false,
                        });
                      }}
                      onMouseLeave={() => {
                        setShowSortArrowStatusOptions({
                          forDueDate: false,
                          forFollowUpdate: false,
                          forPriority: false,
                          orderByCreateDate: false,
                        });
                      }}
                    >
                      {!formsData.orderByDueDate &&
                        sortShowArrowStatusOptions.forDueDate && (
                          <FaAngleDown
                            style={{
                              transform: "rotate(180deg)",
                            }}
                          />
                        )}
                      {formsData.orderByDueDate && (
                        <FaAngleDown
                          style={{
                            transform: !formsData.descendingSort
                              ? "rotate(180deg)"
                              : "",
                          }}
                        />
                      )}
                      تاریخ سررسید
                    </p>
                    <p
                      className={`${styles.importedTime}`}
                      onClick={() => {
                        if (formsData.orderByCreateDate === true) {
                          setFormsData((prevState) => ({
                            ...prevState,
                            orderByCreateDate: !prevState.orderByCreateDate,
                          }));
                          return;
                        }
                        setFormsData((prevState) => ({
                          ...prevState,
                          orderByDueDate: false,
                          orderByFollowUpDate: false,
                          orderByPriority: false,
                          descendingSort: false,
                          orderByCreateDate: true,
                        }));
                      }}
                      onMouseEnter={() => {
                        setShowSortArrowStatusOptions({
                          forDueDate: false,
                          forFollowUpdate: false,
                          forPriority: false,
                          orderByCreateDate: true,
                        });
                      }}
                      onMouseLeave={() => {
                        setShowSortArrowStatusOptions({
                          forDueDate: false,
                          forFollowUpdate: false,
                          forPriority: false,
                          orderByCreateDate: false,
                        });
                      }}
                    >
                      {!formsData.orderByCreateDate &&
                        sortShowArrowStatusOptions.orderByCreateDate && (
                          <FaAngleDown
                            style={{
                              transform: "rotate(180deg)",
                            }}
                          />
                        )}
                      {formsData.orderByCreateDate && (
                        <FaAngleDown
                          style={{
                            transform: !formsData.orderByCreateDate
                              ? "rotate(180deg)"
                              : "",
                          }}
                        />
                      )}
                      زمان شروع وظیفه
                    </p>
                    <p className={`${styles.description}`}>توضیحات</p>
                    <p className={`${styles.processName}`}> نام فرآیند</p>
                    <p
                      className={`${styles.processInstanceTitle}`}
                      onClick={() => {
                        if (formsData.orderByFollowUpDate === true) {
                          setFormsData((prevState) => ({
                            ...prevState,
                            descendingSort: !prevState.descendingSort,
                          }));
                          return;
                        }
                        setFormsData((prevState) => ({
                          ...prevState,
                          orderByDueDate: false,
                          orderByPriority: false,
                          orderByFollowUpDate: true,
                          descendingSort: false,
                          orderByCreateDate: false,
                        }));
                      }}
                      onMouseEnter={() => {
                        setShowSortArrowStatusOptions({
                          forDueDate: false,
                          forFollowUpdate: true,
                          forPriority: false,
                          orderByCreateDate: false,
                        });
                      }}
                      onMouseLeave={() => {
                        setShowSortArrowStatusOptions({
                          forDueDate: false,
                          forFollowUpdate: false,
                          forPriority: false,
                          orderByCreateDate: false,
                        });
                      }}
                    >
                      {!formsData.orderByFollowUpDate &&
                        sortShowArrowStatusOptions.forFollowUpdate && (
                          <FaAngleDown
                            style={{
                              transform: "rotate(180deg)",
                            }}
                          />
                        )}
                      {formsData.orderByFollowUpDate && (
                        <FaAngleDown
                          style={{
                            transform: !formsData.descendingSort
                              ? "rotate(180deg)"
                              : "",
                          }}
                        />
                      )}
                      عنوان
                    </p>
                    <p className={`${styles.taskName}`}>نام وظیفه</p>
                  </div>
                  <div
                    className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                  >
                    {tasks.content.map((item) => (
                      <div
                        key={item.taskId}
                        className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                      >
                        <p className={`${styles.operationTab}`}>
                          <Link
                            to={`/tasks/task-detail/${item.taskId}`}
                            className={` operationEvent editBtn`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={currLanguage.more}
                          >
                            <CgDetailsMore />
                          </Link>
                        </p>

                        {/* <p
                          className={`${styles.priorityTab}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            item.priorityType.title.length >= 20
                              ? item.priorityType.title
                              : ""
                          }
                        >
                          {item.priorityType.title.length >= 20
                            ? "..." + item.priorityType.title.substring(0, 19)
                            : item.priorityType.title}
                        </p> */}

                        <p className={`${styles.dueDate}`}>
                          {item?.dueDate?.jalaliDate || "بدون تاریخ"}
                        </p>
                        <p className={`${styles.importedTime}`}>
                          {item?.createDate?.jalaliDate || "بدون تاریخ"}
                        </p>

                        <p
                          className={`${styles.description}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            item.description
                              ? item.description.length >= 20
                                ? item.description
                                : ""
                              : ""
                          }
                        >
                          {Boolean(item.description)
                            ? item.description.length >= 20
                              ? "..." + item.description.substring(0, 19)
                              : item.description
                            : "فاقد توضیحات"}
                        </p>

                        <p className={`${styles.processName}`}>
                          {item.processName}
                        </p>

                        <p
                          className={`${styles.processInstanceTitle} rtl truncated`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={item.processInstanceTitle}
                        >
                          {item.processInstanceTitle || "بدون نام"}
                          {/* {item.followUpDate ? (
                            <MdDone
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          ) : (
                            <BsDashLg
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          )} */}
                        </p>

                        <p
                          className={`${styles.taskName}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            item.taskTitle.length >= 20 ? item.taskTitle : ""
                          }
                        >
                          {item.taskTitle.length >= 20
                            ? "..." + item.taskTitle.substring(0, 19)
                            : item.taskTitle}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              )}
            </>
          ) : (
            <div
              className={`${styles.cards} w-100 d-flex flex-column align-items-center`}
            >
              <>
                {tasks.content.map((item) => (
                  <div key={item.taskId} className="w-100">
                    <div
                      className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                    >
                      <p>{item.taskTitle}</p>
                      <p>نام وظیفه </p>
                    </div>
                    <div
                      className={`${styles.tableRow} ${
                        isNightOn ? "nightBg2" : ""
                      } w-100 d-flex align-items-center justify-content-between `}
                    >
                      <p>
                        <span>توضیحات</span>
                        <span>{item.description || "فاقد توضیحات"}</span>
                      </p>
                      <p>
                        <span>بروزرسانی</span>
                        <span>
                          {item.followUpDate ? (
                            <MdDone
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          ) : (
                            <BsDashLg
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          )}
                        </span>
                      </p>
                      <p>
                        <span>سررسید</span>
                        <span>{item.dueDate?.jalaliDate || "بدون تاریخ"}</span>
                      </p>
                      <p>
                        <span>اولویت</span>
                        <span>{item.priorityType.title}</span>
                      </p>

                      <p>
                        <span>عملیات</span>
                        <Link
                          to={`/tasks/task-detail/${item.taskId}`}
                          className={` operationEvent editBtn`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={currLanguage.more}
                        >
                          <CgDetailsMore />
                        </Link>
                      </p>
                    </div>
                  </div>
                ))}
              </>
            </div>
          )}
        </>
      )}

      <Pagination
        dataForPagination={{
          currPage: formsData.page,
          size: formsData.size,
          totalPages: tasks.totalPages,
        }}
        onPageNumberChange={(page) => {
          setFormsData((prevState) => ({
            ...prevState,
            page,
          }));
          dispatch(
            searchAndBackSlice.actions.setPageNumberAndSizeOfTasksPage({
              page,
              size: formsData.size,
            })
          );
        }}
        onPageNumbersClick={(page: number) => {
          setFormsData((prevState) => ({
            ...prevState,
            page,
          }));
          dispatch(
            searchAndBackSlice.actions.setPageNumberAndSizeOfTasksPage({
              page,
              size: formsData.size,
            })
          );
        }}
        onPageSizeChange={(size) => {
          setFormsData((prevState) => ({
            ...prevState,
            size: Number(size),
          }));
          dispatch(
            searchAndBackSlice.actions.setPageNumberAndSizeOfTasksPage({
              page: formsData.page,
              size,
            })
          );
        }}
        search={""}
      />
    </div>
  );
};

export default Tasks;
