import { AxiosResponse } from "axios";
import { _axios } from "../../../Base_Axios_Config/_axios";
import { I_HistoryDetail, I_Message } from "../../../../Models/interfaces";

export const getSingleActivityHistoryDetailWithProcessInstanceService =
  (_data: {
    userToken: string;
    _data: { processInstanceId: string | number; historyId: string };
  }): Promise<
    AxiosResponse<{
      error: number;
      messages: I_Message[];
      result: I_HistoryDetail;
    }>
  > => {
    return _axios.get(
      `/processes/running-instances/${_data._data.processInstanceId}/history/activities/${_data._data.historyId}/detail
    `,
      {
        headers: {
          Authorization: `Bearer ${_data.userToken}`,
        },
      }
    );
  };
