// React
import { useEffect } from "react";
// React

// Event Manager
import {
  addPersianDatePicker_EVENT,
  removePersianDatePicker_EVENT,
} from "../Events/managePersianDatePicker_EVENT";
// Event Manager

const usePersianDatePickerEventHandler = (isPending: boolean) => {
  useEffect(() => {
    if (isPending) return;
    // const config = { attributes: true, childList: true, subtree: true };
    // const observer = new MutationObserver((mutations, observer) => {
    //   addPersianDatePicker_EVENT();
    // });

    // observer.observe(
    //   (() => {
    //     return document;
    //   })(),
    //   config
    // );

    const timeOut = setTimeout(() => {
      addPersianDatePicker_EVENT();
    }, 1200);

    return () => {
      // observer.disconnect();
      clearTimeout(timeOut);
      removePersianDatePicker_EVENT(() => {});
    };
  }, [isPending]);
};

export default usePersianDatePickerEventHandler;
