import { AxiosResponse } from "axios";
import { I_Message, I_TenantsRecevedData } from "../../../Models/interfaces";
import { _axios } from "../../Base_Axios_Config/_axios";

export const getAllTenantsService = async (_data: {
  userToken: string;
  _data: {
    page: number;
    size: number;
    search: string;
    state: number;
  };
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_TenantsRecevedData;
  }>
> => {
  return _axios.get(
    `/tenancy/accessible-tenants-information?page=${_data._data.page}&size=${_data._data.size}&search=${_data._data.search}&state=${_data._data.state}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
