import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
import { I_Message } from "../../../Models/interfaces";

export const getProcessSchemaAs_XML_Service = async (_data: {
  userToken: string;
  processKey: string;
  tenantId?: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {
      bpmnXml: string;
    };
  }>
> => {
  return _axios.get(
    `/processes/${_data.processKey}/schema?tenant=${
      Boolean(_data.tenantId) ? _data.tenantId : ""
    }`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
