// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import {
  I_Message,
  I_StartingFormRecevedData,
} from "../../../Models/interfaces";
// Models

export const getStartingFormService = async (
  userToken: string,
  processKey: string,
  tenant?: string
): Promise<
  AxiosResponse<{
    error: number;
    message: I_Message[];
    result: I_StartingFormRecevedData;
  }>
> => {
  return _axios.get(
    `/processes/${processKey}/starting-form?tenant=${tenant ? tenant : ""}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
