// React
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
// React

// CSS
import styles from "./AddNewForm.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  addAsyncForm,
  formsSlice,
} from "../../../Features/FormsSlice/FormsSlice";
// Redux

// Components
import { FormBuilder } from "@formio/react";
// Components

// Modules
import { useNavigate, useParams } from "react-router-dom";
// Modules

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import CustomFormioComponents from "../../../Utils/CustomFormioComponents/CustomFormioComponents";
import { Spinner } from "reactstrap";
import { formioCustomComponentAdderFunction } from "../../../Utils/FormioUtils/formioCustomComponentAdderFunction";
// Utils

// Data
import { formIoLanguagePack } from "../../../Language/formioLanguage";
// Data

// Redux
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
import {
  formSamplesSlice,
  getAsyncFormSamples,
} from "../../../Features/FormSamples/formSamplesSlice";
// Redux

// Contexts
// import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
// import { ExecutingPermissionsContext } from "../../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
// import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Components
import AddFormSampleModal from "../../FormSamples/AddNewFormSample/AddFormSampleModal/AddFormSampleModal";
import LoadFormFROMsampleOrAddToCurrentForm from "../../../Components/LoadFormFROMsampleOrAddToCurrentForm/LoadFormFROMsampleOrAddToCurrentForm";
// Components

// Hooks
import useReRenderMap from "../../../Hooks/useReRenderMap";
import { useMapRenderInLittleModal } from "../../../Hooks/MapHooks/useMapRenderInLittleModal";
import { useFormioDialogChanger } from "../../../Hooks/useFormioDialogChanger";
// Hooks

// Constants
import { formioDialogForDatePicker } from "../../../Constants/constants";
import { permissionChecker } from "../../../Modules/permissionChecker";
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
// Constants

export type MapDataRefType = {
  lat: string;
  lon: string;
};

const AddNewForm = () => {
  const [form, setForm] = useState({
    display: "form",
    components: [],
  });
  // constants
  const { processKey, persianProcessName, tenantId: tenantInUrl } = useParams();
  const navigate = useNavigate();
  const isNightMode = useContext(IsNightModeOnContext);
  // constants

  //   Redux
  const dispatch = useAppDispatch();
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { isAddingFormPending, isFormAdded } = useAppSelector(
    (state) => state.forms
  );
  const currLanguage = useAppSelector(
    (state) => state.currLanguage.currLanguage
  );
  //   Redux

  //   localStates
  //
  const [isInputReadingPending, setIsReadInputPending] =
    useState<boolean>(false);
  //
  const [nForm, setNForm] = useState({});
  console.log(nForm);
  //
  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const [newFormData, setNewFormData] = useState<{
    tanentId: string;
    title: string;
    formKey: string;
  }>({
    tanentId: selectedTenant.tenantId,
    title: "",
    formKey: "",
  });
  const [modalData, setModalData] = useState<{
    selectOptionValue: string;
    formUrl: string;
  }>({
    selectOptionValue: "",
    formUrl: "",
  });
  const [isModalInScreen, setIsModalInScreen] = useState<boolean>(false);
  //   localStates

  const mapDataRef = useRef<MapDataRefType>({
    lat: "",
    lon: "",
  });

  const [forceReRender, setForceReRender] = useState<boolean>(false);

  const formioOptions: any = {
    language: currLanguage,
    i18n: formIoLanguagePack.i18n,
  };

  const formAttacher = useMemo(
    () => (
      <LoadFormFROMsampleOrAddToCurrentForm
        form={form}
        modalData={modalData}
        setForm={setForm}
        setIsModalInScreen={setIsModalInScreen}
        setIsReadInputPending={setIsReadInputPending}
        setModalData={setModalData}
      />
    ),
    [form, modalData]
  );
  //
  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("افزودن فرم"));
  }, [dispatch]);
  //
  useEffect(() => {
    if (isFormAdded) {
      if (!tenantInUrl) {
        navigate(
          `/forms/show-form-by-process-key/${processKey}/${persianProcessName}`
        );
        return;
      }
      navigate(
        `/forms/show-form-by-process-key/${processKey}/${persianProcessName}/${tenantInUrl}`
      );
      return;
    }

    return () => {
      dispatch(formsSlice.actions.resetAddingStateOfForm());
    };
  }, [
    isFormAdded,
    dispatch,
    navigate,
    processKey,
    persianProcessName,
    tenantInUrl,
  ]);
  //
  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncFormSamples({
          userToken,
          _data: {
            page: 1,
            search: "",
            size: 999,
            type: "Form-IO",
            tenant: selectedTenant.tenantId,
            id: "",
          },
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, userToken, selectedTenant]);
  //
  useEffect(() => {
    return () => {
      dispatch(formSamplesSlice.actions.resetRecevedFormSamplesState());
    };
  }, [dispatch]);
  //

  useReRenderMap({
    forceReRender,
    form,
    isPending: false,
  });

  useMapRenderInLittleModal(false);
  useFormioDialogChanger(formioDialogForDatePicker);

  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);

  return (
    <div
      className={`${
        styles.addFormContainer
      } d-flex flex-column align-items-center w-100 ${
        isNightMode ? "p-2" : ""
      }`}
    >
      <div className={`${styles.topItems} ${isNightMode ? "nightBg1" : ""}`}>
        <div className="w-100 d-flex flex-row align-items-center justify-content-between">
          <button
            className={` operationEvent   csvBtn   align-self-start d-flex align-items-center justify-content-center`}
            onClick={() => {
              if (isAddingFormPending) return;
              if (
                !permissionChecker(
                  allGroups,
                  [
                    "Tenant_Full_Manager",
                    "Tenant_Basic_Manager",
                    "Process_Form_Editor",
                    "Process_Full_Manager",
                  ],
                  isFullRenderd
                )
              )
                return;
              dispatch(
                addAsyncForm({
                  userToken,
                  _data: {
                    formType: "Form_Io",
                    formData: form,
                    processKey: String(processKey),
                    tenantId: newFormData.tanentId,
                    title: newFormData.title,
                    key: newFormData.formKey,
                  },
                })
              );
            }}
          >
            {isAddingFormPending ? (
              <>
                <Spinner />
              </>
            ) : (
              <>تایید</>
            )}
          </button>
          <p className="boldText1">
            افزودن فرم حدید به فرآیند {">"} {persianProcessName}
          </p>
        </div>

        {/* FORM WILL CREATE IN HERE AND ATTACH TO THE FORM BUILDER ! */}
        {/*                   */}
        {formAttacher}
        {/*                    */}
        {/* FORM WILL CREATE IN HERE AND ATTACH TO THE FORM BUILDER ! */}

        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        <div
          className={`${styles.formInformation} w-100 d-flex align-items-center justify-content-between my-2 flex-wrap`}
        >
          <CustomInput1
            defaultValue={newFormData.formKey}
            value={newFormData.formKey}
            onChange={(e) => {
              setNewFormData((prevState) => ({
                ...prevState,
                formKey: e.target.value,
              }));
            }}
            onBlur={() => {}}
            inputType="text"
            placeHolder="شناسه یکتا فرم"
            //   keyForShowOnTop="شناسه یکتا فرم"
            dontShowSearchIcon
            containerClassName="w-48"
          />
          <CustomInput1
            defaultValue={newFormData.title}
            value={newFormData.title}
            onChange={(e) => {
              setNewFormData((prevState) => ({
                ...prevState,
                title: e.target.value,
              }));
            }}
            onBlur={() => {}}
            inputType="text"
            placeHolder="نام فرم"
            //  keyForShowOnTop="نام فرم"
            dontShowSearchIcon
            containerClassName="w-48"
          />
          {/* {permitionCheckerWithoutToast(
            allGroups,
            permitions.canSeeTenantTextBox,
            isFullRenderd
          ) ? (
            <CustomInput1
              value={selectedTenant.tenantTitle}
              onChange={(e) => {
                return false;
              }}
              onBlur={() => {
                return false;
              }}
              inputType="text"
              placeHolder="نام سازمان"
              //      keyForShowOnTop="نام سازمان"
              dontShowSearchIcon
              disabled
            />
          ) : null} */}
        </div>
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}

        {isModalInScreen ? (
          <AddFormSampleModal
            formUrl={modalData.formUrl}
            setIsModalInScreen={setIsModalInScreen}
            dataSetter={(data) => {
              setForm((prevState: any) => ({
                ...prevState,
                components: [...prevState.components, ...data],
              }));
            }}
          />
        ) : null}
      </div>

      <div
        className={`${styles.formBuilderContainer} w-100  ${
          isNightMode ? "nightBg1" : ""
        }`}
      >
        {isInputReadingPending ? (
          <></>
        ) : (
          <>
            <div className="w-100 d-flex flex-row align-items-center justify-content-end mb-3 ">
              <CustomFormioComponents
                dataSetter={(data) => {
                  formioCustomComponentAdderFunction(data, setForm, mapDataRef);
                }}
                containerClassName=""
              />
            </div>

            <FormBuilder
              form={form}
              onUpdateComponent={(data: any) => {
                setNForm((prevState) => data);
                setForceReRender((prev) => !prev);
              }}
              onSaveComponent={(data: any) => {
                setNForm((prevState) => data);
                setForceReRender((prev) => !prev);
              }}
              onDeleteComponent={(data: any) => {
                setNForm((prevState) => data);
                setForceReRender((prev) => !prev);
              }}
              onCancelComponent={(data: any) => {
                setNForm((prevState) => data);
                setForceReRender((prev) => !prev);
              }}
              onEditComponent={(data: any) => {
                setNForm((prevState) => data);
                setForceReRender((prev) => !prev);
              }}
              onChange={(data: any) => {
                setNForm((prevState) => data);
                setForceReRender((prev) => !prev);
              }}
              options={formioOptions}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AddNewForm;
