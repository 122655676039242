import { _axios } from "../../Base_Axios_Config/_axios";

export const changeUserPasswordService = async (
  userToken: string,
  data: {
    oldPassword: string;
    newPassword: string;
  }
) => {
  return _axios.post("/profile/change-password", data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
};
