// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_ChangingStateOfARequestSendingData } from "../../../Models/sendingDataSchema";
import { I_Message } from "../../../Models/interfaces";
// Models

export const changeStateOfRegistrationRequestService = (_data: {
  userToken: string;
  _data: I_ChangingStateOfARequestSendingData;
  requestId: string | number;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {};
  }>
> => {
  return _axios.post(
    `/call-of-registrations/requests/${_data.requestId}/change-state`,
    _data._data,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
