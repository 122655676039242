// React
import React from "react";
// React

// CSS
import styles from "./RowItem.module.css";
// CSS

type RowItemProps = {
  isOdd: boolean;
  data: {
    title: string | number;
    value: string | number | React.ReactElement;
  };
  isTitleRtl?: boolean;
};

const RowItem: React.FunctionComponent<RowItemProps> = ({
  data,
  isOdd,
  isTitleRtl,
}) => {
  return (
    <div
      className={`${
        styles.rowItemContainer
      }  d-flex flex-row-reverse gap-2 rounded-2 align-items-center justify-content-between px-4 py-2 ${
        !isOdd && styles.odd
      } `}
    >
      <p className={`${styles.title} ${isTitleRtl && "rtl"}`}>{data.title}</p>
      <p className={`${styles.value}`}>{data.value}</p>
    </div>
  );
};

export default RowItem;
