// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_Message, I_TaskDetailByProcesses } from "../../../Models/interfaces";
// Models

export const getTaskDetailsFromProcessesService = (
  userToken: string,
  taskId: string
): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_TaskDetailByProcesses;
  }>
> => {
  return _axios.get(`/processes/tasks/${taskId}/detail`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
};
