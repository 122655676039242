// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Services
import { getSingleUserAccessibleGroupsService } from "../../Services/Users/get/getSingleUserAccessibleGroupsService";
// Services

// Models
import { I_GetSingleUserAccessibleGroupsSendingData } from "../../Models/sendingDataSchema";
import { I_AccessibleUserGroups } from "../../Models/interfaces";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Models

const initialState: I_AccessibleUserGroups & {
  isPending: boolean;
  isError: boolean;
} = {
  pageNumber: 0,
  pageSize: 0,
  totalPages: 0,
  totalElements: 0,
  hasNextPage: false,
  content: [],

  //   added by Developer
  isPending: false,
  isError: false,
  //   added by Developer
};

export const getAsyncAccessibleSingleUserGroup = createAsyncThunk(
  "singleUserAccessibleGroup/getAccessibleUsersGroup",
  async (_data: {
    userToken: string;
    username: string;
    searchQuery: I_GetSingleUserAccessibleGroupsSendingData;
  }) => {
    try {
      const { data } = await getSingleUserAccessibleGroupsService(
        _data.userToken,
        _data.username,
        _data.searchQuery
      );

      return data.result;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const singleUserAccessibleGroupSlice = createSlice({
  name: "singleUserAccessibleGroup",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAsyncAccessibleSingleUserGroup.pending, (state) => {
      state.isPending = true;
      state.isError = false;
    });
    builder.addCase(
      getAsyncAccessibleSingleUserGroup.fulfilled,
      (state, action) => {
        state.isPending = false;
        state.pageNumber = action.payload!.pageNumber;
        state.pageSize = action.payload!.pageSize;
        state.totalPages = action.payload!.totalPages;
        state.totalElements = action.payload!.totalElements;
        state.hasNextPage = action.payload!.hasNextPage;
        state.content = action.payload!.content;
        state.isError = false;
      }
    );
    builder.addCase(getAsyncAccessibleSingleUserGroup.rejected, (state) => {
      state.isPending = false;
      state.isError = true;
    });
  },
});
