// Axios
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_EditUserInformations } from "../../../Models/sendingDataSchema";
// Models

export const editUserInformationService = async (
  userToken: string,
  username: string,
  data: I_EditUserInformations
) => {
  return _axios.post(`/users/${username}/edit`, data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });
};
