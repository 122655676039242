import { _axios } from "../../Base_Axios_Config/_axios";

import { I_GetSingleUserAccessibleGroupsSendingData } from "../../../Models/sendingDataSchema";

import { AxiosResponse } from "axios";
import {
  I_Message,
  I_PossibleAccessibleGroupForCurrUser,
} from "../../../Models/interfaces";

// this api returns groups that curr user can grant to other users

export const getPossibleAccessibleGroupsForCurrUserService = async (
  userToken: string,
  userName: string,
  queryData: I_GetSingleUserAccessibleGroupsSendingData
): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message;
    result: I_PossibleAccessibleGroupForCurrUser;
  }>
> => {
  return _axios.get(
    `/users/${userName}/accessible-groups/possible-accesses?page=${queryData.page}&size=${queryData.size}&search=${queryData.search}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
