// React
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// React

// CSS
import "./App.css";
//// FontIcons
import "./Fonts/FontIcons/style.css";
//// FontIcons
import "react-tooltip/dist/react-tooltip.css";
// CSS

// Routes
import NewRoutes from "./Routes/NewRoutes";
// import Router from "./Routes/Routes";
// Routes

// Redux Toolkit
import { useAppDispatch, useAppSelector } from "./app/store";
// Redux Toolkit

// Modules
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Modules

// Models
import { I_ExecutablePermissions } from "./Models/interfaces";
// Models

// Redux
import { getAsyncRoles } from "./Features/RolesSlice/RolesSlice";
import { getAsyncUserData } from "./Features/UserInformationSlice/userInformationSlice";
// Redux

// Containers
import MasterContainer from "./Containers/MasterContainer/MasterContainer";
// Containers

// Components
import Map from "./Components/Map/Map";
import { Tooltip as ReactTooltip } from "react-tooltip";
// Components

// Contexts
import Contexts from "./Contexts/Contexts";
// Contexts

// Ng Progress
import TopProgressBar from "./Utils/TopProgressBar/TopProgressBar";
// Ng Progress

// Event Manager
import { addPersianDatePicker_EVENT } from "./Events/managePersianDatePicker_EVENT";
// Event Manager

// Utils
import { customizedToast } from "./Utils/CustomizedToast/customizedToast";
// Utils

// Hooks
import usePersianDatePickerEventHandler from "./Hooks/usePersianDatePickerEventHandler";
import { tenantsSlice } from "./Features/TenantsSlice/tenantsSlice";
import { onMapModalClose } from "./Events/MapEvents/documentEvents";
import { useRouteResetHandler } from "./Hooks/useRouteResetHandler";
import { useLocation } from "react-router-dom";
// Hooks

// Constants
import { mapInputs } from "./Constants/constants";
import {
  initialProcessHistoryContextData,
  T_ProcessHistoryContext,
} from "./Contexts/ProcessHistoryContext/ProcessHistoryContext";
// Constants

export const processHistoryResetEvent = new CustomEvent("processHistoryReset");
export const processDataGettingEvent = new CustomEvent(
  "processDataGettingEvent"
);
export const processDataLevelTabEvent = new CustomEvent(
  "processDataLevelTabEvent"
);
export const resetProcessTable = new CustomEvent("resetProcessTable");
export const processTableSelect = new CustomEvent("processTableSelect");

export const processItemClicked = new CustomEvent("processClicked");

export const diagramClickEvent = new CustomEvent("diagramClickEvent");
export const nextAndPrevButtonClickEventInhProcessHistory = new CustomEvent(
  "nextAndPrevButtonClickEventInhProcessHistory"
);

export const fullResetterProcessHistoryEvent = new CustomEvent(
  "fullResetterProcessHistoryEvent"
);

export const openProcessInThisTabEvent = new CustomEvent(
  "openProcessInThisTabEvent"
);

const App = () => {
  const dispatch = useAppDispatch();

  const executePermissionsRef = useRef<I_ExecutablePermissions>({
    canSeeTenantTextBox: ["Tenant_Full_Manager", "Tenant_Basic_Manager"],
  });

  const { accessToken } = useAppSelector((state) => state.loginData);
  const { language } = useAppSelector((state) => state.currLanguage);

  const state = useAppSelector((state) => state.loading);

  //

  const [isNightMode, setIsNightMode] = useState<boolean>(
    Boolean(
      JSON.parse(localStorage.getItem("isNightMode") || JSON.stringify(false))
    )
  );
  const [userGroups, setUserGroups] = useState<string[]>([]);
  console.log(userGroups);

  const [isFullRenderd, setIsFullRenderd] = useState<boolean>(false);
  const [isSidebarClosed, setIsSidebarClosed] = useState<boolean>(false);
  const [isMapInView, setIsMapInView] = useState<boolean>(false);

  const [processHistoryData, setProcessHistoryData] =
    useState<T_ProcessHistoryContext>(initialProcessHistoryContextData);

  const [necessaryDataFetchingStatus, setNecessaryDataFetchingStatus] =
    useState<"pending" | "rejected" | "done">("pending");

  const recevedUserGroups = useAppSelector(
    (state) => state.userInformation.allGroups
  );

  const groups = useMemo(
    () => JSON.parse(localStorage.getItem("userGroups") || JSON.stringify([])),
    []
  );

  const { pathname } = useLocation();

  const _path = useRef<string>(pathname);

  const receiveUserData = useCallback(() => {
    setNecessaryDataFetchingStatus("pending");
    dispatch(
      getAsyncRoles({
        userToken: accessToken,
        onError: () => {
          setNecessaryDataFetchingStatus("rejected");
        },
      })
    )
      .then(() => {
        dispatch(
          getAsyncUserData({
            userToken: accessToken,
            onDone: (data) => {
              localStorage.setItem("userGroups", JSON.stringify(data));
              setNecessaryDataFetchingStatus("done");
            },
            disableError: true,
          })
        );
      })
      .then(() => {
        setIsFullRenderd(true);
      })
      .catch((err) => {
        setNecessaryDataFetchingStatus("rejected");
      });
  }, [dispatch, accessToken]);

  const documentEvent = useCallback((e: any) => {
    if (
      mapInputs(e.target.id) ||
      e?.srcElement.classList.contains("leaflet-interactive")
    ) {
      return;
    } else {
      dispatchEvent(onMapModalClose);
    }
    console.log(e);
  }, []);

  useEffect(() => {
    if (_path.current.includes("login")) return;
    const dataGetter = () => {
      try {
        receiveUserData();
      } catch (err) {
        console.log(err);
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("refreshToken");
        window.localStorage.removeItem("userData");
        window.location.reload();
      }
    };
    const timeOut = setTimeout(dataGetter, 500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [receiveUserData]);

  useEffect(() => {
    localStorage.setItem("isNightMode", JSON.stringify(isNightMode));
  }, [isNightMode]);

  useEffect(() => {
    if ("jalaliDatepicker" in window) {
      const _window: any = window;
      _window.jalaliDatepicker.startWatch();
      addPersianDatePicker_EVENT();
    }
  }, []);

  useEffect(() => {
    if ("jalaliDatepicker" in window) {
      addPersianDatePicker_EVENT();
    }
  });

  useEffect(() => {
    dispatch(
      tenantsSlice.actions.setSelectedTenant({
        tenantId: JSON.parse(
          localStorage.getItem("tenantId") || JSON.stringify("")
        ),
        tenantTitle: JSON.parse(
          localStorage.getItem("tenantTitle") || JSON.stringify("سازمان پیشفرض")
        ),
        tenantImage: JSON.parse(
          localStorage.getItem("tenantImage") || JSON.stringify("سازمان پیشفرض")
        ),
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const offline = () => {
      customizedToast(language.online);
    };
    const online = () => {
      customizedToast(language.offline);
    };
    window.addEventListener("online", offline);
    window.addEventListener("offline", online);

    return () => {
      window.removeEventListener("online", offline);
      window.removeEventListener("offline", online);
    };
  }, [language]);

  useEffect(() => {
    window.addEventListener("mapopen", () => {
      setIsMapInView(true);
    });
    window.addEventListener("mapclose", () => {
      setIsMapInView(false);
    });
    window.addEventListener("mapinputchange", () => {
      setIsMapInView(false);
    });
  }, []);

  useEffect(() => {
    if (!isMapInView) return;
    document.addEventListener("click", documentEvent);

    return () => {
      document.removeEventListener("click", documentEvent);
    };
  }, [isMapInView, documentEvent]);

  usePersianDatePickerEventHandler(false);
  useRouteResetHandler();

  return (
    <>
      {state.isAnimating && (
        <TopProgressBar
          isAnimating={state.isAnimating}
          key={state.key}
          animationDuration={1}
        />
      )}

      <ReactTooltip
        id="my-tooltip"
        variant="dark"
        place="left"
        style={{ zIndex: "9999999" }}
      />
      <ReactTooltip
        id="formTooltip"
        variant="dark"
        place="bottom"
        style={{ zIndex: "9999999" }}
      />
      <ToastContainer
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{
          width: "max-content",
        }}
        hideProgressBar={true}
        closeButton={window.innerWidth > 600 ? true : false}
        position="top-center"
      />

      <Contexts
        executePermissionsRef={executePermissionsRef}
        isFullRenderd={isFullRenderd}
        isSidebarClosed={isSidebarClosed}
        setIsFullRenderd={setIsFullRenderd}
        setIsSidebarClosed={setIsSidebarClosed}
        setUserGroups={setUserGroups}
        userGroups={
          Array.isArray(groups) && groups.length !== 0
            ? groups
            : recevedUserGroups
        }
        isNightMode={isNightMode}
        setIsNightMode={setIsNightMode}
        isMapInView={isMapInView}
        setIsMapInView={setIsMapInView}
        necessaryDataFetchingStatus={necessaryDataFetchingStatus}
        setNecessaryDataFetchingStatus={setNecessaryDataFetchingStatus}
        processHistoryData={processHistoryData}
        setProcessHistoryData={setProcessHistoryData}
      >
        <MasterContainer>
          {isMapInView ? <Map /> : null}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/* Routes */}
          <NewRoutes receiveUserData={receiveUserData} />
          {/* <Router /> */}
          {/* Routes */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
        </MasterContainer>
      </Contexts>
    </>
  );
};

export default App;
