// Axios
import { _axios } from "../../../Base_Axios_Config/_axios";
import { AxiosResponse } from "axios";
// Axios

// Models
import { I_Message, I_SingleActivity } from "../../../../Models/interfaces";
// Models

export const getStepsOfSingleActivityBySchemaIdService = (_data: {
  userToken: string;
  _data: {
    processInstanceId: string | number;
    activitySchemaId: string | number;
    page: string | number;
    size: string | number;
  };
}): Promise<
  AxiosResponse<{
    error: number;
    message: I_Message[];
    result: I_SingleActivity;
  }>
> => {
  return _axios.get(
    `/processes/running-instances/${_data._data.processInstanceId}/history/steps/per-activity/${_data._data.activitySchemaId}?page=${_data._data.page}&size=${_data._data.size}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
