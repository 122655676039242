// React
import React, { useContext, useEffect } from "react";
// React

// Hooks
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// Hooks

// Contexts
import { routesAndPermissions } from "../Constants/routesAndPermissions";
import { UserGroupsContext } from "../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../Contexts/UserGroupsContext/IsFullRenderdContext";
import { NecessaryDataFetchingStatus } from "../Contexts/NecessaryDataFetchingStatus/NecessaryDataFetchingStatus";
// Contexts

// Modules
import { permitionCheckerWithoutToast } from "../Modules/permitionCheckerWithoutToast";
// Modules

// Pages
import NotFound from "../Pages/NotFound/NotFound";
import ShowTaskIdFromProcesses from "../Pages/Processes/ShowTaskIdFromProcesses/ShowTaskIdFromProcesses";
import Login from "../Pages/Login/Login";
// Pages

// Utils
import InnerRedirector from "../Utils/InnerRedirector/InnerRedirector";
// Utils

// Redux
import { useAppSelector } from "../app/store";
import Pending from "../Components/Pending/Pending";
// Redux

type NewRoutesProps = {
  receiveUserData: () => void;
};

const NewRoutes: React.FunctionComponent<NewRoutesProps> = ({
  receiveUserData,
}) => {
  const allGroups = useContext(UserGroupsContext);

  const navigate = useNavigate();
  const { accessToken, refreshToken } = useAppSelector(
    (state) => state.loginData
  );

  const { pathname } = useLocation();

  useEffect(() => {
    return () => {
      localStorage.setItem("pathName", JSON.stringify(pathname));
    };
  }, [pathname]);

  useEffect(() => {
    if (accessToken === "init" || refreshToken === "init") {
      console.log();
      navigate(
        `/login?returnUrl=${JSON.parse(
          (() => {
            const p = localStorage.getItem("pathName");
            if (!p) return JSON.stringify("/");
            if (typeof p === "undefined") return JSON.stringify("/");
            if (p?.includes("login")) return JSON.stringify("/");
            return p;
          })()
        )}`
      );
    }
  }, [navigate, accessToken, refreshToken]);

  const isFullRenderd = useContext(IsFullRenderdContext);

  const currFetchingDataStatus = useContext(NecessaryDataFetchingStatus);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {currFetchingDataStatus === "pending" ? (
        <Route path="*" element={<Pending />} />
      ) : null}
      {currFetchingDataStatus === "rejected" ? (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <button
            className="btn btn-danger px-4 py-1 rounded-2"
            onClick={receiveUserData}
          >
            تلاش دوباره
          </button>
        </div>
      ) : null}
      {currFetchingDataStatus === "done" ? (
        <>
          {isFullRenderd ? (
            <>
              {routesAndPermissions
                .map(
                  ({
                    Element,
                    neededPermissions,
                    route,
                    alternativePermission,
                  }) => {
                    if (
                      permitionCheckerWithoutToast(allGroups, neededPermissions)
                    ) {
                      return (
                        <Route key={route} path={route} element={<Element />} />
                      );
                    } else if (alternativePermission) {
                      if (!alternativePermission(allGroups)) return false;
                      return (
                        <Route key={route} path={route} element={<Element />} />
                      );
                    } else {
                      return false;
                    }
                  }
                )
                .filter((item) => !!item)}
            </>
          ) : null}

          <Route
            path="/process"
            element={<InnerRedirector to="/processes" />}
          />
          <Route
            path="/processes/show-tasks-by-processKey"
            element={<InnerRedirector to="/processes" />}
          />
          <Route
            path="/processes/edit-process-by-processKey/:processFileName"
            element={<InnerRedirector to="/processes" />}
          />
          <Route
            path="/processes/edit-process-by-processKey"
            element={<InnerRedirector to="/processes" />}
          />
          <Route
            path="/processes/view-more-about-selected-task/:processKey/:taskId"
            element={<ShowTaskIdFromProcesses />}
          />
          <Route
            path="/users/single-user"
            element={<InnerRedirector to="/users" />}
          />
          <Route
            path="/forms/show-form-by-process-key"
            element={<InnerRedirector to="/processes" />}
          />
          <Route path="/forms" element={<InnerRedirector to="/processes" />} />
          <Route
            path="/processes/reports"
            element={<InnerRedirector to="/processes" />}
          />

          <Route
            path="*"
            element={!isFullRenderd ? <Pending /> : <NotFound />}
          />
        </>
      ) : null}
    </Routes>
  );
};

export default NewRoutes;
