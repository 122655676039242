import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// import { I_Message } from "../../Models/interfaces";
import { I_SendingNewProcessData } from "../../../Models/sendingDataSchema";
import { I_Message } from "../../../Models/interfaces";

export const addNewProcessService = async (
  _data: I_SendingNewProcessData
): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {};
  }>
> => {
  return _axios.post(
    `/processes/add`,
    { ..._data._data },
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
