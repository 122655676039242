// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import {
  I_IndirectMembershipData,
  I_Message,
} from "../../../Models/interfaces";
import { I_SendingPaginationData } from "../../../Models/sendingDataSchema";
// Models

export const getIndirectMembershipsService = async (_data: {
  _data: I_SendingPaginationData;
  userToken: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_IndirectMembershipData;
  }>
> => {
  return _axios.get(
    `/groups/indirect-memberships?page=${_data._data.page}&size=${_data._data.size}&search=${_data._data.search}&tenant=${_data._data.tenant}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
