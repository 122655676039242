// React
import React, { useCallback, useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./CustomSelectOption2.module.css";
// CSS

// Contexts
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Icons
import { IoIosArrowDown } from "react-icons/io";
// Icons

type CustomSelectOption2Props = {
  options: { title: string; value: string | number }[];

  initialValue: string | number;
  initialTitle: string;

  containerClassName?: string;
  containerStyle?: React.CSSProperties;

  optionsContainerClassName?: string;
  optionsContainerStyles?: React.CSSProperties;

  optionClassName?: string;
  optionStyles?: React.CSSProperties;

  getSelectedOption: <T extends string | number>(data: T) => T | void;

  attributes?: {};
};

const CustomSelectOption2: React.FunctionComponent<
  CustomSelectOption2Props
> = ({
  getSelectedOption,

  options,

  containerClassName,
  containerStyle,

  optionsContainerClassName,
  optionsContainerStyles,
  optionClassName,
  optionStyles,

  initialTitle,
  initialValue,

  attributes,
}) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<{
    initialValue: string | number;
    initialTitle: string;
  }>({
    initialTitle,
    initialValue,
  });

  const isNightModeOn = useContext(IsNightModeOnContext);

  const closeDropDown = useCallback(() => {
    setIsDropDownOpen(false);
  }, []);

  useEffect(() => {
    if (isDropDownOpen) {
      setTimeout(() => {
        document.addEventListener("click", closeDropDown);
      }, 10);
      return;
    } else {
      document.removeEventListener("click", closeDropDown);
    }
  }, [isDropDownOpen, closeDropDown]);

  return (
    <div
      className={`${styles.customSelectOption2Container}  ${containerClassName}`}
      onClick={() => {
        setIsDropDownOpen(true);
      }}
      style={containerStyle}
    >
      <p className={`${styles.selectedItem}`} {...attributes}>
        <IoIosArrowDown
          style={{
            transform: isDropDownOpen ? "rotate(180deg)" : "",
          }}
        />
        {selectedItem.initialTitle}
      </p>
      {isDropDownOpen ? (
        <div
          className={`${styles.dropDownContainer} ${
            isNightModeOn ? "nightBg1" : ""
          } ${optionsContainerClassName}`}
          style={optionsContainerStyles}
        >
          {options.map((item) => (
            <p
              className={`${styles.optionItem} ${optionClassName} `}
              key={item.value}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedItem({
                  initialTitle: item.title,
                  initialValue: item.value,
                });
                getSelectedOption(item.value);
                setIsDropDownOpen(false);
              }}
              style={optionStyles}
            >
              {item.title}
            </p>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default CustomSelectOption2;
