// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux
// Services
import { getBasicInformationsService } from "../../Services/Basic_Information/get/getBasicInformationsService";
// Services
// Utils
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
// Utils
import { I_BasicData } from "../../Models/interfaces";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";

const initialState: { data: I_BasicData } & {
  isBassicInformationsReceved: boolean;
} = {
  data: {
    bankGatewayExtraInfoTypes: {
      paypingCardHashPan: {
        title: "",
        value: "",
      },
    },
    bankGatewayTypes: {
      payping: {
        title: "",
        value: "",
      },
    },
    demoAccountActiveState: false,
    errors: {},
    formType: {},
    groupCategory: {
      fundamental: {
        title: "",
        value: "",
      },
      public: {
        title: "",
        value: "",
      },
      publicEmpowered: {
        title: "",
        value: "",
      },
      system: {
        title: "",
        value: "",
      },
      tenancyManager: {
        title: "",
        value: "",
      },
    },
    identityInformationDataTypes: {
      double: { title: "", value: "" },
      image: { title: "", value: "" },
      integer: { title: "", value: "" },
      singleSelection: { title: "", value: "" },
      text: { title: "", value: "" },
    },
    orderState: {
      active: { title: "", value: "" },
      canceled: { title: "", value: "" },
      expired: { title: "", value: "" },
      pending: { title: "", value: "" },
      transformed: { title: "", value: "" },
      unpaid: { title: "", value: "" },
    },
    orderType: {
      extension: { title: "", value: "" },
      planPurchase: { title: "", value: "" },
      planRepurchase: { title: "", value: "" },
    },

    processDefaultVariables: {},
    registrationRequestState: {
      accepted: {
        title: "",
        value: 0,
      },
      rejected: {
        title: "",
        value: 0,
      },
      waiting: {
        title: "",
        value: 0,
      },
    },
    taskPriority: {
      high: {
        maxValue: 0,
        minValue: 0,
        title: "",
      },
      highest: {
        maxValue: 0,
        minValue: 0,
        title: "",
      },
      low: {
        maxValue: 0,
        minValue: 0,
        title: "",
      },
      lowest: {
        maxValue: 0,
        minValue: 0,
        title: "",
      },
      normal: {
        maxValue: 0,
        minValue: 0,
        title: "",
      },
    },
    tenantState: {
      active: {
        title: "",
        value: 0,
      },
      deactivated: {
        title: "",
        value: 0,
      },
      waiting: {
        title: "",
        value: 0,
      },
    },
    transactionExtraInfoTypes: {
      purchasedPlanId: {
        title: "",
        value: "",
      },
      username: {
        title: "",
        value: "",
      },
    },
    transactionTypes: {
      planOrdering: {
        title: "",
        value: "",
      },
    },

    postPlacementTypes: {},
    securityRiskType: {},
    securityEventType: {},
    securityEventEffectType: {},
    processVersionDeleteType: {},
  },

  // added by Developer
  isBassicInformationsReceved: false,
  // added by Developer
};

export const getAsyncRoles = createAsyncThunk(
  "allRoles/getRoles",
  async (_data: { userToken: string; onError: () => void }) => {
    if (
      !localStorage.getItem("accessToken") ||
      !localStorage.getItem("refreshToken") ||
      localStorage.getItem("accessToken") === JSON.stringify("init") ||
      localStorage.getItem("refreshToken") === JSON.stringify("init")
    ) {
      return;
    }
    let maxRetry = 3;
    for (let i = 1; i <= maxRetry; i++) {
      try {
        const { data } = await getBasicInformationsService(_data);
        return data;
      } catch (err: any) {
        reduxCatchHandler(err);
        throw err;
      }
    }

    if (maxRetry >= 4 && typeof _data.onError === "function") {
      _data.onError();
    }
  }
);

export const rolesSlice = createSlice({
  name: "allRoles",
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAsyncRoles.pending, (state, action) => {
      if (
        !localStorage.getItem("accessToken") ||
        !localStorage.getItem("refreshToken") ||
        localStorage.getItem("accessToken") === JSON.stringify("init") ||
        localStorage.getItem("refreshToken") === JSON.stringify("init")
      ) {
        return;
      }
      console.log("pending !");
      state.isBassicInformationsReceved = false;
    });
    builder.addCase(getAsyncRoles.fulfilled, (state, action) => {
      if (
        !localStorage.getItem("accessToken") ||
        !localStorage.getItem("refreshToken") ||
        localStorage.getItem("accessToken") === JSON.stringify("init") ||
        localStorage.getItem("refreshToken") === JSON.stringify("init")
      ) {
        return;
      }
      const err: any = action.payload;

      if (err?.response?.data?.messages) {
        for (
          let i: number = 0;
          i <= err?.response.data.messages.length - 1;
          i++
        ) {
          customizedToast(err?.response.data.messages[i].message, "error");
        }

        state.isBassicInformationsReceved = false;
      } else {
        if (!action.payload) return;
        state.data = action.payload.result;
        state.isBassicInformationsReceved = true;

        localStorage.setItem("basicItems", JSON.stringify(action.payload));

        return;
      }
    });
    builder.addCase(getAsyncRoles.rejected, (state, action) => {
      state.isBassicInformationsReceved = false;
    });
  },
});
