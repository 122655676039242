// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../Base_Axios_Config/_axios";
// Axios

// Models
import { I_Message } from "../../Models/interfaces";
// Models

export const editSingleExistingOptionService = async (_data: {
  userToken: string;
  tenant: string;
  _data: {
    attributeIdOrKey: string;
    optionId: string;
  };
  optionName: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {};
  }>
> => {
  return _axios.post(
    `/users/identity/attributes/${_data._data.attributeIdOrKey}/options/${_data._data.optionId}/edit?tenant=${_data.tenant}`,
    {
      title: _data.optionName,
    },
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
