// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./AllGroupsTab.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncAllGroups,
  groupSlice,
  postAsyncPublicGroupRemoval,
} from "../../../Features/GroupSlice/groupSlice";
// Redux

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import Pagination from "../../../Utils/Pagination/Pagination";
import { CSVLink } from "react-csv";
// Utils

// Components
// Components

// Modules
import { useNavigate } from "react-router-dom";
import { permissionChecker } from "../../../Modules/permissionChecker";
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
// Modules

// Utils
import { Spinner } from "reactstrap";
import Swal from "sweetalert2";
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
// Utils

// Contexts
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { ExecutingPermissionsContext } from "../../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

const AllGroupsTab = () => {
  const isNightModeOn = useContext(IsNightModeOnContext);

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );
  const [paginationData, setPaginationData] = useState<{
    currPage: number;
    pageSize: number;
    tenantId: string;
    search: string;
  }>({
    currPage: 1,
    pageSize: 10,
    tenantId: selectedTenant.tenantId,
    search: "",
  });
  const permitions = useContext(ExecutingPermissionsContext);
  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);

  const dispatch = useAppDispatch();
  const groupsData = useAppSelector((state) => state.groups.allGroupsData);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const navigate = useNavigate();

  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncAllGroups({
          userToken,
          _data: {
            page: paginationData.currPage,
            size: paginationData.pageSize,
            tenant: paginationData.tenantId,
            search: paginationData.search,
          },
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, userToken, paginationData]);

  useEffect(() => {
    return () => {
      dispatch(groupSlice.actions.resetAllGroupsData());
    };
  }, [dispatch]);

  const csvReport = {
    headers: [
      {
        label: "شمارنده",
        key: "id",
      },
      {
        label: "نام",
        key: "title",
      },
      {
        label: "گروه بالاتر",
        key: "categoryType",
      },
    ],
    data: groupsData.content.map((item, index) => ({
      id: item.categoryType.value,
      title: item.title,
      categoryType: item.categoryType.title,
    })),
    filename: "groups.csv",
  };

  return (
    <div
      className={`${styles.allGroupsContainer} w-100 ${
        isNightModeOn ? "nightBg1" : ""
      }`}
      style={{}}
    >
      <div className="w-100 d-flex flex-row-reverse align-items-center justify-content-between">
        <p
          style={{
            fontWeight: "bold",
            fontSize: "18px",
          }}
        >
          گروه ها
        </p>

        {window.innerWidth < 1000 ? (
          <div className={`${styles.absoluteBottomItems}`}>
            {permitionCheckerWithoutToast(
              allGroups,
              [
                "Tenant_Full_Manager",
                "Tenant_Basic_Manager",
                "All_Group_Manager",
                "Public_Group_Manager",
              ],
              isFullRenderd
            ) && (
              <button
                className={`operationEvent submitBtn`}
                style={{ fontSize: "12px !important", border: "none" }}
                onClick={() => {
                  navigate(`/groups/new-group`);
                }}
              >
                افزودن گروه
                <span
                  className="icon-Plus"
                  style={{ marginLeft: "6px" }}
                ></span>
              </button>
            )}
            <CSVLink {...csvReport} className={"operationEvent csvBtn"}>
              csv خروجی
            </CSVLink>
          </div>
        ) : (
          <div className="d-flex flex-row align-items-center justify-content-center">
            {permitionCheckerWithoutToast(
              allGroups,
              [
                "Tenant_Full_Manager",
                "Tenant_Basic_Manager",
                "All_Group_Manager",
                "Public_Group_Manager",
              ],
              isFullRenderd
            ) && (
              <button
                className={`operationEvent submitBtn`}
                style={{ fontSize: "12px !important", border: "none" }}
                onClick={() => {
                  navigate(`/groups/new-group`);
                }}
              >
                افزودن گروه
                <span
                  className="icon-Plus"
                  style={{ marginLeft: "6px" }}
                ></span>
              </button>
            )}
            <CSVLink {...csvReport} className={"operationEvent csvBtn"}>
              csv خروجی
            </CSVLink>
          </div>
        )}
      </div>
      <div
        className={`${styles.inputsContainer} w-100 d-flex flex-row align-items-center mt-2 justify-content-end flex-wrap `}
      >
        {permitionCheckerWithoutToast(
          allGroups,
          permitions.canSeeTenantTextBox,
          isFullRenderd
        ) && (
          <CustomInput1
            inputType="text"
            onBlur={() => {
              return false;
            }}
            onChange={(e) => {
              return false;
            }}
            placeHolder="  نام سازمان"
            // keyForShowOnTop="  نام سازمان"
            containerStyle={{
              width: "30%",
            }}
            value={selectedTenant.tenantTitle}
            defaultValue={selectedTenant.tenantTitle}
            dontShowSearchIcon
            disabled
          />
        )}
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={paginationData.search}
          defaultValue={paginationData.search}
          onChange={(e) => {
            setPaginationData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          placeHolder="نام گروه"
          //    keyForShowOnTop="نام گروه"
          containerStyle={{
            width: "30%",
          }}
        />
      </div>

      {groupsData.isRecevedGroupsPending ? (
        <div className="w-100 d-flex align-items-center justify-content-center flex-column mt-4">
          <span>در حال بارگذاری</span>
          <Spinner className="mt-2" />
        </div>
      ) : (
        <>
          {groupsData.content.length === 0 ? (
            <div className="w-100 d-flex align-items-center justify-content-center">
              موردی وجود ندارد
            </div>
          ) : (
            <>
              {window.innerWidth > 1000 ? (
                <div
                  className={`${styles.myTable} ${
                    isNightModeOn ? "nightBg2 pcNight" : ""
                  } w-100 d-flex flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    <p className={`${styles.categoryTab}`}>دسته بندی</p>
                    <p className={`${styles.nameTab}`}>نام پارسی</p>
                    <p className={`${styles.detailsTab}`}>نام لاتین </p>
                  </div>

                  <div
                    className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                  >
                    {groupsData.content.map((item) => (
                      <div
                        key={item.value}
                        className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                      >
                        <div className={`${styles.operationTab}`}>
                          {permitionCheckerWithoutToast(
                            allGroups,
                            [
                              "Tenant_Full_Manager",
                              "Tenant_Basic_Manager",
                              "All_Group_Manager",
                              "Public_Group_Manager",
                            ],
                            isFullRenderd
                          ) && item.categoryType.value.includes("PUBLIC") ? (
                            <button
                              className={` operationEvent editBtn`}
                              onClick={() => {
                                if (
                                  !permissionChecker(
                                    allGroups,
                                    [
                                      "Tenant_Full_Manager",
                                      "Tenant_Basic_Manager",
                                      "All_Group_Manager",
                                      "Public_Group_Manager",
                                    ],
                                    isFullRenderd
                                  )
                                )
                                  return;
                                if (
                                  !item.categoryType.value.includes("PUBLIC")
                                ) {
                                  customizedToast(
                                    "گروه اساسی قابل ویرایش نیست",
                                    "info"
                                  );
                                  return;
                                }
                                navigate(
                                  `/groups/edit-public-groups/${item.value}/${item.title}`
                                );
                              }}
                            >
                              <span
                                className="icon-edit"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="ویرایش"
                              ></span>
                            </button>
                          ) : (
                            <p
                              className=" deleteBtn p-2"
                              style={{
                                cursor: "not-allowed",
                                border: "1px solid #eb6363",
                                borderRadius: "4px",
                              }}
                            >
                              غیرقابل ویرایش
                            </p>
                          )}

                          {permitionCheckerWithoutToast(
                            allGroups,
                            [
                              "Tenant_Full_Manager",
                              "Tenant_Basic_Manager",
                              "All_Group_Manager",
                              "Public_Group_Manager",
                            ],
                            isFullRenderd
                          ) && item.categoryType.value.includes("PUBLIC") ? (
                            <button
                              className={"operationEvent deleteBtn"}
                              onClick={() => {
                                if (
                                  permissionChecker(
                                    allGroups,
                                    [
                                      "Tenant_Full_Manager",
                                      "Tenant_Basic_Manager",
                                      "All_Group_Manager",
                                      "Public_Group_Manager",
                                    ],
                                    isFullRenderd
                                  )
                                )
                                  Swal.fire({
                                    icon: "question",
                                    title: "حذف گروه بدون بازگشت می باشد ",
                                    text: "آیا ادامه میدهید ؟",
                                    showCancelButton: true,
                                    cancelButtonText: "لغو",
                                    confirmButtonText: "حذف",
                                    customClass: isNightModeOn
                                      ? "swalNight"
                                      : "",
                                  }).then(({ isConfirmed }) => {
                                    if (!isConfirmed) return;
                                    dispatch(
                                      postAsyncPublicGroupRemoval({
                                        userToken,
                                        groupId: String(item.value),
                                      })
                                    ).then(() => {
                                      dispatch(
                                        getAsyncAllGroups({
                                          userToken,
                                          _data: {
                                            page: paginationData.currPage,
                                            size: paginationData.pageSize,
                                            tenant: paginationData.tenantId,
                                            search: paginationData.search,
                                          },
                                        })
                                      );
                                    });
                                  });
                              }}
                            >
                              <span
                                className="icon-trash "
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="حذف"
                              ></span>
                            </button>
                          ) : null}
                        </div>
                        <p
                          className={`${styles.categoryTab}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            item.categoryType.title.length >= 16
                              ? item.categoryType.title
                              : ""
                          }
                        >
                          {item.categoryType.title.length >= 16
                            ? "..." + item.categoryType.title.substring(0, 15)
                            : item.categoryType.title}
                        </p>
                        <p
                          className={`${styles.nameTab}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            item.title.length >= 16 ? item.title : ""
                          }
                        >
                          {item.title.length >= 16
                            ? "..." + item.title.substring(0, 15)
                            : item.title}
                        </p>
                        <p
                          className={`${styles.detailsTab}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            String(item.value).length >= 16
                              ? String(item.value)
                              : ""
                          }
                        >
                          {String(item.value).length >= 16
                            ? "..." + String(item.value).substring(0, 15)
                            : String(item.value)}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              ) : (
                <div
                  className={`${styles.cards} mt-4  w-100 d-flex flex-column align-items-center`}
                >
                  {groupsData.content.map((item) => (
                    <div key={item.value} className="w-100">
                      <div
                        className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>{item.categoryType.title}</p>
                        <p>نوع گروه </p>
                      </div>
                      <div
                        className={`${styles.tableRow} ${
                          isNightModeOn ? "nightBg2" : ""
                        } w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>
                          <span>نام گروه</span>
                          <span>{item.title}</span>
                        </p>
                        <p>
                          <span>نام لاتین گروه</span>
                          <span>{item.value}</span>
                        </p>
                        <div className={`${styles.operationTab}`}>
                          {permitionCheckerWithoutToast(
                            allGroups,
                            [
                              "Tenant_Full_Manager",
                              "Tenant_Basic_Manager",
                              "All_Group_Manager",
                              "Public_Group_Manager",
                            ],
                            isFullRenderd
                          ) && item.categoryType.value.includes("PUBLIC") ? (
                            <button
                              className={` operationEvent editBtn`}
                              onClick={() => {
                                if (
                                  !permissionChecker(
                                    allGroups,
                                    [
                                      "Tenant_Full_Manager",
                                      "Tenant_Basic_Manager",
                                      "All_Group_Manager",
                                      "Public_Group_Manager",
                                    ],
                                    isFullRenderd
                                  )
                                )
                                  return;
                                if (
                                  !item.categoryType.value.includes("PUBLIC")
                                ) {
                                  customizedToast(
                                    "گروه اساسی قابل ویرایش نیست",
                                    "info"
                                  );
                                  return;
                                }
                                navigate(
                                  `/groups/edit-public-groups/${item.categoryType.value}/${item.title}`
                                );
                              }}
                            >
                              <span
                                className="icon-edit"
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="ویرایش"
                              ></span>
                            </button>
                          ) : (
                            <p
                              className=" deleteBtn p-2"
                              style={{
                                cursor: "not-allowed",
                                border: "1px solid #eb6363",
                                borderRadius: "4px",
                              }}
                            >
                              غیرقابل ویرایش
                            </p>
                          )}

                          {permitionCheckerWithoutToast(
                            allGroups,
                            [
                              "Tenant_Full_Manager",
                              "Tenant_Basic_Manager",
                              "All_Group_Manager",
                              "Public_Group_Manager",
                            ],
                            isFullRenderd
                          ) && item.categoryType.value.includes("PUBLIC") ? (
                            <button
                              className={"operationEvent deleteBtn"}
                              onClick={() => {
                                if (
                                  permissionChecker(
                                    allGroups,
                                    [
                                      "Tenant_Full_Manager",
                                      "Tenant_Basic_Manager",
                                      "All_Group_Manager",
                                      "Public_Group_Manager",
                                    ],
                                    isFullRenderd
                                  )
                                )
                                  Swal.fire({
                                    icon: "question",
                                    title: "حذف گروه بدون بازگشت می باشد ",
                                    text: "آیا ادامه میدهید ؟",
                                    showCancelButton: true,
                                    cancelButtonText: "لغو",
                                    confirmButtonText: "حذف",
                                    customClass: isNightModeOn
                                      ? "swalNight"
                                      : "",
                                  }).then(({ isConfirmed }) => {
                                    if (!isConfirmed) return;
                                    dispatch(
                                      postAsyncPublicGroupRemoval({
                                        userToken,
                                        groupId: String(item.value),
                                      })
                                    ).then(() => {
                                      dispatch(
                                        getAsyncAllGroups({
                                          userToken,
                                          _data: {
                                            page: paginationData.currPage,
                                            size: paginationData.pageSize,
                                            tenant: paginationData.tenantId,
                                            search: paginationData.search,
                                          },
                                        })
                                      );
                                    });
                                  });
                              }}
                            >
                              <span
                                className="icon-trash "
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="حذف"
                              ></span>
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}

      <Pagination
        dataForPagination={{
          currPage: paginationData.currPage,
          size: paginationData.pageSize,
          totalPages: groupsData.totalPages,
        }}
        onPageNumberChange={(pageNumber) => {
          setPaginationData((prevState) => ({
            ...prevState,
            currPage: Number(pageNumber),
          }));
        }}
        onPageNumbersClick={(pageNumber: number) => {
          setPaginationData((prevState) => ({
            ...prevState,
            currPage: Number(pageNumber),
          }));
        }}
        onPageSizeChange={(size) => {
          setPaginationData((prevState) => ({
            ...prevState,
            pageSize: Number(size),
          }));
          setPaginationData((prevState) => ({ ...prevState, currPage: 1 }));
        }}
        search={paginationData.search}
      />
    </div>
  );
};

export default AllGroupsTab;
