// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_SendingDataForGettingProcessVersion } from "../../../Models/sendingDataSchema";
import { T_ProcessVersion } from "../../../Models/interfaces";
// Models

export const getSingleProcessVersion = (
  _data: I_SendingDataForGettingProcessVersion
): Promise<AxiosResponse<T_ProcessVersion>> => {
  return _axios.get(
    `/processes/${_data._data.processKey}/versions?tenant=${_data._data.tenant}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
