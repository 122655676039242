// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Models
import {
  UserIdentitiesWithOptions,
  UserIdentity,
  UserIdentityOption,
} from "../../Models/types";
// Models

// Modules
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
// Modules

// Services
import { getAllUserIdentitiesService } from "../../Services/UserIdentities/getAllUserIdentitiesService";
import { getAllOptionsForSingleIdentityService } from "../../Services/UserIdentities/getAllOptionsForSingleIdentityService";
import { getAllIdentityAttributeWithTheirOptionsService } from "../../Services/UserIdentities/getAllIdentityAttributeWithTheirOptionsService";
import {
  I_EditSingleAttribute,
  I_NewIdentitySendingData,
} from "../../Models/sendingDataSchema";
import { addNewUserIdentityService } from "../../Services/UserIdentities/addNewUserIdentityService";
import { removeSingleUserIdentityService } from "../../Services/UserIdentities/removeSingleUserIdentityService";
import { editSingleUserIdentityService } from "../../Services/UserIdentities/editSingleUserIdentityService";
import { addNewOptionToSingleIdentityAttributeService } from "../../Services/UserIdentities/addNewOptionToSingleIdentityAttributeService";
import { removeSingleOptionOfExistingAttributeValueService } from "../../Services/UserIdentities/removeSingleOptionOfExistingAttributeValueService";
import { editSingleExistingOptionService } from "../../Services/UserIdentities/editSingleExistingOptionService";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Services

const initialState: {
  userIdentities: {
    data: UserIdentity[];

    // added by Developer
    isPending: boolean;
    // added by Developer
  };
} & {
  singleAttributeOptions: {
    data: UserIdentityOption[];
    // added by Developer
    isPending: boolean;
    // added by Developer
  };
} & {
  userIdentitiesWithOptions: {
    data: UserIdentitiesWithOptions[];
    // added by Developer
    isPending: boolean;
    // added by Developer
  };
} & {
  addingUserIdentity: {
    // added by Developer
    isPending: boolean;
    isDone: boolean;
    // added by Developer
  };
} & {
  editingUserIdentity: {
    // added by Developer
    isPending: boolean;
    isDone: boolean;
    // added by Developer
  };
} & {
  addNewOptionToExistingIdentity: {
    // added by Developer
    isPending: boolean;
    isDone: boolean;
    // added by Developer
  };
} & {
  editSingleExistingOptionOfIdentity: {
    // added by Developer
    isPending: boolean;
    isDone: boolean;
    // added by Developer
  };
} = {
  userIdentities: {
    data: [],
    isPending: true,
  },
  singleAttributeOptions: {
    data: [],
    isPending: true,
  },
  userIdentitiesWithOptions: {
    data: [],
    isPending: true,
  },
  addingUserIdentity: {
    // added by Developer
    isDone: false,
    isPending: false,
    // added by Developer
  },
  editingUserIdentity: {
    // added by Developer
    isDone: false,
    isPending: false,
    // added by Developer
  },
  addNewOptionToExistingIdentity: {
    // added by Developer
    isDone: false,
    isPending: false,
    // added by Developer
  },
  editSingleExistingOptionOfIdentity: {
    // added by Developer
    isDone: false,
    isPending: false,
    // added by Developer
  },
};

export const addAsyncNewUserIdentity = createAsyncThunk(
  "userIdentities/addNew",
  async (_data: {
    userToken: string;
    tenant: string;
    _data: I_NewIdentitySendingData;
  }) => {
    try {
      const { data } = await addNewUserIdentityService(_data);

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncAllUserIdentities = createAsyncThunk(
  "userIdentities/getAllIdentities",
  async (_data: { userToken: string; tenantId: string }) => {
    try {
      const { data } = await getAllUserIdentitiesService(_data);

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncAllOptionsForSingleAttribute = createAsyncThunk(
  "userIdentities/getOptionsForSingleAttribute",
  async (_data: { userToken: string; attributeId: string; tenant: string }) => {
    try {
      const { data } = await getAllOptionsForSingleIdentityService(_data);

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncAllIdentityAttributeWithOptions = createAsyncThunk(
  "userIdentities/getAllWithOptions",
  async (_data: { userToken: string; tenantId: string }) => {
    try {
      const { data } = await getAllIdentityAttributeWithTheirOptionsService(
        _data
      );
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const removeAsyncUserIdentity = createAsyncThunk(
  "userIdentities/removeUserIdentity",
  async (_data: {
    userToken: string;
    attributeIdOrKey: string;
    tenant: string;
  }) => {
    try {
      const { data } = await removeSingleUserIdentityService(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const editAsyncUserIdentity = createAsyncThunk(
  "userIdentities/editUserIdentity",
  async (_data: {
    userToken: string;
    attributeIdOrKey: string;
    _data: I_EditSingleAttribute;
    tenant: string;
  }) => {
    try {
      const { data } = await editSingleUserIdentityService(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const addAsyncNewOptionToExistingUserIdentity = createAsyncThunk(
  "userIdentities/addNewOptionToExistingUserIdentity",
  async (_data: {
    userToken: string;
    attributeIdOrKey: string;
    _data: string;
    tenant: string;
  }) => {
    try {
      const { data } = await addNewOptionToSingleIdentityAttributeService(
        _data
      );
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const removeAsyncSingleExistingOptionOfUserAttribute = createAsyncThunk(
  "userIdentities/removeSingleOption",
  async (_data: {
    userToken: string;
    tenant: string;
    _data: {
      attributeIdOrKey: string;
      optionId: string;
    };
  }) => {
    try {
      const { data } = await removeSingleOptionOfExistingAttributeValueService(
        _data
      );

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const editAsyncSingleExistingOptionOfUserAttribute = createAsyncThunk(
  "userIdentities",
  async (_data: {
    userToken: string;
    tenant: string;
    _data: {
      attributeIdOrKey: string;
      optionId: string;
    };
    optionName: string;
  }) => {
    try {
      const { data } = await editSingleExistingOptionService(_data);

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const userIdentitiesSlice = createSlice({
  name: "userIdentities",
  initialState,
  reducers: {
    resetUserIdentities: (state) => {
      state.userIdentities.data = [];
      state.userIdentities.isPending = true;
    },
    resetSingleAttributeData: (state) => {
      state.singleAttributeOptions.data = [];
      state.singleAttributeOptions.isPending = true;
    },
    resetUserIdentitiesWithOptions: (state) => {
      state.userIdentitiesWithOptions.data = [];
      state.userIdentitiesWithOptions.isPending = true;
    },
    resetAddingIdentityStatus: (state) => {
      state.addingUserIdentity.isPending = false;
      state.addingUserIdentity.isDone = false;
    },
    resetEditingUserIdentityStatus: (state) => {
      state.editingUserIdentity.isPending = false;
      state.editingUserIdentity.isDone = false;
    },
    resetAddingOptionToUserIdentityStatus: (state) => {
      state.addNewOptionToExistingIdentity.isPending = false;
      state.addNewOptionToExistingIdentity.isDone = false;
    },
    resetEditingSingleOption: (state) => {
      state.editSingleExistingOptionOfIdentity.isPending = false;
      state.editSingleExistingOptionOfIdentity.isDone = false;
    },
  },
  extraReducers: (builder) => {
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    builder.addCase(getAsyncAllUserIdentities.pending, (state, action) => {
      state.userIdentities.isPending = true;
    });
    builder.addCase(getAsyncAllUserIdentities.fulfilled, (state, action) => {
      state.userIdentities.isPending = false;

      if (action?.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        state.userIdentities.data = action.payload!.result;
        return;
      }
    });
    builder.addCase(getAsyncAllUserIdentities.rejected, (state, action) => {
      state.userIdentities.isPending = false;
    });
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    builder.addCase(
      getAsyncAllOptionsForSingleAttribute.pending,
      (state, action) => {
        state.singleAttributeOptions.isPending = true;
      }
    );
    builder.addCase(
      getAsyncAllOptionsForSingleAttribute.fulfilled,
      (state, action) => {
        state.singleAttributeOptions.isPending = false;

        if (action?.payload?.error === 0 || Boolean(action.payload) === true) {
          for (
            let i: number = 0;
            i <= action.payload!.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload!.messages[i].message, "success");
          }
          state.singleAttributeOptions.data = action.payload!.result;
          return;
        }
      }
    );
    builder.addCase(
      getAsyncAllOptionsForSingleAttribute.rejected,
      (state, action) => {
        state.singleAttributeOptions.isPending = false;
      }
    );
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    builder.addCase(
      getAsyncAllIdentityAttributeWithOptions.pending,
      (state, action) => {
        state.userIdentitiesWithOptions.isPending = true;
      }
    );
    builder.addCase(
      getAsyncAllIdentityAttributeWithOptions.fulfilled,
      (state, action) => {
        state.userIdentitiesWithOptions.isPending = false;

        if (action?.payload?.error === 0 || Boolean(action.payload) === true) {
          for (
            let i: number = 0;
            i <= action.payload!.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload!.messages[i].message, "success");
          }
          state.userIdentitiesWithOptions.data = action.payload!.result;
          return;
        }
      }
    );
    builder.addCase(
      getAsyncAllIdentityAttributeWithOptions.rejected,
      (state, action) => {
        state.userIdentitiesWithOptions.isPending = false;
      }
    );
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    builder.addCase(addAsyncNewUserIdentity.pending, (state) => {
      state.addingUserIdentity.isPending = true;
      state.addingUserIdentity.isDone = false;
    });
    builder.addCase(addAsyncNewUserIdentity.fulfilled, (state, action) => {
      state.addingUserIdentity.isPending = false;
      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        customizedToast("شناسه با موفقیت ثبت شد", "success");
        state.addingUserIdentity.isDone = true;
        return;
      }
    });
    builder.addCase(addAsyncNewUserIdentity.rejected, (state) => {
      state.addingUserIdentity.isPending = false;
      state.addingUserIdentity.isDone = false;
    });
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    builder.addCase(removeAsyncUserIdentity.pending, (state, action) => {
      customizedToast("درخواست حذف ارسال شد", "info");
      state.userIdentities.isPending = true;
    });
    builder.addCase(removeAsyncUserIdentity.fulfilled, (state, action) => {
      state.userIdentities.isPending = false;
      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        customizedToast("اطلاعات با موفقیت بروز شد", "success");
        return;
      }
    });
    builder.addCase(removeAsyncUserIdentity.rejected, (state, action) => {
      state.userIdentities.isPending = false;
      customizedToast("درخواست حذف ناموفق بود", "error");
    });
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    builder.addCase(editAsyncUserIdentity.pending, (state, action) => {
      state.editingUserIdentity.isDone = false;
      state.editingUserIdentity.isPending = true;
    });
    builder.addCase(editAsyncUserIdentity.fulfilled, (state, action) => {
      state.editingUserIdentity.isPending = false;
      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        customizedToast("اطلاعات با موفقیت ویرایش شد", "success");
        state.editingUserIdentity.isDone = true;
        return;
      }
    });
    builder.addCase(editAsyncUserIdentity.rejected, (state, action) => {
      state.editingUserIdentity.isDone = false;
      state.editingUserIdentity.isPending = false;
    });
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    builder.addCase(
      addAsyncNewOptionToExistingUserIdentity.pending,
      (state, action) => {
        state.addNewOptionToExistingIdentity.isPending = true;
        state.addNewOptionToExistingIdentity.isDone = false;
      }
    );
    builder.addCase(
      addAsyncNewOptionToExistingUserIdentity.fulfilled,
      (state, action) => {
        state.addNewOptionToExistingIdentity.isPending = false;
        if (action.payload?.error === 0 || Boolean(action.payload) === true) {
          for (
            let i: number = 0;
            i <= action.payload!.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload!.messages[i].message, "success");
          }
          customizedToast("اطلاعات با موفقیت درج شد", "success");
          state.addNewOptionToExistingIdentity.isDone = true;
          return;
        }
      }
    );
    builder.addCase(
      addAsyncNewOptionToExistingUserIdentity.rejected,
      (state, action) => {
        state.addNewOptionToExistingIdentity.isPending = false;
        state.addNewOptionToExistingIdentity.isDone = false;
      }
    );
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    builder.addCase(
      editAsyncSingleExistingOptionOfUserAttribute.pending,
      (state, action) => {
        state.editSingleExistingOptionOfIdentity.isPending = true;
        state.editSingleExistingOptionOfIdentity.isDone = false;
      }
    );
    builder.addCase(
      editAsyncSingleExistingOptionOfUserAttribute.fulfilled,
      (state, action) => {
        state.editSingleExistingOptionOfIdentity.isPending = false;
        if (action.payload?.error === 0 || Boolean(action.payload) === true) {
          for (
            let i: number = 0;
            i <= action.payload!.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload!.messages[i].message, "success");
          }
          customizedToast("اطلاعات با موفقیت درج شد", "success");
          state.editSingleExistingOptionOfIdentity.isDone = true;
          return;
        }
      }
    );
    builder.addCase(
      editAsyncSingleExistingOptionOfUserAttribute.rejected,
      (state, action) => {
        state.editSingleExistingOptionOfIdentity.isPending = false;
        state.editSingleExistingOptionOfIdentity.isDone = false;
      }
    );
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
    ////////////////////////////////////////
  },
});
