import { _axios } from "../../Base_Axios_Config/_axios";

export const startSingleInstanceProcessService = async (_data: {
  userToken: string;
  _data: {
    processKey: string;
    tenant?: string;
    _data: {
      title: string;
      formData?: any;
      businessKey: string;
    };
  };
}) => {

  return _axios.post(
    `/processes/${_data._data.processKey}/start?tenant=${
      Boolean(_data._data.tenant) ? _data._data.tenant : ""
    }`,
    _data._data._data,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
