// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_SendingDataForRemoveSingleProcessInstance } from "../../../Models/sendingDataSchema";
import { I_Message } from "../../../Models/interfaces";
// Models

export const removeSingleProcessInstanceService = (_data: {
  userToken: string;
  _data: I_SendingDataForRemoveSingleProcessInstance;
}): Promise<
  AxiosResponse<{
    messages: I_Message[];
  }>
> => {
  return _axios.post(
    `/processes/running-instances/${_data._data.processInstanceId}/delete`,
    {},
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
