// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./EditPublicGroup.module.css";
// CSS

// Modules
import { useNavigate, useParams } from "react-router-dom";
// Modules

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  groupSlice,
  postAsyncPublicGroupEdit,
} from "../../../Features/GroupSlice/groupSlice";
// Redux

// Utils
import { Spinner } from "reactstrap";
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
// Utils

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import CustomSelectOption2 from "../../../Utils/CustomSelectOption2/CustomSelectOption2";
// Contexts

const EditPublicGroup = () => {
  const dispatch = useAppDispatch();

  const { groupId, groupPersianName } = useParams();
  const navigate = useNavigate();

  const [singleEditGroupData, setSingleGroupData] = useState<{
    category: string;
    groupTitle: string;
  }>({
    category: "PUBLIC",
    groupTitle: String(groupPersianName),
  });

  const { isEditPubicGroupsPending: isPending, isEditPublicGroupDone: isDone } =
    useAppSelector((state) => state.groups);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const groupCreatorTypes = useAppSelector(
    (state) => state.allRoles.data.groupCategory
  );

  const isNightOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    if (!isDone) return;
    navigate("/groups");
  }, [isDone, navigate]);

  useEffect(() => {
    return () => {
      dispatch(groupSlice.actions.resetPublicGroupEditingData());
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.editPublicGroupContainer} ${
        isNightOn ? "nightBg1" : ""
      }`}
    >
      <>
        <p
          style={{
            width: "100%",
            textAlign: "right",
            color: "#111D64",
            fontWeight: "bold",
            fontSize: "20px",
            direction: "rtl",
          }}
        >
          <span>ویرایش گروه</span> : <span>{groupPersianName}</span>
        </p>
        <p
          style={{
            width: "100%",
            textAlign: "right",
            color: "#8392AB",
            fontSize: "16px",
            direction: "rtl",
          }}
        >
          <span> گروه</span> : <span>{groupId}</span>
        </p>
      </>
      <div
        className={`${styles.editPublicGroupContainer} ${
          isNightOn ? "nightBg1" : ""
        } w-100 d-flex flex-row align-items-center justify-content-between flex-wrap`}
      >
        {/* <CustomSelectOption
          defaultValue={
            Object.keys(groupCreatorTypes)
              .map((item) => ({
                title: groupCreatorTypes[`${item}`].title,
                value: groupCreatorTypes[`${item}`].value,
              }))
              .filter((_item) => _item.value.includes("PUBLIC"))[0]?.value ||
            "PUBLIC"
          }
          firstItemForSelectAll="گروه ( پیشفرض : عمومی )"
          nameForShowInInitial="گروه ( پیشفرض : عمومی )"
          onChange={(category) => {
            setSingleGroupData((prevState) => ({ ...prevState, category }));
          }}
          options={Object.keys(groupCreatorTypes)
            .map((item) => ({
              title: groupCreatorTypes[`${item}`].title,
              value: groupCreatorTypes[`${item}`].value,
            }))
            .filter(
              (_item, index) => _item.value.includes("PUBLIC") && index > 0
            )}
          inputPlaceHolder="نوع گروه را انتخاب کنید"
          selectStyles={{
            width: "45%",
          }}
        /> */}

        <CustomSelectOption2
          getSelectedOption={(data) => {
            setSingleGroupData((prevState) => ({
              ...prevState,
              category: String(data),
            }));
          }}
          initialTitle="ویرایش گروه ( پیشفرص : عمومی )"
          initialValue={
            Object.keys(groupCreatorTypes)
              .map((item) => ({
                title: groupCreatorTypes[`${item}`].title,
                value: groupCreatorTypes[`${item}`].value,
              }))
              .filter((_item) => _item.value.includes("PUBLIC"))[0]?.value ||
            "PUBLIC"
          }
          options={Object.keys(groupCreatorTypes)
            .map((item) => ({
              title: groupCreatorTypes[`${item}`].title,
              value: groupCreatorTypes[`${item}`].value,
            }))
            .filter(
              (_item, index) => _item.value.includes("PUBLIC") && index > 0
            )
            .map((item) => ({ title: item.title, value: item.value }))}
        />

        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={singleEditGroupData.groupTitle}
          onChange={(e) => {
            setSingleGroupData((prevState) => ({
              ...prevState,
              groupTitle: e.target.value,
            }));
          }}
          placeHolder="نام گروه"
          dontShowSearchIcon
          //  keyForShowOnTop="نام گروه"
          defaultValue={singleEditGroupData.groupTitle}
          containerStyle={{
            width: "45%",
          }}
        />
      </div>

      <button
        className="operationEvent submitBtn"
        onClick={() => {
          dispatch(
            postAsyncPublicGroupEdit({
              userToken,
              groupId: String(groupId),
              _data: {
                category: singleEditGroupData.category,
                groupTitle: singleEditGroupData.groupTitle,
              },
            })
          );
        }}
      >
        {isPending ? <Spinner /> : "تایید"}
      </button>
    </div>
  );
};

export default EditPublicGroup;
