// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./AddGroup.module.css";
// CSS

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import { Spinner } from "reactstrap";
// Utils

// Models
import { I_NewGroupSendingData } from "../../../Models/sendingDataSchema";
import { permissionChecker } from "../../../Modules/permissionChecker";
// Models

// Contexts
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
import { ExecutingPermissionsContext } from "../../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  groupSlice,
  postAsyncNewGroup,
} from "../../../Features/GroupSlice/groupSlice";
// Redux

// Modules
import { useNavigate } from "react-router-dom";
import CustomSelectOption2 from "../../../Utils/CustomSelectOption2/CustomSelectOption2";
// Modules

const AddGroup = () => {
  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const permitions = useContext(ExecutingPermissionsContext);
  const isNightOn = useContext(IsNightModeOnContext);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAddingGroupsDone } = useAppSelector((state) => state.groups);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { isAddGroupPending } = useAppSelector((state) => state.groups);

  const groupCreatorTypes = useAppSelector(
    (state) => state.allRoles.data.groupCategory
  );

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const [groupData, setGroupData] = useState<I_NewGroupSendingData>({
    tenantId: selectedTenant.tenantId,
    category: "PUBLIC",
    groupId: "",
    groupTitle: "",
  });

  useEffect(() => {
    if (!isAddingGroupsDone) return;

    navigate("/groups");
  }, [isAddingGroupsDone, navigate]);

  useEffect(() => {
    return () => {
      dispatch(groupSlice.actions.resetAddingGroupData());
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.addGroupContainer} ${isNightOn ? "nightBg1" : ""}`}
    >
      <p
        style={{
          width: "100%",
          textAlign: "right",
          color: "#111D64",
          fontWeight: "bold",
          fontSize: "20px",
        }}
      >
        افزودن گروه
      </p>
      <p
        style={{
          width: "100%",
          textAlign: "right",
          color: "#8392AB",
        }}
      >
        مشخصات کاربر
      </p>
      <div
        className={`${styles.inputsContainer} w-100 d-flex flex-row align-items-center justify-content-between flex-wrap `}
        style={{
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <CustomSelectOption2
          getSelectedOption={(data) => {
            setGroupData((prevState) => ({
              ...prevState,
              category: String(data),
            }));
          }}
          initialTitle="نوع گروه ( پیشفرض : عادی )"
          initialValue={
            Object.keys(groupCreatorTypes)
              .map((item) => ({
                title: groupCreatorTypes[`${item}`]?.title,
                value: groupCreatorTypes[`${item}`]?.value,
              }))
              .filter((_item) => _item?.value?.includes("PUBLIC"))[0]?.value ||
            "PUBLIC"
          }
          options={Object.keys(groupCreatorTypes)
            .map((item) => ({
              title: groupCreatorTypes[`${item}`].title,
              value: groupCreatorTypes[`${item}`].value,
            }))
            .filter(
              (_item, index) => _item.value.includes("PUBLIC") && index > 0
            )
            .map((item) => ({
              title: item.title,
              value: item.value,
            }))}
        />

        {permitionCheckerWithoutToast(
          allGroups,
          permitions.canSeeTenantTextBox,
          isFullRenderd
        ) && (
          <CustomInput1
            inputType="text"
            onBlur={() => {
              return false;
            }}
            onChange={(e) => {
              return false;
            }}
            placeHolder="نام سازمان"
            //     keyForShowOnTop="نام سازمان"
            value={selectedTenant.tenantTitle}
            defaultValue={selectedTenant.tenantTitle}
            dontShowSearchIcon
            disabled
          />
        )}
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          onChange={(e) => {
            setGroupData((prevState) => ({
              ...prevState,
              groupId: e.target.value,
            }));
          }}
          placeHolder="آدرس یکتا"
          //     keyForShowOnTop="آدرس یکتا"

          value={groupData.groupId}
          defaultValue={groupData.groupId}
          dontShowSearchIcon
        />
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          onChange={(e) => {
            setGroupData((prevState) => ({
              ...prevState,
              groupTitle: e.target.value,
            }));
          }}
          placeHolder="عنوان گروه"
          value={groupData.groupTitle}
          defaultValue={groupData.groupTitle}
          dontShowSearchIcon
        />
      </div>
      <button
        className="operationEvent submitBtn"
        onClick={() => {
          if (
            !permissionChecker(
              allGroups,
              [
                "Tenant_Full_Manager",
                "Tenant_Basic_Manager",
                "All_Group_Manager",
                "Public_Group_Manager",
              ],
              isFullRenderd
            )
          )
            return;
          dispatch(
            postAsyncNewGroup({
              _data: groupData,
              userToken,
            })
          );
        }}
      >
        {isAddGroupPending ? <Spinner /> : <>ذخیره گروه</>}
      </button>
    </div>
  );
};

export default AddGroup;
