// React
import React, { useContext, useEffect } from "react";
// React

// CSS
import styles from "./PersonalTab.module.css";
// CSS

// Utils
import CustomInput2 from "../../../../Utils/CustomInput2/CustomInput2";
import { customizedToast } from "../../../../Utils/CustomizedToast/customizedToast";
// Utils

// Icons
import { IoIosArrowBack } from "react-icons/io";
// Icons

// Contexts
import { IsNightModeOnContext } from "../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Formats
import { mailformat } from "../../../../Formats/formats";
// Formats

type PersonalTabProps = {
  //
  setNewUserData: React.Dispatch<
    React.SetStateAction<{
      tenantId: string;
      firstname: string;
      lastname: string;
      email: string;
      username: string;
      password: string;
      extraIdentityInformation: {
        [key: string]: string | number;
      };
      groupMembershipToAdd: {
        groupId: string;
      }[];
    }>
  >;

  setSelectedTab: React.Dispatch<
    React.SetStateAction<"personal" | "groups" | "extraIdentity">
  >;
  //
  newUserData: {
    tenantId: string;
    firstname: string;
    lastname: string;
    email: string;
    username: string;
    password: string;
    retypePassword?: string;
    extraIdentityInformation: {
      [key: string]: string | number;
    };
    groupMembershipToAdd: {
      groupId: string;
    }[];
  };
  //
  handleChangeValue: (key: string, value: string) => void;
  //
  //
};

const PersonalTab: React.FunctionComponent<PersonalTabProps> = ({
  newUserData,
  setNewUserData,
  setSelectedTab,
}) => {
  const isNightModeOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    setNewUserData((prevState) => ({ ...prevState, retypePassword: "" }));
  }, [setNewUserData]);
  return (
    <>
      <div
        className={`${styles.inputContainer} ${
          isNightModeOn ? "nightBg1" : ""
        }  d-flex flex-column align-items-start w-100`}
      >
        <p
          style={{
            width: "100%",
            textAlign: "right",
            color: "#111D64",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          افزودن کاربر جدید
        </p>
        <p
          style={{
            width: "100%",
            textAlign: "right",
            color: "#8392AB",
          }}
        >
          مشخصات کاربر
        </p>
        <div className="w-100 d-flex align-items-center justify-content-between flex-row-reverse flex-wrap">
          <CustomInput2
            defaultValue={newUserData.firstname}
            onBlur={(e) =>
              setNewUserData((prevState) => ({
                ...prevState,
                firstname: e.target.value,
              }))
            }
            plaeHolder="برای مثال: علی"
            title="نام"
            type="text"
          />
          <CustomInput2
            defaultValue={newUserData.lastname}
            onBlur={(e) => {
              setNewUserData((prevState) => ({
                ...prevState,
                lastname: e.target.value,
              }));
            }}
            plaeHolder="برای مثال: هاشمی"
            title="نام خانوادگی"
            type="text"
          />
          <CustomInput2
            defaultValue={newUserData.email}
            onBlur={(e) => {
              setNewUserData((prevState) => ({
                ...prevState,
                email: e.target.value,
              }));
            }}
            plaeHolder="برای مثال: abcd.1234@gmail.com"
            title="ایمیل"
            type="email"
          />
          <CustomInput2
            defaultValue={newUserData.username}
            onBlur={(e) => {
              setNewUserData((prevState) => ({
                ...prevState,
                username: e.target.value,
              }));
            }}
            plaeHolder="برای مثال: Ali.123"
            title="نام کاربری"
            type="text"
          />
          <CustomInput2
            defaultValue={newUserData.password}
            onBlur={(e) => {
              setNewUserData((prevState) => ({
                ...prevState,
                password: e.target.value,
              }));
            }}
            plaeHolder="8 کاراکتر شامل عدد و حروف انگلیسی"
            title="رمز عبور"
            type="password"
          />
          <CustomInput2
            defaultValue=""
            onBlur={(e) => {
              setNewUserData((prevState) => ({
                ...prevState,
                retypePassword: e.target.value,
              }));
            }}
            plaeHolder="تکرار رمز عبور"
            title="تکرار رمز عبور"
            type="password"
          />
        </div>

        <button
          className={`${styles.nextStepBtn}`}
          onClick={() => {
            if (newUserData.firstname.trim().length < 2) {
              customizedToast("طول نام کاربری از حد مجاز کمتر است", "error");
              return;
            }
            if (newUserData.lastname.trim().length < 2) {
              customizedToast("طول نام خانوادگی از حد مجاز کمتر است", "error");
              return;
            }
            if (!mailformat.test(newUserData.email)) {
              customizedToast("ایمیل ورودی صحیح نیست", "error");
              return;
            }
            if (newUserData.username.trim().length < 5) {
              customizedToast("طول نام کاربری از حد مجاز کمتر است", "error");
              return;
            }
            if (newUserData.password !== newUserData.retypePassword) {
              customizedToast("رمز عبور و تکرار آن یکسان نیست", "error");
              return;
            }
            setSelectedTab("groups");
          }}
        >
          <IoIosArrowBack />
          <span>انتخاب گروه‌ها</span>
        </button>
      </div>

      {/* <div
        className={`d-flex align-items-center justify-content-between flex-row-reverse `}
      ></div> */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/* <p
        className="w-100 mt-5"
        style={{
          textAlign: "right",
          direction: "rtl",
        }}
      >
        موارد اضافه :
      </p>
      <div
        className={`
        d-flex flex-column align-items-center justify-content-between w-100`}
      >
        {Object.entries(newUserData.extraIdentityInformation).map(
          ([key, value]) => (
            <div key={key}>
              <p
                style={{
                  direction: "rtl",
                }}
              >
                {key} :
              </p>
              <input
                className="my-2 "
                value={value}
                onChange={(e) => handleChangeValue(key, e.target.value)}
                style={{
                  borderBottom: "1px solid black",
                  direction: "initial",
                }}
              />
            </div>
          )
        )}
      </div> */}
      {/* 
      <button
        className="btn btn-primary mt-5 d-flex align-items-center justify-content-center "
        onClick={() => {
          const fieldName = prompt("نام مقدار را به لاتین بنویسید : ");
          if (!fieldName) return; // User canceled

          setNewUserData((prevState) => ({
            ...prevState,
            extraIdentityInformation: {
              ...prevState.extraIdentityInformation,
              [fieldName]: "مقدار را اینجا بنویسید",
            },
          }));
        }}
      >
        افزودن مورد اضافه
      </button> 
    */}
    </>
  );
};

export default PersonalTab;
