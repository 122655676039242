// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Models
import { I_UserData } from "../../Models/interfaces";
import { T_AddFunctionToExistObject } from "../../Models/sendingDataSchema";
// Models

// Services
import { getUserProfileService } from "../../Services/Profile/get/getUserProfileService";
import { editUserProfileService } from "../../Services/Profile/post/editUserProfileService";
import { changeUserPasswordService } from "../../Services/Profile/post/changeUserPasswordService";
// Services

// Utils
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Utils

const initialState: I_UserData & {
  isUserInformationChangePending: boolean;
  isPasswordChangePending: boolean;
  isUserDataFetchPending: boolean;
  isUserDateReceved: boolean;
  isUserValid: boolean;
} = {
  firstname: "",
  lastname: "",
  username: "",
  email: "",
  tenantOwner: false,
  tenantInformation: {
    id: "",
    title: "",
    domain: "",
    logo: "",
    mainTenant: true,
    demoEnvironment: false,
    state: {
      value: 0,
      title: "",
    },
  },
  extraIdentityInformation: {},
  groupMembership: [],
  indirectGroupMembership: [],

  // added by Developer
  allGroups: [],
  allGroupsWithTitles: [],
  isUserInformationChangePending: false,
  isPasswordChangePending: false,
  isUserDataFetchPending: true,
  isUserDateReceved: false,
  isUserValid: false,
  // added by Developer
};

/**************************************************** */
/**************************************************** */
/**************************************************** */
/**************************************************** */
export const getAsyncUserData = createAsyncThunk(
  "userInformation/getUserData",
  async (_data: {
    onDone?: (data: string[]) => void;
    onError?: () => void;
    userToken: string;
    disableError?: boolean;
  }) => {
    try {
      const { data } = await getUserProfileService(_data.userToken);
      localStorage.setItem(
        "groupsWithTitles",
        JSON.stringify(
          [
            ...data.result.groupMembership,
            ...data.result.indirectGroupMembership,
          ].map((item) => ({ title: item.title, value: item.value }))
        )
      );

      if (_data?.onDone) {
        console.log(
          " data.data.result.groupMembership",
          data.result.groupMembership
        );
        _data.onDone(
          [
            ...data.result.groupMembership,
            ...data.result.indirectGroupMembership,
          ].map((item) => item.value)
        );
      }
      return data.result;
    } catch (err: any) {
      if (_data.onError) {
        _data.onError();
      }
      if (!_data.disableError) {
        reduxCatchHandler(err);
      }
    }
  }
);
/**************************************************** */
/**************************************************** */
/**************************************************** */
/**************************************************** */
export const editAsyncUserData = createAsyncThunk(
  "userInformation/editUserData",
  async (_data: {
    _data: {
      firstname?: string;
      lastname?: string;
      email?: string;
      extraIdentityInformation?: {
        [key: string]: any;
      };
    };
    userToken: string;
  }) => {
    try {
      const { data } = await editUserProfileService(_data);
      return data.result;
      //
      //
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);
/**************************************************** */
/**************************************************** */
/**************************************************** */
/**************************************************** */
export const changeAsyncPassword = createAsyncThunk(
  "userInformation/changePassword",
  async (
    _data: T_AddFunctionToExistObject<
      { oldPassword: string; newPassword: string },
      "onDone",
      () => void
    >
  ) => {
    try {
      const {
        data: { messages },
      } = await changeUserPasswordService(
        String(JSON.parse(localStorage.getItem("accessToken")!)),
        _data
      );
      if (typeof _data.onDone === "function") {
        _data.onDone();
      }
      return messages;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);
/**************************************************** */
/**************************************************** */
/**************************************************** */
/**************************************************** */

export const userInformationSlice = createSlice({
  name: "userInformation",
  initialState,
  reducers: {
    setUserInformations: (state, action) => {
      state.allGroups = action.payload.allGroups;
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.username = action.payload.username;
      state.email = action.payload.email;

      state.allGroups = action.payload.allGroups;
      state.allGroupsWithTitles = action.payload.allGroupsWithTitles;
      state.email = action.payload.email;
      state.extraIdentityInformation = action.payload.extraIdentityInformation;
      state.tenantOwner = action.payload.tenantOwner;
      state.tenantInformation = action.payload.tenantInformation;
      state.extraIdentityInformation = action.payload.extraIdentityInformation;
      state.groupMembership = action.payload.groupMembership;
      state.indirectGroupMembership = action.payload.indirectGroupMembership;

      state.isUserDateReceved = true;
    },
  },
  extraReducers: (builder) => {
    /**************************************************** */
    /**************************************************** */
    /**************************************************** */
    /**************************************************** */
    builder.addCase(getAsyncUserData.pending, (state, action) => {
      if (
        !localStorage.getItem("accessToken") ||
        !localStorage.getItem("refreshToken") ||
        localStorage.getItem("accessToken") === JSON.stringify("init") ||
        localStorage.getItem("refreshToken") === JSON.stringify("init")
      ) {
        return;
      }
      state.isUserDataFetchPending = true;
      state.isUserDateReceved = false;
    });
    builder.addCase(getAsyncUserData.fulfilled, (state, action) => {
      state.isUserDataFetchPending = false;
      const err: any = action.payload;
      if (
        err?.response?.data?.messages ||
        Boolean(action.payload?.groupMembership) === false
      ) {
        customizedToast("هنگام دریافت اطلاعات مشکلی پیش آمده", "error");

        for (
          let i: number = 0;
          i <= err?.response.data.messages.length - 1;
          i++
        ) {
          customizedToast(err?.response.data.messages[i].message, "error");
        }

        return;
      } else {
        const memberships = [...action.payload!.groupMembership];

        state.allGroups = [
          ...memberships,
          ...action.payload!.indirectGroupMembership,
        ].map((item: { title: string; value: string }) => item.value);
        state.allGroupsWithTitles = [
          ...memberships,
          ...action.payload!.indirectGroupMembership,
        ].map((item: { title: string; value: string }) => item);

        state.email = action.payload!.email;
        state.extraIdentityInformation =
          action.payload!.extraIdentityInformation;
        state.firstname = action.payload!.firstname;
        state.groupMembership = action.payload!.groupMembership;
        state.indirectGroupMembership = action.payload!.indirectGroupMembership;
        state.lastname = action.payload!.lastname;
        state.tenantInformation = action.payload!.tenantInformation;
        state.username = action.payload!.username;
        state.isUserDateReceved = true;

        // localStorage.setItem("userData", JSON.stringify(action.payload));
      }
      // customizedToast(
      //   JSON.parse(
      //     localStorage.getItem("currLanguage") || JSON.stringify("fa")
      //   ) === "fa"
      //     ? language.fa.userFetchDataSuccess
      //     : language.en.userFetchDataSuccess,
      //   "success"
      // );
    });
    builder.addCase(getAsyncUserData.rejected, (state, action) => {
      state.isUserDataFetchPending = false;
      state.isUserDateReceved = false;
      // customizedToast(
      //   JSON.parse(
      //     localStorage.getItem("currLanguage") || JSON.stringify("fa")
      //   ) === "fa"
      //     ? language.fa.userFetchDataFailed
      //     : language.en.userFetchDataFailed,
      //   "error"
      // );
    });
    /**************************************************** */
    /**************************************************** */
    /**************************************************** */
    /**************************************************** */
    builder.addCase(editAsyncUserData.pending, (state, action) => {
      console.log("editAsyncUserData PENDING");
      state.isUserInformationChangePending = true;
    });
    builder.addCase(editAsyncUserData.fulfilled, (state, action) => {
      state.isUserInformationChangePending = false;

      // if (!action.payload) return;

      // const memberships = [...action.payload.groupMembership];
      // state.allGroups = memberships.map(
      //   (item: { title: string; value: string }) => item.value
      // );
      // state.email = action.payload.email;
      // state.extraIdentityInformation = action.payload.extraIdentityInformation;
      // state.firstname = action.payload.firstname;
      // state.groupMembership = action.payload.groupMembership;
      // state.indirectGroupMembership = action.payload.indirectGroupMembership;
      // state.lastname = action.payload.lastname;
      // state.tenantInformation = action.payload.tenantInformation;
      // state.username = action.payload.username;
      // customizedToast(
      //   JSON.parse(
      //     localStorage.getItem("currLanguage") || JSON.stringify("fa")
      //   ) === "fa"
      //     ? language.fa.userEditDataSuccess
      //     : language.en.userEditDataSuccess,
      //   "success"
      // );
    });
    builder.addCase(editAsyncUserData.rejected, (state, action) => {
      state.isUserInformationChangePending = false;

      console.log("editAsyncUserData REJECTED !");
    });
    /**************************************************** */
    /**************************************************** */
    /**************************************************** */
    /**************************************************** */
    builder.addCase(changeAsyncPassword.pending, (state, action) => {
      console.log("changeAsyncPassword PENDING");
      state.isPasswordChangePending = true;
    });
    builder.addCase(changeAsyncPassword.fulfilled, (state, action) => {
      state.isPasswordChangePending = false;

      for (let i: number = 0; i <= action.payload?.length - 1; i++) {
        customizedToast(action.payload[i].message, "success");
      }
    });
    builder.addCase(changeAsyncPassword.rejected, (state, action) => {
      state.isPasswordChangePending = false;

      console.log("changeAsyncPassword REJECTED");
    });
  },
});
