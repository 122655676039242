// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Utils
// Utils

// Services
import { loginService } from "../../Services/Authentication/post/loginService";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
import { logOutWithCookieService } from "../../Services/Authentication/post/logOutWithCookieService";
// Services

const initialState: {
  accessToken: string;
  refreshToken: string;
  isPending: boolean;
} = {
  accessToken: JSON.parse(
    localStorage.getItem("accessToken") || JSON.stringify("")
  ),
  refreshToken: JSON.parse(
    localStorage.getItem("refreshToken") || JSON.stringify("")
  ),
  isPending: false,
};

export const postAsyncLogin = createAsyncThunk(
  "loginData/loginUser",
  async (_data: {
    userName: string;
    password: string;
    cookieModeRememberMe: boolean;
    onDone?: (_accessToken: string, _refreshToken: string) => void;
  }) => {
    try {
      const data = await loginService({
        userName: _data.userName,
        password: _data.password,
        cookieModeRememberMe: _data.cookieModeRememberMe,
      });

      if (!!_data.onDone) {
        _data.onDone(
          data.data.result.accessToken,
          data.data.result.refreshToken
        );
      }
      if (!data.data.result) return;
      return data.data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const postAsyncLogOutCookie = createAsyncThunk(
  "loginData",
  async (_data: { onDone: () => void }) => {
    try {
      const { data } = await logOutWithCookieService();
      if (typeof _data.onDone === "function") {
        _data.onDone();
        return data;
      }
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const loginSlice = createSlice({
  name: "loginData",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("userData");
      localStorage.removeItem("basicItems");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("tenant");
      localStorage.removeItem("isNightMode");
      localStorage.removeItem("userGroups");
      state.accessToken = "init";
      state.refreshToken = "init";
      window.location.reload();
    },
    addLoginToken: (state, action) => {
      state.accessToken = action.payload;
    },
    addRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    //
    builder.addCase(postAsyncLogin.pending, (state) => {
      state.isPending = true;
    });
    //
    builder.addCase(postAsyncLogin.fulfilled, (state, action) => {
      state.isPending = false;
      if (!action.payload) {
        // state.accessToken = JSON.stringify("cookieMode");
        // state.refreshToken = JSON.stringify("cookieMode");
        // localStorage.setItem("accessToken", JSON.stringify("cookieMode"));
        // localStorage.setItem("refreshToken", JSON.stringify("cookieMode"));
        return;
      }
      state.accessToken = action.payload.result.accessToken;
      state.refreshToken = action.payload.result.refreshToken;

      localStorage.setItem(
        "accessToken",
        JSON.stringify(action.payload.result.accessToken)
      );
      localStorage.setItem(
        "refreshToken",
        JSON.stringify(action.payload.result.refreshToken)
      );
    });
    //
    builder.addCase(postAsyncLogin.rejected, (state) => {
      state.isPending = false;
      state.accessToken = "init";
      state.refreshToken = "init";
    });
  },
});
