// React
import React, { useCallback, useContext, useEffect, useRef } from "react";
// React

// CSS
import styles from "./UserDetailModal.module.css";
// CSS

// Models
import { initialUserDataModalData, UserModalData } from "../UsersDetail";
import { IsNightModeOnContext } from "../../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import RowItem from "../../HistoryTab/HistoryTabItem/RowItem/RowItem";
import { withoutInformation } from "../../HistoryTab/HistoryTabItem/TaskInformation/TaskInformation";
// Models

type UserDetailModalProps = {
  modalData: UserModalData;
  setModalData: React.Dispatch<React.SetStateAction<UserModalData>>;
};

const UserDetailModal: React.FunctionComponent<UserDetailModalProps> = ({
  modalData,
  setModalData,
}) => {
  const bgRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const isNightModeOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!bgRef.current) return;
      if (!contentRef.current) return;

      bgRef.current.style.opacity = "0.7";
      contentRef.current.style.top = "0px";
    }, 50);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  const closeModal = useCallback(() => {
    if (!bgRef.current) return;
    if (!contentRef.current) return;

    bgRef.current.style.opacity = "0";
    contentRef.current.style.top = "100vh";

    setTimeout(() => {
      setModalData(initialUserDataModalData);
    }, 500);
  }, [setModalData]);

  return (
    <div
      className={`${styles.userDetailModal}  w-100 d-flex align-items-center justify-content-center`}
    >
      <div className={`${styles.bgRef}`} ref={bgRef} onClick={closeModal}></div>

      <div
        className={`${styles.content} d-flex flex-column  ${
          isNightModeOn ? "nightBg1" : ""
        } `}
        ref={contentRef}
      >
        <RowItem
          data={{
            title: "نام",
            value: modalData.modalData?.firstname,
          }}
          isOdd={false}
        />
        <RowItem
          data={{
            title: "نام خانوادگی",
            value: modalData.modalData?.lastname,
          }}
          isOdd
        />
        <RowItem
          data={{
            title: "ایمیل",
            value: modalData.modalData?.email,
          }}
          isOdd={false}
        />
        <RowItem
          data={{
            title: "نام کاربری",
            value: modalData.modalData?.username,
          }}
          isOdd
        />

        {Object.keys(modalData.modalData.extraIdentityInformation).length ===
        0 ? (
          ""
        ) : (
          <>
            {/* <p className="w-100 my-2 d-flex flex-row align-items-center justify-content-center">
              اطلاعات هویتی
            </p> */}
            {Object.keys(modalData.modalData.extraIdentityInformation).map(
              (item, index) => (
                <RowItem
                  key={item}
                  data={{
                    title:
                      modalData.modalData?.extraIdentityInformation[item]
                        ?.identityAttribute?.title || withoutInformation,
                    value:
                      modalData.modalData?.extraIdentityInformation[item]
                        ?.value || withoutInformation,
                  }}
                  isOdd={index % 2 !== 0}
                />
              )
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserDetailModal;
