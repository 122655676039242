// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// React

// CSS
import styles from "./ViewSingleFormSample.module.css";
// CSS

// Modules
import { useNavigate, useSearchParams } from "react-router-dom";
import { mainUrl } from "../../../Services/urls";
// Modules

// Utils
import KeyValuePairShow from "../../../Utils/KeyValuePairShow/KeyValuePairShow";
// Utils

// Context
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Context

// Components
import { Form } from "@formio/react";
// Components

// Hooks
import { useMapRenderInLittleModal } from "../../../Hooks/MapHooks/useMapRenderInLittleModal";
import useReRenderMap from "../../../Hooks/useReRenderMap";
import { useAppSelector } from "../../../app/store";
import { getAllFormSamplesService } from "../../../Services/FormSamples/get/getAllFormSamplesService";
// Hooks

const ViewSingleFormSample = () => {
  const navigate = useNavigate();

  const dataRef = useRef<any>();

  const [searchParams] = useSearchParams();

  const [form, setForm] = useState<any>({});
  const [forceReRender] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);

  const isNightModeOn = useContext(IsNightModeOnContext);

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const [isFormFileLoadPending, setIsFormFileLoadPending] =
    useState<boolean>(true);

  const getFormJSON = useCallback(async () => {
    setIsFormPending(true);
    setIsError(false);
    setIsFormFileLoadPending(true);

    if (typeof searchParams.get("id") !== "string") {
      navigate("/processes");
      return;
    }

    getAllFormSamplesService({
      _data: {
        page: 1,
        search: "",
        size: 1,
        tenant: selectedTenant.tenantId,
        type: "Form-IO",
        id: String(searchParams.get("id")),
      },
      userToken,
    })
      .then(({ data }) => data.result.content[0])
      .then((data) => {
        setIsFormFileLoadPending(true);
        fetch(`${mainUrl}${data.url}`)
          .then((res) => res.json())
          .then((res) => {
            setForm(res);
            setIsError(false);
          })
          .catch((err) => {
            console.log(err);
            setIsError(true);
          })
          .finally(() => {
            setIsFormPending(false);
            setIsFormFileLoadPending(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
      })
      .finally(() => {
        setIsFormPending(false);
      });
  }, [searchParams, navigate, selectedTenant.tenantId, userToken]);

  useEffect(() => {
    const timeOut = setTimeout(getFormJSON, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [navigate, getFormJSON]);

  useMapRenderInLittleModal(isFormPending);

  useReRenderMap({
    forceReRender,
    form,
    isPending: false,
  });

  return (
    <div
      className={` d-flex flex-column align-items-center w-100  ${styles.formSampleContainer}`}
    >
      <div
        className={`${styles.keyValueContainers} ${
          isNightModeOn ? "nightBg1" : ""
        } w-100 d-flex flex-column`}
      >
        <KeyValuePairShow
          _key={{
            title: "نام فرم",
            className: styles.keyClassName,
          }}
          _value={{
            title: searchParams.get("title") || "تعریف نشده",
            className: styles.valueClassName,
          }}
        />
        <KeyValuePairShow
          _key={{
            title: "توضیحات فرم",
            className: styles.keyClassName,
          }}
          _value={{
            title: searchParams.get("description") || "تعریف نشده",
            className: styles.valueClassName,
          }}
        />
        <KeyValuePairShow
          _key={{
            title: "آدرس فرم",
            className: styles.keyClassName,
          }}
          _value={{
            title: searchParams.get("url") || "تعریف نشده",
            className: styles.valueClassName,
          }}
        />
      </div>

      {(isFormPending && !isError) || isFormFileLoadPending
        ? "در حال بارگذاری فرم "
        : null}
      {!isFormPending && !isError && !isFormFileLoadPending ? (
        <div
          className={`${styles.formContainer} ${
            isNightModeOn ? "nightBg1" : ""
          }  w-100 d-flex flex-column mt-2`}
        >
          <Form
            src={form}
            onChange={(data: any) => {
              dataRef.current = data;
            }}
            onFormLoad={() => {}}
            onSubmit={(data: any) => {
              console.log(data);
              console.log("submited !", dataRef.current.data);
            }}
          />
        </div>
      ) : null}
      {isError && !isFormPending && !isFormFileLoadPending ? (
        <button className="operationEvent submitBtn" onClick={getFormJSON}>
          تلاش مجدد
        </button>
      ) : null}
    </div>
  );
};

export default ViewSingleFormSample;
