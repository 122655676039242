// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// React

// CSS
import styles from "./AddFormSampleModal.module.css";
// CSS

// Redux
import { useAppSelector } from "../../../../app/store";
// Redux

// Context
import { IsNightModeOnContext } from "../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Context

// Components
import { Form } from "@formio/react";
// Components

// Urls
import { mainUrl } from "../../../../Services/urls";
// Urls

// Utils
import { customizedToast } from "../../../../Utils/CustomizedToast/customizedToast";
// Utils

// Languages
import { formIoLanguagePack } from "../../../../Language/formioLanguage";
import { separator } from "../../../../Images/images";
// Languages

// Hooks
import usePersianDatePickerEventHandler from "../../../../Hooks/usePersianDatePickerEventHandler";
import useReRenderMap from "../../../../Hooks/useReRenderMap";
import { useMapRenderInLittleModal } from "../../../../Hooks/MapHooks/useMapRenderInLittleModal";
import { resetReferenceOfObject } from "../../../../Utils/resetReferenceOfObject";
import { customMapComponentPattern } from "../../../../Formats/formats";
// Hooks

type AddFormSampleModalProps = {
  formUrl: string;
  setIsModalInScreen: React.Dispatch<React.SetStateAction<boolean>>;
  dataSetter: (data: any) => void;
};

const AddFormSampleModal: React.FunctionComponent<AddFormSampleModalProps> = ({
  formUrl,
  setIsModalInScreen,
  dataSetter,
}) => {
  const bgRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const isNightModeOn = useContext(IsNightModeOnContext);

  const [form, setForm] = useState<any>({ display: "form", components: [] });
  const [isError, setIsError] = useState<boolean>(false);
  const [isFormPending, setIsFormPending] = useState<boolean>(false);

  const getFormJSON = useCallback(
    async function () {
      setIsFormPending(true);
      setIsError(false);
      fetch(`${mainUrl}${formUrl}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          setForm(res);
        })
        .catch((err) => {
          console.log(err);
          setIsError(true);
        })
        .finally(() => {
          setIsFormPending(false);
        });
    },
    [formUrl]
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!bgRef.current) return;
      if (!contentRef.current) return;

      bgRef.current.style.opacity = "0.7";
      contentRef.current.style.top = "0px";
    }, 50);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    getFormJSON();
  }, [getFormJSON]);

  const closeModal = useCallback(() => {
    if (!bgRef.current) return;
    if (!contentRef.current) return;

    bgRef.current.style.opacity = "0";
    contentRef.current.style.top = "100vh";

    setTimeout(() => {
      setIsModalInScreen(false);
    }, 500);
  }, [setIsModalInScreen]);

  const currLanguage = useAppSelector(
    (state) => state.currLanguage.currLanguage
  );

  const formioOptions: any = {
    language: currLanguage,
    i18n: formIoLanguagePack.i18n,
  };

  usePersianDatePickerEventHandler(isFormPending);

  useReRenderMap({
    forceReRender: true,
    form,
    isPending: isFormPending,
  });

  useMapRenderInLittleModal(false);

  return (
    <div
      className={`${styles.formSampleModalContainer} w-100 d-flex align-items-center justify-content-center`}
    >
      <div
        className={`${styles.bgRef} w-100`}
        onClick={closeModal}
        ref={bgRef}
      ></div>
      <div
        className={`${styles.content} ${
          isNightModeOn ? "nightBg2" : ""
        } d-flex flex-column align-items-center `}
        ref={contentRef}
      >
        {!isError && isFormPending ? null : (
          <p
            className="w-100 d-flex flex-row align-items-center justify-content-end "
            style={{
              fontSize: "20px",
            }}
          >
            : فرم انتخاب شده
          </p>
        )}

        {!isError && isFormPending ? <>در حال بارگذاری</> : null}

        {!isError && !isFormPending ? (
          <Form
            form={form}
            onLoad={() => {}}
            onError={() => {
              customizedToast("مشکلی در هنگام دریافت فرم به وجود آمد", "error");
            }}
            onSubmit={() => {}}
            onChange={console.log}
            submission={{}}
            options={formioOptions}
          />
        ) : null}

        {!isError && !isFormPending ? (
          <>
            <img src={separator} alt="" className="w-100 mt-4" />
            <button
              className="operationEvent submitBtn mt-4"
              onClick={() => {
                if (!("components" in form)) return;

                const { components } = form;
                const _comp = resetReferenceOfObject(components);
                function replacer(match: any, p1: any) {
                  const randomSuffix = Math.floor(Math.random() * 123220);
                  return `customMapComponent${randomSuffix}`;
                }
                const res = JSON.parse(
                  JSON.stringify(_comp).replace(
                    customMapComponentPattern,
                    replacer
                  )
                );

                dataSetter(resetReferenceOfObject(res));
                closeModal();
              }}
            >
              افزودن فرم به فرم فعلی
            </button>
          </>
        ) : null}

        {isError && !isFormPending ? (
          <button className="operationEvent submitBtn" onClick={getFormJSON}>
            تلاش دوباره
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default AddFormSampleModal;
