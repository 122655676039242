// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import {
  I_Message,
  I_SingleCallOfRegistrationAttributes,
} from "../../../Models/interfaces";
// Models

export const getIdentityAttributesOfSingleCallOfRegistrationService =
  async (_data: {
    userToken: string;
    _data: {
      callOfRegistrationId: string | number;
    };
  }): Promise<
    AxiosResponse<{
      error: number;
      messages: I_Message[];
      result: I_SingleCallOfRegistrationAttributes[];
    }>
  > => {
    return _axios.get(
      `/call-of-registrations/${_data._data.callOfRegistrationId}/identity-attributes`,
      {
        headers: {
          Authorization: `Bearer ${_data.userToken}`,
        },
      }
    );
  };
