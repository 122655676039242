import { AxiosResponse } from "axios";
import { I_Message } from "../../Models/interfaces";
import { _axios } from "../Base_Axios_Config/_axios";

export const removeSingleUserIdentityService = (_data: {
  userToken: string;
  attributeIdOrKey: string;
  tenant: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
  }>
> => {
  return _axios.post(
    `/users/identity/attributes/${_data.attributeIdOrKey}/delete?tenant=${_data.tenant}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
