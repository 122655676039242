// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_GetAllReportsSendingData } from "../../../Models/sendingDataSchema";
import { I_Message, I_ReportData } from "../../../Models/interfaces";
// Models

export const getAllProcessReportService = async (_data: {
  _data: I_GetAllReportsSendingData;
  userToken: string;
  processKey: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_ReportData;
  }>
> => {
  return _axios.get(
    `/processes/${_data.processKey}/reports?page=${_data._data.page}&size=${_data._data.size}&search=${_data._data.search}&tenant=${_data._data.tenant}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
