// RegEx
import {
  dateFormat,
  formioDateFormat2,
  formioDateFormat3,
} from "../../Formats/formats";
// RegEx

// Date Converters
import { gregorian_to_jalali, jalali_to_gregorian } from "./getPersianDate";
import { JalaliDate } from "./toGeorgian";
// Date Converters

export const convertToGeorgianDateFormPersianDateInString = (
  stringData: string
) => {
  const _JalaliDate: any = JalaliDate;

  // const now = new Date();
  // const hour = now.getHours();
  // const minute = now.getMinutes();
  // const second = now.getSeconds();

  if (dateFormat.test(JSON.stringify(stringData))) {
    const modifiedString = JSON.stringify(stringData).replaceAll(
      dateFormat,
      (match) => {
        let str: string = "";
        if (match.split("/").length === 3) {
          const jD = _JalaliDate.jalaliToGregorian(
            match.split("/")[0],
            match.split("/")[1],
            match.split("/")[2]
          );
          // const t = `${
          //   String(hour).length === 1 ? `0${hour}` : hour
          // }:${String(minute).length === 1 ? `0${minute}` : minute}:${
          //   String(second).length === 1 ? `0${second}` : second
          // }`
          str = `${jD[0]}-${jD[1]}-${jD[2]}T00:00:00`;
        }
        return str;
      }
    );
    const modifiedObject = JSON.parse(modifiedString);
    return modifiedObject;
  }
  if (formioDateFormat2.test(JSON.stringify(stringData))) {
    const modifiedString = JSON.stringify(stringData).replaceAll(
      formioDateFormat2,
      (match: any) => {
        if (match.split("T").length === 2) {
          if (match.split("T")[0][0] === "1") {
            const date = match.split("T")[0].split("-");
            const year = date[0];
            const month = date[1];
            const day = date[2];
            const res = gregorian_to_jalali(
              Number(year),
              Number(month),
              Number(day)
            );

            // const t = `${String(hour).length === 1 ? `0${hour}` : hour}:${
            //   String(minute).length === 1 ? `0${minute}` : minute
            // }:${String(second).length === 1 ? `0${second}` : second}`;

            const str = `${res[0]}/${
              String(res[1]).length === 1 ? `0${res[1]}` : res[1]
            }/${String(res[2]).length === 1 ? `0${res[2]}` : res[2]}T00:00:00`;
            return str;
          } else {
            let str: string = "";
            console.log(match.split("T"));
            const date = match.split("T")[0].split("-");
            const year = date[0];
            const month = date[1];
            const day = date[2];
            const res = gregorian_to_jalali(
              Number(year),
              Number(month),
              Number(day)
            );

            // const x = `${
            //   String(hour).length === 1 ? `0${hour}` : hour
            // }:${String(minute).length === 1 ? `0${minute}` : minute}:${
            //   String(second).length === 1 ? `0${second}` : second
            // }`

            str = `${res[0]}-${res[1]}-${res[2]}T00:00:00`;

            return str;
          }
        }
        return match;
      }
    );
    const modifiedObject = JSON.parse(modifiedString);
    console.log(modifiedObject);
    return modifiedObject;
  }
  if (formioDateFormat3.test(JSON.stringify(stringData))) {
    const modifiedString = JSON.stringify(stringData).replaceAll(
      formioDateFormat3,
      (match) => {
        let str: string = "";
        if (
          match.split("T")[0][0].startsWith("2") &&
          match.split("T").length === 2
        ) {
          const date = match.split("T")[0].split("/");
          console.log(date);
          const year = date[0];
          const month = date[1];
          const day = date[2];

          const res = gregorian_to_jalali(
            Number(year),
            Number(month),
            Number(day)
          );

          // const str = `${
          //   String(hour).length === 1 ? `0${hour}` : hour
          // }:${String(minute).length === 1 ? `0${minute}` : minute}:${
          //   String(second).length === 1 ? `0${second}` : second
          // }`

          str = `${res[0]}-${
            String(res[1]).length === 1 ? `0${res[1]}` : res[1]
          }-${String(res[2]).length === 1 ? `0${res[2]}` : res[2]}T00:00:00`;

          console.log(str);
          return str;
        }
        if (
          match.split("T")[0][0].startsWith("1") &&
          match.split("T").length === 2
        ) {
          const date = match.split("T")[0].split("/");
          console.log(date);
          const year = date[0];
          const month = date[1];
          const day = date[2];

          const res = jalali_to_gregorian(
            Number(year),
            Number(month),
            Number(day)
          );

          // const str = `${
          //   String(hour).length === 1 ? `0${hour}` : hour
          // }:${String(minute).length === 1 ? `0${minute}` : minute}:${
          //   String(second).length === 1 ? `0${second}` : second
          // }`

          str = `${res[0]}-${
            String(res[1]).length === 1 ? `0${res[1]}` : res[1]
          }-${String(res[2]).length === 1 ? `0${res[2]}` : res[2]}T00:00:00`;

          console.log(str);
          return str;
        }

        console.log(str);
        return str;
      }
    );

    const modifiedObject = JSON.parse(modifiedString);
    console.log(modifiedObject);
    return modifiedObject;
  }
};
