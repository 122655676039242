export const formioCustomDataTypes: {
  type:
    | ""
    | "CUSTOM_IMAGE_COMPONENT"
    | "CUSTOM_PERSIAN_DATE_PICKER"
    | "CUSTOM_MAP_COMPONENT"
    | "CUSTOM_EMAIL_COMPONENT"
    | "CUSTOM_MAP_COMPONENT_WITH_INPUT"
    | "CUSTOM_FORMIO_COMPLEX_PERSIAN_DATE_PICKER";
  title: string;
}[] = [
  {
    type: "",
    title: "لطفا انتخاب کنید",
  },
  {
    type: "CUSTOM_IMAGE_COMPONENT",
    title: "عکس خاص",
  },
  {
    type: "CUSTOM_PERSIAN_DATE_PICKER",
    title: "تقویم فارسی",
  },
  // {
  //   type: "CUSTOM_FORMIO_COMPLEX_PERSIAN_DATE_PICKER",
  //   title: "تقویم فارسی پیشرفته",
  // },
  {
    type: "CUSTOM_EMAIL_COMPONENT",
    title: "ایمیل",
  },
  {
    type: "CUSTOM_MAP_COMPONENT",
    title: "نمایش نقطه روی نقشه",
  },
  {
    type: "CUSTOM_MAP_COMPONENT_WITH_INPUT",
    title: "انتخاب نقطه روی نقشه",
  },
];
