// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Utils
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
// Utils

// Models
import {
  I_CallOfRegistrationRecevedData,
  I_RecevedSingleCallOfRegistrationRequestsData,
  I_ResponseOfCheckingRequestState,
  I_SingleCallOfRegistrationAttributes,
  I_SingleCallOfRegistrationRecevedData,
} from "../../Models/interfaces";
import {
  I_AddingNewSingleCallOfRegistration,
  I_ChangingStateOfARequestSendingData,
  I_EditingSingleCallOfRegistration,
  I_GetCallOfRegistrationSendingData,
  I_SendingCallOfRegistrationRequest,
} from "../../Models/sendingDataSchema";
// Models

// Services
import { getAllCallOfRegistrationService } from "../../Services/CallOfRegistration/get/getAllCallOfRegistrationService";
import { getIdentityAttributesOfSingleCallOfRegistrationService } from "../../Services/CallOfRegistration/get/getIdentityAttributesOfSingleCallOfRegistrationService";
import { getAllDetailsOfSingleCallOfRegistrationService } from "../../Services/CallOfRegistration/get/getAllDetailsOfSingleCallOfRegistrationService";
import { getAllRequestForSingleCallOfRegistrationService } from "../../Services/CallOfRegistration/get/getAllRequestForSingleCallOfRegistrationService";
import { addNewSingleCallOfRegistrationService } from "../../Services/CallOfRegistration/post/addNewSingleCallOfRegistrationService";
import { editSingleCallOfRegistrationService } from "../../Services/CallOfRegistration/post/editSingleCallOfRegistrationService";
import { checkRequestStateOfRegistrationRequestService } from "../../Services/CallOfRegistration/post/checkRequestStateOfRegistrationRequestService";
import { changeStateOfRegistrationRequestService } from "../../Services/CallOfRegistration/post/changeStateOfRegistrationRequestService";
import { resetReferenceOfObject } from "../../Utils/resetReferenceOfObject";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Services

const initialState: {
  callOfRegistrationData: {
    data: I_CallOfRegistrationRecevedData;

    //added By Developer
    isPending: boolean;
    isError: boolean;
    //added By Developer
  };
} & {
  singleCallOfRegistrationAttributes: {
    data: I_SingleCallOfRegistrationAttributes[];
    isPending: boolean;
    isError: boolean;
  };
} & {
  singleCallOfRegistrationDetails: {
    data: I_SingleCallOfRegistrationRecevedData;
    isPending: boolean;
    isError: boolean;
  };
} & {
  singleCallOfRegistrationRequests: {
    data: I_RecevedSingleCallOfRegistrationRequestsData;
    isPending: boolean;
    isError: boolean;
  };
} & {
  addingCallOfRegistrationDetails: {
    isPending: boolean;
    isDone: boolean;
  };
} & {
  editingCallOfRegistration: {
    isPending: boolean;
    isDone: boolean;
  };
} & {
  checkStateOfRegistrationRequest: {
    isPending: boolean;
    isDone: boolean;
    data: I_ResponseOfCheckingRequestState;
  };
} & {
  editSingleRegistrationRequest: {
    isPending: boolean;
    isDone: boolean;
  };
} = {
  callOfRegistrationData: {
    data: {
      content: [],
      hasNextPage: false,
      pageNumber: 1,
      pageSize: 10,
      totalElements: 1,
      totalPages: 1,
    },
    isPending: true,
    isError: false,
  },
  singleCallOfRegistrationAttributes: {
    data: [],
    isPending: true,
    isError: false,
  },
  singleCallOfRegistrationDetails: {
    data: {
      id: 1,
      title: "",
      description: "",
      active: false,
      startTime: {
        gregorianDate: "",
        jalaliDate: "",
      },
      endTime: {
        gregorianDate: "",
        jalaliDate: "",
      },
      groups: [],
    },
    isError: false,
    isPending: true,
  },
  singleCallOfRegistrationRequests: {
    data: {
      pageNumber: 1,
      pageSize: 10,
      totalElements: 1,
      totalPages: 1,
      hasNextPage: false,
      content: [],
    },
    isError: false,
    isPending: true,
  },
  addingCallOfRegistrationDetails: {
    isDone: false,
    isPending: false,
  },
  editingCallOfRegistration: {
    isDone: false,
    isPending: false,
  },
  checkStateOfRegistrationRequest: {
    isPending: false,
    isDone: false,
    data: {
      registeredUsername: "",
      firstname: "",
      lastname: "",
      rejectingDescription: "",
      state: {
        value: 0,
        title: "",
      },
      callOfRegistration: {
        id: 0,
        title: "",
        description: "",
        active: false,
        startTime: {
          gregorianDate: "",
          jalaliDate: "",
        },
        endTime: {
          gregorianDate: "",
          jalaliDate: "",
        },
      },
    },
  },
  editSingleRegistrationRequest: {
    isPending: false,
    isDone: false,
  },
};

export const getAsyncAllCallOfRegistrations = createAsyncThunk(
  "callOfRegistration/getAll",
  async (_data: {
    userToken: string;
    _data: I_GetCallOfRegistrationSendingData;
  }) => {
    try {
      const { data } = await getAllCallOfRegistrationService(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncAllRelatedAttributesOfSingleCallOfRegistration =
  createAsyncThunk(
    "callOfRegistration/getSingleRelatedAttributesOfCallOfRegistration",
    async (_data: {
      userToken: string;
      _data: {
        callOfRegistrationId: string | number;
      };
    }) => {
      try {
        const { data } =
          await getIdentityAttributesOfSingleCallOfRegistrationService(_data);
        return data;
      } catch (err: any) {
        console.log(err);
        if (err?.response.data.messages) {
          for (
            let i: number = 0;
            i <= err?.response.data.messages.length - 1;
            i++
          ) {
            customizedToast(err?.response.data.messages[i].message, "error");
          }
          throw err;
        }
        throw err;
      }
    }
  );

export const getAsyncAllDetailsForSingleCallOfRegistration = createAsyncThunk(
  "callOfRegistration/getDetailsOfSingleCallOfRegistration",
  async (_data: { userToken: string; callOfRegistrationId: string }) => {
    try {
      const { data } = await getAllDetailsOfSingleCallOfRegistrationService(
        _data
      );
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncAllRelatedCallOfRegistrationRequests = createAsyncThunk(
  "callOfRegistration/getRelatedCallOfRegistration",
  async (_data: {
    userToken: string;
    _data: I_SendingCallOfRegistrationRequest;
  }) => {
    try {
      const { data } = await getAllRequestForSingleCallOfRegistrationService(
        _data
      );
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const addAsyncSingleCallOfRegistration = createAsyncThunk(
  "callOfRegistration/addingSingleCallOfRegistration",
  async (_data: {
    _data: I_AddingNewSingleCallOfRegistration;
    userToken: string;
  }) => {
    try {
      const { data } = await addNewSingleCallOfRegistrationService(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const editAsyncSingleCallOfRegistration = createAsyncThunk(
  "callOfRegistration/editSingleCallOfRegistration",
  async (_data: {
    userToken: string;
    callOfRegistrationId: string | number;
    _data: I_EditingSingleCallOfRegistration;
  }) => {
    try {
      const { data } = await editSingleCallOfRegistrationService(_data);

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const editAsyncSingleCallOfRegistrationWithoutDoneChanging =
  createAsyncThunk(
    "callOfRegistration/editSingleCallOfRegistrationWithoutChangingDone",
    async (_data: {
      userToken: string;
      callOfRegistrationId: string | number;
      _data: I_EditingSingleCallOfRegistration;
    }) => {
      try {
        const { data } = await editSingleCallOfRegistrationService(_data);

        return data;
      } catch (err: any) {
        console.log(err);
        if (err?.response.data.messages) {
          for (
            let i: number = 0;
            i <= err?.response.data.messages.length - 1;
            i++
          ) {
            customizedToast(err?.response.data.messages[i].message, "error");
          }
          throw err;
        }
        throw err;
      }
    }
  );

export const checkAsyncSingleRegistrationState = createAsyncThunk(
  "callOfRegistration/checkAsyncRegistrationState",
  async (_data: {
    _data: {
      username: string;
      trackingCode: string;
    };
  }) => {
    try {
      const { data } = await checkRequestStateOfRegistrationRequestService(
        _data
      );
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const editAsyncRegistrationRequest = createAsyncThunk(
  "callOfRegistration",
  async (_data: {
    userToken: string;
    _data: I_ChangingStateOfARequestSendingData;
    requestId: string | number;
  }) => {
    try {
      const { data } = await changeStateOfRegistrationRequestService(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const callOfRegistrationSlice = createSlice({
  name: "callOfRegistration",
  initialState,
  reducers: {
    resetRecevedCallOfRegistrationData: (state) => {
      state.callOfRegistrationData.data = resetReferenceOfObject(
        initialState.callOfRegistrationData.data
      );
      state.callOfRegistrationData.isPending = true;
      state.callOfRegistrationData.isError = false;
    },
    resetRecevedAttributesOfSingleCallOfRegistration: (state) => {
      state.singleCallOfRegistrationAttributes.isPending = true;
    },
    resetRecevedDetailsOfSingleCallOfRegistration: (state) => {
      state.singleCallOfRegistrationDetails.isError = false;
      state.singleCallOfRegistrationDetails.isPending = true;
      state.singleCallOfRegistrationDetails.data = resetReferenceOfObject(
        initialState.singleCallOfRegistrationDetails.data
      );
    },
    resetRecevedSingleCallOfRegistrationRequests: (state) => {
      state.singleCallOfRegistrationRequests.data = resetReferenceOfObject(
        initialState.singleCallOfRegistrationRequests.data
      );

      state.singleCallOfRegistrationRequests.isPending = true;
      state.singleCallOfRegistrationRequests.isError = false;
    },
    resetAddingSingleCallOfRegistrationState: (state) => {
      state.addingCallOfRegistrationDetails.isDone = false;
      state.addingCallOfRegistrationDetails.isPending = false;
    },
    resetEditingSingleCallOfRegistrationState: (state) => {
      state.editingCallOfRegistration.isDone = false;
      state.editingCallOfRegistration.isPending = false;
    },
    resetCheckingRequestOfSingleRegistration: (state) => {
      state.checkStateOfRegistrationRequest.isPending = false;
      state.checkStateOfRegistrationRequest.isDone = false;
    },
    resetEditingSingleRegistrationRequestState: (state) => {
      state.editSingleRegistrationRequest.isDone = false;
      state.editSingleRegistrationRequest.isPending = false;
    },
  },
  extraReducers: (builder) => {
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    builder.addCase(getAsyncAllCallOfRegistrations.pending, (state, action) => {
      state.callOfRegistrationData.isPending = true;
      state.callOfRegistrationData.isError = false;
    });
    builder.addCase(
      getAsyncAllCallOfRegistrations.fulfilled,
      (state, action) => {
        state.callOfRegistrationData.isPending = false;

        if (action.payload?.error === 0 || Boolean(action.payload) === true) {
          for (
            let i: number = 0;
            i <= action.payload!.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload!.messages[i].message, "success");
          }
          state.callOfRegistrationData.isError = false;
          state.callOfRegistrationData.data = action.payload!.result;
        } else {
          state.callOfRegistrationData.isError = true;
        }
      }
    );
    builder.addCase(
      getAsyncAllCallOfRegistrations.rejected,
      (state, action) => {
        state.callOfRegistrationData.isPending = false;
        state.callOfRegistrationData.isError = true;
      }
    );
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    builder.addCase(
      getAsyncAllRelatedAttributesOfSingleCallOfRegistration.pending,
      (state, action) => {
        state.singleCallOfRegistrationAttributes.isPending = true;
        state.singleCallOfRegistrationAttributes.isError = false;
      }
    );
    builder.addCase(
      getAsyncAllRelatedAttributesOfSingleCallOfRegistration.fulfilled,
      (state, action) => {
        state.singleCallOfRegistrationAttributes.isPending = false;

        if (action.payload?.error === 0 || Boolean(action.payload) === true) {
          for (
            let i: number = 0;
            i <= action.payload!.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload!.messages[i].message, "success");
          }
          state.singleCallOfRegistrationAttributes.isError = false;
          state.singleCallOfRegistrationAttributes.data = action.payload.result;
        }
      }
    );
    builder.addCase(
      getAsyncAllRelatedAttributesOfSingleCallOfRegistration.rejected,
      (state, action) => {
        state.singleCallOfRegistrationAttributes.isPending = false;
        state.singleCallOfRegistrationAttributes.isError = true;
      }
    );
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    builder.addCase(
      getAsyncAllDetailsForSingleCallOfRegistration.pending,
      (state, action) => {
        state.singleCallOfRegistrationDetails.isPending = true;
        state.singleCallOfRegistrationDetails.isError = false;
      }
    );
    builder.addCase(
      getAsyncAllDetailsForSingleCallOfRegistration.fulfilled,
      (state, action) => {
        state.singleCallOfRegistrationDetails.isPending = false;

        if (action.payload?.error === 0 || Boolean(action.payload) === true) {
          for (
            let i: number = 0;
            i <= action.payload!.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload!.messages[i].message, "success");
          }
          state.singleCallOfRegistrationDetails.data = action.payload!.result;
          state.singleCallOfRegistrationDetails.isError = false;
        }
      }
    );
    builder.addCase(
      getAsyncAllDetailsForSingleCallOfRegistration.rejected,
      (state, action) => {
        state.singleCallOfRegistrationDetails.isPending = false;
        state.singleCallOfRegistrationDetails.isError = true;
      }
    );
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    builder.addCase(
      getAsyncAllRelatedCallOfRegistrationRequests.pending,
      (state, action) => {
        state.singleCallOfRegistrationRequests.isPending = true;
        state.singleCallOfRegistrationRequests.isError = false;
      }
    );
    builder.addCase(
      getAsyncAllRelatedCallOfRegistrationRequests.fulfilled,
      (state, action) => {
        state.singleCallOfRegistrationRequests.isPending = false;
        if (action.payload?.error === 0 || Boolean(action.payload) === true) {
          for (
            let i: number = 0;
            i <= action.payload!.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload!.messages[i].message, "success");
          }
          state.singleCallOfRegistrationRequests.data = action.payload!.result;
          state.singleCallOfRegistrationRequests.isError = false;
        }
      }
    );
    builder.addCase(
      getAsyncAllRelatedCallOfRegistrationRequests.rejected,
      (state, action) => {
        state.singleCallOfRegistrationRequests.isPending = false;
        state.singleCallOfRegistrationRequests.isError = true;
      }
    );
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    builder.addCase(
      addAsyncSingleCallOfRegistration.pending,
      (state, action) => {
        state.addingCallOfRegistrationDetails.isDone = false;
        state.addingCallOfRegistrationDetails.isPending = true;
      }
    );
    builder.addCase(
      addAsyncSingleCallOfRegistration.fulfilled,
      (state, action) => {
        state.addingCallOfRegistrationDetails.isPending = false;

        if (action.payload?.error === 0 || Boolean(action.payload) === true) {
          for (
            let i: number = 0;
            i <= action.payload!.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload!.messages[i].message, "success");
          }
          state.addingCallOfRegistrationDetails.isDone = true;
        }
      }
    );
    builder.addCase(
      addAsyncSingleCallOfRegistration.rejected,
      (state, action) => {
        state.addingCallOfRegistrationDetails.isDone = false;
        state.addingCallOfRegistrationDetails.isPending = false;
      }
    );
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    builder.addCase(
      editAsyncSingleCallOfRegistration.pending,
      (state, action) => {
        state.editingCallOfRegistration.isDone = false;
        state.editingCallOfRegistration.isPending = true;
      }
    );
    builder.addCase(
      editAsyncSingleCallOfRegistration.fulfilled,
      (state, action) => {
        state.editingCallOfRegistration.isPending = false;
        if (action.payload?.error === 0 || Boolean(action.payload) === true) {
          for (
            let i: number = 0;
            i <= action.payload!.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload!.messages[i].message, "success");
          }
          state.editingCallOfRegistration.isDone = true;
        }
      }
    );
    builder.addCase(
      editAsyncSingleCallOfRegistration.rejected,
      (state, action) => {
        state.editingCallOfRegistration.isDone = false;
        state.editingCallOfRegistration.isPending = false;
      }
    );
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    builder.addCase(
      editAsyncSingleCallOfRegistrationWithoutDoneChanging.pending,
      (state, action) => {
        state.editingCallOfRegistration.isDone = false;
        state.editingCallOfRegistration.isPending = true;
      }
    );
    builder.addCase(
      editAsyncSingleCallOfRegistrationWithoutDoneChanging.fulfilled,
      (state, action) => {
        state.editingCallOfRegistration.isPending = false;

        if (action.payload?.error === 0 || Boolean(action.payload) === true) {
          for (
            let i: number = 0;
            i <= action.payload!.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload!.messages[i].message, "success");
          }
        }
      }
    );
    builder.addCase(
      editAsyncSingleCallOfRegistrationWithoutDoneChanging.rejected,
      (state, action) => {
        state.editingCallOfRegistration.isDone = false;
        state.editingCallOfRegistration.isPending = false;
      }
    );
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    builder.addCase(checkAsyncSingleRegistrationState.pending, (state) => {
      state.checkStateOfRegistrationRequest.isPending = true;
      state.checkStateOfRegistrationRequest.isDone = false;
    });
    builder.addCase(
      checkAsyncSingleRegistrationState.fulfilled,
      (state, action) => {
        if (action.payload?.error === 0 || Boolean(action.payload) === true) {
          for (
            let i: number = 0;
            i <= action.payload!.messages.length - 1;
            i++
          ) {
            customizedToast(action.payload!.messages[i].message, "success");
          }
          state.checkStateOfRegistrationRequest.isPending = false;
          state.checkStateOfRegistrationRequest.isDone = true;

          state.checkStateOfRegistrationRequest.data = action.payload!.result;
        }
      }
    );
    builder.addCase(checkAsyncSingleRegistrationState.rejected, (state) => {
      state.checkStateOfRegistrationRequest.isPending = false;
      state.checkStateOfRegistrationRequest.isDone = false;
    });
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    ///////////////////////////
    builder.addCase(editAsyncRegistrationRequest.pending, (state, action) => {
      state.editSingleRegistrationRequest.isDone = false;
      state.editSingleRegistrationRequest.isPending = true;
    });
    builder.addCase(editAsyncRegistrationRequest.fulfilled, (state, action) => {
      state.editSingleRegistrationRequest.isPending = false;
      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        state.editSingleRegistrationRequest.isDone = true;
      }
    });
    builder.addCase(editAsyncRegistrationRequest.rejected, (state, action) => {
      state.editSingleRegistrationRequest.isDone = false;
      state.editSingleRegistrationRequest.isPending = false;
    });
  },
});
