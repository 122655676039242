// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Models
import { I_ServerManagementData } from "../../Models/interfaces";
import {
  I_SendingNewServerData,
  I_SendingServerManagementData,
} from "../../Models/sendingDataSchema";
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { getAllServersManagementService as serverManagementService } from "../../Services/ServerManagement/get/getAllServersManagementService";
import { addServerService } from "../../Services/ServerManagement/post/addServerService";
import { removeServerService } from "../../Services/ServerManagement/post/removeServerService";
import { editServerDataService } from "../../Services/ServerManagement/post/editServerDataService";
import { dataServerGenerateNewTokenService } from "../../Services/ServerManagement/post/dataServerGenerateNewTokenService";
import { resetReferenceOfObject } from "../../Utils/resetReferenceOfObject";
import { copyToClipboard } from "../../Utils/copyToClipboard";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Models

const initialState: I_ServerManagementData & {
  isPending: boolean;
  isError: boolean;
} & {
  serverAdding: {
    isPending: boolean;
    isDone: boolean;
  };
} & {
  editingServerStatus: {
    isPending: boolean;
    isDone: boolean;
  };
} = {
  pageNumber: 1,
  pageSize: 10,
  totalPages: 0,
  totalElements: 0,
  hasNextPage: false,
  content: [],

  //   added by developer
  isPending: true,
  isError: false,
  //   added by developer

  //   added by developer
  serverAdding: {
    isDone: false,
    isPending: false,
  },
  //   added by developer

  //   added by developer
  editingServerStatus: {
    isDone: false,
    isPending: false,
  },
  //   added by developer
};

export const getAsyncServerData = createAsyncThunk(
  "servers/getAllServers",
  async (_data: {
    userToken: string;
    _data: I_SendingServerManagementData;
  }) => {
    try {
      const { data } = await serverManagementService(_data);
      return data.result;
      //
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const removeAsyncServerDataItem = createAsyncThunk(
  "servers/removeDataServer",
  async (_data: { userToken: string; serverId: string }) => {
    try {
      const { data } = await removeServerService(
        _data.userToken,
        _data.serverId
      );

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const addAsyncServerToDataServer = createAsyncThunk(
  "servers/addServer",
  async (_data: { userToken: string; _data: I_SendingNewServerData }) => {
    try {
      const { data } = await addServerService(_data);
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const editAsyncServerData = createAsyncThunk(
  "servers/editSelectedServer",
  async (_data: {
    userToken: string;
    serverId: string;
    _data: {
      serverAddress: string;
      setAsCentralServer: boolean;
    };
  }) => {
    try {
      const { data } = await editServerDataService(_data);

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncNewTokenForDataServer = createAsyncThunk(
  "servers/getNewToken",
  async (_data: { userToken: string; dataServerId: string }) => {
    try {
      const { data } = await dataServerGenerateNewTokenService(
        _data.userToken,
        _data.dataServerId
      );

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const serverManagementSlice = createSlice({
  name: "servers",
  initialState,
  reducers: {
    resetServerAddingState: (state) => {
      state.serverAdding.isDone = false;
      state.serverAdding.isPending = false;
    },
    resetServerEditState: (state) => {
      state.editingServerStatus.isDone = false;
      state.editingServerStatus.isPending = false;
    },
    resetServerData: (state) => {
      state.content = resetReferenceOfObject(initialState.content);
      state.isPending = true;
    },
  },
  extraReducers: (builder) => {
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncServerData.pending, (state, action) => {
      state.isPending = true;
      state.isError = false;
    });
    builder.addCase(getAsyncServerData.fulfilled, (state, action) => {
      state.isPending = false;

      if (!action.payload) return;

      state.pageNumber = action.payload!.pageNumber;
      state.pageSize = action.payload!.pageSize;
      state.totalPages = action.payload!.totalPages;
      state.totalElements = action.payload!.totalElements;
      state.hasNextPage = action.payload!.hasNextPage;
      state.content = action.payload!.content;
      state.isError = false;
    });
    builder.addCase(getAsyncServerData.rejected, (state, action) => {
      state.isPending = false;
      state.isError = true;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(addAsyncServerToDataServer.pending, (state, action) => {
      state.serverAdding.isDone = false;
      state.serverAdding.isPending = true;
    });
    builder.addCase(addAsyncServerToDataServer.fulfilled, (state, action) => {
      state.serverAdding.isPending = false;
      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        customizedToast("سرور با موفقیت اضافه شد", "success");
      }
      state.serverAdding.isDone = true;
    });
    builder.addCase(addAsyncServerToDataServer.rejected, (state, action) => {
      state.serverAdding.isDone = false;
      state.serverAdding.isPending = false;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(removeAsyncServerDataItem.pending, (state, action) => {
      customizedToast("درخواست حذف ارسال شد", "info");
    });
    builder.addCase(removeAsyncServerDataItem.fulfilled, (state, action) => {
      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        return;
      }
    });
    builder.addCase(removeAsyncServerDataItem.rejected, (state, action) => {});
    //
    //
    //
    //
    //
    //
    builder.addCase(editAsyncServerData.pending, (state, action) => {
      state.editingServerStatus.isDone = false;
      state.editingServerStatus.isPending = true;
    });
    builder.addCase(editAsyncServerData.fulfilled, (state, action) => {
      state.editingServerStatus.isPending = false;
      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
        customizedToast("عملیات موفق", "success");

        state.editingServerStatus.isDone = true;
        return;
      }
    });
    builder.addCase(editAsyncServerData.rejected, (state, action) => {
      state.editingServerStatus.isDone = false;
      state.editingServerStatus.isPending = false;
    });
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncNewTokenForDataServer.pending, (state) => {});
    builder.addCase(
      getAsyncNewTokenForDataServer.fulfilled,
      (state, action) => {
        if (action.payload?.result.passcode) {
          copyToClipboard(action.payload.result.passcode);
        }
      }
    );
    builder.addCase(getAsyncNewTokenForDataServer.rejected, (state) => {});
  },
});
