// React
import React, { useContext, useEffect, useRef, useState } from "react";
// React

// CSS
import styles from "./EditProcessByProcessKey.module.css";
// CSS

// BPMN
import BpmnModeler from "bpmn-js/lib/Modeler";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
// BPMN

// Utils
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
// Utils

// Modules
import { useNavigate, useParams } from "react-router-dom";
// Modules

// Axios
import axios from "axios";
// Axios

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  editAsyncSingleProcess,
  processesSlice,
} from "../../../Features/ProcessesSlice/ProcessesSlice";
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
// Redux

// Urls
import { baseUrl } from "../../../Services/urls";
// Urls

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

const EditProcessByProcessKey = () => {
  const navigate = useNavigate();

  const { isEditDone } = useAppSelector((state) => state.processes);

  const containerRef = useRef<any>(null);
  const modelerRef = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [isPending, setIsPending] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const { processKey, tenantId, processFileName } = useParams();

  const [bpmnXML_Text, setBpmnXML_Text] = useState<string>(``);

  const dispatch = useAppDispatch();

  const isNightModeOn = useContext(IsNightModeOnContext);

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  /** */
  /** */
  /** */
  /** */
  /** */
  /** */
  /** */
  /** */

  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("ویرایش فرآیند"));
  }, [dispatch]);

  useEffect(() => {
    if (isPending) return;
    modelerRef.current = new BpmnModeler({
      container: containerRef.current,
    });

    // Load a BPMN diagram into the modeler (replace with your BPMN XML or URL)
    modelerRef.current.importXML("<Your BPMN XML or URL>", (err: any) => {
      if (err) {
        console.error("Error importing BPMN:", err);
      }
    });
  }, [isPending]);

  useEffect(() => {
    if (!isEditDone) return;
    navigate(`/processes`);
  }, [isEditDone, navigate]);

  useEffect(() => {
    return () => {
      dispatch(processesSlice.actions.resetProcessEditState());
    };
  }, [dispatch]);
  /** */
  /** */
  /** */
  /** */
  /** */
  /** */
  /** */
  /** */
  useEffect(() => {
    if (isPending) return;

    async function getXml() {
      try {
        const result = await modelerRef.current.importXML(bpmnXML_Text);
        const { warnings } = result;
        console.log(warnings);
        customizedToast("فایل با موفقیت آماده شد", "success");
      } catch (err: any) {
        console.log(err.message, err.warnings);
        customizedToast("فایل ارسالی به صورت درست ساخته نشده", "error");
        console.error("Error importing BPMN:", err);
      }
    }

    getXml();
    //
  }, [bpmnXML_Text, isPending]);
  /** */
  /** */
  /** */
  /** */
  /** */
  /** */
  /** */
  /** */
  //   Handling Events
  useEffect(() => {
    if (isPending) return;

    const handleKeyDown = (event: any) => {
      // Handle undo (Ctrl + Z)
      if (event.ctrlKey && event.key === "z") {
        event.preventDefault();
        modelerRef.current.get("commandStack").undo();
      }
      // Handle undo (Ctrl + Z)

      // Handle redo (Ctrl + Y)
      else if (event.ctrlKey && event.key === "y") {
        event.preventDefault();
        modelerRef.current.get("commandStack").redo();
      }
      // Handle redo (Ctrl + Y)

      // Handle delete
      else if (event.key === "Delete" || event.key === "Backspace") {
        event.preventDefault();
        const selection = modelerRef.current.get("selection").get();
        if (selection.length > 0) {
          modelerRef.current.get("modeling").removeElements(selection);
        }
      }
      // Handle delete
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isPending]);
  //   Handling Events
  /** */
  /** */
  /** */
  /** */
  /** */
  /** */
  /** */
  /** */
  //   fetch Data
  useEffect(() => {
    axios
      .get(
        `${baseUrl}/processes/${processKey}/schema${
          tenantId ? `?tenant=${tenantId}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then(
        ({
          data: {
            result: { bpmnXml },
          },
        }) => {
          setBpmnXML_Text(bpmnXml);
          return bpmnXml;
        }
      )
      .then(() => setIsError(false))
      .then(() => setIsPending(false))
      .catch(() => setIsError(true))
      .catch(() => setIsPending(true))
      .catch((err) => {
        console.log(err);
      })
      .catch(() => {
        navigate(-1);
      });
  }, [processKey, tenantId, userToken, navigate]);
  //   fetch Data
  /** */
  /** */
  /** */
  /** */
  /** */
  /** */
  /** */
  /** */
  const handleSave = async () => {
    try {
      const result = await modelerRef.current.saveXML({
        format: true,
      });
      const { xml } = result;
      setBpmnXML_Text(xml);
      return xml;
    } catch (err: any) {
      console.log(err);
      if (err?.response.data.messages) {
        for (
          let i: number = 0;
          i <= err?.response.data.messages.length - 1;
          i++
        ) {
          customizedToast(err?.response.data.messages[i].message, "error");
        }
        throw err;
      }
      throw err;
    }
  };

  return (
    <div
      className={`${styles.editProcessContainer} ${
        isNightModeOn ? "nightBg1" : ""
      } w-100 d-flex flex-column align-items-center `}
    >
      <div className="d-flex flex-row w-100 justify-content-between align-items-center">
        <button
          className={`operationEvent   submitBtn `}
          onClick={() => {
            handleSave().then((data) => {
              dispatch(
                editAsyncSingleProcess({
                  userToken,
                  processKey: String(processKey),
                  _data: {
                    bpmnXmlData: data,
                    fileName: String(processFileName),
                    tenantId: tenantId === undefined ? "" : tenantId,
                  },
                })
              );
            });
          }}
        >
          ذخیره تغییرات
        </button>
        <button
          className={`btn btn-secondary operationEvent  `}
          onClick={() => {
            if (!inputRef.current) return;
            inputRef.current.click();
          }}
        >
          آپلود فایل جدید
        </button>
        <input
          type="file"
          style={{
            display: "none",
          }}
          onChange={(e) => {
            const file = e.target.files![0];
            if (file) {
              const reader = new FileReader();
              reader.onload = (event: any) => {
                const content = event.target.result;
                setIsPending(true);
                setBpmnXML_Text(content);
                setIsPending(false);
              };
              reader.readAsText(file, "UTF-8");
            }
          }}
          ref={inputRef}
          accept=".json,application/json,.bpmn,.xml,application/xml"
        />
      </div>
      {isPending === true && isError === false ? <>در حال بارگذاری</> : null}

      {isPending === false && isError === false ? (
        <>
          <div
            ref={containerRef}
            className="bpmn-container w-100"
            style={{
              height: "70vh",
            }}
          ></div>
        </>
      ) : null}

      {isPending === false && isError === true ? (
        <button
          className="btn btn-secondary w-100 flex align-items-center justify-content-center"
          onClick={() => {
            window.location.reload();
          }}
        >
          دوباره تلاش کن
        </button>
      ) : null}
    </div>
  );
};

export default EditProcessByProcessKey;
