// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./PersonalTab.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  editAsyncUserInformation,
  singleAccessibleUserSlice,
} from "../../../../Features/SingleAccessibleUserSlice/singleAccessibleUserSlice";
// Redux

// Moduels
import { permissionChecker } from "../../../../Modules/permissionChecker";
import { useNavigate } from "react-router-dom";
// Moduels

// Utils
import { Spinner } from "reactstrap";
import CustomInput2 from "../../../../Utils/CustomInput2/CustomInput2";
import { customizedToast } from "../../../../Utils/CustomizedToast/customizedToast";
// Utils

// Contexts
import { UserGroupsContext } from "../../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsNightModeOnContext } from "../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Urls
import { mainUrl } from "../../../../Services/urls";
import {
  getAsyncAllIdentityAttributeWithOptions,
  userIdentitiesSlice,
} from "../../../../Features/userIdentitiesSlice/userIdentitiesSlice";
// Urls

type PersonalTabProps = {
  setExternalUserInformation: React.Dispatch<
    React.SetStateAction<{
      password?: string;
      externalIdentitiyInformation: {
        [key: string]: string | number | any;
      };
    }>
  >;
  externalUserInformation: {
    password?: string;
    externalIdentitiyInformation: {
      [key: string]: string | number | any;
    };
  };
};

type CustomFormData = {
  [key: string]: {
    type: string;
    value: any;
    persianName: string;
    otherOptions: { id: string | number; title: string }[];
    objectName: string;
    isTouched: boolean;
    helperData: Blob;
    ref: React.RefObject<any>;
    isRequired: boolean;
  };
};

const PersonalTab: React.FunctionComponent<PersonalTabProps> = ({
  setExternalUserInformation,
  externalUserInformation,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const singleUser = useAppSelector((state) => state.singleAccessibleUser);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const isFullRenderd = useContext(IsFullRenderdContext);
  const allGroups = useContext(UserGroupsContext);

  const [retypePassword, setRetypePassword] = useState<string>("");

  const isNightOn = useContext(IsNightModeOnContext);

  const { selectedTenant } = useAppSelector((state) => state.tenants);

  const userIdentities = useAppSelector(
    (state) => state.userIdentities.userIdentitiesWithOptions
  );
  const singleUserExtraIdentityData = useAppSelector(
    (state) => state.singleAccessibleUser.extraIdentityInformation
  );
  const { currLanguage } = useAppSelector((state) => state.currLanguage);

  const [formsData, setFormsData] = useState<{
    firstname: string;
    lastname: string;
    username: string;
    email: string;
  }>({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
  });

  const [additionalFormData, setAdditionalFormData] = useState<CustomFormData>(
    {}
  );

  useEffect(() => {
    if (!singleUser.isUserChangeDataDone) return;

    navigate("/users");
  }, [singleUser.isUserChangeDataDone, navigate]);

  useEffect(() => {
    setFormsData({
      email: singleUser.email,
      firstname: singleUser.firstname,
      lastname: singleUser.lastname,
      username: singleUser.username,
    });
  }, [singleUser]);

  useEffect(() => {
    dispatch(
      getAsyncAllIdentityAttributeWithOptions({
        userToken,
        tenantId: selectedTenant.tenantId,
      })
    );
  }, [dispatch, userToken, selectedTenant]);

  useEffect(() => {
    const identityKeys = userIdentities.data.map((item) => item.key);
    const resultObject: CustomFormData = {};

    for (let i = 0; i <= identityKeys.length - 1; i++) {
      const selcetedItem = userIdentities.data.find(
        (item) => item.key === identityKeys[i]
      );
      console.log(singleUserExtraIdentityData[identityKeys[i]]);
      resultObject[String(identityKeys[i])] = {
        type: String(selcetedItem?.type?.value),
        persianName: String(selcetedItem?.title),
        value: singleUserExtraIdentityData[identityKeys[i]]?.value || "",
        objectName: identityKeys[i],
        otherOptions: selcetedItem?.identityOptions
          ? selcetedItem?.identityOptions?.map((item) => ({
              id: String(item?.id),
              title: String(item?.title),
            }))
          : [],
        helperData: new Blob(),
        isTouched: false,
        ref: React.createRef(),
        isRequired: Boolean(selcetedItem?.required),
      };
    }

    setAdditionalFormData(resultObject);
  }, [userIdentities, singleUserExtraIdentityData]);

  useEffect(() => {
    return () => {
      dispatch(singleAccessibleUserSlice.actions.resetEditingUserData());
      dispatch(userIdentitiesSlice.actions.resetUserIdentitiesWithOptions());
    };
  }, [dispatch]);

  return (
    <div
      className={`${
        styles.personalTab
      } w-100 d-flex flex-column align-items-start 
      ${isNightOn ? "nightBg1" : ""}
      `}
    >
      <p
        style={{
          width: "100%",
          textAlign: "right",
          color: "#111D64",
          fontWeight: "bold",
          fontSize: "20px",
        }}
      >
        ویرایش کاربر
      </p>
      <p
        style={{
          width: "100%",
          textAlign: "right",
          color: "#8392AB",
        }}
      >
        مشخصات کاربر
      </p>
      <div
        className={`${styles.masterInputContianer} w-100 d-flex flex-row-reverse align-items-center  flex-wrap`}
      >
        <CustomInput2
          defaultValue={formsData.firstname}
          onChange={(e) => {
            setFormsData((prevState) => ({
              ...prevState,
              firstname: e.target.value,
            }));
          }}
          plaeHolder="برای مثال: علی"
          title="نام"
          type="text"
          containerClassName="mt-2"
        />
        <CustomInput2
          defaultValue={formsData.lastname}
          onChange={(e) => {
            setFormsData((prevState) => ({
              ...prevState,
              lastname: e.target.value,
            }));
          }}
          plaeHolder="برای مثال: هاشمی"
          title="نام خانوادگی"
          type="text"
          containerClassName="mt-2"
        />
        <CustomInput2
          defaultValue={formsData.email}
          onChange={(e) => {
            setFormsData((prevState) => ({
              ...prevState,
              email: e.target.value,
            }));
          }}
          plaeHolder="برای مثال: abcd.1234@gmail.com"
          title="ایمیل"
          type="email"
          containerClassName="mt-2"
        />
        <CustomInput2
          defaultValue={formsData.username}
          onChange={(e) => {
            setFormsData((prevState) => ({
              ...prevState,
              username: e.target.value,
            }));
          }}
          plaeHolder="برای مثال: Ali.123"
          title="نام کاربری"
          type="text"
          containerClassName="mt-2"
        />
        <CustomInput2
          defaultValue=""
          onChange={(e) => {
            setExternalUserInformation((prevState) => ({
              ...prevState,
              password: e.target.value,
            }));
          }}
          plaeHolder="8 کاراکتر شامل عدد و حروف انگلیسی"
          title="رمز عبور"
          type="password"
          containerClassName="mt-2"
        />
        <CustomInput2
          defaultValue=""
          onChange={(e) => {
            setRetypePassword(e.target.value);
          }}
          plaeHolder="تکرار رمز عبور"
          title="تکرار رمز عبور"
          type="password"
          containerClassName="mt-2"
        />

        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*** Additional Data ***/}
        {(() => {
          const keys = Object.keys(additionalFormData);
          return keys.map((item) => {
            const selectedItem = additionalFormData[item];
            if (selectedItem.type === "IMAGE") {
              return (
                <React.Fragment key={selectedItem.objectName}>
                  <CustomInput2
                    onChange={(e) => {
                      if (!e.target.files) return;
                      const fileReader = new FileReader();

                      setAdditionalFormData((prevState) => ({
                        ...prevState,
                        [item]: {
                          ...prevState[item],
                          helperData: e.target.files
                            ? e.target.files[0]
                            : prevState[item].helperData,
                          isTouched: true,
                        },
                      }));
                      fileReader.onload = () => {
                        setAdditionalFormData((prevState) => ({
                          ...prevState,
                          [item]: {
                            ...prevState[item],
                            value: fileReader.result,
                            isTouched: true,
                          },
                        }));
                      };
                      fileReader.readAsDataURL(e.target?.files[0]);
                    }}
                    plaeHolder={selectedItem.persianName}
                    title={selectedItem.persianName}
                    type="file"
                    containerClassName="mt-2 "
                    imageRenderingOptions={{
                      src: selectedItem.isTouched
                        ? URL.createObjectURL(selectedItem.helperData)
                        : `${mainUrl}${selectedItem.value}`,
                      buttonForChangeOnClick: () => {
                        if (!selectedItem.ref.current) return;
                        selectedItem.ref.current.click();
                      },
                      buttonInnerText: ` تعویض عکس برای - ${selectedItem.persianName} `,
                    }}
                    fileInputReference={selectedItem.ref}
                    containerStyle={{
                      direction: currLanguage !== "fa" ? "rtl" : "ltr",
                    }}
                    required={selectedItem.isRequired}
                    accept="png, .jpg, .jpeg"
                  />
                </React.Fragment>
              );
            } else if (
              selectedItem.type === "TEXT" ||
              selectedItem.type === "DOUBLE" ||
              selectedItem.type === "INTEGER"
            ) {
              return (
                <React.Fragment key={selectedItem.objectName}>
                  <CustomInput2
                    onChange={(e) => {
                      setAdditionalFormData((prevState) => ({
                        ...prevState,
                        [item]: {
                          ...prevState[item],
                          isTouched: true,
                          value: e.target.value,
                        },
                      }));
                    }}
                    defaultValue={selectedItem.value}
                    plaeHolder={selectedItem.persianName}
                    title={selectedItem.persianName}
                    type="text"
                    containerClassName="mt-2"
                    required={selectedItem.isRequired}
                  />
                </React.Fragment>
              );
            }
            return (
              <React.Fragment key={selectedItem.objectName}></React.Fragment>
            );
          });
        })()}

        {/*** Additional Data ***/}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
      </div>

      <div
        className={`${styles.operationButtons} w-100 d-flex flex-row align-items-center justify-content-start mt-5`}
      >
        {permissionChecker(
          allGroups,
          [
            "Full_Manager",
            "Tenant_Full_Manager",
            "Tenant_Basic_Manager",
            "User_Editor",
          ],
          isFullRenderd
        ) && (
          <>
            <button
              className={`${styles.nextStepBtn}`}
              onClick={() => {
                if (singleUser.isUserDataChangePending) return;
                if (
                  !permissionChecker(
                    allGroups,
                    [
                      "Full_Manager",
                      "Tenant_Full_Manager",
                      "Tenant_Basic_Manager",
                      "User_Editor",
                    ],
                    isFullRenderd
                  )
                )
                  return;
                if (externalUserInformation.password !== retypePassword) {
                  customizedToast("رمز عبور و تکرار آن یکسان نیست", "error");
                  return;
                }
                dispatch(
                  editAsyncUserInformation({
                    userToken,
                    newData: {
                      ...formsData,
                      password: externalUserInformation.password
                        ? externalUserInformation.password
                        : undefined,
                      extraIdentityInformation: (() => {
                        const keys = Object.keys(additionalFormData);
                        let result: { [key: string]: any } = {};
                        for (let i = 0; i < keys.length; i++) {
                          if (additionalFormData[keys[i]].isTouched) {
                            result[additionalFormData[keys[i]].objectName] =
                              additionalFormData[keys[i]].value;
                          }
                        }
                        return result;
                      })(),
                    },
                    userName: singleUser.username,
                  })
                );
              }}
            >
              {singleUser.isUserDataChangePending ? (
                <>
                  <Spinner
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                    color="transparent"
                  />
                </>
              ) : (
                <>تایید</>
              )}
            </button>
            {/* <button
              className="operationEvent editBtn"
              onClick={() => {
                navigate(
                  `/users/single-user/add-identity-to-user/${singleUser.username}`,
                  {
                    state: {
                      attributes: singleUserExtraIdentityData,
                    },
                  }
                );
              }}
            >
              افزودن فیلد هویتی
            </button> */}
          </>
        )}
      </div>
    </div>
  );
};

export default PersonalTab;
