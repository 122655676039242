// React
import React, { useContext, useEffect, useMemo, useState } from "react";
// React

// CSS
import styles from "./ShowTaskIdFromProcesses.module.css";
// CSS

// Modules
import { useNavigate, useParams } from "react-router-dom";
// Modules

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncTaskDataFromProcesses,
  processesSlice,
} from "../../../Features/ProcessesSlice/ProcessesSlice";
// Redux


// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
// Contexts

// Components
import ShowAndSubmitFormByTaskId from "../ShowAndSubmitFormByTaskId/ShowAndSubmitFormByTaskId";
// Components

// Icons
import { MdOutlineDescription, MdOutlineLowPriority } from "react-icons/md";
import { BiTimer } from "react-icons/bi";
import { FaAngleDown, FaWpforms } from "react-icons/fa6";
import { TbSchema } from "react-icons/tb";
import { GiProcessor } from "react-icons/gi";
import { VscTypeHierarchySuper } from "react-icons/vsc";
// Icons

// Accordion Component
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Pending from "../../../Components/Pending/Pending";
// Accordion Component

const ShowTaskIdFromProcesses = () => {
  // constants
  const { taskId, processKey } = useParams();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const taskDetailByProcesses = useAppSelector(
    (state) => state.processes.taskDetailByProcesses
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isAcardionOpen, setIsAcardionOpen] = useState<boolean>(false);

  const isNightOn = useContext(IsNightModeOnContext);
  const allGroups = useContext(UserGroupsContext);
  // constants

  //   LiftCycles
  //
  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncTaskDataFromProcesses({
          taskId: String(taskId),
          userToken,
          onError: () => {},
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, taskId, userToken]);
  //
  useEffect(() => {
    return () => {
      dispatch(processesSlice.actions.resetTaskData());
    };
  }, [dispatch]);
  //
  useEffect(() => {
    if (taskDetailByProcesses.isError) {
      navigate(`/processes/show-tasks-by-processKey/${processKey}`);
      return;
    }
  }, [taskDetailByProcesses.isError, navigate, processKey]);
  //
  //   LiftCycles

  const __form__ = useMemo(
    () => (
      <ShowAndSubmitFormByTaskId
        taskId={String(taskId)}
        state={{
          formUrl: taskDetailByProcesses.form.url,
        }}
        isPending={taskDetailByProcesses.isTaskDetailsPending}
        processKey={String(processKey)}
      />
    ),
    [taskId, taskDetailByProcesses, processKey]
  );

  return (
    <>
      <div
        className={`${styles.taskDetailsFromProcessContainer} w-100 ${
          isNightOn ? "nightBg1" : ""
        }`}
      >
        {taskDetailByProcesses.isTaskDetailsPending ? (
          <Pending className="mt-4" />
        ) : (
          <div
            className="w-100 d-flex flex-row align-items-center justify-content-between flex-wrap mb-2"
            style={{
              gap: "8px",
            }}
          >
            <div
              className={`${styles.card} ${styles.cardFull} ${
                isNightOn ? styles.dark : ""
              } ${styles.bigOnHover} `}
            >
              <div className={`${styles.rightItems}`}>
                <p>نام فرم </p>
                <span
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={
                    taskDetailByProcesses.form.title
                      ? taskDetailByProcesses.form.title.length >= 23
                        ? taskDetailByProcesses.form.title
                        : ""
                      : "تنظیم نشده"
                  }
                >
                  {taskDetailByProcesses.form.title
                    ? taskDetailByProcesses.form.title.length >= 26
                      ? taskDetailByProcesses.form.title.substring(0) + "..."
                      : taskDetailByProcesses.form.title
                    : "تنظیم نشده"}
                </span>
              </div>
              <FaWpforms
                style={{
                  fontSize: "1.4rem",
                }}
              />
            </div>

            <Accordion
              allowZeroExpanded
              className={`${styles.accordionContainer} ${styles.cardFull} w-100  `}
            >
              <AccordionItem>
                <AccordionItemHeading
                  className={`accordionHeaderCustomClass `}
                  onClick={() => setIsAcardionOpen((prevState) => !prevState)}
                >
                  <AccordionItemButton
                    className={`${styles.accButton} ${
                      isNightOn ? "nightBg2" : ""
                    } d-flex align-items-center justify-content-center py-3 `}
                  >
                    <FaAngleDown
                      style={{
                        transform: isAcardionOpen ? "rotate(180deg)" : "",
                      }}
                    />
                    اطلاعات مربوط به وظیفه
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className="  w-100  mt-2">
                  <div
                    className="d-flex flex-row  w-100  justify-content-between flex-wrap"
                    style={{
                      gap: "8px",
                    }}
                  >
                    <div
                      className={`${styles.card} ${
                        isNightOn ? styles.dark : ""
                      } ${styles.bigOnHover} `}
                    >
                      <div className={`${styles.rightItems}`}>
                        <p>توضیحات </p>
                        <span
                          data-tooltip-content={
                            taskDetailByProcesses.description
                              ? taskDetailByProcesses.description.length >= 23
                                ? taskDetailByProcesses.description
                                : ""
                              : "تنظیم نشده"
                          }
                        >
                          {taskDetailByProcesses.description
                            ? taskDetailByProcesses.description.length >= 26
                              ? taskDetailByProcesses.description.substring(0) +
                                "..."
                              : taskDetailByProcesses.description
                            : "تنظیم نشده"}
                        </span>
                      </div>
                      <MdOutlineDescription
                        style={{
                          fontSize: "1.4rem",
                        }}
                      />
                    </div>

                    <div
                      className={`${styles.card} ${
                        isNightOn ? styles.dark : ""
                      } ${styles.bigOnHover} `}
                    >
                      <div className={`${styles.rightItems}`}>
                        <p> زمان انجام</p>
                        <span>
                          {taskDetailByProcesses?.dueDate?.jalaliDate ||
                            "تنظیم نشده"}
                        </span>
                      </div>
                      <BiTimer
                        style={{
                          fontSize: "1.4rem",
                        }}
                      />
                    </div>

                    <div
                      className={`${styles.card} ${
                        isNightOn ? styles.dark : ""
                      } ${styles.bigOnHover} `}
                    >
                      <div className={`${styles.rightItems}`}>
                        <p>اولویت </p>
                        <span
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            taskDetailByProcesses.priorityType.title
                              ? taskDetailByProcesses.priorityType.title
                                  .length >= 26
                                ? taskDetailByProcesses.priorityType.title
                                : ""
                              : "تنظیم نشده"
                          }
                        >
                          {taskDetailByProcesses.priorityType.title
                            ? taskDetailByProcesses.priorityType.title.length >=
                              26
                              ? taskDetailByProcesses.priorityType.title.substring(
                                  0
                                ) + "..."
                              : taskDetailByProcesses.priorityType.title
                            : "تنظیم نشده"}
                        </span>
                      </div>
                      <MdOutlineLowPriority
                        style={{
                          fontSize: "1.4rem",
                        }}
                      />
                    </div>

                    {allGroups.includes("Full_Manager") && (
                      <>
                        <div
                          className={`${styles.card} ${
                            isNightOn ? styles.dark : ""
                          } ${styles.bigOnHover} `}
                        >
                          <div
                            className={`${styles.rightItems}`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              taskDetailByProcesses.schemaId
                                ? taskDetailByProcesses.schemaId.length >= 23
                                  ? taskDetailByProcesses.schemaId
                                  : ""
                                : "تنظیم نشده"
                            }
                          >
                            <p>کد ساختار </p>
                            <span>
                              {taskDetailByProcesses.schemaId
                                ? taskDetailByProcesses.schemaId.length >= 23
                                  ? taskDetailByProcesses.schemaId.substring(
                                      0,
                                      25
                                    ) + "..."
                                  : taskDetailByProcesses.schemaId
                                : "تنظیم نشده"}
                            </span>
                          </div>
                          <TbSchema
                            style={{
                              fontSize: "1.4rem",
                            }}
                          />
                        </div>
                        <div
                          className={`${styles.card} ${
                            isNightOn ? styles.dark : ""
                          } ${styles.bigOnHover} `}
                        >
                          <div className={`${styles.rightItems}`}>
                            <p>شمارنده فرآیند </p>
                            <span
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={
                                taskDetailByProcesses.processId
                                  ? taskDetailByProcesses.processId.length >= 26
                                    ? taskDetailByProcesses.processId
                                    : ""
                                  : "تنظیم نشده"
                              }
                            >
                              {taskDetailByProcesses.processId
                                ? taskDetailByProcesses.processId.length >= 23
                                  ? taskDetailByProcesses.processId.substring(
                                      0,
                                      25
                                    ) + "..."
                                  : taskDetailByProcesses.processId
                                : "تنظیم نشده"}
                            </span>
                          </div>
                          <GiProcessor
                            style={{
                              fontSize: "1.4rem",
                            }}
                          />
                        </div>
                        <div
                          className={`${styles.card} ${
                            isNightOn ? styles.dark : ""
                          } ${styles.bigOnHover} `}
                        >
                          <div className={`${styles.rightItems}`}>
                            <p>شمارنده نمونه نوع </p>
                            <span
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={
                                taskDetailByProcesses.processInstanceId
                                  ? taskDetailByProcesses.processInstanceId
                                      .length >= 26
                                    ? taskDetailByProcesses.processInstanceId
                                    : ""
                                  : "تنظیم نشده"
                              }
                            >
                              {taskDetailByProcesses.processInstanceId
                                ? taskDetailByProcesses.processInstanceId
                                    .length >= 26
                                  ? taskDetailByProcesses.processInstanceId.substring(
                                      0,
                                      25
                                    ) + "..."
                                  : taskDetailByProcesses.processInstanceId
                                : "تنظیم نشده"}
                            </span>
                          </div>
                          <VscTypeHierarchySuper
                            style={{
                              fontSize: "1.4rem",
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        )}
        {taskDetailByProcesses.isTaskDetailsPending ? null : <>{__form__}</>}
      </div>
    </>
  );
};

export default ShowTaskIdFromProcesses;
