// React
import React, { useCallback, useEffect, useRef, useState } from "react";
// React

// CSS
import styles from "./ShowSelectedProcessByProcessId.module.css";
// CSS

// Modules
import { useParams } from "react-router-dom";
// Modules

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
// Redux

// Bpmn
import BpmnViewer from "bpmn-js";
// Bpmn

// Axios
import axios from "axios";
// Axios

// Utils
import { Spinner } from "reactstrap";
// Utils

// Icons
import { AiOutlineDownload } from "react-icons/ai";
// Icons

// Redux
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
import {
  getAsyncProcessXML,
  processesSlice,
} from "../../../Features/ProcessesSlice/ProcessesSlice";
// Redux

// Utils
import { downloadAsXml } from "../../../Utils/DownloadUtils/downloadAsXml";
import { downloadAsBpmnFile } from "../../../Utils/DownloadUtils/downloadAsBpmnFile";
// Utils

// urls
import { baseUrl } from "../../../Services/urls";
// urls

// Constants
import { downloadProcessNamePreFix } from "../../../Constants/constants";
// Constants

const ShowSelectedProcessByProcessId = () => {
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const { selectedTenant } = useAppSelector((state) => state.tenants);

  const [isPending, setIsPending] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [bpmnXML, setBpmXML] = useState<string>("");
  const [validSchemas, setValidSchemas] = useState<{ schemaId: string }[]>([]);
  console.log(validSchemas);
  const { processKey, tanentId } = useParams();
  const dispatch = useAppDispatch();
  const [viewerReference, setViewerReference] = useState<any>();
  const [zoomLevel, setZoomLevel] = useState(0.6);
  const [_isPending, _setIsPending] = useState<boolean>(true);

  const canvasRef = useRef<any>(null);

  const handleZoom = useCallback(
    (factor: number) => {
      if (viewerReference) {
        const newZoomLevel = zoomLevel + factor;
        setZoomLevel(newZoomLevel);

        const canvas = viewerReference.get("canvas");
        const oldViewbox = canvas.viewbox();
        const zoomedViewbox = {
          ...oldViewbox,
          width: oldViewbox.width / canvas.zoom(),
          height: oldViewbox.height / canvas.zoom(),
        };

        canvas.zoom(newZoomLevel, zoomedViewbox);
      }
    },
    [zoomLevel, viewerReference]
  );

  const handleZoomIn = () => {
    handleZoom(0.1);
  };

  const handleZoomOut = () => {
    handleZoom(-0.1);
  };

  useEffect(() => {
    if (viewerReference) {
      const canvas = viewerReference.get("canvas");
      canvasRef.current = canvas;

      const container: any = document.getElementById("bpmn-container");

      let isDragging = false;
      let startPos = { x: 0, y: 0 };
      let accumulatedDisplacement = { x: 0, y: 0 };
      let oldViewbox: any;

      const handleMouseDown = (event: any) => {
        isDragging = true;
        startPos = { x: event.clientX, y: event.clientY };
        oldViewbox = canvas.viewbox();
      };

      const handleMouseMove = (event: any) => {
        if (!isDragging) return;

        const dx = event.clientX - startPos.x;
        const dy = event.clientY - startPos.y;

        accumulatedDisplacement.x += dx;
        accumulatedDisplacement.y += dy;

        const newX = oldViewbox.x - accumulatedDisplacement.x;
        const newY = oldViewbox.y - accumulatedDisplacement.y;

        canvas.viewbox({ ...oldViewbox, x: newX, y: newY });

        startPos = { x: event.clientX, y: event.clientY };
      };

      const handleMouseUp = () => {
        isDragging = false;
        accumulatedDisplacement = { x: 0, y: 0 };
      };

      container.addEventListener("mousedown", handleMouseDown);
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);

      container.addEventListener("pointerdown", handleMouseDown);
      window.addEventListener("pointermove", handleMouseMove);
      window.addEventListener("pointerup", handleMouseUp);

      setTimeout(() => {
        const els = document.querySelectorAll("text > tspan");
        console.log(els);
        if (els.length === 0) return;
        els.forEach((item) => {
          const currX = item.getAttribute("x");
          item.setAttribute("x", String(Number(currX) / 2.4));
        });
      }, 100);

      return () => {
        container.removeEventListener("mousedown", handleMouseDown);
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);

        container.removeEventListener("pointerdown", handleMouseDown);
        window.removeEventListener("pointermove", handleMouseMove);
        window.removeEventListener("pointerup", handleMouseUp);
      };
    }
  }, [viewerReference]);

  useEffect(() => {
    dispatch(
      searchAndBackSlice.actions.changeCurrLocation("نمایش فرآیند انتخابی")
    );
  }, [dispatch]);

  useEffect(() => {
    axios
      .get(
        `${baseUrl}/processes/${processKey}/schema${
          tanentId ? `?tenant=${tanentId}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then(
        ({
          data: {
            result: { bpmnXml, validSchemas },
          },
        }) => {
          setBpmXML(bpmnXml);
          setValidSchemas(validSchemas);
        }
      )
      .then(() => setIsError(false))
      .then(() => setIsPending(false))
      .catch(() => setIsError(true))
      .catch(() => setIsPending(true))
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }, [processKey, tanentId, userToken]);

  useEffect(() => {
    if (isError) return;
    if (isPending) return;
    if (bpmnXML === "") return;
    const container = document.getElementById("bpmn-container");
    const viewer = new BpmnViewer({ container });
    const currentProcessState = viewer.getDefinitions();
    console.log(currentProcessState);
    console.log("viewer D", viewer._definitions);
    console.log("viewer", viewer);
    const eventBus = viewer.get("eventBus");
    eventBus.on("element.click", (event: any) => {
      const element = event.element;
      if (element.type === "bpmn:Task") {
        console.log("Task clicked:", element.id);
      }
    });
    setViewerReference(viewer);
    viewer.get("canvas").zoom("fit-viewport", "auto");
    _setIsPending(false);

    // Path to your BPMN file

    // Load and display the BPMN diagram
    // viewer.importXML(bpmnXML, (err: any) => {
    //   if (err) {
    //     console.error("Error rendering BPMN diagram.", err);
    //   }
    // });
    async function loader() {
      try {
        const result = await viewer.importXML(bpmnXML);
        const { warnings } = result;
        console.log(warnings);
      } catch (err: any) {
        console.log(err.message, err.warnings);
      }
    }

    loader();
  }, [isError, isPending, bpmnXML]);

  useEffect(() => {
    if (_isPending) return;
    viewerReference.get("canvas").zoom("fit-viewport");
  }, [viewerReference, _isPending]);

  return (
    <div
      className={`${styles.processesShowerContainer} w-100 d-flex flex-column align-items-center justify-content-between`}
    >
      {isError === true && isPending === false ? (
        <>هنگام پردازش اطلاعات مشکلی پیش آمده</>
      ) : null}
      {isError === false && isPending === false ? (
        <>
          <div className=" d-flex flex-row align-items-center justify-content-between align-self-end">
            <div
              className="operationEvent submitBtn"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="BPMN دانلود فایل "
              onClick={() => {
                dispatch(
                  getAsyncProcessXML({
                    processKey: String(processKey),
                    userToken,
                    tenantId: selectedTenant.tenantId,
                    onDone: (data) => {
                      downloadAsXml(
                        [data],
                        `${downloadProcessNamePreFix}${processKey}`
                      );
                      downloadAsBpmnFile(
                        [data],
                        `${downloadProcessNamePreFix}${processKey}`
                      );
                    },
                  })
                ).then(() => {
                  dispatch(processesSlice.actions.resetXmlData());
                });
              }}
              style={{
                height: "max-content !important",
              }}
            >
              <AiOutlineDownload />
            </div>
            <button
              onClick={handleZoomIn}
              className={` operationEvent  zoomInBtn`}
            >
              <span className="icon-search-zoom-in"></span>
            </button>
            <button
              onClick={handleZoomOut}
              className={` operationEvent zoomOutBtn`}
            >
              <span className="icon-search-zoom-out"></span>
            </button>
          </div>
          <div
            id="bpmn-container"
            style={{ height: "600px", width: "100%", cursor: "grab" }}
          ></div>
        </>
      ) : null}
      {isError === false && isPending === true ? (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
          <span>در حال بارگذاری</span>
          <Spinner />
        </div>
      ) : null}
    </div>
  );
};

export default ShowSelectedProcessByProcessId;
