// React
import React, { useCallback, useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./BPMN_STEPS_VIEWER.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncProcessActivitySteps,
  getAsyncProcessActivityStepsWithoutPendingWithSameDataFlow,
  getAsyncSingleActivity,
  processInstanceHistorySlice,
} from "../../../Features/ProcessesSlice/ProcessInstanceHistorySlice/processInstanceHistorySlice";
// Redux

// Models
import {
  I_ProcessActivitySteps,
  I_SingleActivity,
  processActivityStep,
} from "../../../Models/interfaces";
// Models

// Utils
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
import PendingManager from "../../PendingManager/PendingManager";
// Utils

// Components
import TopDetailItem from "./TopDetailItem/TopDetailItem";
// Components

// Constants
import { withoutInformation } from "../StepsAndHistoryViewer/HistoryTab/HistoryTabItem/TaskInformation/TaskInformation";
// Constants

// Contexts
import {
  ProcessHistoryContext,
  SetProcessHistoryContext,
} from "../../../Contexts/ProcessHistoryContext/ProcessHistoryContext";
import {
  fullResetterProcessHistoryEvent,
  nextAndPrevButtonClickEventInhProcessHistory,
} from "../../../App";
// Contexts

type BPMN_STEPS_VIEWER__PROPS = {
  instanceId: string;
  selectedDiagramIndexId: string;
  getRelatedData: (data: {
    maxStep: string | number;
    steps: processActivityStep;
    type: "next" | "prev";
    currentStep: string | number;
  }) => void;
  setSelectedStep: React.Dispatch<React.SetStateAction<string>>;
};

type calculateAllStepsAndFinishedStepsReturnType = {
  allSteps: number;
  finishedSteps: number;
  steps: {
    before: number[];
    after: number[];
  };
  lastStep: number;
};

export function calculateAllStepsAndFinishedSteps(
  data: I_ProcessActivitySteps
): calculateAllStepsAndFinishedStepsReturnType {
  const res: calculateAllStepsAndFinishedStepsReturnType = {
    allSteps: 1,
    finishedSteps: 0,
    steps: {
      after: [],
      before: [],
    },
    lastStep: 1,
  };

  const before = data.stepsBefore.steps;
  const after = data.stepsAfter.steps;

  res.allSteps = before.length + after.length;

  for (let i = 0; i <= before.length - 1; i++) {
    if (before[i].finished === true) {
      res.finishedSteps += 1;
    }
    res.steps.before.push(before[i].stepNumber);
  }
  for (let i = 0; i <= after.length - 1; i++) {
    if (after[i].finished === true) {
      res.finishedSteps += 1;
    }
    res.steps.after.push(after[i].stepNumber);
  }

  res.lastStep = (() => {
    let arr = after.map((_i) => _i.stepNumber);
    if (after.length === 0) {
      arr = before.map((_i) => _i.stepNumber);
    }
    let max = arr[0];

    for (let i = 1; i < arr.length; i++) {
      if (arr[i] > max) {
        max = arr[i];
      }
    }
    return max;
  })();

  return res;
}

export function findNearestNumberFromTop(
  arr: number[],
  num: number,
  onEnd: (_next: number) => void
): number {
  const _arr = arr.sort();
  let greaterNumbers = _arr.filter((n) => n > num);

  let nearest = Math.min(...greaterNumbers);
  if (greaterNumbers.length === 0) {
    onEnd(num);
    return num;
  }
  return nearest;
}

export function findNearestFromBottom(
  arr: number[],
  num: number,
  onEnd: (_next: number, curr: number) => void
): number {
  let filteredArr = arr.filter((n) => n < num);

  let nearestBelow = Math.max(...filteredArr) || num;

  if (filteredArr.length === 0) {
    onEnd(nearestBelow, num);
    return num;
  }
  return nearestBelow;

  // if (filteredArr.length === 0) {
  //   customizedToast("این اولین مرحله میباشد", "info");
  //   return num;
  // }
}

export function transformActivities(
  data: I_SingleActivity
): processActivityStep {
  const stepsMap = new Map<number, processActivityStep>();

  data.content.forEach((activity) => {
    const {
      stepNumber,
      totalCount,
      finishedCount,
      finished,
      hasDirectDetail,
      idForDetail,
      startDate,
      endDate,
      schemaId,
      title,
      activityType,
    } = activity;

    if (!stepsMap.has(stepNumber)) {
      stepsMap.set(stepNumber, {
        stepNumber,
        totalCount,
        finishedCount,
        finished,
        hasDirectDetail,
        idForDetail,
        activities: [],
        startDate,
        endDate,
      } as any);
    }

    const step = stepsMap.get(stepNumber)!;
    (step as any).activities.push({
      schemaId,
      title,
      activityType,
    });
  });

  return Array.from(stepsMap.values()) as any;
}

export function moreReturner(a: number, b: number) {
  return a >= b ? a : b;
}

export function lessReturner(a: number, b: number) {
  return a <= b ? a : b;
}

const BPMN_STEPS_VIEWER: React.FunctionComponent<BPMN_STEPS_VIEWER__PROPS> = ({
  instanceId,
  getRelatedData,
  selectedDiagramIndexId,
  setSelectedStep,
}) => {
  const dispatch = useAppDispatch();
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const [pageData, setPageData] = useState<{ max: number; min: number }>({
    max: 1,
    min: 1,
  });

  const [stepsPaginationData, setStepsPaginationData] = useState<{
    stepNumber: number;
    size: number;
    type: "next" | "prev";
  }>({
    stepNumber: 1,
    size: 1,
    type: "next",
  });

  const { isDone, isError, isPending, data } = useAppSelector(
    (state) => state.processInstanceHistorySlice.processActivitySteps
  );

  const [isButtonsDisabled, setIsButtonsDisabled] = useState<boolean>(false);

  const { isPending: isActivityPending } = useAppSelector(
    (state) => state.processInstanceHistorySlice.activitySteps
  );

  const instanceData = useAppSelector(
    (state) => state.processes.processInstanceData.processInstanceSchema
  );

  const {
    allSteps: _allSteps,
    clickedElementId,
    isTopItemsDisabled,
  } = useContext(ProcessHistoryContext);
  const setProcessHistoryContext = useContext(SetProcessHistoryContext);

  const [allSteps, setAllSteps] = useState<processActivityStep>([]);

  const fetchStepData = useCallback(
    (stepNumber: number | string) => {
      dispatch(
        getAsyncProcessActivitySteps({
          userToken,
          _data: {
            processInstanceId: instanceId,
            size: stepsPaginationData.size,
            stepNumber: stepNumber.toString(),
            onDone: (__data: I_ProcessActivitySteps) => {
              const newSteps = [
                ...__data.stepsBefore.steps,
                ...__data.stepsAfter.steps,
              ].sort((a, b) => a.stepNumber - b.stepNumber);
              setAllSteps((prevSteps) => {
                const data = [...new Set([...prevSteps, ...newSteps])].sort(
                  (a, b) => a.stepNumber - b.stepNumber
                );

                return data;
              });
              setStepsPaginationData((prevState) => {
                setProcessHistoryContext((_prevState) => {
                  const data = [
                    ...new Set([..._prevState.allSteps, ...newSteps]),
                  ].sort((a, b) => a.stepNumber - b.stepNumber);
                  return {
                    ..._prevState,
                    allSteps: data,
                    stepNumber:
                      __data.stepsBefore?.steps[0]?.stepNumber ||
                      (stepNumber as number),
                    _stepNumber:
                      __data.stepsBefore?.steps[0]?.stepNumber ||
                      (stepNumber as number),
                  };
                });
                return {
                  ...prevState,
                  stepNumber:
                    __data.stepsBefore?.steps[0]?.stepNumber ||
                    (stepNumber as number),
                };
              });

              setSelectedStep(
                __data.stepsBefore.steps[0]?.activities[0]?.schemaId
              );
              setStepsPaginationData((prevState) => ({
                ...prevState,
                stepNumber:
                  __data.stepsBefore?.steps[0]?.stepNumber ||
                  (stepNumber as number),
              }));

              setPageData((prevState) => ({
                ...prevState,
                max: __data.stepsBefore.steps[0]?.stepNumber,
              }));
            },
            onBeforeRequest: () => {
              dispatch(
                processInstanceHistorySlice.actions.resetProcessActivitySteps()
              );
            },
          },
        })
      );
    },
    [
      dispatch,
      userToken,
      instanceId,
      setSelectedStep,
      stepsPaginationData.size,
      setProcessHistoryContext,
    ]
  );

  const fetchStepDataWithoutPending = useCallback(
    (stepNumber: number | string, showAlert?: boolean) => {
      dispatch(
        getAsyncProcessActivityStepsWithoutPendingWithSameDataFlow({
          userToken,
          _data: {
            processInstanceId: instanceId,
            size: stepsPaginationData.size,
            stepNumber: stepNumber.toString(),
            onDone: (__data: I_ProcessActivitySteps) => {
              const newSteps = [
                ...__data.stepsBefore.steps,
                ...__data.stepsAfter.steps,
              ].sort((a, b) => a.stepNumber - b.stepNumber);
              setAllSteps((prevSteps) => {
                const data = [...new Set([...prevSteps, ...newSteps])].sort(
                  (a, b) => a.stepNumber - b.stepNumber
                );
                setProcessHistoryContext((_prevState) => ({
                  ..._prevState,
                  _stepNumber:
                    __data.stepsBefore?.steps[0]?.stepNumber ||
                    _prevState._stepNumber,
                  allSteps: data,
                }));

                return data;
              });
              setSelectedStep(
                __data.stepsBefore.steps[0]?.activities[0]?.schemaId
              );
              setStepsPaginationData((prevState) => ({
                ...prevState,
                stepNumber:
                  __data.stepsBefore?.steps[0]?.stepNumber ||
                  (stepNumber as number),
              }));
            },
            onBeforeRequest: () => {
              if (!showAlert) return;
              customizedToast("در حال دریافت اطلاعات", "info");
            },
          },
        })
      );
    },
    [
      dispatch,
      userToken,
      instanceId,
      setSelectedStep,
      stepsPaginationData.size,
      setProcessHistoryContext,
    ]
  );

  const getRelatedStepByDiagram = useCallback(
    (clickedElementId: string) => {
      const validItems = instanceData.validSchemas.map((_i) => _i.schemaId);
      if (validItems.includes(clickedElementId)) {
        dispatch(
          getAsyncSingleActivity({
            userToken,
            _data: {
              activitySchemaId: clickedElementId,
              page: 1,
              size: 5,
              processInstanceId: instanceId,
              onDone: (_data: any) => {
                setTimeout(() => {
                  const receivedData: I_SingleActivity = _data;
                  const newSteps = [
                    ...(() => {
                      let newData: processActivityStep = [];
                      receivedData.content.forEach((item) => {
                        newData.push({
                          ...item,
                          activities: [
                            {
                              activityType: item.activityType,
                              schemaId: item.schemaId,
                              title: item.title,
                            },
                          ],
                        });
                      });
                      return newData;
                    })(),
                  ].sort((a, b) => a.stepNumber - b.stepNumber);
                  const data = [...new Set([...newSteps])].sort(
                    (a, b) => a.stepNumber - b.stepNumber
                  );
                  setProcessHistoryContext((_prevState) => ({
                    ..._prevState,
                    allSteps: data,
                  }));
                  setAllSteps((prevSteps) => {
                    return data;
                  });
                  let selectedIndex = receivedData?.content.findIndex(
                    (_jx) => _jx.schemaId === clickedElementId
                  );
                  if (selectedIndex === -1) {
                    selectedIndex = 0;
                  }
                  setSelectedStep(
                    receivedData?.content[selectedIndex]?.schemaId
                  );
                  setStepsPaginationData((prevState) => ({
                    ...prevState,
                    stepNumber: receivedData.content[selectedIndex].stepNumber,
                  }));
                  setProcessHistoryContext((_prevState) => ({
                    ..._prevState,
                    allSteps: [
                      ..._prevState.allSteps,
                      {
                        ...receivedData.content[selectedIndex],
                        activities: [
                          { ...receivedData.content[selectedIndex] },
                        ],
                      },
                    ],
                  }));
                  setSelectedStep(
                    receivedData?.content[selectedIndex]?.schemaId
                  );
                  setStepsPaginationData((prevState) => ({
                    ...prevState,
                    stepNumber:
                      receivedData?.content[selectedIndex]?.stepNumber,
                  }));
                }, 1);
              },
              onBeforeRequest: () => {},
            },
          })
        );
      }
    },
    [
      instanceData,
      dispatch,
      userToken,
      instanceId,
      setSelectedStep,
      setProcessHistoryContext,
    ]
  );

  const dataGetter = useCallback(() => {
    fetchStepData("");
  }, [fetchStepData]);

  const goPrevStep = useCallback(() => {
    dispatchEvent(nextAndPrevButtonClickEventInhProcessHistory);
    setProcessHistoryContext((prevState) => ({
      ...prevState,
      selectedHistoryType: "",
      allHistory: [],
      selectedHistoryId: "",
    }));
    setStepsPaginationData((prevState) => {
      const prevStepNumber = findNearestFromBottom(
        calculateAllStepsAndFinishedSteps({
          stepsAfter: {
            hasNextPage: false,
            steps: [],
            totalElements: 1,
          },
          stepsBefore: {
            hasNextPage: false,
            steps: allSteps,
            totalElements: 1,
          },
        }).steps.before,
        prevState.stepNumber,
        (prevStepNumber, curr) => {
          setSelectedStep(
            allSteps.find((_i) => _i.stepNumber === prevStepNumber)
              ?.activities[0].schemaId || ""
          );

          if (
            data.stepsBefore.hasNextPage &&
            pageData.min !== prevState.stepNumber
          ) {
            fetchStepDataWithoutPending(prevState.stepNumber);
          } else {
            if (pageData.min <= prevState.stepNumber) {
              customizedToast("این اولین مرحله میباشد", "info");
              setPageData((_prevState) => ({
                ..._prevState,
                min: Math.min(...allSteps.map((_x) => _x.stepNumber)),
              }));
              fetchStepDataWithoutPending(prevState.stepNumber);
            } else {
              fetchStepDataWithoutPending(prevState.stepNumber + 1);
              setPageData((_prevState) => ({
                ..._prevState,
                min: Math.min(...allSteps.map((_x) => _x.stepNumber)),
              }));
            }

            setSelectedStep(
              allSteps.find((_i) => _i.stepNumber === prevStepNumber)
                ?.activities[0].schemaId || ""
            );
          }
        }
      );
      setProcessHistoryContext((prevState) => ({
        ...prevState,
        _stepNumber: prevStepNumber,
      }));
      setSelectedStep(
        allSteps.find((_i) => _i.stepNumber === prevStepNumber)?.activities[0]
          .schemaId || ""
      );

      // Fetch new data if required
      if (!allSteps.some((step) => step.stepNumber === prevStepNumber)) {
        fetchStepDataWithoutPending(prevStepNumber);
      }

      return {
        ...prevState,
        type: "prev",
        stepNumber: prevStepNumber,
      };
    });
  }, [
    allSteps,
    setSelectedStep,
    fetchStepDataWithoutPending,
    data.stepsBefore.hasNextPage,
    pageData,
    setProcessHistoryContext,
  ]);

  const goNextStep = useCallback(() => {
    dispatchEvent(nextAndPrevButtonClickEventInhProcessHistory);
    setProcessHistoryContext((prevState) => ({
      ...prevState,
      selectedHistoryType: "",
      allHistory: [],
      selectedHistoryId: "",
    }));
    setStepsPaginationData((prevState) => {
      const nextStepNumber = findNearestNumberFromTop(
        calculateAllStepsAndFinishedSteps({
          stepsAfter: {
            hasNextPage: false,
            steps: allSteps,
            totalElements: 1,
          },
          stepsBefore: {
            hasNextPage: false,
            steps: [],
            totalElements: 1,
          },
        }).steps.after,
        prevState.stepNumber,
        (_nextStepNumber) => {
          setSelectedStep(
            allSteps.find((_i) => _i.stepNumber === _nextStepNumber)
              ?.activities[0].schemaId || ""
          );
          setSelectedStep(
            allSteps.find((_i) => _i.stepNumber === _nextStepNumber)
              ?.activities[0].schemaId || ""
          );
          if (
            data.stepsAfter.hasNextPage &&
            pageData.max !== prevState.stepNumber
          ) {
            fetchStepDataWithoutPending(_nextStepNumber);
          } else {
            if (pageData.max >= prevState.stepNumber) {
              customizedToast("این آخرین مرحله میباشد", "info");
              setPageData((_prevState) => ({
                ..._prevState,
                max: Math.max(...allSteps.map((_x) => _x.stepNumber)),
              }));
            } else {
              setPageData((_prevState) => ({
                ..._prevState,
                max: Math.max(...allSteps.map((_x) => _x.stepNumber)),
              }));
            }
          }
        }
      );

      setProcessHistoryContext((prevState) => ({
        ...prevState,
        _stepNumber: nextStepNumber,
      }));
      setSelectedStep(
        allSteps.find((_i) => _i.stepNumber === nextStepNumber)?.activities[0]
          .schemaId || ""
      );

      // Fetch new data if required
      if (!allSteps.some((step) => step.stepNumber === nextStepNumber)) {
        fetchStepDataWithoutPending(nextStepNumber);
      }

      return {
        ...prevState,
        type: "next",
        stepNumber: nextStepNumber,
      };
    });
  }, [
    allSteps,
    setSelectedStep,
    fetchStepDataWithoutPending,
    data.stepsAfter.hasNextPage,
    pageData,
    setProcessHistoryContext,
  ]);

  useEffect(() => {
    const timeOut = setTimeout(dataGetter, 500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [dataGetter]);

  useEffect(() => {
    const data1 = calculateAllStepsAndFinishedSteps({
      stepsAfter: {
        hasNextPage: false,
        steps: [],
        totalElements: 1,
      },
      stepsBefore: {
        hasNextPage: false,
        steps: allSteps,
        totalElements: 1,
      },
    });
    getRelatedData({
      currentStep: stepsPaginationData.stepNumber,
      maxStep: data1.lastStep,
      steps: allSteps,
      type: stepsPaginationData.type,
    });
  }, [getRelatedData, allSteps, stepsPaginationData]);

  useEffect(() => {
    setStepsPaginationData((prevState) => ({
      ...prevState,
      stepNumber:
        allSteps.find((_i) =>
          (() => {
            const selectedIndex = _i?.activities.findIndex((_j) =>
              _j.schemaId.startsWith(clickedElementId)
            );
            return _i?.activities[selectedIndex === -1 ? 0 : selectedIndex]
              ?.schemaId;
          })()
        )?.stepNumber || prevState.stepNumber,
    }));
  }, [allSteps, clickedElementId, setStepsPaginationData]);

  useEffect(() => {
    // getRelatedStepByDiagram(clickedElementId);
  }, [getRelatedStepByDiagram, clickedElementId]);

  useEffect(() => {
    const ev = () => {
      setIsButtonsDisabled(true);
      setProcessHistoryContext((prevState) => ({
        ...prevState,
        isTopItemsDisabled: true,
      }));
    };

    window.addEventListener("diagramClickEvent", ev);
    return () => {
      window.removeEventListener("diagramClickEvent", ev);
    };
  }, [setProcessHistoryContext]);

  return (
    <div
      className={`${styles.BPMN_STEPS_VIEWER_CONTAINER}  w-100 d-flex flex-row align-items-center justify-content-between`}
    >
      <PendingManager
        isDone={isDone}
        isError={isError}
        isPending={isPending}
        onError={dataGetter}
        showAfterDone={
          <>
            <div
              className={`${styles.leftData}  d-flex flex-row align-items-center gap-2


              `}
              onClick={() => {
                if (isButtonsDisabled) {
                  customizedToast("غیر فعال");
                }
              }}
            >
              <TopDetailItem
                title={{
                  data: "",
                }}
                value={{
                  data: (
                    <p>
                      {isActivityPending ? (
                        "* درحال بارگیری *"
                      ) : isTopItemsDisabled ? (
                        <span className="littleBold">
                          <span className="littleBold">
                            {String(_allSteps[0]?.finishedCount) === "undefined"
                              ? withoutInformation
                              : String(_allSteps[0]?.finishedCount)}
                          </span>
                        </span>
                      ) : (
                        <span className="littleBold">
                          {String(
                            allSteps.find((_i) =>
                              _i.activities
                                .map((_x) => _x.schemaId)
                                .includes(selectedDiagramIndexId)
                            )?.finishedCount
                          ) === "undefined"
                            ? withoutInformation
                            : String(
                                allSteps.find((_i) =>
                                  _i.activities
                                    .map((_x) => _x.schemaId)
                                    .includes(selectedDiagramIndexId)
                                )?.finishedCount
                              )}
                        </span>
                      )}
                      مرحله از
                      {isActivityPending ? (
                        "* درحال بارگیری *"
                      ) : isTopItemsDisabled ? (
                        <span className="littleBold">
                          {String(_allSteps[0]?.totalCount) === "undefined"
                            ? withoutInformation
                            : String(_allSteps[0]?.totalCount)}
                        </span>
                      ) : (
                        <span className="littleBold">
                          {String(
                            allSteps.find((_i) =>
                              _i.activities
                                .map((_x) => _x.schemaId)
                                .includes(selectedDiagramIndexId)
                            )?.totalCount
                          ) === "undefined"
                            ? withoutInformation
                            : String(
                                allSteps.find((_i) =>
                                  _i.activities
                                    .map((_x) => _x.schemaId)
                                    .includes(selectedDiagramIndexId)
                                )?.totalCount
                              )}
                        </span>
                      )}
                      مرحله پایان یافته
                    </p>
                  ),
                  className: "rtl",
                }}
              />
              <TopDetailItem
                title={{
                  data: "تاریخ شروع",
                }}
                value={{
                  data: isTopItemsDisabled
                    ? String(_allSteps[0]?.startDate?.jalaliDate) ===
                      "undefined"
                      ? withoutInformation
                      : String(_allSteps[0]?.startDate?.jalaliDate)
                    : allSteps.find(
                        (item) =>
                          String(item.stepNumber) ===
                          String(stepsPaginationData.stepNumber)
                      )?.startDate?.jalaliDate || "بدون تاریخ",
                }}
                containerClassName={`${
                  isButtonsDisabled ? styles.disabled : ""
                }`}
              />
              <TopDetailItem
                title={{
                  data: "تاریخ پایان",
                }}
                value={{
                  data: isTopItemsDisabled
                    ? String(_allSteps[0]?.endDate?.jalaliDate) === "undefined"
                      ? withoutInformation
                      : String(_allSteps[0]?.endDate?.jalaliDate)
                    : allSteps.find(
                        (item) =>
                          String(item.stepNumber) ===
                          String(stepsPaginationData.stepNumber)
                      )?.endDate?.jalaliDate || "بدون تاریخ",
                }}
                containerClassName={`${
                  isButtonsDisabled ? styles.disabled : ""
                }`}
              />
            </div>
            <div
              className={`${styles.rightData} d-flex flex-row align-items-center gap-2
                

                `}
            >
              {isButtonsDisabled ? (
                <button
                  className=""
                  onClick={() => {
                    window.location.reload();
                    dispatchEvent(fullResetterProcessHistoryEvent);
                  }}
                >
                  ریست
                </button>
              ) : null}

              <button
                onClick={isButtonsDisabled ? () => {} : goNextStep}
                className={`
                ${
                  //  ${isButtonsDisabled ? "not-allow " : ""}
                  isButtonsDisabled ? styles.disabled : ""
                }`}
              >
                مرحله بعد
              </button>
              <p>
                {isButtonsDisabled
                  ? isActivityPending
                    ? "در حال بارگذاری"
                    : _allSteps[
                        _allSteps?.findIndex(
                          (_x) =>
                            _x?.activities[0]?.schemaId === clickedElementId
                        )
                      ]?.stepNumber
                  : stepsPaginationData.stepNumber}
              </p>
              <button
                onClick={isButtonsDisabled ? () => {} : goPrevStep}
                className={`
                ${
                  //  ${isButtonsDisabled ? "not-allow " : ""}
                  isButtonsDisabled ? styles.disabled : ""
                }`}
              >
                مرحله قبل
              </button>
            </div>
          </>
        }
      />
    </div>
  );
};

export default BPMN_STEPS_VIEWER;
