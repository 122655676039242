import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
import { I_Message } from "../../../Models/interfaces";

export const submitTaskFormService = async (
  userToken: string,
  taskId: string,
  formData: any
): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {};
  }>
> => {
  console.log("formData xxxxxxxx", formData);
  return _axios.post(
    `/tasks/${taskId}/submit-form`,
    { formData },
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
