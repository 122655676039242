// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Models
import { I_TenantsRecevedData } from "../../Models/interfaces";
// Models

// Services
import { getAllTenantsService } from "../../Services/TenancyManager/get/getAllTenantsService";
// Services

// Modules
import { logo } from "../../Images/images";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Modules

const initialState: I_TenantsRecevedData & {
  isPending: boolean;
  selectedTenant: {
    tenantId: string;
    tenantTitle: string;
    tenantImage: string;
  };
} = {
  pageNumber: 1,
  pageSize: 10,
  totalPages: 0,
  totalElements: 0,
  hasNextPage: false,
  content: [],

  //   Added By Developer
  isPending: false,
  selectedTenant: {
    tenantId: JSON.parse(
      localStorage.getItem("tenantId") || JSON.stringify("")
    ),
    tenantTitle: JSON.parse(
      localStorage.getItem("tenantTitle") || JSON.stringify("سازمان پیشفرض")
    ),
    tenantImage: JSON.parse(
      typeof localStorage.getItem("tenantImage") === "object"
        ? JSON.stringify(logo)
        : localStorage.getItem("tenantImage") !== ""
        ? localStorage.getItem("tenantImage")
          ? localStorage.getItem("tenantImage")!
          : logo
        : JSON.stringify(logo)
    ),
  },
  //   Added By Developer
};

export const getAsyncAllTenants = createAsyncThunk(
  "tenants/getAllTenants",
  async (_data: {
    userToken: string;
    _data: {
      page: number;
      size: number;
      search: string;
      state: number;
    };
  }) => {
    try {
      const { data } = await getAllTenantsService(_data);

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const tenantsSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    resetTenantsPending: (state) => {
      state.isPending = true;
      state.content = [];
    },
    setSelectedTenant: (state, action) => {
      console.log(action.payload);
      state.selectedTenant.tenantId = action.payload.tenantId;
      state.selectedTenant.tenantTitle = action.payload.tenantTitle;
      state.selectedTenant.tenantImage = action.payload.tenantImage;
      localStorage.setItem("tenantId", JSON.stringify(action.payload.tenantId));
      localStorage.setItem(
        "tenantTitle",
        JSON.stringify(action.payload.tenantTitle)
      );
      localStorage.setItem(
        "tenantImage",
        JSON.stringify(action.payload.tenantImage)
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAsyncAllTenants.pending, (state, action) => {
      state.isPending = true;
    });
    builder.addCase(getAsyncAllTenants.fulfilled, (state, action) => {
      state.isPending = false;
      if (!action.payload?.result) return;
      state.pageNumber = action.payload.result.pageNumber;
      state.pageSize = action.payload.result.pageSize;
      state.totalPages = action.payload.result.totalPages;
      state.totalElements = action.payload.result.totalElements;
      state.hasNextPage = action.payload.result.hasNextPage;
      state.content = action.payload.result.content;
    });
    builder.addCase(getAsyncAllTenants.rejected, (state, action) => {
      state.isPending = false;
    });
  },
});
