// React
import React, { useContext, useEffect, useRef, useState } from "react";
// React

// BPMN
import BpmnViewer from "bpmn-js";
// BPMN

// Utils
import { downloadAsBpmnFile } from "../../../Utils/DownloadUtils/downloadAsBpmnFile";
// Utils

// Constants
import { downloadProcessNamePreFix } from "../../../Constants/constants";
// Constants

// Icons
import { AiOutlineDownload } from "react-icons/ai";
// Icons

// Redux
import { useAppSelector } from "../../../app/store";
// Redux

// Events
// Events

// Contexts
import { SetProcessHistoryContext } from "../../../Contexts/ProcessHistoryContext/ProcessHistoryContext";
import { diagramClickEvent } from "../../../App";
// Contexts

type BPMN_Props = {
  xmlData: string;
  currentStateIdContainer?: string;
  className?: string;
  colorControlling: {
    bgDoneColor: string;
    setBgDoneColor: React.Dispatch<React.SetStateAction<string>>;
    textDoneColor: string;
    setTextDoneColor: React.Dispatch<React.SetStateAction<string>>;
  };
  getSelectedStep: (stepId: string, extraData?: any) => void;
  selectedElements: {
    elementId: string;
  };
  getNewSchema: (stepId: string) => void;
};

const BPMN_VIEWER: React.FunctionComponent<BPMN_Props> = ({
  xmlData,
  currentStateIdContainer,
  className,
  colorControlling: { bgDoneColor, textDoneColor },
  getSelectedStep,
  selectedElements,
}) => {
  const [viewerReference, setViewerReference] = useState<any>();
  const [isPending, setIsPending] = useState<boolean>(true);
  console.log(isPending);
  const [zoomLevel, setZoomLevel] = useState(0.7);

  const canvasRef = useRef<any>(null);

  const setProcessHistoryData = useContext(SetProcessHistoryContext);

  const instanceData = useAppSelector(
    (state) => state.processes.processInstanceData.processInstanceSchema
  );

  useEffect(() => {
    if (viewerReference) {
      const canvas = viewerReference.get("canvas");
      canvasRef.current = canvas;

      const container: any = document.getElementById("bpmn-container");

      let isDragging = false;
      let startPos = { x: 0, y: 0 };
      let accumulatedDisplacement = { x: 0, y: 0 };
      let oldViewbox: any;

      const handleMouseDown = (event: any) => {
        isDragging = true;
        startPos = { x: event.clientX, y: event.clientY };
        oldViewbox = canvas.viewbox();
      };

      const handleMouseMove = (event: any) => {
        if (!isDragging) return;

        const dx = event.clientX - startPos.x;
        const dy = event.clientY - startPos.y;

        accumulatedDisplacement.x += dx;
        accumulatedDisplacement.y += dy;

        const newX = oldViewbox.x - accumulatedDisplacement.x;
        const newY = oldViewbox.y - accumulatedDisplacement.y;

        canvas.viewbox({ ...oldViewbox, x: newX, y: newY });

        startPos = { x: event.clientX, y: event.clientY };
      };

      const handleMouseUp = () => {
        isDragging = false;
        accumulatedDisplacement = { x: 0, y: 0 };
      };

      container.addEventListener("mousedown", handleMouseDown);
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);

      container.addEventListener("pointerdown", handleMouseDown);
      window.addEventListener("pointermove", handleMouseMove);
      window.addEventListener("pointerup", handleMouseUp);
      return () => {
        container.removeEventListener("mousedown", handleMouseDown);
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);

        container.removeEventListener("pointerdown", handleMouseDown);
        window.removeEventListener("pointermove", handleMouseMove);
        window.removeEventListener("pointerup", handleMouseUp);
      };
    }
  }, [viewerReference]);
  //
  useEffect(() => {
    const viewer = viewerReference;
    if (!viewer) return;
    if (
      typeof selectedElements.elementId === "string" &&
      selectedElements.elementId !== ""
    ) {
      var selection = viewer.get("selection");
      var elementRegistry = viewer.get("elementRegistry");
      var rectElement = elementRegistry.get(selectedElements.elementId);

      if (rectElement) {
        console.clear();
        console.log(rectElement);
        if ("id" in rectElement) {
          const selectedId = rectElement["id"];
          if (
            typeof selectedId === "string" &&
            instanceData.validSchemas
              .map((_x) => _x.schemaId)
              .includes(selectedId)
          ) {
            selection.select(rectElement);
          } else {
            console.log(selection);
            selection.select(null);
          }
        }
        // zoom to selected Rect
        viewer.get("canvas").zoom("fit-viewport", rectElement);
        // zoom to selected Rect
      }
    }
  }, [selectedElements.elementId, viewerReference, instanceData.validSchemas]);
  //
  useEffect(() => {
    if (xmlData === "") return;
    const container = document.getElementById("bpmn-container");
    const viewer = new BpmnViewer({ container });
    setProcessHistoryData((prevState) => ({
      ...prevState,
      bpmnReference: viewer,
    }));
    const currentProcessState = viewer.getDefinitions();
    console.log(currentProcessState);
    console.log("viewer D", viewer._definitions);
    console.log("viewer", viewer);
    const eventBus = viewer.get("eventBus");

    // function applyCustomStylesToAll(viewer: any) {
    //   const elementRegistry = viewer.get("elementRegistry");

    //   elementRegistry.forEach(function (element: any) {
    //     const gfx = viewer.get("elementRegistry").getGraphics(element.id);
    //     alert(JSON.stringify(gfx));
    //   });
    // }

    eventBus.on("element.click", (event: any) => {
      const element = event.element;
      // dispatchEvent(processHistoryResetEvent);
      console.log(event?.element?.id);
      if (
        String(event?.element?.id).toLowerCase()?.includes("activity") &&
        instanceData.validSchemas
          .map((_x) => _x.schemaId)
          .includes(event?.element?.id)
      ) {
        getSelectedStep(event?.element?.id, event);
        setProcessHistoryData((prevState) => ({
          ...prevState,
          selectedDiagramId: event?.element?.id,
          clickedElementId: event?.element?.id,
        }));

        setTimeout(() => {
          dispatchEvent(diagramClickEvent);
        }, 500);
        // setTimeout(() => {
        //   dispatchEvent(resetProcessTable);
        //   setTimeout(() => {
        //     dispatchEvent(processItemClicked);
        //   }, 100);
        // }, 500);
      }
      if (element.id === "bpmn:Task") {
        alert("x");
        console.log("Task clicked:", element.id);
      }
    });

    viewer.on("element.hover", function (event: any) {
      const gfx = event.gfx;
      console.clear();
      console.log(event);
      if ("element" in event) {
        if ("id" in event.element) {
          if (
            instanceData.validSchemas
              .map((_x) => _x.schemaId)
              .includes(event.element.id)
          ) {
            gfx.classList.add("hover-effect");
          }
        }
      }
    });

    viewer.on("element.out", function (event: any) {
      const gfx = event.gfx;
      gfx.classList.remove("hover-effect");
    });

    setViewerReference(viewer);
    viewer.get("canvas").zoom("fit-viewport", "auto");
    setIsPending(false);
    setTimeout(() => {
      const els = document.querySelectorAll("text > tspan");
      console.log(els);
      if (els.length === 0) return;
      els.forEach((item) => {
        const currX = item.getAttribute("x");
        item.setAttribute("x", String(Number(currX) / 2));
      });
    }, 100);

    async function loadXmlData() {
      try {
        const result = await viewer.importXML(xmlData);
        const { warnings } = result;
        console.log(warnings);
      } catch (err: any) {
        console.log(err.message, err.warnings);
      }
    }

    loadXmlData().then(() => {
      // applyCustomStylesToAll(viewer);
    });

    return () => {
      viewer.destroy();
    };
  }, [xmlData, getSelectedStep, setProcessHistoryData, instanceData]);
  //

  const handleZoom = (factor: number) => {
    if (viewerReference) {
      const newZoomLevel = zoomLevel + factor;
      setZoomLevel(newZoomLevel);

      const canvas = viewerReference.get("canvas");
      const oldViewbox = canvas.viewbox();
      const zoomedViewbox = {
        ...oldViewbox,
        width: oldViewbox.width / canvas.zoom(),
        height: oldViewbox.height / canvas.zoom(),
      };

      canvas.zoom(newZoomLevel, zoomedViewbox);
    }
  };

  const handleZoomIn = () => {
    handleZoom(0.1);
  };

  const handleZoomOut = () => {
    handleZoom(-0.1);
  };

  return (
    <>
      <div
        className={` d-flex flex-row align-items-center align-self-end ${className}`}
      >
        <button
          className="operationEvent submitBtn"
          data-tooltip-id="my-tooltip"
          data-tooltip-content="BPMN دانلود فایل "
          onClick={() => {
            downloadAsBpmnFile(
              [xmlData],
              `${downloadProcessNamePreFix}${currentStateIdContainer}`
            );
          }}
          style={{
            height: "max-content !important",
          }}
        >
          <AiOutlineDownload />
        </button>
        <button onClick={handleZoomIn} className={` operationEvent  zoomInBtn`}>
          <span className="icon-search-zoom-in"></span>
        </button>
        <button
          onClick={handleZoomOut}
          className={` operationEvent zoomOutBtn`}
        >
          <span className="icon-search-zoom-out"></span>
        </button>
      </div>
      <style>
        {`

        ${
          // CSS FOR BPMN DIAGRAM
          ""
          // CSS FOR BPMN DIAGRAM
        }
        .hover-effect .djs-visual > :nth-child(1) {
            stroke: #5e72e4 !important;
            stroke-width: 8px !important;
        }

        .hover-effect .djs-visual > :nth-child(2) {
            fill: rgba(51, 51, 51, 1) !important;
        }

        .hover-reset .djs-visual > :nth-child(1) {
            stroke: none !important;
            stroke-width: 8px !important;
        }

        .hover-reset .djs-visual > :nth-child(2) {
            fill: none !important;
        }

        ${
          // CSS FOR BPMN DIAGRAM
          ""
          // CSS FOR BPMN DIAGRAM
        }
  
      [data-element-id="${currentStateIdContainer}"] g rect {
        fill: ${bgDoneColor} !important;
      }
      [data-element-id="${currentStateIdContainer}"] tspan {
        fill: ${textDoneColor} !important;
      }
      [id="${currentStateIdContainer}"] g rect {
        stroke: teal !important;
        fill: ${bgDoneColor} !important;
      }
      [id="${currentStateIdContainer}"] tspan {
      }
      #${currentStateIdContainer} g rect,
      #${currentStateIdContainer} g > rect {
        fill: ${bgDoneColor} !important;
      }
      #${currentStateIdContainer} tspan {
        fill: ${textDoneColor} !important;
      }`}
      </style>

      <style>
        {`
          ${instanceData.validSchemas
            .map((item) => item.schemaId)
            .map(
              (item) => `
            [data-element-id="${item}"] g rect {
                 filter: drop-shadow(0px 0px 10px yellow);
            }

             [data-element-id="${item}"] > g > rect {
                 filter: drop-shadow(0px 0px 10px yellow);
            }
            
             
            `
            )}
          
          `}
      </style>
      <div
        id="bpmn-container"
        style={{ height: "300px", width: "100%", cursor: "grab" }}
        // onClick={() => {
        //   dispatchEvent(processHistoryResetEvent);
        // }}
      ></div>
    </>
  );
};

export default BPMN_VIEWER;
