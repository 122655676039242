// Axios
import { AxiosResponse } from "axios";
import { _axios_withoutHeaderToken } from "../../Base_Axios_Config/_axios_withoutHeaderToken";
// Axios

// Models
import {
  I_Message,
  I_ResponseOfCheckingRequestState,
} from "../../../Models/interfaces";
// Models

export const checkRequestStateOfRegistrationRequestService = async (_data: {
  _data: {
    username: string;
    trackingCode: string;
  };
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_ResponseOfCheckingRequestState;
  }>
> => {
  return _axios_withoutHeaderToken.post(
    `/call-of-registrations/requests/trace-state`,
    _data._data,
    {}
  );
};
