// React
import { useRef } from "react";
// React

// Utils
// Utils

export const useFormioDialogChanger = (
  data: {
    title: string;
    _key: string;
  }[]
) => {
  const uniqueValues: any = new Set();

  const els = useRef<any>([]);

  const formioDialogContainer: any = document.querySelector(`.preview-panel`);
  if (!formioDialogContainer) return;
  const isDateInputRenderd =
    formioDialogContainer.querySelector('[data-jdp="true"]');

  if (!isDateInputRenderd) return;
  data.forEach((_data, index) => {
    const el = document.createElement("div");
    el.innerHTML = `
          <div class="card panel preview-panel newData" data-dabbleChecker=${index}>
              <div class="card-header">
                <h4 class="card-title mb-0">${_data.title}</h4>
              </div>
              <div class="card-body">
                <div ref="preview" class="component-preview"><div ref="component" class="formio-component formio-component-form  formio-component-label-hidden" id="e9ezqba">
                <div novalidate="" 
                ref="webform" class="formio-form"><div ref="component"
                class="form-group has-feedback formio-component formio-component-textfield formio-component-textField1" id="e0r6z9a"
                >
                <label id="l-e0r6z9a-textField1" for="e0r6z9a-textField1" class="col-form-label" ref="label">
                     ${_data._key}
                </label>
                <div ref="element">
                </div>
          <div class="formio-errors invalid-feedback" ref="messageContainer"></div>
        </div>
        </div>
          <div class="formio-errors invalid-feedback" ref="messageContainer"></div>
        </div>
        </div>
        </div>
        </div>`;
    if (!formioDialogContainer) return;
    els.current = [...els.current, el];
    formioDialogContainer.appendChild(el);
  });

  const allElements: any = document.querySelectorAll("[data-dabbleChecker]");

  allElements.forEach((element: any) => {
    const value = element.getAttribute("data-dabbleChecker");

    // Check if the value is unique
    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);
    } else {
      // If the value is not unique, remove the duplicate element
      formioDialogContainer.removeChild(formioDialogContainer.lastChild);
    }
  });
};
