// React
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
// React

// CSS
import styles from "./AddNewFormSample.module.css";
// CSS

// Modules
import { useNavigate } from "react-router-dom";
// Modules

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
// Redux

// Utils
import CustomFormioComponents from "../../../Utils/CustomFormioComponents/CustomFormioComponents";
import { Spinner } from "reactstrap";
// Utils

// Data
import { formIoLanguagePack } from "../../../Language/formioLanguage";
// Data

// Redux
import {
  addAsyncFormSample,
  formSamplesSlice,
  getAsyncFormSamples,
} from "../../../Features/FormSamples/formSamplesSlice";
// Redux

// Components
import { FormBuilder } from "@formio/react";
import AddFormSampleModal from "./AddFormSampleModal/AddFormSampleModal";
import LoadFormFROMsampleOrAddToCurrentForm from "../../../Components/LoadFormFROMsampleOrAddToCurrentForm/LoadFormFROMsampleOrAddToCurrentForm";
import { MapDataRefType } from "../../Forms/AddNewForm/AddNewForm";
// Components

// Models
import { I_AddNewFormSampleSendingData } from "../../../Models/sendingDataSchema";
// Models

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import { formioCustomComponentAdderFunction } from "../../../Utils/FormioUtils/formioCustomComponentAdderFunction";
// Utils

// Hooks
import useReRenderMap from "../../../Hooks/useReRenderMap";
import { useMapRenderInLittleModal } from "../../../Hooks/MapHooks/useMapRenderInLittleModal";
import { useFormioDialogChanger } from "../../../Hooks/useFormioDialogChanger";
// Hooks

// Constants
import { formioDialogForDatePicker } from "../../../Constants/constants";
// Constants

const AddNewFormSample = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  /************************** */
  /************************** */
  /************************** */
  /************************** */
  // REDUX STORE
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );
  const currLanguage = useAppSelector(
    (state) => state.currLanguage.currLanguage
  );
  const { isPending, isDone } = useAppSelector(
    (state) => state.formSamples.addingFormSample
  );
  // REDUX STORE
  /************************** */
  /************************** */
  /************************** */
  /************************** */
  // LOCAL STATES
  const [form, setForm] = useState({
    display: "form",
    components: [],
  });
  const [nForm, setNForm] = useState({});
  console.log(nForm);
  const [isInputReadingPending, setIsReadInputPending] =
    useState<boolean>(false);
  const [newFormData, setNewFormData] = useState<I_AddNewFormSampleSendingData>(
    {
      title: "",
      description: "",
      tenantId: "",
      formType: "Form_Io",
      formData: {},
    }
  );
  const [modalData, setModalData] = useState<{
    selectOptionValue: string;
    formUrl: string;
  }>({
    selectOptionValue: "",
    formUrl: "",
  });
  const [isModalInScreen, setIsModalInScreen] = useState<boolean>(false);
  const [forceReRender, setForceReRender] = useState<boolean>(false);
  // LOCAL STATES
  /************************** */
  /************************** */
  /************************** */
  /************************** */
  // CONTEXTS
  const isNightMode = useContext(IsNightModeOnContext);
  // CONTEXTS
  /************************** */
  /************************** */
  /************************** */
  /************************** */
  // REFERENCES
  const mapDataRef = useRef<MapDataRefType>({
    lat: "",
    lon: "",
  });
  // REFERENCES
  /************************** */
  /************************** */
  /************************** */
  /************************** */
  // Memo Data
  const formAttacher = useMemo(
    () => (
      <LoadFormFROMsampleOrAddToCurrentForm
        form={form}
        modalData={modalData}
        setForm={setForm}
        setIsModalInScreen={setIsModalInScreen}
        setIsReadInputPending={setIsReadInputPending}
        setModalData={setModalData}
      />
    ),
    [form, modalData]
  );
  // Memo Data
  /************************** */
  /************************** */
  /************************** */
  /************************** */
  // CONSTANTS
  const formioOptions: any = {
    language: currLanguage,
    i18n: formIoLanguagePack.i18n,
  };
  // CONSTANTS
  /************************** */
  /************************** */
  /************************** */
  /************************** */
  // EFFECT MANAGING
  useEffect(() => {
    if (!isDone) return;
    navigate("/form-samples");
  }, [isDone, navigate]);
  //
  useEffect(() => {
    dispatch(
      getAsyncFormSamples({
        userToken,
        _data: {
          page: 1,
          search: "",
          size: 999,
          type: "Form-IO",
          tenant: selectedTenant.tenantId,
          id: "",
        },
      })
    );
  }, [dispatch, userToken, selectedTenant]);
  //
  useEffect(() => {
    return () => {
      dispatch(formSamplesSlice.actions.resetAddingFormSamplesState());
      dispatch(formSamplesSlice.actions.resetRecevedFormSamplesState());
    };
  }, [dispatch]);
  // EFFECT MANAGING
  /************************** */
  /************************** */
  /************************** */
  /************************** */

  useReRenderMap({
    forceReRender,
    form,
    isPending: false,
  });

  useMapRenderInLittleModal(false);
  useFormioDialogChanger(formioDialogForDatePicker);

  return (
    <div
      className={`w-100 d-flex flex-column align-items-center ${
        styles.addNewSampleContainer
      } ${isNightMode ? "nightBg1" : ""}`}
    >
      <div className="w-100 d-flex flex-row align-items-center justify-content-between mb-2">
        <button
          className={` operationEvent   submitBtn   align-self-start d-flex align-items-center justify-content-center`}
          onClick={() => {
            if (isPending) return;
            dispatch(
              addAsyncFormSample({
                userToken,
                _data: { ...newFormData, formData: form },
              })
            );
          }}
        >
          {isPending ? (
            <>
              <Spinner />
            </>
          ) : (
            <>افزودن فرم فعلی به فرم های پیش ساخته</>
          )}
        </button>

        <p
          style={{
            color: "#111D64",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          افزودن فرم پیش ساخته
        </p>
      </div>

      {/* FORM WILL CREATE IN HERE AND ATTACH TO THE FORM BUILDER ! */}
      {/*                   */}
      {formAttacher}
      {/*                    */}
      {/* FORM WILL CREATE IN HERE AND ATTACH TO THE FORM BUILDER ! */}

      <p className="w-100 d-flex flex-row align-items-center justify-content-end mt-4">
        مشخصات فرم جدید
      </p>

      <div
        className={`${styles.inputContainer} my-2 w-100 d-flex flex-row-reverse align-items-center justify-content-between flex-wrap`}
      >
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={newFormData.title}
          defaultValue={newFormData.title}
          onChange={(e) => {
            setNewFormData((prevState) => ({
              ...prevState,
              title: e.target.value,
            }));
          }}
          placeHolder="نام فرم "
          dontShowSearchIcon
        />
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={newFormData.description}
          defaultValue={newFormData.description}
          onChange={(e) => {
            setNewFormData((prevState) => ({
              ...prevState,
              description: e.target.value,
            }));
          }}
          placeHolder="توضیحات"
          dontShowSearchIcon
        />
      </div>

      <div className="w-100 mt-2">
        {isInputReadingPending ? (
          <> در حال بارگذاری فایل </>
        ) : (
          <div className="w-100 d-flex flex-column align-items-end">
            <CustomFormioComponents
              dataSetter={(data) => {
                formioCustomComponentAdderFunction(data, setForm, mapDataRef);
              }}
              containerClassName={`${styles.bg2} p-2`}
            />
            <div className="w-100 mt-2">
              <FormBuilder
                form={form}
                onUpdateComponent={(data: any) => {
                  setNForm((prevState) => data);
                  setForceReRender((prev) => !prev);
                }}
                onSaveComponent={(data: any) => {
                  setNForm((prevState) => data);
                  setForceReRender((prev) => !prev);
                }}
                onDeleteComponent={(data: any) => {
                  setNForm((prevState) => data);
                  setForceReRender((prev) => !prev);
                }}
                onCancelComponent={(data: any) => {
                  setNForm((prevState) => data);
                  setForceReRender((prev) => !prev);
                }}
                onEditComponent={(data: any) => {
                  setNForm((prevState) => data);
                  setForceReRender((prev) => !prev);
                }}
                options={formioOptions}
              />
            </div>
          </div>
        )}
      </div>

      {isModalInScreen ? (
        <AddFormSampleModal
          formUrl={modalData.formUrl}
          setIsModalInScreen={setIsModalInScreen}
          dataSetter={(data) => {
            setForm((prevState: any) => ({
              ...prevState,
              components: [...prevState.components, ...data],
            }));
          }}
        />
      ) : null}
    </div>
  );
};

export default AddNewFormSample;
