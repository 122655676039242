// Utils
import { AxiosError } from "axios";
import { customizedToast } from "../CustomizedToast/customizedToast";
// Utils

export const reduxCatchHandler = <error extends AxiosError>(
  err: any,
  disableError?: boolean
): error => {
  console.log(err);
  if (disableError) {
    throw err;
  }
  if (err?.response.data.messages) {
    for (let i: number = 0; i <= err?.response.data.messages.length - 1; i++) {
      customizedToast(err?.response.data.messages[i].message, "error");
    }
    throw err;
  }
  throw err;
};
