// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_SendingDataForGettingTasks } from "../../../Models/sendingDataSchema";
import { I_Message, I_TasksData } from "../../../Models/interfaces";
// Models

export const getAllTasksService = async (_data: {
  _data: I_SendingDataForGettingTasks;
  userToken: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_TasksData;
  }>
> => {
  return _axios.get(
    `/tasks?page=${_data._data.page}&size=${_data._data.size}
    &orderByDueDate=${_data._data.orderByDueDate}
    &orderByFollowUpDate=${_data._data.orderByFollowUpDate}
    &orderByPriority=${_data._data.orderByPriority}
    &descendingSort=${_data._data.descendingSort}
    &search=${_data._data.search}
    &orderByCreateDate=${_data._data.orderByCreateDate}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
