// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../../Base_Axios_Config/_axios";
// Axios

// Models
import {
  I_ActivityInformation,
  I_Message,
} from "../../../../Models/interfaces";
// Models

export const getActivitiesInformationListByRunningInstanceIdService = (_data: {
  userToken: string;
  _data: {
    processInstanceId: string | number;
    search: string;
    page: string | number;
    size: string | number;
    step: string | number;
  };
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_ActivityInformation;
  }>
> => {
  return _axios.get(
    `/processes/running-instances/${_data._data.processInstanceId}/history/activities?step=${_data._data.step}&search=${_data._data.search}&page=${_data._data.page}&size=${_data._data.size}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
