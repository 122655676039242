// Types
import { LanguageType } from "../Models/types";
// Types

export const formIoLanguagePack: {
  i18n: {
    [key in LanguageType]: {
      [key: string]: string;
    };
  };
} = {
  i18n: {
    fa: {
      "Custom Components": "فیلد های اختصاصی",
      Edit: "ویرایش",
      Preview: "پیشنمایش",
      Save: "ذخیره",
      Cancel: "لغو",
      Remove: "حذف",
      Close: "بستن",
      Move: "انتقال",
      Copy: "رونوشت",
      "Text Field": "متن ساده",
      "Select Boxes": "باکس انتخابی",
      Select: "انتخاب",
      Number: "",
      Password: "",
      Checkbox: "",
      Radio: "دکمه رادیویی",
      Button: "دکمه",
      Data: "داده",
      Validation: "اعتبارسنجی",
      API: "ای پی آی",
      Logic: "منطق",
      Layout: "ظاهر",
      Label: "برچسب",
      LabelPosition: "موقعیت برچست",
      PlaceHolder: "نگهدارنده",
      Description: "توضیحات",
      Tooltip: "راهنما ی بالارونده",
      Prefix: "پیشوند",
      Suffix: "پسوند",
      "Display Mask": "ماسک ظاهری",
      "Apply Mask On": "روی این ماسک بنداز",
      "Custom CSS Class": "کلاس اضافه برای توسعه",
      "Tab Index": "شمارنده تب",
      Autocomplete: "تکمیل خودکار",
      Hidden: "مخفی",
      "Hide Label": "برچسب مخفی",
      "Hide Input": "ورودی مخفی",
      "Initial Focus": "تمرکز اولیه",
      "Allow SpellCheck": "بررسی املایی",
      Disabled: "غیرفعال",
      TableView: "نمایش جدولی",
      ModalEdit: "ویرایش مودال",
      Change: "تغییر",
      BLur: "محو",
      "Multiple Values": "چندین مقدار",
      "Default Value": "مقدر پیشفرض",
      "Persistent ": "مداوم",
      None: "هیچ",
      Server: "سرور",
      Client: "مشتری",
      "Input Format": "قالب ورودی",
      Plain: "ساده",
      HTML: "html",
      "Raw (Insecure)": "خطی (نا ایمن)",
      Protected: "محافظ شده",
      "Database Index": "خانه پایگاه داده",
      "Text Case": "متن",
      "Mixed (Allow upper and lower case)": "مخلوط (هم حروف بزرگ و هم کوچک)",
      "Uppercase ": "حروف بزرگ",
      "Lowercase ": "حروف کوچک",
      "Truncate Multiple Spaces": "فاصله های چندگانه را کوتاه کن",
      Encrypted: "رمزگذاری",
      "Redraw On": "رسم دوباره",
      "Any Change": "با هر تغییر",
      "Clear Value When Hidden ": "حذف مقدار هنگام مخفی شدن",
      "Custom Default Value": "مقدار پیشفرض دلخواه",
      "The following variables are available in all scripts.":
        " تمام این مغیر ها در کل اسکریپت قابل دسترس هستند",
      form: "فرم",
      "The complete form JSON object": "JSON کامل",
      submission: "ارسال",
      "The complete submission object.": "شی کامل ارسالی",
      data: "داده",
      "The complete submission data object.": "اطلاعات کامل شی ارسالی",
      row: "خطی",
      'Contextual "row" data, used within DataGrid, EditGrid, and Container components': `داده های متنی "ردیف"، مورد استفاده در اجزای DataGrid، EditGrid و Container`,
      component: "",
      "The current component JSON": "جیسان کامپوننت فعلی",
      instance: "نمونه نوع",
      "The current component instance.": "نمونه نوعی از کامپونتت",
      value: "مقدار",
      "The current value of the component.": "مقدار فعلی کامپونت",
      moment: "لحظه",
      "The moment.js library for date manipulation.":
        "از moment.js میتونید استفاده کنید",
      _: "لو دش",
      utils: "",
      "An instance of the FormioUtils object.": "شی نمونه نوع فرم ساز",
      util: "کمکی",
      "An alias for 'utils'.": `نام مستعار برای 'کمکی'`,
      JavaScript: "جاوا اسکریپت",
      Example: "مثال",
      "Enter custom javascript code.": "جاوا اسکریپت خاص خود را وارد کنید",
      "Calculate Value on server": "مقادیر در سرور محاسبه گردد",
      "Allow Manual Override of Calculated Value": "تغیر دستی مجاز است",
      "Required ": "اجباری",
      Unique: "خاص",
      Kickbox: "کتابخانه kickbox",
      Enable: "فعالسازی",
      "Validate this email using the Kickbox email validation service.":
        "با کتابخانه ی kickbox اعتبار سنجی گردد",
      "Minimum Length": "حداقل طول",
      "Maximum Length": "حدااکثر طول",
      "Regular Expression Pattern": "regEx مقدار",
      "Error Label": "برچسب خطا",
      "Custom Error Message": "برچسب خطای سفارشی",
      "Secret Validation": "اعتبار سنجی زمردار",
      "Check this if you wish to perform the validation ONLY on the server side. This keeps your validation logic private and secret.":
        "اگر می‌خواهید اعتبارسنجی را فقط در سمت سرور انجام دهید، این را فعال کنید. این منطق اعتبارسنجی شما را خصوصی و مخفی نگه می دارد.",
      "Property Name": "نام پراپرتی",
      "Field Tags": "تگ ها",
      "Custom Properties": "پراپرتی خاص",
      "Add Another": "افزودن موارد دیگر",
      "Advanced Conditions": "شرط های حرفه ای",
      "Advanced Logic": "منطق حرفه ای",
      "Advanced Logic Configured": "شرط های منطقی پیکربندی شد",
      "Add Logic": "اقزودن منطق",
      "Style ": "استایل",
      "Page ": "صفحه",
      "Left ": "چپ",
      "Top ": "بالا",
      "Width ": "طول",
      "Height ": "ارتفاع",
      Advanced: "پیشرفته",
      Email: "صندوق پستی",
      Url: "آدرس url",
      "Phone Number": "شماره تلفن",
      Tags: "تگ ها",
      Address: "نشانی",
      "Date / Time": "تارخ و ساعت",
      Day: "روز",
      Time: "زمان",
      Survay: "نظر سنجی",
      Signature: "امضا",
      Component: "کامپوننت",
      Conditional: "شرطی",
      Display: "نمایش",
      Key: "کلید",
      Value: "مقدار",
      True: "درست",
      False: "غلط",
      Simple: "ساده",
      "This component should Display:": "این کامپوننت نمایش داده شود : ",
      "When the form component:": "وقتی که از کامپوننت",
      "Has the value:": "این مقدار را دارد :",
      online: `شما آنلاین شدید`,
      offline: `شما آفلاین شدید`,
      back: "بازگشت",
    },
    en: {},
  },
};
