// React
import React, { useCallback, useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./Header.module.css";
// CSS

// Modules
import { Link, useNavigate } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
// Modules

// Utils
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import Swal from "sweetalert2";
// Utils

// Redux
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  loginSlice,
  postAsyncLogOutCookie,
} from "../../Features/LoginSlice/loginSlice";
// Redux

// Contexts
// Contexts

// Icons
import { RxHamburgerMenu } from "react-icons/rx";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
// Icons

// Contexts
import { IsSidebarOpenContext } from "../../Contexts/IsSidebarOpen/IsSidebarOpenContext";
import {
  IsNightModeOnContext,
  SetIsNightModeOnContext,
} from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import { SetUserGroupsContext } from "../../Contexts/UserGroupsContext/UserGroupsContext";
import { IoMdClose } from "react-icons/io";
import { useBreadCrumbsItems } from "../../Hooks/BreadCrumbsHooks/useBreadCrumbsItems";
import { currLanguageSlice } from "../../Features/CurrLanguageSlice/currLanguageSlice";
// Contexts

const Header = () => {
  const dispatch = useAppDispatch();

  const { currLanguage } = useAppSelector((state) => state.currLanguage);

  const breadCrumbData = useBreadCrumbsItems(currLanguage);
  const breadcrumbs = useBreadcrumbs(breadCrumbData);

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState<boolean>(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const _isSidebarOpen = useContext(IsSidebarOpenContext);
  const isNightOn = useContext(IsNightModeOnContext);
  const setIsNightModeOn = useContext(SetIsNightModeOnContext);
  const setUserGroups = useContext(SetUserGroupsContext);

  const navigate = useNavigate();

  const { firstname, lastname } = useAppSelector(
    (state) => state.userInformation
  );

  const profileEvFunction = useCallback(() => {
    setIsProfileModalOpen(false);
  }, []);

  const helpEvFunction = useCallback(() => {
    setIsHelpModalOpen(false);
  }, []);

  useEffect(() => {
    if (isProfileModalOpen) {
      setTimeout(() => {
        document.addEventListener("click", profileEvFunction);
      }, 10);
      return;
    } else {
      document.removeEventListener("click", profileEvFunction);
    }
  }, [isProfileModalOpen, profileEvFunction]);

  useEffect(() => {
    if (isHelpModalOpen) {
      setTimeout(() => {
        document.addEventListener("click", helpEvFunction);
      }, 10);

      return;
    } else {
      document.removeEventListener("click", helpEvFunction);
    }
  }, [isHelpModalOpen, helpEvFunction]);

  return (
    <div
      className={`${styles.headerContainer} ${
        _isSidebarOpen ? styles.full : ""
      } ${currLanguage !== "fa" && styles.englishStyles}`}
    >
      <div
        className={`${styles.left} d-flex flex-row align-items-center justify-content-between `}
        onMouseLeave={() => {}}
      >
        <div className={`${styles.leftBtn} d-flex flex-row align-items-center`}>
          <span
            className="icon-back"
            onClick={() => {
              navigate(-1);
            }}
          ></span>

          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              Swal.fire({
                text: "آیا میخواهید از سیستم خارج شوید ؟",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#00A36C",
                confirmButtonText: "بله ، خروج",
                cancelButtonText: "خیر",
                customClass: isNightOn ? "swalNight" : "",
              }).then((result) => {
                if (result.isConfirmed) {
                  customizedToast("در حال خروج شما از سیستم", "info");
                  dispatch(
                    postAsyncLogOutCookie({
                      onDone: () => {
                        setTimeout(() => {
                          setUserGroups([]);
                          dispatch(loginSlice.actions.logout());
                        }, 1000);
                      },
                    })
                  );
                }
              });
            }}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 3.42857C10.4734 3.42857 10.8571 3.81233 10.8571 4.28571V10C10.8571 10.4734 10.4734 10.8571 10 10.8571C9.52661 10.8571 9.14286 10.4734 9.14286 10V4.28571C9.14286 3.81233 9.52661 3.42857 10 3.42857ZM7.71429 5.52255C7.71429 5.3137 7.49781 5.17669 7.31635 5.28008C5.67687 6.21427 4.57143 7.97804 4.57143 10C4.57143 12.9981 7.00188 15.4286 10 15.4286C12.9981 15.4286 15.4286 12.9981 15.4286 10C15.4286 7.97804 14.3231 6.21427 12.6836 5.28008C12.5022 5.17669 12.2857 5.3137 12.2857 5.52255V6.93279C12.2857 7.02071 12.3266 7.10322 12.3938 7.15992C13.2014 7.84126 13.7143 8.86073 13.7143 10C13.7143 12.0513 12.0513 13.7143 10 13.7143C7.94866 13.7143 6.28571 12.0513 6.28571 10C6.28571 8.86073 6.79864 7.84126 7.60616 7.15992C7.67336 7.10322 7.71429 7.02071 7.71429 6.93279V5.52255Z"
              fill="white"
            />
          </svg>

          <div
            className="position-relative d-felx align-items-center justify-content-center "
            style={{
              marginBottom: "-3px",
            }}
          >
            <span
              className="icon-profile "
              onClick={() => {
                setIsProfileModalOpen((prevState) => !prevState);
              }}
              style={{
                width: "24px",
                height: "24px",
                marginTop: "4px",
              }}
            ></span>

            {isProfileModalOpen ? (
              <div
                className={`${styles.profileModal} ${
                  isProfileModalOpen ? styles.visible : ""
                } ${
                  isNightOn ? `nightBg1 ${styles.profileModalNight}` : ""
                } d-flex flex-column align-items-cetner`}
              >
                <p>{firstname + " " + lastname}</p>
                <span className={`${styles.devider}`}></span>
                <Link to={"/profile"}>پروفایل</Link>
                <Link
                  to={"/profile"}
                  state={{
                    page: "changePassword",
                  }}
                >
                  تغییر رمزعبور
                </Link>
                <span className={`${styles.devider}`}></span>
                <p
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Swal.fire({
                      text: "آیا میخواهید از سیستم خارج شوید ؟",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#d33",
                      cancelButtonColor: "#00A36C",
                      confirmButtonText: "بله ، خروج",
                      cancelButtonText: "خیر",
                      customClass: isNightOn ? "swalNight" : "",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        customizedToast("در حال خروج شما از سیستم", "info");
                        dispatch(
                          postAsyncLogOutCookie({
                            onDone: () => {
                              setTimeout(() => {
                                setUserGroups([]);
                                dispatch(loginSlice.actions.logout());
                              }, 1000);
                            },
                          })
                        );
                      }
                    });
                  }}
                >
                  خروج
                </p>
              </div>
            ) : null}
          </div>

          <span
            className="icon-moon"
            onClick={() => {
              setIsNightModeOn((prevState) => !prevState);
            }}
          ></span>

          <div className="position-relative d-felx ">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => {
                setIsHelpModalOpen((prevState) => !prevState);
              }}
            >
              <path
                d="M17 2.43005H7C4 2.43005 2 4.43005 2 7.43005V13.4301C2 16.4301 4 18.4301 7 18.4301V20.5601C7 21.3601 7.89 21.8401 8.55 21.3901L13 18.4301H17C20 18.4301 22 16.4301 22 13.4301V7.43005C22 4.43005 20 2.43005 17 2.43005ZM12 14.6001C11.58 14.6001 11.25 14.2601 11.25 13.8501C11.25 13.4401 11.58 13.1001 12 13.1001C12.42 13.1001 12.75 13.4401 12.75 13.8501C12.75 14.2601 12.42 14.6001 12 14.6001ZM13.26 10.4501C12.87 10.7101 12.75 10.8801 12.75 11.1601V11.3701C12.75 11.7801 12.41 12.1201 12 12.1201C11.59 12.1201 11.25 11.7801 11.25 11.3701V11.1601C11.25 10.0001 12.1 9.43005 12.42 9.21005C12.79 8.96005 12.91 8.79005 12.91 8.53005C12.91 8.03005 12.5 7.62005 12 7.62005C11.5 7.62005 11.09 8.03005 11.09 8.53005C11.09 8.94005 10.75 9.28005 10.34 9.28005C9.93 9.28005 9.59 8.94005 9.59 8.53005C9.59 7.20005 10.67 6.12005 12 6.12005C13.33 6.12005 14.41 7.20005 14.41 8.53005C14.41 9.67005 13.57 10.2401 13.26 10.4501Z"
                fill="white"
              />
            </svg>
            {isHelpModalOpen ? (
              <div
                className={`${styles.helpModal} ${
                  isHelpModalOpen ? styles.visible : ""
                } ${
                  isNightOn ? `nightBg1 ${styles.profileModalNight}` : ""
                } d-flex flex-column align-items-cetner`}
              >
                <p>
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                  با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و
                  مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی
                  تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای
                  کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و
                  آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم
                  افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                  طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این
                  صورت می توان امید داشت که تمام و دشواری موجود در ارائه
                  راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل
                  حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای
                  موجود طراحی اساسا مورد استفاده قرار گیرد.
                </p>
              </div>
            ) : null}
          </div>
        </div>

        {window.innerWidth > 1000 ? null : (
          <div className="position-relative">
            {isSidebarOpen ? (
              <IoMdClose
                style={{
                  color: "#000",
                  marginLeft: "1rem",
                  fontSize: "2rem",
                  background: "#fff",
                  borderRadius: "4px",
                }}
              />
            ) : (
              <RxHamburgerMenu
                style={{
                  color: "#000",
                  marginLeft: "1rem",
                  fontSize: "2rem",
                  background: "#fff",
                  borderRadius: "4px",
                  padding: "0.2rem",
                }}
                onClick={() => {
                  setIsSidebarOpen(true);
                }}
              />
            )}

            {isSidebarOpen ? (
              <HamburgerMenu setIsOpen={setIsSidebarOpen} />
            ) : null}
          </div>
        )}
      </div>

      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      <div
        className={`${styles.right} d-flex flex-column align-items-start justify-content-center mb-2`}
      >
        <div className="d-flex flex-row align-items-center justify-content-center">
          {window.innerWidth > 1001 ? (
            <span
              className="icon-breadcrumb"
              style={{
                fontSize: "1.3rem",
              }}
              onClick={() => {
                if (currLanguage === "fa") {
                  dispatch(currLanguageSlice.actions.toEn());
                  return;
                }
                if (currLanguage === "en") {
                  dispatch(currLanguageSlice.actions.toFa());
                  return;
                }
              }}
            ></span>
          ) : (
            <span className="icon-breadcrumb"></span>
          )}
          {breadcrumbs
            .filter((item) => item.breadcrumb !== " ")
            .map(({ match, breadcrumb }) => (
              <Link key={match.pathname} to={match.pathname} style={{}}>
                /&nbsp; {breadcrumb} &nbsp;
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
