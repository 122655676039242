import { _axios } from "../../Base_Axios_Config/_axios";

export const editUserProfileService = async (_data: {
  _data: {
    firstname?: string;
    lastname?: string;
    email?: string;
    extraIdentityInformation?: {
      [key: string]: any;
    };
  };
  userToken: string;
}) => {
  return _axios.post("/profile/edit", _data._data, {
    headers: {
      Authorization: `Bearer ${_data.userToken}`,
    },
  });
};
