// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_Forms, I_Message } from "../../../Models/interfaces";
// Models

export const getFormsByProcessKeyService = async (_data: {
  userToken: string;
  processKey: string;
  page: number | string;
  size: number | string;
  search: string;
  tenant: number | string;
  id: string | number;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message;
    result: I_Forms;
  }>
> => {
  const { userToken, processKey, page, size, search, tenant, id } = _data;
  return _axios.get(
    `/processes/${processKey}/forms?page=${page}&size=${size}&search=${search}&tenant=${tenant}&id=${id}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
