// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
// React

// CSS
import styles from "./GroupsTab.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  getAsyncCandidateGroupsForProcessHistory,
  processInstanceHistorySlice,
} from "../../../../Features/ProcessesSlice/ProcessInstanceHistorySlice/processInstanceHistorySlice";
// Redux

// Contexts
import { IsNightModeOnContext } from "../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import { ProcessHistoryContext } from "../../../../Contexts/ProcessHistoryContext/ProcessHistoryContext";
// Contexts

// Utils
import PendingManager from "../../../PendingManager/PendingManager";
import Pagination from "../../../../Utils/Pagination/Pagination";
// Utils

// Components
import { withoutInformation } from "../HistoryTab/HistoryTabItem/TaskInformation/TaskInformation";
import CustomInput1 from "../../../../Utils/CustomInput1/CustomInput1";
// Components

type GroupsTabProps = {
  instanceId: string;
  setExtraChild: React.Dispatch<React.SetStateAction<React.ReactElement>>;
};

const GroupsTab: React.FunctionComponent<GroupsTabProps> = ({
  instanceId,
  setExtraChild,
}) => {
  const dispatch = useAppDispatch();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const { data, isDone, isError, isPending } = useAppSelector(
    (state) => state.processInstanceHistorySlice.candidateGroups
  );

  const [paginationData, setPaginationData] = useState<{
    page: number;
    size: number;
    search: string;
  }>({
    page: 1,
    search: "",
    size: 5,
  });

  const { selectedHistoryId } = useContext(ProcessHistoryContext);
  const isNightOn = useContext(IsNightModeOnContext);

  const dataGetter = useCallback(() => {
    dispatch(
      getAsyncCandidateGroupsForProcessHistory({
        userToken,
        _data: {
          ...paginationData,
          historyId: selectedHistoryId,
          processInstanceId: instanceId,
        },
      })
    );
  }, [dispatch, paginationData, selectedHistoryId, instanceId, userToken]);

  const memoInput = useMemo(
    () => (
      <>
        <CustomInput1
          defaultValue=""
          inputType="text"
          onBlur={() => {}}
          onChange={(e) => {
            setPaginationData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          placeHolder="نام گروه موردنظر را وارد کنید"
          value={paginationData.search}
          dontShowSearchIcon
          placeHolderClassName="text-12"
        />
      </>
    ),
    [paginationData.search]
  );

  useEffect(() => {
    const timeOut = setTimeout(dataGetter, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dataGetter]);

  useEffect(() => {
    return () => {
      dispatch(processInstanceHistorySlice.actions.resetGroupsHistory());
    };
  }, [dispatch]);

  useEffect(() => {
    setExtraChild(memoInput);
  }, [memoInput, setExtraChild]);

  useEffect(() => {
    return () => {
      setExtraChild(<></>);
    };
  }, [setExtraChild]);

  return (
    <div className={`${styles.groupsTabContainer} w-100`}>
      <PendingManager
        isDone={isDone}
        isError={isError}
        isPending={isPending}
        onError={dataGetter}
        showAfterDone={
          <>
            <div
              className={`${styles.myTable} ${
                isNightOn ? "nightBg2 pcNight" : ""
              }  w-100 d-flex mt-3 flex-column align-items-center align-items-center`}
            >
              <div
                className={`${styles.tableHead} w-100 d-flex flex-row-reverse align-items-center justify-content-between align-items-center`}
              >
                <p className={`${styles.groupName}`}>نام گروه </p>
                <p className={`${styles.latinName}`}>نام لاتین گروه</p>
                <p className={`${styles.extraName}`}>دسته بندی گروه </p>
              </div>
              <div
                className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
              >
                {data.content.length === 0 ? (
                  <p className="w-100 d-flex flex-row p-0 m-0 mt-2 align-items-center justify-content-center">
                    موردی وجود ندارد
                  </p>
                ) : (
                  <>
                    {data.content.map((item) => (
                      <div
                        key={item.value}
                        className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                      >
                        <p className={`${styles.extraName}  px-2 rounded-2`}>
                          {item?.categoryType?.title || withoutInformation}
                        </p>
                        <p className={`${styles.latinName}`}>
                          {item?.value || withoutInformation}
                        </p>

                        <p className={`${styles.groupName}`}>
                          {item?.title || withoutInformation}
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className={`${styles.tableFooter}`}></div>
            </div>
            <Pagination
              dataForPagination={{
                currPage: paginationData.page,
                size: paginationData.size,
                totalPages: data.totalPages,
              }}
              onPageNumberChange={(page) => {
                setPaginationData((prevState) => ({
                  ...prevState,
                  page,
                }));
              }}
              onPageNumbersClick={(page) => {
                setPaginationData((prevState) => ({
                  ...prevState,
                  page,
                }));
              }}
              onPageSizeChange={(size) => {
                setPaginationData((prevState) => ({
                  ...prevState,
                  page: 1,
                  size,
                }));
              }}
              contaienrClassName="w-100"
              search=""
            />
          </>
        }
      />
    </div>
  );
};

export default GroupsTab;
