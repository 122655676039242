// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./TaskDetailFromTasks.module.css";
// CSS

// Modules
import { useNavigate, useParams } from "react-router-dom";
// Modules

// Utils
import { Spinner } from "reactstrap";
// Utils

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncTaskDetailByTaskIdInTasks,
  taskSlice,
} from "../../../Features/TasksSlice/tasksSlice";
// Redux

// Components
import TaskFormForShowInTaskDetail from "../TaskFormForShowInTaskDetail/TaskFormForShowInTaskDetail";
// Components

// Contexts
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import { MdOutlineDescription, MdOutlineLowPriority } from "react-icons/md";
// Contexts

// Icons
import { BiTimer } from "react-icons/bi";
import { FaAngleDown, FaWpforms } from "react-icons/fa6";
import { VscTypeHierarchySuper } from "react-icons/vsc";
import { GiProcessor } from "react-icons/gi";
import { TbSchema } from "react-icons/tb";
// Icons

// Accardeon Component
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
// Accardeon Component

const TaskDetailFromTasks = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightModeOn = useContext(IsNightModeOnContext);

  const [isAcardionOpen, setIsAcardionOpen] = useState<boolean>(false);

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const singleTaskData = useAppSelector(
    (state) => state.tasks.singleTaskDetail
  );
  const { taskId } = useParams();

  // const persianDatePickerEventHandler = useCallback(
  //   addPersianDatePicker_EVENT,
  //   []
  // );

  useEffect(() => {
    if (!isFullRenderd) return;
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncTaskDetailByTaskIdInTasks({
          userToken,
          taskId: String(taskId),
          onError: () => {
            navigate(-1);
          },
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, userToken, taskId, navigate, isFullRenderd]);

  useEffect(() => {
    return () => {
      dispatch(taskSlice.actions.resetTaskDetail());
    };
  }, [dispatch]);

  // useEffect(() => {
  //   if (singleTaskData.isSingleTaskDetailPending) return;
  //   persianDatePickerEventHandler();
  // }, [singleTaskData.isSingleTaskDetailPending, persianDatePickerEventHandler]);

  // useEffect(() => {
  //   return () => {
  //     removePersianDatePicker_EVENT(persianDatePickerEventHandler);
  //   };
  // }, [persianDatePickerEventHandler]);

  return (
    <>
      <div
        className={`${styles.taskDetailsByTasksContainer} ${
          isNightModeOn ? "nightBg1" : ""
        } w-100 d-flex flex-column align-items-center`}
      >
        {singleTaskData.isSingleTaskDetailPending ? (
          <div className="w-100 d-flex flex-row flex-column align-items-center justify-content-center">
            <p>در حال بارگذاری اطلاعات وظیفه</p>
            <Spinner />
          </div>
        ) : (
          <>
            <div
              className={`${styles.taskData} w-100 d-flex flex-column align-items-center justify-content-center`}
            >
              <p
                className="w-100 d-flex align-items-center justify-content-end"
                style={{
                  color: "#231D64",
                  fontSize: "20px",
                  fontWeight: "bold",
                  paddingRight: "16px",
                }}
              >
                اطلاعات مربوط به وظیفه
              </p>

              <div className="w-100 d-flex flex-row align-items-center justify-content-between flex-wrap">
                <div
                  className={`${styles.card} ${styles.cardFull} ${
                    isNightModeOn ? styles.dark : ""
                  } ${styles.bigOnHover} `}
                >
                  <div className={`${styles.rightItems}`}>
                    <p>نام فرم </p>
                    <span
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={
                        singleTaskData.singleTaskDetail.form.title
                          ? singleTaskData.singleTaskDetail.form.title.length >=
                            23
                            ? singleTaskData.singleTaskDetail.form.title
                            : ""
                          : "تنظیم نشده"
                      }
                    >
                      {singleTaskData.singleTaskDetail.form.title
                        ? singleTaskData.singleTaskDetail.form.title.length >=
                          26
                          ? singleTaskData.singleTaskDetail.form.title.substring(
                              0
                            ) + "..."
                          : singleTaskData.singleTaskDetail.form.title
                        : "تنظیم نشده"}
                    </span>
                  </div>
                  <FaWpforms
                    style={{
                      fontSize: "1.4rem",
                    }}
                  />
                </div>

                <Accordion
                  allowZeroExpanded
                  className={`${styles.accordionContainer} ${styles.cardFull} w-100  `}
                >
                  <AccordionItem>
                    <AccordionItemHeading
                      className={`accordionHeaderCustomClass mt-3`}
                      onClick={() =>
                        setIsAcardionOpen((prevState) => !prevState)
                      }
                    >
                      <AccordionItemButton
                        className={`${styles.accButton} ${
                          isNightModeOn ? "nightBg2" : ""
                        } d-flex align-items-center justify-content-center py-3 `}
                      >
                        <FaAngleDown
                          style={{
                            transform: isAcardionOpen ? "rotate(180deg)" : "",
                          }}
                        />
                        اطلاعات مربوط به وظیفه
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="  w-100  ">
                      <div
                        className="d-flex flex-row  w-100  justify-content-between flex-wrap mt-3"
                        style={{
                          gap: "8px",
                        }}
                      >
                        <div
                          className={`${styles.card}  ${
                            isNightModeOn ? styles.dark : ""
                          } ${styles.bigOnHover} `}
                        >
                          <div className={`${styles.rightItems}`}>
                            <p>توضیحات </p>
                            <span
                              data-tooltip-content={
                                singleTaskData.singleTaskDetail.description
                                  ? singleTaskData.singleTaskDetail.description
                                      .length >= 23
                                    ? singleTaskData.singleTaskDetail
                                        .description
                                    : ""
                                  : "تنظیم نشده"
                              }
                            >
                              {singleTaskData.singleTaskDetail.description
                                ? singleTaskData.singleTaskDetail.description
                                    .length >= 26
                                  ? singleTaskData.singleTaskDetail.description.substring(
                                      0
                                    ) + "..."
                                  : singleTaskData.singleTaskDetail.description
                                : "تنظیم نشده"}
                            </span>
                          </div>
                          <MdOutlineDescription
                            style={{
                              fontSize: "1.4rem",
                            }}
                          />
                        </div>
                        <div
                          className={`${styles.card}   ${
                            isNightModeOn ? styles.dark : ""
                          } ${styles.bigOnHover} `}
                        >
                          <div className={`${styles.rightItems}`}>
                            <p> زمان انجام</p>
                            <span>
                              {singleTaskData?.singleTaskDetail?.dueDate
                                ?.jalaliDate || "تنظیم نشده"}
                            </span>
                          </div>
                          <BiTimer
                            style={{
                              fontSize: "1.4rem",
                            }}
                          />
                        </div>
                        <div
                          className={`${styles.card} ${
                            isNightModeOn ? styles.dark : ""
                          } ${styles.bigOnHover} `}
                        >
                          <div className={`${styles.rightItems}`}>
                            <p>اولویت </p>
                            <span
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={
                                singleTaskData.singleTaskDetail.priorityType
                                  .title
                                  ? singleTaskData.singleTaskDetail.priorityType
                                      .title.length >= 26
                                    ? singleTaskData.singleTaskDetail
                                        .priorityType.title
                                    : ""
                                  : "تنظیم نشده"
                              }
                            >
                              {singleTaskData.singleTaskDetail.priorityType
                                .title
                                ? singleTaskData.singleTaskDetail.priorityType
                                    .title.length >= 26
                                  ? singleTaskData.singleTaskDetail.priorityType.title.substring(
                                      0
                                    ) + "..."
                                  : singleTaskData.singleTaskDetail.priorityType
                                      .title
                                : "تنظیم نشده"}
                            </span>
                          </div>
                          <MdOutlineLowPriority
                            style={{
                              fontSize: "1.4rem",
                            }}
                          />
                        </div>
                        {allGroups.includes("Full_Manager") && (
                          <>
                            <div
                              className={`${styles.card} ${
                                isNightModeOn ? styles.dark : ""
                              } ${styles.bigOnHover} `}
                            >
                              <div
                                className={`${styles.rightItems}`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={
                                  singleTaskData.singleTaskDetail.schemaId
                                    ? singleTaskData.singleTaskDetail.schemaId
                                        .length >= 23
                                      ? singleTaskData.singleTaskDetail.schemaId
                                      : ""
                                    : "تنظیم نشده"
                                }
                              >
                                <p>کد ساختار </p>
                                <span>
                                  {singleTaskData.singleTaskDetail.schemaId
                                    ? singleTaskData.singleTaskDetail.schemaId
                                        .length >= 23
                                      ? singleTaskData.singleTaskDetail.schemaId.substring(
                                          0,
                                          25
                                        ) + "..."
                                      : singleTaskData.singleTaskDetail.schemaId
                                    : "تنظیم نشده"}
                                </span>
                              </div>
                              <TbSchema
                                style={{
                                  fontSize: "1.4rem",
                                }}
                              />
                            </div>
                            <div
                              className={`${styles.card} ${
                                isNightModeOn ? styles.dark : ""
                              } ${styles.bigOnHover} `}
                            >
                              <div className={`${styles.rightItems}`}>
                                <p>شمارنده فرآیند </p>
                                <span
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={
                                    singleTaskData.singleTaskDetail.processId
                                      ? singleTaskData.singleTaskDetail
                                          .processId.length >= 26
                                        ? singleTaskData.singleTaskDetail
                                            .processId
                                        : ""
                                      : "تنظیم نشده"
                                  }
                                >
                                  {singleTaskData.singleTaskDetail.processId
                                    ? singleTaskData.singleTaskDetail.processId
                                        .length >= 23
                                      ? singleTaskData.singleTaskDetail.processId.substring(
                                          0,
                                          25
                                        ) + "..."
                                      : singleTaskData.singleTaskDetail
                                          .processId
                                    : "تنظیم نشده"}
                                </span>
                              </div>
                              <GiProcessor
                                style={{
                                  fontSize: "1.4rem",
                                }}
                              />
                            </div>
                            <div
                              className={`${styles.card} ${
                                isNightModeOn ? styles.dark : ""
                              } ${styles.bigOnHover} `}
                            >
                              <div className={`${styles.rightItems}`}>
                                <p>شمارنده نمونه نوع </p>
                                <span
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={
                                    singleTaskData.singleTaskDetail
                                      .processInstanceId
                                      ? singleTaskData.singleTaskDetail
                                          .processInstanceId.length >= 26
                                        ? singleTaskData.singleTaskDetail
                                            .processInstanceId
                                        : ""
                                      : "تنظیم نشده"
                                  }
                                >
                                  {singleTaskData.singleTaskDetail
                                    .processInstanceId
                                    ? singleTaskData.singleTaskDetail
                                        .processInstanceId.length >= 26
                                      ? singleTaskData.singleTaskDetail.processInstanceId.substring(
                                          0,
                                          25
                                        ) + "..."
                                      : singleTaskData.singleTaskDetail
                                          .processInstanceId
                                    : "تنظیم نشده"}
                                </span>
                              </div>
                              <VscTypeHierarchySuper
                                style={{
                                  fontSize: "1.4rem",
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>

                {/*           
              <div
                className={`${styles.card}  ${
                  isNightModeOn ? styles.dark : ""
                } ${styles.bigOnHover} `}
              >
                <div className={`${styles.rightItems}`}>
                  <p>قابل بروزرسانی </p>
                  <span data-tooltip-id="my-tooltip">
                    {String(
                      singleTaskData?.singleTaskDetail?.followUpDate
                        ?.jalaliDate || "تنظیم نشده"
                    )}
                  </span>
                </div>
                <GrUpdate
                  style={{
                    fontSize: "1.4rem",
                  }}
                />
              </div> */}
              </div>

              {!singleTaskData.isSingleTaskDetailPending &&
              !singleTaskData.isError ? (
                <TaskFormForShowInTaskDetail
                  formUrl={singleTaskData.singleTaskDetail.form.url}
                  _recevedFormData={
                    singleTaskData.singleTaskDetail.form.formData
                  }
                  taskId={String(taskId)}
                  containerClassName="mt-4"
                />
              ) : null}
            </div>
          </>
        )}
      </div>

      {singleTaskData.isSingleTaskDetailPending && !singleTaskData.isError ? (
        <div className="w-100 d-flex flex-row flex-column align-items-center justify-content-center">
          <p>در حال بارگذاری فرم مربوطه</p>
          <Spinner />
        </div>
      ) : null}

      {!singleTaskData.isSingleTaskDetailPending && singleTaskData.isError ? (
        <div
          className="w-100 d-flex flex-row flex-column align-items-center justify-content-center"
          onClick={() => {
            dispatch(
              getAsyncTaskDetailByTaskIdInTasks({
                userToken,
                taskId: String(taskId),
                onError: () => {
                  navigate(-1);
                },
              })
            );
          }}
        >
          مشکلی پیش آمده ، برای تلاش مجدد کلیک کنید
        </div>
      ) : null}
    </>
  );
};

export default TaskDetailFromTasks;
