// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./GroupsTab.module.css";
// CSS

// Utils
import Pagination from "../../../../Utils/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import CustomCheckbox from "../../../../Utils/CustomCheckbox/CustomCheckbox";
// Utils

// Redux
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { accessibleUsersListSlice } from "../../../../Features/AccessibleUsersListSlice/AccessibleUsersListSlice";
import {
  getAsyncPossibleGroupMembershipSlice,
  possibleGroupMemberShipsSlice,
} from "../../../../Features/PossibleGroupMemberShipsSlice/possibleGroupMemberShipsSlice";
// Redux

// Moduels
// Moduels

// Contexts
import { IsNightModeOnContext } from "../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

type GroupsTabProps = {
  setGroupsForChecking: React.Dispatch<React.SetStateAction<string[]>>;
  paginationData: {
    currPage: number;
    pageSize: number;
    tenantId: string;
    search: string;
  };
  groupsForChecking: string[];
  setPaginationData: React.Dispatch<
    React.SetStateAction<{
      currPage: number;
      pageSize: number;
      tenantId: string;
      search: string;
    }>
  >;
  setSelectedTab: React.Dispatch<
    React.SetStateAction<"groups" | "extraIdentity" | "personal">
  >;
};

const GroupsTab: React.FunctionComponent<GroupsTabProps> = ({
  paginationData,
  setGroupsForChecking,
  setPaginationData,
  groupsForChecking,
  setSelectedTab,
}) => {
  const groupsData = useAppSelector((state) => state.possibleGroupMembership);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { isUserCreated } = useAppSelector((state) => state.accessibleUsers);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState<string>("");

  const isNightModeOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncPossibleGroupMembershipSlice({
          userToken,
          searchQuery: {
            page: paginationData.currPage,
            size: paginationData.pageSize,
            tenantId: paginationData.tenantId,
            search: inputValue,
          },
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, paginationData, userToken, inputValue]);

  useEffect(() => {
    if (isUserCreated) {
      return navigate("/users");
    }
    return () => {
      dispatch(accessibleUsersListSlice.actions.resetIsUserCreated());
      dispatch(possibleGroupMemberShipsSlice.actions.resetPossiblePermition());
    };
  }, [dispatch, isUserCreated, navigate]);
  return (
    <div
      className={`${styles.groupsTabContainer} 
      ${isNightModeOn ? "nightBg1" : ""}
      w-100 d-flex flex-column align-items-center`}
    >
      <p
        style={{
          width: "100%",
          textAlign: "right",
          color: "#111D64",
          fontWeight: "bold",
          fontSize: "20px",
        }}
      >
        افزودن کاربر جدید
      </p>
      <p
        style={{
          width: "100%",
          textAlign: "right",
          color: "#8392AB",
          fontSize: "16px",
        }}
      >
        انتخاب گروه ها
      </p>
      <div className="w-100 d-flex flex-row-reverse align-items-center justify-content-between my-2">
        <p
          style={{
            textAlign: "right",
            color: "#111D64",
            fontWeight: "bold",
          }}
        >
          انتخاب گروه‌
        </p>
        <input
          type="text"
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          style={{
            border: "1px solid #E9ECEF",
            direction: "rtl",
            padding: "0 8px",
            borderRadius: "8px",
            minHeight: "32px",
          }}
          placeholder="جست و جو کنید ..."
        />
      </div>
      <div
        className="w-100 flex-row-reverse"
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {groupsData.isPending ? (
          <div className="w-100" style={{ textAlign: "right" }}>
            در حال بارگذاری
          </div>
        ) : (
          <>
            {groupsData.content.length === 0 ? (
              <>موردی وجود ندارد </>
            ) : (
              <>
                {groupsData.content.map((item, index) => (
                  <CustomCheckbox
                    containerClassName={`${styles.groupItem} mt-2`}
                    key={item.value}
                    onClick={() => {
                      setGroupsForChecking((prevState) =>
                        prevState.includes(item.value)
                          ? prevState.filter((_item) => _item !== item.value)
                          : [...prevState, item.value]
                      );
                    }}
                    onChange={() => {
                      setGroupsForChecking((prevState) =>
                        prevState.includes(item.value)
                          ? prevState.filter((_item) => _item !== item.value)
                          : [...prevState, item.value]
                      );
                    }}
                    title={item.title}
                    isChecked={groupsForChecking.includes(item.value)}
                  />
                ))}
              </>
            )}
          </>
        )}
      </div>

      <Pagination
        dataForPagination={{
          currPage: paginationData.currPage,
          size: paginationData.pageSize,
          totalPages: groupsData.totalPages,
        }}
        onPageNumberChange={(pageNumber) => {
          setPaginationData((prevState) => ({
            ...prevState,
            currPage: Number(pageNumber),
          }));
        }}
        onPageNumbersClick={(pageNumber: number) => {
          setPaginationData((prevState) => ({
            ...prevState,
            currPage: Number(pageNumber),
          }));
        }}
        onPageSizeChange={(size) => {
          setPaginationData((prevState) => ({
            ...prevState,
            pageSize: Number(size),
          }));
        }}
        search={paginationData.search}
      />
      <button
        className={`${styles.nextStepBtn} align-self-start`}
        onClick={() => {
          setSelectedTab("extraIdentity");
        }}
      >
        افزودن فیلد هویتی
      </button>
    </div>
  );
};

export default GroupsTab;
