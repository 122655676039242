// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./NewIdentity.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  addAsyncNewUserIdentity,
  userIdentitiesSlice,
} from "../../../Features/userIdentitiesSlice/userIdentitiesSlice";
// Redux

// Modules
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
// Modules

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
// Utils

// Models
import { I_NewIdentitySendingData } from "../../../Models/sendingDataSchema";
// Models

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import CustomSelectOption2 from "../../../Utils/CustomSelectOption2/CustomSelectOption2";
import CustomCheckbox from "../../../Utils/CustomCheckbox/CustomCheckbox";
// Contexts

const NewIdentity = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<I_NewIdentitySendingData>({
    key: "",
    title: "",
    type: "TEXT",
    required: false,
    unique: false,
    searchable: false,
    availableOnAccessList: false,
    minimumValue: "",
    maximumValue: "",
  });

  const { selectedTenant } = useAppSelector((state) => state.tenants);

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { isPending, isDone } = useAppSelector(
    (state) => state.userIdentities.addingUserIdentity
  );
  const {
    data: { identityInformationDataTypes },
  } = useAppSelector((state) => state.allRoles);

  const isNightOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    if (!isDone) return;
    navigate("/user-identities");

    return () => {
      dispatch(userIdentitiesSlice.actions.resetAddingIdentityStatus());
    };
  }, [isDone, navigate, dispatch]);

  return (
    <div
      className={`${styles.newIdentityContainer} ${
        isNightOn ? "nightBg1" : ""
      }  w-100 d-flex flex-column algin-items-center justify-content-center`}
    >
      <div
        className={`${styles.newIdentityInputsContainer} w-100 d-flex flex-row-reverse flex-wrap justify-content-between `}
      >
        <CustomSelectOption2
          getSelectedOption={(data) => {
            setFormData((prevState) => ({ ...prevState, type: String(data) }));
          }}
          initialTitle="پیشفرض : متن"
          initialValue={"TEXT"}
          options={((): { title: string; value: string }[] => {
            const _identityInformationDataTypes: any = {
              ...identityInformationDataTypes,
            };
            const keys: any = Object.keys(identityInformationDataTypes);
            console.log("keys", keys);
            const arr: { title: string; value: string }[] = [];

            for (let i: number = 0; i <= keys.length - 1; i++) {
              arr[i] = _identityInformationDataTypes[keys[i]];
            }
            console.log(arr);
            return arr;
          })().map((item) => ({
            title: item.title,
            value: item.value,
          }))}
        />

        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={String(formData.title)}
          defaultValue={String(formData.title)}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              title: e.target.value,
            }));
          }}
          placeHolder="نام"
          containerClassName=""
          dontShowSearchIcon
        />
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={String(formData.key)}
          defaultValue={String(formData.key)}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              key: e.target.value,
            }));
          }}
          placeHolder="کلید"
          containerClassName=""
          dontShowSearchIcon
        />
        {/* <CustomSelectOption
          defaultValue={"TEXT"}
          onChange={(data) => {
            setFormData((prevState) => ({ ...prevState, type: data }));
          }}
          firstItemForSelectAll=""
          inputPlaceHolder="جستجو کنید ..."
          nameForShowInInitial="انتخاب کنید ( متن )"
          options={((): { title: string; value: string }[] => {
            const _identityInformationDataTypes: any = {
              ...identityInformationDataTypes,
            };
            const keys: any = Object.keys(identityInformationDataTypes);
            console.log("keys", keys);
            const arr: { title: string; value: string }[] = [];

            for (let i: number = 0; i <= keys.length - 1; i++) {
              arr[i] = _identityInformationDataTypes[keys[i]];
            }
            console.log(arr);
            return arr;
          })()}
        /> */}

        {formData.type === "DOUBLE" || formData.type === "INTEGER" ? (
          <>
            <CustomInput1
              inputType="text"
              onBlur={() => {}}
              value={formData.minimumValue}
              defaultValue={formData.minimumValue}
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  minimumValue: e.target.value,
                }));
              }}
              placeHolder="کف"
              containerClassName=""
              dontShowSearchIcon
            />
            <CustomInput1
              inputType="text"
              value={String(formData.maximumValue)}
              defaultValue={String(formData.maximumValue)}
              onBlur={() => {}}
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  maximumValue: e.target.value,
                }));
              }}
              placeHolder="سقف"
              containerClassName=""
              dontShowSearchIcon
            />
          </>
        ) : null}

        <CustomCheckbox
          isChecked={formData.required}
          onClick={() => {
            setFormData((prevState) => ({
              ...prevState,
              required: !prevState.required,
            }));
          }}
          onChange={() => {
            setFormData((prevState) => ({
              ...prevState,
              required: !prevState.required,
            }));
          }}
          title="الزامی"
        />

        <CustomCheckbox
          isChecked={formData.unique}
          onClick={() => {
            setFormData((prevState) => ({
              ...prevState,
              unique: !prevState.unique,
            }));
          }}
          onChange={() => {
            setFormData((prevState) => ({
              ...prevState,
              unique: !prevState.unique,
            }));
          }}
          title="خاص"
        />

        <CustomCheckbox
          isChecked={formData.searchable}
          onClick={() => {
            setFormData((prevState) => ({
              ...prevState,
              searchable: !prevState.searchable,
            }));
          }}
          onChange={() => {
            setFormData((prevState) => ({
              ...prevState,
              searchable: !prevState.searchable,
            }));
          }}
          title="قابل جستجو"
        />

        <CustomCheckbox
          isChecked={formData.availableOnAccessList}
          onClick={() => {
            setFormData((prevState) => ({
              ...prevState,
              availableOnAccessList: !prevState.availableOnAccessList,
            }));
          }}
          onChange={() => {
            setFormData((prevState) => ({
              ...prevState,
              availableOnAccessList: !prevState.availableOnAccessList,
            }));
          }}
          title="قابل مشاهده در لیست ها "
        />
      </div>
      <button
        className="align-self-start operationEvent submitBtn mt-4"
        onClick={() => {
          dispatch(
            addAsyncNewUserIdentity({
              userToken,
              _data: formData,
              tenant: selectedTenant.tenantId,
            })
          );
        }}
      >
        {isPending ? <Spinner /> : "تایید"}
      </button>
    </div>
  );
};

export default NewIdentity;
