// React
import React, { useContext, useState } from "react";
// React

// CSS
import styles from "./GroupTab.module.css";
// CSS

// Redux
import { useAppSelector } from "../../../app/store";
// Redux

// Context
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import CustomCheckbox from "../../../Utils/CustomCheckbox/CustomCheckbox";
// Context

const GroupTab = () => {
  const groupsData = useAppSelector((state) => state.userInformation);

  const isNightModeOn = useContext(IsNightModeOnContext);

  const [inputValue, setInputValue] = useState<string>("");

  return (
    <div
      className={`${
        styles.groupTabContainer
      } w-100 d-flex flex-column align-items-end ${
        isNightModeOn ? "nightBg1" : ""
      } `}
    >
      <div className="w-100 d-flex flex-row-reverse align-items-center justify-content-between my-2">
        <p
          style={{
            textAlign: "right",
            color: "#111D64",
            fontWeight: "bold",
          }}
        >
          نام گروه‌
        </p>
        <input
          type="text"
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          style={{
            border: "1px solid #E9ECEF",
            direction: "rtl",
            padding: "0 8px",
            borderRadius: "8px",
            minHeight: "32px",
          }}
          placeholder="جست و جو کنید ..."
        />
      </div>
      <div className="w-100 d-flex flex-row-reverse flex-wrap align-items-center justify-content-between">
        {inputValue.trim().length === 0
          ? groupsData.allGroupsWithTitles.map((item) => (
              <CustomCheckbox
                key={item.value}
                isChecked
                onChange={() => false}
                onClick={() => false}
                title={item.title}
                containerClassName={`${styles.groupItem} mt-2`}
              />
            ))
          : groupsData.allGroupsWithTitles
              .filter((item) => item.title.includes(inputValue))
              .map((item) => (
                <CustomCheckbox
                  key={item.value}
                  isChecked
                  onChange={() => false}
                  onClick={() => false}
                  title={item.title}
                  containerClassName={`${styles.groupItem} mt-2`}
                />
              ))}
      </div>
    </div>
  );
};

export default GroupTab;
