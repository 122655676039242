// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./AddOptionToSingleAttribute.module.css";
// CSS

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import { Spinner } from "reactstrap";
import Swal from "sweetalert2";
// Utils

// Modules
import { useNavigate, useParams } from "react-router-dom";
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
// Modules

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  addAsyncNewOptionToExistingUserIdentity,
  getAsyncAllOptionsForSingleAttribute,
  removeAsyncSingleExistingOptionOfUserAttribute,
  userIdentitiesSlice,
} from "../../../Features/userIdentitiesSlice/userIdentitiesSlice";
// Redux

// Contexts
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

const AddOptionToSingleAttribute = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { identityId, persianName } = useParams();

  const [valueOfField, setValueOfField] = useState<string>("");

  const { isPending: isAddingPending, isDone: isAddingDone } = useAppSelector(
    (state) => state.userIdentities.addNewOptionToExistingIdentity
  );
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { data, isPending: isAttributesPending } = useAppSelector(
    (state) => state.userIdentities.singleAttributeOptions
  );
  const { selectedTenant } = useAppSelector((state) => state.tenants);
  const language = useAppSelector((state) => state.currLanguage.language);

  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    if (!isAddingDone) return;
    if (!isFullRenderd) return;
    dispatch(
      getAsyncAllOptionsForSingleAttribute({
        userToken,
        attributeId: String(identityId),
        tenant: selectedTenant.tenantId,
      })
    );
    setValueOfField("");
  }, [
    isAddingDone,
    userToken,
    identityId,
    selectedTenant,
    dispatch,
    isFullRenderd,
  ]);

  useEffect(() => {
    return () => {
      dispatch(userIdentitiesSlice.actions.resetSingleAttributeData());
      dispatch(
        userIdentitiesSlice.actions.resetAddingOptionToUserIdentityStatus()
      );
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getAsyncAllOptionsForSingleAttribute({
        userToken,
        attributeId: String(identityId),
        tenant: selectedTenant.tenantId,
      })
    );
  }, [dispatch, userToken, identityId, selectedTenant]);

  return (
    <div
      className={`${styles.addNewOptionToSingleAttributeContainer} w-100 d-flex flex-column align-items-center`}
    >
      <div
        className={`${styles.addAttributeForm} ${
          isNightOn ? "nightBg1" : ""
        }  w-100 d-flex flex-column align-items-center`}
      >
        <p
          className="d-flex flex-row align-items-center justify-content-start w-100 "
          style={{
            direction: "rtl",
          }}
        >
          <span>افزودن فیلد به شناسه </span>
          &nbsp; : &nbsp;
          <span>{persianName}</span>
        </p>
        <div
          className={`${styles.addNewOptionsInputContainer} mt-3 w-100 d-flex flex-row align-items-center justify-content-between flex-wrap`}
        >
          <CustomInput1
            inputType="text"
            onBlur={() => {}}
            onChange={(e) => {
              setValueOfField(e.target.value);
            }}
            placeHolder="مقدار  فیلد"
            dontShowSearchIcon
            containerClassName="w-100"
            value={valueOfField}
            defaultValue={valueOfField}
          />
        </div>
        <button
          className="operationEvent submitBtn align-self-start mt-4"
          onClick={() => {
            if (isAddingPending) return;
            dispatch(
              addAsyncNewOptionToExistingUserIdentity({
                userToken,
                _data: valueOfField,
                attributeIdOrKey: String(identityId),
                tenant: selectedTenant.tenantId,
              })
            );
          }}
        >
          {isAddingPending ? <Spinner /> : "تایید"}
        </button>
      </div>

      <div
        className={`${styles.identityOptions} ${
          isNightOn ? "nightBg1" : ""
        }  w-100`}
      >
        {isAttributesPending ? (
          <div className="w-100 d-flex flex-column align-items-center justify-content-center">
            <p>در حال بارگیری</p>
            <Spinner />
          </div>
        ) : (
          <div
            className={`${styles.myTable} ${
              isNightOn ? "nightBg2 pcNight" : ""
            } w-100 d-flex flex-column align-items-center align-items-center`}
          >
            <div
              className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
            >
              <p className={`${styles.oprationTab}`}>عملیات</p>
              <p className={`${styles.title}`}>عملیات</p>
              <p className={`${styles.id}`}>شمارنده</p>
            </div>
            <div
              className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
            >
              {data.map((item) => (
                <div
                  key={item.id}
                  className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                >
                  <p className={`${styles.oprationTab}`}>
                    {permitionCheckerWithoutToast(
                      allGroups,
                      [
                        "Tenant_Full_Manager",
                        "Tenant_Basic_Manager",
                        "Identity_Attributes_Manager",
                      ],
                      isFullRenderd
                    ) && (
                      <button
                        className="operationEvent deleteBtn"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={language.delete}
                        onClick={() => {
                          Swal.fire({
                            icon: "question",
                            title: "آیا این زیر شناسه حذف شود ؟",
                            text: "عملیات غیرقابل بازگشت است",
                            showCancelButton: true,
                            cancelButtonText: "لغو",
                            confirmButtonText: "حذف",
                            customClass: isNightOn ? "swalNight" : "",
                          }).then(({ isConfirmed }) => {
                            if (!isConfirmed) return;

                            dispatch(
                              removeAsyncSingleExistingOptionOfUserAttribute({
                                userToken,
                                tenant: selectedTenant.tenantId,
                                _data: {
                                  attributeIdOrKey: String(identityId),
                                  optionId: String(item.id),
                                },
                              })
                            ).then(() => {
                              dispatch(
                                getAsyncAllOptionsForSingleAttribute({
                                  userToken,
                                  attributeId: String(identityId),
                                  tenant: selectedTenant.tenantId,
                                })
                              );
                            });
                          });
                        }}
                      >
                        <span className="icon-trash"></span>
                      </button>
                    )}
                    {permitionCheckerWithoutToast(
                      allGroups,
                      [
                        "Tenant_Full_Manager",
                        "Tenant_Basic_Manager",
                        "Identity_Attributes_Manager",
                      ],
                      isFullRenderd
                    ) && (
                      <button
                        className="operationEvent editBtn"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={language.edit}
                        onClick={() => {
                          navigate(
                            `/user-identities/add-option-to-single-attribute/edit-selected-attribute/${identityId}/${item.id}/${item.title}`
                          );
                        }}
                      >
                        <span className="icon-edit"></span>
                      </button>
                    )}
                  </p>
                  <p className={`${styles.title}`}>{item.title}</p>
                  <p className={`${styles.id}`}>{item.id}</p>
                </div>
              ))}
            </div>
            <div className={`${styles.tableFooter}`}></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddOptionToSingleAttribute;
