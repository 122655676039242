// React
import React, { useContext } from "react";
// React

// CSS
import styles from "./../Profile.module.css";
// CSS

// Models

import { I_UserData } from "../../../Models/interfaces";
// Models

// Utils
import UserPersonalInformation from "./UserPersonalInformation/UserPersonalInformation";
import PasswordsTab from "./PasswordsTab/PasswordsTab";
// Utils

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

type PersonTabProps = {
  selectedInnerPersonTab: "userInformation" | "password";
  setSelectedInnerPersonTab: React.Dispatch<
    React.SetStateAction<"userInformation" | "password">
  >;

  setUserDataForOverWrite: React.Dispatch<React.SetStateAction<I_UserData>>;
  userDataForOverWrite: I_UserData;

  setPasswordData: React.Dispatch<
    React.SetStateAction<{ oldPassword: string; newPassword: string }>
  >;
  passwordData: { oldPassword: string; newPassword: string };
};

const PersonTab: React.FunctionComponent<PersonTabProps> = ({
  selectedInnerPersonTab,
  setSelectedInnerPersonTab,
  setUserDataForOverWrite,
  userDataForOverWrite,
  passwordData,
  setPasswordData,
}) => {
  const isNightModeOn = useContext(IsNightModeOnContext);

  return (
    <>
      {/* Tab Selector  */}
      <div className="w-100 d-flex flex-column align-items-center">
        <div
          className={`${styles.topButtonsContainer} ${
            isNightModeOn ? "nightBg1" : ""
          }  d-flex flex-row-reverse align-items-center justify-content-around mb-4  `}
          style={{
            alignSelf: "flex-end",
          }}
        >
          <button
            className={` p-2 ${isNightModeOn ? "nightBg2" : ""}`}
            onClick={() => {
              setSelectedInnerPersonTab("userInformation");
            }}
            style={{
              boxShadow:
                selectedInnerPersonTab === "userInformation"
                  ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                  : "",
              border:
                selectedInnerPersonTab === "userInformation" && isNightModeOn
                  ? "1px solid #fff"
                  : "",
            }}
          >
            اطلاعات هویتی
          </button>
          <button
            className={`p-2  ${isNightModeOn ? "nightBg2" : ""}`}
            onClick={() => {
              setSelectedInnerPersonTab("password");
            }}
            style={{
              boxShadow:
                selectedInnerPersonTab === "password"
                  ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                  : "",
              border:
                selectedInnerPersonTab === "password" && isNightModeOn
                  ? "1px solid #fff"
                  : "",
            }}
          >
            تغییر رمز ورود به سیستم
          </button>
        </div>
      </div>
      {/* Tab Selector  */}

      {selectedInnerPersonTab === "userInformation" ? (
        <UserPersonalInformation
          setUserDataForOverWrite={setUserDataForOverWrite}
          userDataForOverWrite={userDataForOverWrite}
        />
      ) : null}

      {selectedInnerPersonTab === "password" ? (
        <PasswordsTab
          passwordData={passwordData}
          setPasswordData={setPasswordData}
        />
      ) : null}
    </>
  );
};

export default PersonTab;
