// React
import React, { useContext, useMemo } from "react";
// React

// CSS
import styles from "./ProcessData.module.css";
// CSS

// Models
import { I_HistoryDetail } from "../../../../../../Models/interfaces";
// Models

// Contexts
import { ProcessHistoryContext } from "../../../../../../Contexts/ProcessHistoryContext/ProcessHistoryContext";
// Contexts

// Constants
import { withoutInformation } from "../TaskInformation/TaskInformation";
import Accordion from "../../../../../Accordion/Accordion";
import RowItem from "../RowItem/RowItem";
// Constants

type ProcessDataProps = {
  data: I_HistoryDetail;
};

const ProcessData: React.FunctionComponent<ProcessDataProps> = ({
  data: _data,
}) => {
  const processHistoryData = useContext(ProcessHistoryContext);

  const data: {
    title: string | number;
    value: string | number | React.ReactElement;
  }[] = useMemo(
    () =>
      processHistoryData.selectedHistoryType === "callActivity"
        ? [
            {
              title: "کدفرآیند",
              value:
                _data?.callActivityDetail?.process?.processId ||
                withoutInformation,
            },
            {
              title: "شمارنده استقرار",
              value:
                _data?.callActivityDetail?.process?.deploymentId ||
                withoutInformation,
            },
            {
              title: "نام فرآیند",
              value:
                _data?.callActivityDetail?.process?.processName ||
                withoutInformation,
            },
            // {
            //   title: "نام فایل فرآیند",
            //   value:
            //     _data?.callActivityDetail?.process?.processFileName ||
            //     withoutInformation,
            // },
            {
              title: "وضعیت دسترسی",
              value:
                _data?.callActivityDetail?.process?.accessType?.title ||
                withoutInformation,
            },
            {
              title: "وضعیت شروع",
              value: _data?.callActivityDetail?.process?.startable
                ? "قابل شروع"
                : "غیر قابل شروع",
            },
            {
              title: "وضعیت فرم شروع",
              value: _data?.callActivityDetail?.process?.hasStartingForm
                ? "دارند"
                : "ندارند",
            },
            // {
            //   title: "مشاهده فرآیند",
            //   value: (
            //     <button
            //       className="w-100 submitBtn px-2 rounded-2"
            //       onClick={() => {
            //         setProcessSelectModalData({
            //           isModalInScreen: true,
            //           processId: _data?.callActivityDetail?.instanceId || "",
            //         });
            //       }}
            //     >
            //       مشاهده فرآیند
            //     </button>
            //   ),
            // },
          ]
        : [],
    [_data, processHistoryData]
  );

  return (
    <div className={`${styles.processDataContainer} w-100`}>
      <Accordion
        topName="اطلاعات فرآیند"
        containerClassName="w-100 d-flex flex-column align-items-center mt-4"
      >
        <div
          className="w-100 d-flex flex-row align-items-center
         justify-content-center flex-wrap py-2
         gap-2
         "
        >
          {data.map((item, index) => (
            <RowItem data={item} key={item.title} isOdd />
          ))}
        </div>
      </Accordion>
    </div>
  );
};

export default ProcessData;
