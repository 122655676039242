// Axios
import { _axios } from "../../Base_Axios_Config/_axios";
import { AxiosResponse } from "axios";
// Axios

// Models
import { I_SendingDataForGettingFormsSample } from "../../../Models/sendingDataSchema";
import {
  I_FormSamplesRecevedData,
  I_Message,
} from "../../../Models/interfaces";
// Models

export const getAllFormSamplesService = async (_data: {
  userToken: string;
  _data: I_SendingDataForGettingFormsSample;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_FormSamplesRecevedData;
  }>
> => {
  return _axios.get(
    `/form-samples?page=${_data._data.page}&size=${_data._data.size}&type=${_data._data.type}&search=${_data._data.search}&tenant=${_data._data.tenant}&id=${_data._data.id}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
