import { JalaliDate } from "./toGeorgian";

export function convertToGeorgian(value) {
  const _JalaliDate = JalaliDate;
  const myDate = value.split("/").join("-"),
    dateSplitted = myDate.split("-"),
    jD = _JalaliDate.jalaliToGregorian(
      dateSplitted[0],
      dateSplitted[1],
      dateSplitted[2]
    );

  return `${jD[0]}-${jD[1]}-${jD[2]}`;
}
