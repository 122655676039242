// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./Groups.module.css";
// CSS

// Components
import AllGroupsTab from "./AllGroupsTab/AllGroupsTab";
import IndirectMembership from "./IndirectMembership/IndirectMembership";
import GroupOnGroupAccess from "./GroupOnGroupAccess/GroupOnGroupAccess";
import EditableGroupTab from "./EditableGroupTab/EditableGroupTab";
// Components

// Redux
import { searchAndBackSlice } from "../../Features/SearchAndBackSlice/searchAndBackSlice";
import { useAppDispatch } from "../../app/store";
// Redux

// Models
import { permitionCheckerWithoutToast } from "../../Modules/permitionCheckerWithoutToast";
// Models

// Contexts
import { UserGroupsContext } from "../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

const Groups = () => {
  const dispatch = useAppDispatch();
  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightModeOn = useContext(IsNightModeOnContext);

  const [currentTab, setCurrentTab] = useState<
    "allGroup" | "indirectMembership" | "groupOnGroupAccess" | "editableGroups"
  >("allGroup");

  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("فرآیند ها"));
  }, [dispatch]);

  return (
    <div
      className={`${styles.groupsPageContainer} w-100 d-flex flex-column align-items-center`}
    >
      <div
        className={`${styles.tabsContainer} ${
          isNightModeOn ? "nightBg2" : ""
        } d-flex flex-row flex-row-reverse align-items-center justify-content-center mb-2  flex-wrap `}
        style={{}}
      >
        <button
          className={`${isNightModeOn ? "nightBg1" : ""}`}
          onClick={() => {
            setCurrentTab("allGroup");
          }}
          style={{
            boxShadow:
              currentTab === "allGroup"
                ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                : "",
            padding: "8px 24px",
            border:
              currentTab === "allGroup" && isNightModeOn
                ? "1px solid #fff"
                : "",
          }}
          data-tooltip-id="my-tooltip"
          data-tooltip-content={"All Groups"}
        >
          نمایش کل گروه ها
        </button>

        {permitionCheckerWithoutToast(
          allGroups,
          [
            "Tenant_Full_Manager",
            "Tenant_Basic_Manager",
            "All_Group_Manager",
            "Public_Group_Manager",
          ],
          isFullRenderd
        ) && (
          <button
            className={`${isNightModeOn ? "nightBg1" : ""}`}
            onClick={() => {
              setCurrentTab("indirectMembership");
            }}
            style={{
              boxShadow:
                currentTab === "indirectMembership"
                  ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                  : "",
              padding: "8px 24px",
              border:
                currentTab === "indirectMembership" && isNightModeOn
                  ? "1px solid #fff"
                  : "",
            }}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={"Indirect Memberships"}
          >
            دسترسی غیر مستقیم
          </button>
        )}

        {(allGroups.includes("Tenant_Full_Manager") &&
          allGroups.includes("Tenant_Basic_Manager")) ||
        allGroups.includes("Tenant_Full_Manager") ||
        allGroups.includes("Tenant_Basic_Manager") ? (
          <button
            className={`${isNightModeOn ? "nightBg1" : ""}`}
            onClick={() => {
              setCurrentTab("groupOnGroupAccess");
            }}
            style={{
              boxShadow:
                currentTab === "groupOnGroupAccess"
                  ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                  : "",
              padding: "8px 24px",
              border:
                currentTab === "groupOnGroupAccess" && isNightModeOn
                  ? "1px solid #fff"
                  : "",
            }}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={"Group On Group Access"}
          >
            گروه در گروه
          </button>
        ) : null}

        {permitionCheckerWithoutToast(
          allGroups,
          [
            "Tenant_Full_Manager",
            "Tenant_Basic_Manager",
            "All_Group_Manager",
            "Public_Group_Manager",
          ],
          isFullRenderd
        ) && (
          <button
            className={`${isNightModeOn ? "nightBg1" : ""}`}
            onClick={() => {
              setCurrentTab("editableGroups");
            }}
            style={{
              boxShadow:
                currentTab === "editableGroups"
                  ? "0px 2px 6px 0px rgba(0, 0, 0, 0.25)"
                  : "",
              padding: "8px 24px",
              border:
                currentTab === "editableGroups" && isNightModeOn
                  ? "1px solid #fff"
                  : "",
            }}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={"Editable Groups"}
          >
            گروه های قابل ویرایش
          </button>
        )}
      </div>

      {currentTab === "allGroup" ? <AllGroupsTab /> : null}
      {currentTab === "indirectMembership" ? <IndirectMembership /> : null}
      {currentTab === "groupOnGroupAccess" ? <GroupOnGroupAccess /> : null}
      {currentTab === "editableGroups" ? <EditableGroupTab /> : null}
    </div>
  );
};

export default Groups;
