import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
import { I_Message } from "../../../Models/interfaces";

export const revertReportStateService = async (
  userToken: string,
  processKey: string,
  reportId: string
): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {};
  }>
> => {
  return _axios.post(
    `/processes/${processKey}/reports/${reportId}/revert-readonly`,
    {},
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
