// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
// React

// CSS
import styles from "./HistoryTab.module.css";
// CSS

// Icons
// Icons

// Redux
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import {
  getAsyncActivityInformation,
  processInstanceHistorySlice,
} from "../../../../Features/ProcessesSlice/ProcessInstanceHistorySlice/processInstanceHistorySlice";
// Redux

// Utils
import Pagination from "../../../../Utils/Pagination/Pagination";
import PendingManager from "../../../PendingManager/PendingManager";
import { copyToClipboard } from "../../../../Utils/copyToClipboard";
import CustomInput1 from "../../../../Utils/CustomInput1/CustomInput1";
// Utils

// Components
// Components

// Contexts
import { IsNightModeOnContext } from "../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import {
  initialProcessHistoryContextData,
  ProcessHistoryContext,
  SetProcessHistoryContext,
  T_HistoryType,
} from "../../../../Contexts/ProcessHistoryContext/ProcessHistoryContext";
// Contexts

// Models
import { I_ActivityInformation } from "../../../../Models/interfaces";
// Models

// Modules
import { T_TableTab, tabs } from "../StepsAndHistoryViewer";
// Modules

type HistoryTabProps = {
  instanceId: string;
  setSelectedStep: React.Dispatch<React.SetStateAction<string>>;
  selectedStep: string;
  setExtraChild: React.Dispatch<React.SetStateAction<React.ReactElement>>;
  setSelectedTab: React.Dispatch<React.SetStateAction<T_TableTab>>;
};

const HistoryTab: React.FunctionComponent<HistoryTabProps> = ({
  instanceId,
  selectedStep,
  setSelectedStep,
  setExtraChild,
  setSelectedTab,
}) => {
  const dispatch = useAppDispatch();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const [paginationData, setPaginationData] = useState<{
    page: number;
    size: number;
    search: string;
  }>({
    page: 1,
    search: "",
    size: 5,
  });

  const { data, isDone, isError, isPending } = useAppSelector(
    (state) => state.processInstanceHistorySlice.activityInformation
  );

  const isNightOn = useContext(IsNightModeOnContext);

  const { _stepNumber } = useContext(ProcessHistoryContext);

  const setProcessHistoryData = useContext(SetProcessHistoryContext);

  const dataGetter = useCallback(() => {
    const stepNumber = _stepNumber;

    dispatch(
      getAsyncActivityInformation({
        userToken,
        _data: {
          processInstanceId: instanceId,
          ...paginationData,
          step: stepNumber,
          onDone: (_data: any) => {
            const data = _data as I_ActivityInformation;
            setProcessHistoryData((prevState) => ({
              ...prevState,
              allHistory: data.content,
            }));
            if (data.content.length === 1) {
              const selectedItem = data.content[0];
              const selectedStep = selectedItem.id?.split(":")[0];
              setSelectedStep(selectedStep);
              setProcessHistoryData((prevState) => ({
                ...initialProcessHistoryContextData,
                ...prevState,
                allHistory: data.content,
                selectedHistoryId: selectedItem.id,
                selectedHistoryType: selectedItem.activityType
                  .value as T_HistoryType,
                signalToChangeTab: false,
              }));
              setSelectedTab(tabs.find((_i) => _i.name === "History Detail")!);
              // customizedToast(
              //   "فقط 1 تاریخچه موجود است و شما به سمت آن هدایت میشوید",
              //   "info"
              // );
            }
          },
        },
      })
    );
  }, [
    dispatch,
    userToken,
    instanceId,
    paginationData,
    setSelectedStep,
    setProcessHistoryData,
    setSelectedTab,
    _stepNumber,
  ]);

  const memoInput = useMemo(
    () => (
      <CustomInput1
        defaultValue=""
        inputType="text"
        onBlur={() => {}}
        onChange={(e) => {
          setPaginationData((prevState) => ({
            ...prevState,
            search: e.target.value,
          }));
        }}
        placeHolder="جستجوی تاریخچه"
        value={paginationData.search}
      />
    ),
    [paginationData.search]
  );

  useEffect(() => {
    setExtraChild(memoInput);
  }, [setExtraChild, memoInput]);

  useEffect(() => {
    const timeOut = setTimeout(dataGetter, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dataGetter]);

  useEffect(() => {
    return () => {
      dispatch(
        processInstanceHistorySlice.actions.resetActivityInformationPending()
      );
      setExtraChild(<></>);
    };
  }, [dispatch, setExtraChild]);

  return (
    <div className={`${styles.historyTabContainer} w-100`}>
      <PendingManager
        isDone={isDone}
        isError={isError}
        isPending={isPending}
        onError={dataGetter}
        showAfterDone={
          <>
            <div
              className={`${styles.myTable} ${
                isNightOn ? "nightBg2 pcNight" : ""
              }  w-100 d-flex mt-3 flex-column align-items-center align-items-center`}
            >
              <div
                className={`${styles.tableHead} w-100 d-flex flex-row-reverse align-items-center justify-content-between align-items-center`}
              >
                <p className={`${styles.stepIndex}`}>شماره مرحله</p>
                <p className={`${styles.stepName}`}>نام مرحله</p>
                <p className={`${styles.activityType}`}>نوع فعالیت</p>
                <p className={`${styles.schemaId}`}>کد ساختار</p>
                <p className={`${styles.status}`}>وضعیت </p>
              </div>
              <div
                className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
              >
                {data.content.length === 0 ? (
                  <p className="w-100 d-flex flex-row align-items-center p-0 m-0 mt-2 justify-content-center">
                    موردی وجود ندارد
                  </p>
                ) : (
                  <>
                    {data.content.map((item) => (
                      <div
                        key={item.id}
                        className={`${styles.tableData} w-100 d-flex ${
                          item.id?.split(":")[0]?.length > 1 ? "pointer" : ""
                        } flex-row-reverse ${
                          // selectedStep === item.id?.split(":")[0]
                          //   ? styles.activeStep
                          //   : ""
                          ""
                        }
                             align-items-center justify-content-between align-items-center`}
                        onClick={() => {
                          const selectedStep = item.id?.split(":")[0];
                          setSelectedStep(selectedStep);
                          setProcessHistoryData((prevState) => ({
                            ...prevState,
                            selectedHistoryId: item.id,
                            selectedHistoryType: item.activityType
                              .value as T_HistoryType,
                          }));
                          setSelectedTab(
                            tabs.find((_i) => _i.name === "History Detail")!
                          );
                        }}
                      >
                        <p className={`${styles.stepIndex}`}>
                          {item.stepNumber}
                        </p>
                        <p className={`${styles.stepName}`}>{item.title}</p>
                        <p className={`${styles.activityType}`}>
                          {item?.activityType?.title}
                        </p>
                        <p
                          className={`${styles.schemaId}`}
                          onDoubleClick={() => {
                            copyToClipboard(item.schemaId);
                          }}
                        >
                          {item?.schemaId}
                        </p>
                        <p className={`${styles.status}`}>
                          {item.finished ? "خاتمه یافته" : "درحال اجرا"}
                        </p>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className={`${styles.tableFooter}`}></div>
            </div>
            <Pagination
              dataForPagination={{
                currPage: paginationData.page,
                size: paginationData.size,
                totalPages: data.totalPages,
              }}
              onPageNumberChange={(page) => {
                setPaginationData((prevState) => ({
                  ...prevState,
                  page,
                }));
              }}
              onPageNumbersClick={(page) => {
                setPaginationData((prevState) => ({
                  ...prevState,
                  page,
                }));
              }}
              onPageSizeChange={(size) => {
                setPaginationData((prevState) => ({
                  ...prevState,
                  page: 1,
                  size,
                }));
              }}
              contaienrClassName="w-100"
              search=""
            />
          </>
        }
      />
    </div>
  );
};

export default HistoryTab;
