// React
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
// React

// CSS
import styles from "./Login.module.css";
// CSS

// Redux + Deps
import { useAppDispatch, useAppSelector } from "../../app/store";
import { postAsyncLogin } from "../../Features/LoginSlice/loginSlice";
import { getAsyncAllPostsData } from "../../Features/PostsSlice/postsSlice";
// Redux + Deps

// Modules
import { useNavigate, useSearchParams } from "react-router-dom";
// Modules

// Utils
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { Spinner } from "reactstrap";
import CustomInput1 from "../../Utils/CustomInput1/CustomInput1";
import HTMLReactParser from "html-react-parser";
// Utils

// Icons
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import ToggleBtn from "../../Utils/ToggleBtn/ToggleBtn";
import { GoEye } from "react-icons/go";

// Icons

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FaEyeSlash } from "react-icons/fa6";
import { getAsyncUserData } from "../../Features/UserInformationSlice/userInformationSlice";
import Pending from "../../Components/Pending/Pending";
import { isCookieMode } from "../../Services/Base_Axios_Config/_axios";
// Swiper

const Login = () => {
  const [currMode] = useState<"login" | "resetPassword">("login");
  const [rememberMe, setRememberMe] = useState<boolean>(true);

  const [isUserCheckingPending, setIsUserCheckingPending] =
    useState<boolean>(true);

  const [canShowPassword, setCanShowPassword] = useState<boolean>(false);

  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const { login, userName, password, userNamelengthError } = useAppSelector(
    (state) => state.currLanguage.language
  );

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  // local State
  const [formData, setFormData] = useState<{
    userName: string;
    password: string;
  }>({ userName: "", password: "" });
  // local State

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { accessToken, refreshToken, isPending } = useAppSelector(
    (state) => state.loginData
  );

  const { postsData } = useAppSelector((state) => state.posts);

  const [searchParams] = useSearchParams();

  useLayoutEffect(() => {
    setIsUserCheckingPending(true);
    dispatch(
      getAsyncUserData({
        userToken,
        onDone: () => {
          window.location.href = window.location.origin;
        },
        onError: () => {
          console.log("Try To Auth");
          setIsUserCheckingPending(false);
        },
        disableError: true,
      })
    );
  }, [dispatch, userToken, navigate]);

  //   LifeCycles
  useEffect(() => {
    if (
      (accessToken !== "init" || refreshToken !== "init") &&
      isCookieMode === false
    ) {
      if (typeof searchParams.get("returnUrl") !== "string") return;
      if (searchParams.get("returnUrl")?.startsWith("login")) return;

      navigate(String(searchParams.get("returnUrl")));
    }
  }, [accessToken, refreshToken, navigate, searchParams]);
  //
  useEffect(() => {
    if (!submitBtnRef.current) return;
    const eventFunction = (e: any) => {
      if (e.key !== "Enter") return;
      if (!submitBtnRef.current) return;
      submitBtnRef.current.click();
    };

    document.addEventListener("keypress", eventFunction);
    return () => {
      document.removeEventListener("keypress", eventFunction);
    };
  }, []);
  //
  useEffect(() => {
    dispatch(
      getAsyncAllPostsData({
        userToken: "",
        _data: {
          active: true,
          page: 1,
          placement: "LOGIN_PAGE",
          search: "",
          size: 999,
          tenant: "",
        },
        disableError: true,
      })
    );
  }, [dispatch]);
  //   LifeCycles

  return isUserCheckingPending ? (
    <Pending className="mt-4" />
  ) : (
    <div className={`${styles.loginPageContainer} `}>
      <div
        className={`${styles.postAndImageContainer} d-flex flex-row align-items-center justify-content-center`}
      >
        {postsData.isPending && !postsData.isError ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="text-white">در حال بارگذاری</p>
            <Spinner />
          </div>
        ) : null}
        {!postsData.isPending && postsData.isError ? (
          <div
            onClick={() => {
              dispatch(
                getAsyncAllPostsData({
                  userToken: "",
                  _data: {
                    active: true,
                    page: 1,
                    placement: "LOGIN_PAGE",
                    search: "",
                    size: 999,
                    tenant: "",
                  },
                })
              );
            }}
          >
            مشکلی پیش آمده
          </div>
        ) : null}

        {!postsData.isError &&
        !postsData.isPending &&
        postsData.data.content.length !== 0 ? (
          <>
            <MdNavigateBefore id="prevBtn" />
            <div className={`${styles.postContainer} `}>
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                initialSlide={0}
                className=""
                direction="horizontal"
                speed={500}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                loop
                simulateTouch
                modules={[
                  Mousewheel,
                  Keyboard,
                  Autoplay,
                  Pagination,
                  Navigation,
                ]}
                pagination={{
                  clickable: true,
                  el: `.${styles.swiperPagination}`,
                  bulletActiveClass: styles.activePostBtn,
                  bulletClass: styles.notActivePostBtn,
                }}
                navigation={{
                  nextEl: "#nextBtn",
                  prevEl: "#prevBtn",
                }}
              >
                {postsData.data.content.map((item) => (
                  <SwiperSlide
                    key={item.id}
                    className={`${styles.loginPostContainer}`}
                  >
                    <div className={`${styles.loginPost}`}>
                      {HTMLReactParser(item.data)}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div
                className={`${styles.swiperPagination} d-flex flex-row align-items-center justify-content-center`}
              ></div>
            </div>
            <MdNavigateNext id="nextBtn" />
          </>
        ) : null}
      </div>
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      <div
        className={`${styles.formAndPasswordResetContainer} d-flex flex-column align-items-center justify-content-center`}
      >
        <h1 className={`${styles.wellComeText}`}>خوش آمدید</h1>
        <p className={`${styles.systemNameContainer}`}>
          به سیستم مدیریت فرآیند سمفونا خوش آمدید.
        </p>

        {currMode === "login" ? (
          <div className={`${styles.formContainer}`}>
            <p
              className="w-100 d-flex align-items-center justify-content-center"
              style={{
                fontSize: "30px",
                color: "#172B4D",
              }}
              onClick={() => {
                // const data = Cookie.get();
                // console.log(Cookie);
              }}
            >
              وارد شوید
            </p>
            <p
              className="w-100 d-flex align-items-center justify-content-center"
              style={{
                color: "#8392AB",
              }}
            >
              برای ورود نام کاربری و رمز عبورتان را وارد کنید
            </p>

            <div
              className={`${styles.formInputsContainer} w-100 d-flex flex-column align-items-center justify-content-center `}
              style={{
                marginTop: "24px",
              }}
            >
              <CustomInput1
                defaultValue={""}
                value={formData.userName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    userName: String(e.target.value),
                  }));
                }}
                onBlur={() => {}}
                inputType="text"
                placeHolder={userName}
                //  keyForShowOnTop={userName}
                dontShowSearchIcon
                inputStyle={{
                  border: "1px solid #E2E8F0",
                }}
                keyStyle={{
                  fontWeight: "bold",
                  color: "#172B4D",
                }}
                containerStyle={{
                  width: "100%",
                }}
              />
              <CustomInput1
                defaultValue={""}
                value={formData.password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    password: String(e.target.value),
                  }));
                }}
                onBlur={() => {}}
                inputType="password"
                placeHolder={password}
                //  keyForShowOnTop={password}
                dontShowSearchIcon
                inputStyle={{
                  border: "1px solid #E2E8F0",
                }}
                keyStyle={{
                  fontWeight: "bold",
                  color: "#172B4D",
                }}
                containerStyle={{
                  marginTop: "24px",
                  width: "100%",
                }}
                additionalElement={
                  !canShowPassword ? (
                    <GoEye
                      onClick={(e) => {
                        e.stopPropagation();
                        setCanShowPassword((prevState) => !prevState);
                      }}
                      className={styles.passwordViewer}
                    />
                  ) : (
                    <FaEyeSlash
                      onClick={(e) => {
                        e.stopPropagation();
                        setCanShowPassword((prevState) => !prevState);
                      }}
                      className={styles.passwordViewer}
                    />
                  )
                }
                refReturner={(ref) => {
                  if (!ref.current) return;
                  if (canShowPassword) {
                    ref.current.type = "text";
                  } else {
                    ref.current.type = "password";
                  }
                }}
              />
            </div>

            <div
              className={`${styles.toggleBtn}  w-100 d-flex flex-row align-items-center justify-content-start`}
              style={{
                marginTop: "1rem",
              }}
            >
              <ToggleBtn
                isChecked={rememberMe}
                onChange={(e) => {
                  setRememberMe(e.target.checked);
                }}
                toggleText="مرا بخاطر بسپار"
              />
            </div>

            <button
              ref={submitBtnRef}
              onClick={() => {
                const { userName, password } = formData;
                if (userName.trim().length < 4) {
                  customizedToast(userNamelengthError, "info");
                  return;
                }
                if (isPending) return;
                dispatch(
                  postAsyncLogin({
                    userName,
                    password,
                    cookieModeRememberMe: !rememberMe,
                    onDone: () => {
                      setTimeout(() => {
                        window.location.href = window.location.origin;
                      }, 100);
                    },
                  })
                );
              }}
            >
              {isPending ? (
                <>
                  <Spinner style={{ width: "16px", height: "16px" }} />
                </>
              ) : (
                <>{login}</>
              )}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Login;
