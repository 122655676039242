// React
import React, { useCallback, useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./HomePageAllocatedProcesses.module.css";
// CSS

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard, Mousewheel, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// Swiper

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  allocatedProcessesSlice,
  getAsyncAllocatedProcesses,
  startAsyncNewAllocatedProcessInstance,
} from "../../../Features/AllocatedProcessesSlice/allocatedProcessesSlice";
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
// Redux

// Contexts
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Modules
import { useNavigate } from "react-router-dom";
// Modules

// Modules
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
// Utils

// Modals
import StartAllocatedProcessModal from "../../AllocatedProcesses/StartAllocatedProcessModal/StartAllocatedProcessModal";
import { Spinner } from "reactstrap";
import Swal from "sweetalert2";
// Modals

type HomePageAllocatedProcessesProps = {
  containerClassName: string;
};

const HomePageAllocatedProcesses: React.FunctionComponent<
  HomePageAllocatedProcessesProps
> = ({ containerClassName }) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightModeOn = useContext(IsNightModeOnContext);

  const [isModalInScreen, setIsModalInScreen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{
    processKey: string;
    processId: string;
  }>({
    processId: "",
    processKey: "",
  });

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const { data, isPending } = useAppSelector(
    (state) => state.allocatedProcesses.allocatedProcessData
  );

  const dataGetter = useCallback(() => {
    dispatch(
      getAsyncAllocatedProcesses({
        userToken,
        _data: { page: 1, search: "", size: 999 },
      })
    );
  }, [userToken, dispatch]);

  useEffect(() => {
    if (!isFullRenderd) return;
    const timeOut = setTimeout(dataGetter, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dataGetter, isFullRenderd]);

  useEffect(() => {
    dispatch(
      searchAndBackSlice.actions.setAllocatedProcessesData({
        pageNumber: 1,
        size: 999,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(allocatedProcessesSlice.actions.resetAllocatedProcessesState());
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.homePageAllocatedProcessesContainer}
        ${containerClassName}
         `}
    >
      {isPending ? (
        <div className="w-100 d-flex flex-column align-items-center mt-2 justify-content-center">
          <p>در حال بارگیری</p>
          <Spinner />
        </div>
      ) : (
        <>
          <p className="w-100 d-flex align-items-center justify-content-end mb-4 bold">
            رویه های کاری
          </p>
          <div className={`${styles.processContainer}  w-100`}>
            <Swiper
              spaceBetween={8}
              slidesPerView={3}
              initialSlide={0}
              className=" w-100"
              direction="vertical"
              speed={500}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              loop
              simulateTouch
              modules={[Mousewheel, Keyboard, Autoplay, Navigation]}
            >
              {data.content.map((item) => (
                <SwiperSlide
                  key={item.deploymentId}
                  className={`${styles.processSlider}  w-100`}
                >
                  <div
                    className={`${styles.processSlide}  ${
                      isNightModeOn ? "nightBg2 pcNight" : ""
                    }
               w-100 d-flex flex-column align-items-center  rounded-2 p-2 justify-content-between
                
                `}
                  >
                    <div className="w-100 gap-2 d-flex flex-column align-items-center ">
                      <p
                        className="w-100 d-flex flex-row align-items-center
                 justify-content-center bold "
                      >
                        {item.processName}
                      </p>
                    </div>

                    <span
                      data-tooltip-content={"برای شروع کلیک کنید"}
                      data-tooltip-id="my-tooltip"
                    >
                      {item.startable ? (
                        <>
                          {item.hasStartingForm ? (
                            <span
                              className="operationEvent startBtn"
                              onClick={() => {
                                navigate(
                                  `/allocated-processes/start-new-instance-with-form/${item.processKey}/${item.processName}`
                                );
                              }}
                            >
                              <span className="icon-start"></span>
                            </span>
                          ) : (
                            <span
                              className="operationEvent startBtn"
                              onClick={() => {
                                Swal.fire({
                                  icon: "question",
                                  title: `فرآیند ${item.processName} شروع شود ؟`,
                                  showCancelButton: true,
                                  confirmButtonText: "بله",
                                  cancelButtonText: "خیر",
                                  customClass: isNightModeOn ? "swalNight" : "",
                                }).then(({ isConfirmed }) => {
                                  if (!isConfirmed) return;

                                  if (item.initialInformationRequired) {
                                    setIsModalInScreen(true);
                                    setModalData({
                                      processId: item.processId,
                                      processKey: item.processKey,
                                    });
                                  } else {
                                    dispatch(
                                      startAsyncNewAllocatedProcessInstance({
                                        userToken,
                                        _data: {
                                          _data: {
                                            formData: {},
                                            businessKey: `${
                                              item.processKey
                                            }_businessKey_${Date.now()}`,
                                            title: `${
                                              item.processName
                                            }_title_${Date.now()}`,
                                          },
                                          processKey: item.processKey,
                                        },
                                      })
                                    );
                                  }
                                });
                              }}
                            >
                              <span className="icon-start"></span>
                            </span>
                          )}
                        </>
                      ) : (
                        <span
                          className="operationEvent startBtn"
                          onClick={() => {
                            customizedToast("فرآیند غیر قابل شروع است", "info");
                          }}
                        >
                          <span className="icon-start"></span>
                        </span>
                      )}
                    </span>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      )}

      {isModalInScreen ? (
        <StartAllocatedProcessModal
          processKey={modalData.processKey}
          setIsModalInScreen={setIsModalInScreen}
          formData={{}}
        />
      ) : null}
    </div>
  );
};

export default HomePageAllocatedProcesses;
