// React
import React, { useCallback, useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./Process.module.css";
// CSS

// Icons
// Icons

// Redux
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  deleteAsyncSingleProcess,
  getAsyncProcessesList,
  processesSlice,
  startAsyncSingleProcessInstance,
} from "../../Features/ProcessesSlice/ProcessesSlice";
import { searchAndBackSlice } from "../../Features/SearchAndBackSlice/searchAndBackSlice";
// Redux

// Components
// Components

// Utils
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { Spinner } from "reactstrap";
import Pagination from "../../Utils/Pagination/Pagination";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import CustomInput1 from "../../Utils/CustomInput1/CustomInput1";
// Utils

// Modules
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { permissionChecker } from "../../Modules/permissionChecker";
import { permitionCheckerWithoutToast } from "../../Modules/permitionCheckerWithoutToast";
// Modules

// Icons
import { BsDashLg } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { MdDone } from "react-icons/md";
import { HiOutlineDocumentReport, HiOutlineFolderRemove } from "react-icons/hi";
// Icons

// Contexts
import { UserGroupsContext } from "../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { ExecutingPermissionsContext } from "../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Components
import ProcessStartModal from "../Forms/ProcessStartModal/ProcessStartModal";
import CustomCheckbox from "../../Utils/CustomCheckbox/CustomCheckbox";
import RemoveProcessByVersionModal from "./RemoveProcessByVersionModal/RemoveProcessByVersionModal";
import { themeColor } from "../../Constants/constants";
// Components

export type T_RemoveProcessByVersionNeededData = {
  isInView: boolean;
  processKey: string;
  deletingType: string;
  version: number;
};

const Processes = () => {
  const { processList } = useAppSelector(
    (state) => state.currLanguage.language
  );
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const processesRecevedData = useAppSelector((state) => state.processes);

  const permitions = useContext(ExecutingPermissionsContext);
  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightOn = useContext(IsNightModeOnContext);

  const currLang = useAppSelector((state) => state.currLanguage.language);

  const [isStartModalOpen, setIsStartModalOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const [searchParam, setSearchParam] = useSearchParams();

  const [formsData, setFormsData] = useState<{
    page: number;
    size: number;
    tenant: string;
    search: string;
    startable: boolean;
  }>({
    page: Number(searchParam.get("page")) || 1,
    size: Number(searchParam.get("size")) || 10,
    tenant: selectedTenant.tenantId,
    search: searchParam.get("search") || "",
    startable: searchParam.get("startable")
      ? Boolean(searchParam.get("startable") === "true") === true
      : false,
  });

  const [selectedProcessKey, setSelectedProcessKey] = useState<string>("");

  const [removeVersionModalData, setRemoveVersionModalData] =
    useState<T_RemoveProcessByVersionNeededData>({
      deletingType: "",
      isInView: false,
      processKey: "",
      version: 0,
    });

  const navigate = useNavigate();

  const dataGetter = useCallback(() => {
    dispatch(
      getAsyncProcessesList({
        _data: formsData,
        userToken,
      })
    );
  }, [dispatch, formsData, userToken]);

  // Life Cycles
  //
  useEffect(() => {
    return () => {
      dispatch(processesSlice.actions.resetPendingState());
    };
  }, [dispatch]);
  //
  useEffect(() => {
    dispatch(processesSlice.actions.resetPerviousLoadedData());
  }, [formsData.tenant, dispatch]);
  //
  useEffect(() => {
    dispatch(
      searchAndBackSlice.actions.setPorcessPageData({
        isStartable: formsData.startable,
        searchParm: formsData.search,
        tenantId: formsData.tenant,
        pageNumber: formsData.page,
        size: formsData.size,
      })
    );
  }, [dispatch, formsData]);
  //
  useEffect(() => {
    setSearchParam(
      {
        page: String(formsData.page) || 1 + "",
        size: String(formsData.size) || 10 + "",
        search: String(formsData.search) || "",
        startable: String(formsData.startable),
      },
      { replace: true }
    );
  }, [formsData, setSearchParam, searchParam]);
  //
  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("فرآیند ها"));
  }, [dispatch]);
  //
  useEffect(() => {
    if (!isFullRenderd) return;
    const timeOut = setTimeout(dataGetter, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dataGetter, isFullRenderd]);
  //
  // Life Cycles

  const csvReport = {
    headers: [
      {
        label: "شمارنده",
        key: "processId",
      },
      {
        label: "نام فرآیند",
        key: "processName",
      },
      {
        label: "کلید یکتا فرآیند",
        key: "processKey",
      },
      {
        label: "شمارنده استقرار",
        key: "deploymentId",
      },
      {
        label: "نام فایل",
        key: "processFileName",
      },
      {
        label: "دارای فرم اولیه",
        key: "hasStartingForm",
      },
    ],

    data: processesRecevedData.content.map((item, index) => ({
      processId: item.processId,
      processKey: item.processKey,
      deploymentId: item.deploymentId,
      processName: item?.processName || "بدون نام",
      processFileName: item.processFileName,
      isStartable: item.startable ? "بله" : "خیر",
      hasStartingForm: item.hasStartingForm ? "بله" : "خیر",
    })),
    filename: "users.csv",
  };

  return (
    <div
      className={`${styles.processMainContainer} ${
        isNightOn ? "nightBg1" : ""
      } w-100 d-flex flex-column `}
      style={{}}
    >
      {isStartModalOpen ? (
        <ProcessStartModal
          formsData={formsData}
          processKey={selectedProcessKey}
          setIsModalInScreen={setIsStartModalOpen}
          tenant={formsData.tenant}
          formIoData={undefined}
        />
      ) : null}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      <div
        className={`${styles.topHeader}  w-100 d-flex flex-row-reverse justify-content-between align-items-center flex-wrap`}
      >
        <p
          className={`${styles.processListName}`}
          style={{
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {processList}
        </p>

        <div className="d-flex align-items-center">
          {permitionCheckerWithoutToast(
            allGroups,
            [
              "Tenant_Full_Manager",
              "Tenant_Basic_Manager",
              "Process_Full_Manager",
              "Process_Editor",
            ],
            isFullRenderd
          ) && (
            <button
              className={`operationEvent submitBtn d-flex align-items-center justify-content-center flex-row-reverse`}
              onClick={() => {
                navigate(`/processes/new-process`);
              }}
              style={{
                padding: "6px 10px",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              <span className="icon-Plus" style={{ marginLeft: "6px" }}></span>
              افزودن فرآیند
            </button>
          )}

          <CSVLink {...csvReport} className={"operationEvent csvBtn"}>
            csv خروجی
          </CSVLink>
        </div>
      </div>
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      <div
        className={`w-100 d-flex mt-3 align-items-center flex-wrap w-100 justify-content-between ${styles.processInfoGetter}`}
      >
        <CustomCheckbox
          onChange={(e) => {
            e.preventDefault();
            setFormsData((prevState) => ({
              ...prevState,
              startable: !prevState.startable,
            }));
          }}
          onClick={(e) => {
            e.preventDefault();
            setFormsData((prevState) => ({
              ...prevState,
              startable: !prevState.startable,
            }));
          }}
          title="فقط موارد قابل شروع"
          isChecked={formsData.startable}
          containerClassName={`  d-flex justify-between ${
            window.innerWidth > 1000 ? "" : "w-100 mt-4"
          }`}
          titleClassName={"mb-0"}
        />

        {permitionCheckerWithoutToast(
          allGroups,
          permitions.canSeeTenantTextBox,
          isFullRenderd
        ) && (
          <CustomInput1
            placeHolder="نام سازمان"
            onBlur={(e) => {
              return false;
            }}
            inputType="text"
            onChange={() => {
              return false;
            }}
            //  keyForShowOnTop="نام سازمان"
            containerStyle={{
              width: "30%",
            }}
            value={selectedTenant.tenantTitle}
            defaultValue={selectedTenant.tenantTitle}
            dontShowSearchIcon
            disabled
          />
        )}

        <CustomInput1
          placeHolder="مقدار برای جستجو"
          onBlur={(e) => {
            return;
          }}
          defaultValue={formsData.search}
          value={formsData.search}
          inputType="text"
          onChange={(e) => {
            if (formsData.search === e.target.value) return;
            setFormsData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          //  keyForShowOnTop="مقدار برای جستجو"
          containerStyle={{
            width: "30%",
          }}
        />
      </div>
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}

      {processesRecevedData.isGettingProcessesPending ? (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-5">
          <p>در حال بارگذاری</p>
          <Spinner />
        </div>
      ) : (
        <>
          {window.innerWidth > 1001 ? (
            <>
              {/* PC */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {processesRecevedData.content.length === 0 ? (
                <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-5">
                  <p>موردی وجود ندارد ، دوباره تلاش کنید</p>
                </div>
              ) : (
                <div
                  className={`${styles.myTable}
                  ${isNightOn ? `nightBg2 pcNight` : ""}
                  w-100 d-flex flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead} ${
                      isNightOn ? `nightBg2 ` : ""
                    } w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    <p className={`${styles.hasStartingForm}`}>فرم اولیه</p>
                    <p className={`${styles.startable}`}>قابل شروع</p>
                    <p className={`${styles.processKey}`}>کلید یکتا</p>
                    <p className={`${styles.processName}`}>نام فرآیند</p>
                  </div>
                  <div
                    className={`${styles.tableBody} ${
                      isNightOn ? "nightBg2" : ""
                    } w-100 d-flex align-items-center flex-column`}
                  >
                    {processesRecevedData.content.map(
                      ({
                        deploymentId,
                        hasStartingForm,
                        processFileName,
                        processKey,
                        processName,
                        startable,
                        version,
                        initialInformationRequired,
                      }) => (
                        <div
                          key={deploymentId}
                          className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                        >
                          <div className={`${styles.operationTab}`}>
                            <div
                              className="d-flex flex-row flex-wrap align-items-center justify-content-around"
                              style={{
                                display: "grid",
                                gridTemplateRows: "auto",
                                gridTemplateColumns: "1fr 1fr 1fr",
                                rowGap: "4px",
                                columnGap: "4px",
                              }}
                            >
                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "Process_Full_Manager",
                                  "Process_Editor",
                                ],
                                isFullRenderd
                              ) && (
                                <HiOutlineFolderRemove
                                  onClick={() => {
                                    setRemoveVersionModalData((prevState) => ({
                                      ...prevState,
                                      processKey,
                                      version,
                                      isInView: true,
                                    }));
                                  }}
                                  color={themeColor}
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={currLang.deleteVersion}
                                />
                              )}
                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "Process_Full_Manager",
                                  "Running_Process_Manager",
                                ],
                                isFullRenderd
                              ) && (
                                <Link
                                  to={`/processes/view-running-instances/${processKey}/${
                                    processName || "بدون نام"
                                  }`}
                                  // onClick={() => {
                                  //   if (
                                  //     !permissionChecker(
                                  //       allGroups,
                                  //       [
                                  //         "Tenant_Full_Manager",
                                  //         "Tenant_Basic_Manager",
                                  //         "Process_Full_Manager",
                                  //       ],
                                  //       isFullRenderd
                                  //     )
                                  //   )
                                  //     return;
                                  //   if (formsData.tenant !== "") {
                                  //     return;
                                  //   }
                                  //   // navigate(
                                  //   //   `/processes/view-running-instances/${processKey}/${
                                  //   //     processName || "بدون نام"
                                  //   //   }`
                                  //   // );
                                  // }}
                                  className={` operationEvent  runningInstancesBtn`}
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={
                                    currLang.runningInstances
                                  }
                                >
                                  <span className="icon-counter"></span>
                                </Link>
                              )}

                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "Process_Full_Manager",
                                  "Process_Starter",
                                ],
                                isFullRenderd
                              ) &&
                                startable && (
                                  <button
                                    onClick={() => {
                                      console.log("start click");
                                      if (!startable) {
                                        customizedToast(
                                          "فرآیند غیر قابل شروع می باشد",
                                          "info"
                                        );
                                        return;
                                      }
                                      if (hasStartingForm) {
                                        navigate(
                                          `/processes/complete-starting-form-by-processKey/${processKey}/${
                                            processName || "بدون نام"
                                          }/${formsData.tenant}`
                                        );
                                        return;
                                      }

                                      if (initialInformationRequired) {
                                        setIsStartModalOpen(true);
                                        setSelectedProcessKey(processKey);
                                      } else {
                                        Swal.fire({
                                          icon: "question",
                                          title: `فرآیند ${processName} شروع شود ؟`,
                                          showCancelButton: true,
                                          cancelButtonText: "لغو",
                                          confirmButtonText: "تایید",
                                        }).then(({ isConfirmed }) => {
                                          if (!isConfirmed) return;
                                          customizedToast(
                                            "درخواست شروع ارسال شد",
                                            "info"
                                          );
                                          dispatch(
                                            startAsyncSingleProcessInstance({
                                              userToken,
                                              _data: {
                                                processKey,
                                                tenant: selectedTenant.tenantId,
                                                _data: {
                                                  businessKey: `${processKey}_businessKey_${Date.now()}`,
                                                  title: `${processKey}_title_${Date.now()}`,
                                                  formData: {},
                                                },
                                              },
                                            })
                                          );
                                        });
                                      }
                                    }}
                                    className={` operationEvent  startBtn`}
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content={currLang.start}
                                  >
                                    <span className="icon-start"></span>
                                  </button>
                                )}
                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "Process_Form_Editor",
                                  "Process_Full_Manager",
                                ],
                                isFullRenderd
                              ) && (
                                <Link
                                  to={`/forms/show-form-by-process-key/${processKey}/${
                                    processName || "بدون نام"
                                  }`}
                                  state={{
                                    processKey,
                                  }}
                                  className={` operationEvent  relatedForms`}
                                  onClick={() => {
                                    if (
                                      permissionChecker(
                                        allGroups,
                                        [
                                          "Tenant_Full_Manager",
                                          "Tenant_Basic_Manager",
                                          "Process_Full_Manager",
                                          "Process_Editor",
                                          "Process_Schema_Analyzer",
                                        ],
                                        isFullRenderd
                                      ) === false
                                    )
                                      return;
                                    localStorage.setItem(
                                      "processKey",
                                      JSON.stringify(processKey)
                                    );
                                  }}
                                  data-tooltip-content={currLang.forms}
                                  data-tooltip-id="my-tooltip"
                                >
                                  <span className="icon-group"></span>
                                </Link>
                              )}

                              {permissionChecker(
                                allGroups,
                                [
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "Process_Full_Manager",
                                  "Process_Editor",
                                  "Process_Schema_Analyzer",
                                ],
                                isFullRenderd
                              ) && (
                                <Link
                                  to={`/processes/show-process-by-processKey/${processKey}/${
                                    processName || "بدون نام"
                                  }`}
                                  state={{
                                    processKey,
                                  }}
                                  className={` operationEvent  viewBtn`}
                                  onClick={() => {
                                    if (!startable) {
                                      customizedToast(
                                        "فرایند قابل شروع نیست",
                                        "info"
                                      );
                                      return;
                                    }
                                    localStorage.setItem(
                                      "processKey",
                                      JSON.stringify(processKey)
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={currLang.seeDetails}
                                >
                                  <AiOutlineEye />
                                </Link>
                              )}
                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "Process_Full_Manager",
                                  "Running_Process_Task_Manager",
                                ],
                                isFullRenderd
                              ) && (
                                <Link
                                  to={`/processes/show-tasks-by-processKey/${processKey}/${
                                    processName || "بدون نام"
                                  }`}
                                  className={` operationEvent  taskBtn`}
                                  onClick={() => {
                                    if (
                                      !permissionChecker(
                                        allGroups,
                                        [
                                          "Tenant_Full_Manager",
                                          "Tenant_Basic_Manager",
                                          "Process_Full_Manager",
                                        ],
                                        isFullRenderd
                                      )
                                    )
                                      return;
                                  }}
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={currLang.seeTasks}
                                >
                                  <span className="icon-tasks"></span>
                                </Link>
                              )}
                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "Process_Full_Manager",
                                  "Process_Editor",
                                ],
                                isFullRenderd
                              ) && (
                                <Link
                                  to={`/processes/edit-process-by-processKey/${processFileName}/${processKey}`}
                                  className={`operationEvent editBtn`}
                                  onClick={() => {
                                    if (
                                      !permissionChecker(
                                        allGroups,
                                        [
                                          "Tenant_Full_Manager",
                                          "Tenant_Basic_Manager",
                                          "Process_Full_Manager",
                                          "Process_Editor",
                                        ],
                                        isFullRenderd
                                      )
                                    )
                                      return;
                                    localStorage.setItem(
                                      "fileName",
                                      JSON.stringify(processFileName)
                                    );
                                    localStorage.setItem(
                                      "processKey",
                                      JSON.stringify(processKey)
                                    );
                                    localStorage.setItem(
                                      "tenantId",
                                      JSON.stringify(formsData.tenant)
                                    );
                                    if (formsData.tenant) {
                                      navigate(
                                        `/processes/edit-process-by-processKey/${processFileName}/${processKey}/${formsData.tenant}`
                                      );
                                      return;
                                    }
                                  }}
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={currLang.edit}
                                >
                                  <span className="icon-edit"></span>
                                </Link>
                              )}
                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "Process_Full_Manager",
                                  "Process_Editor",
                                ],
                                isFullRenderd
                              ) && (
                                <button
                                  onClick={() => {
                                    Swal.fire({
                                      icon: "question",
                                      title:
                                        "آیا از حذف فرآیند اطمینان دارید ؟",
                                      text: "این عملیات بدون بازگشت میباشد",
                                      cancelButtonText: "لغو",
                                      confirmButtonText: "پاک کن",
                                      showCancelButton: true,
                                      customClass: isNightOn ? "swalNight" : "",
                                    }).then(({ isConfirmed }) => {
                                      if (isConfirmed === false) return;
                                      if (
                                        !permissionChecker(
                                          allGroups,
                                          [
                                            "Tenant_Full_Manager",
                                            "Tenant_Basic_Manager",
                                            "Process_Full_Manager",
                                            "Process_Editor",
                                          ],
                                          isFullRenderd
                                        )
                                      )
                                        return;
                                      customizedToast(
                                        "درخواست حذف ارسال شد ",
                                        "info"
                                      );
                                      dispatch(
                                        deleteAsyncSingleProcess({
                                          userToken,
                                          processKey,
                                          tenantId: formsData.tenant,
                                        })
                                      ).then(() => {
                                        if (
                                          !permissionChecker(
                                            allGroups,
                                            [
                                              "Tenant_Full_Manager",
                                              "Tenant_Basic_Manager",
                                              "Process_Full_Manager",
                                              "Process_Staff_Editor",
                                              "Process_Editor",
                                              "Process_Starter",
                                              "Process_Schema_Analyzer",
                                              "Process_Form_Editor",
                                            ],
                                            isFullRenderd
                                          )
                                        )
                                          return;
                                        dispatch(
                                          getAsyncProcessesList({
                                            _data: formsData,
                                            userToken,
                                          })
                                        );
                                      });
                                    });
                                  }}
                                  className={` operationEvent  deleteBtn`}
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={currLang.delete}
                                >
                                  <span className="icon-trash"></span>
                                </button>
                              )}
                              {permitionCheckerWithoutToast(
                                allGroups,
                                [
                                  "Tenant_Full_Manager",
                                  "Tenant_Basic_Manager",
                                  "Process_Full_Manager",
                                ],
                                isFullRenderd
                              ) && (
                                <Link
                                  to={`/processes/reports/${processKey}/${
                                    processName || "بدون نام"
                                  }`}
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={currLang.viewReports}
                                  onClick={() => {
                                    if (
                                      !permitionCheckerWithoutToast(
                                        allGroups,
                                        [
                                          "Tenant_Full_Manager",
                                          "Tenant_Basic_Manager",
                                          "Process_Full_Manager",
                                        ],
                                        isFullRenderd
                                      )
                                    )
                                      return;
                                  }}
                                  className="operationEvent editBtn"
                                >
                                  <HiOutlineDocumentReport />
                                </Link>
                              )}
                            </div>
                          </div>
                          <p className={`${styles.hasStartingForm}`}>
                            {hasStartingForm ? (
                              <MdDone
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            ) : (
                              <BsDashLg
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            )}
                          </p>
                          <p className={`${styles.startable}`}>
                            {startable ? (
                              <MdDone
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            ) : (
                              <BsDashLg
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            )}
                          </p>
                          <p
                            className={`${styles.processKey}`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              processKey.length >= 17 ? processKey : ""
                            }
                          >
                            {processKey.length >= 17
                              ? "..." + processKey.substring(0, 16)
                              : processKey}{" "}
                          </p>
                          <p
                            className={`${styles.processName}`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              String(processName).length >= 17
                                ? processName || "بدون نام"
                                : ""
                            }
                          >
                            {String(processName).length >= 17
                              ? String(processName).substring(0, 16) + "..."
                              : String(processName)}{" "}
                          </p>
                        </div>
                      )
                    )}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              )}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/* PC */}
            </>
          ) : (
            <>
              {/* Mobile */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              <div
                className={`${styles.cards} mt-4 w-100 d-flex flex-column align-items-center`}
              >
                <>
                  {processesRecevedData.content.map(
                    ({
                      deploymentId,
                      hasStartingForm,
                      processFileName,
                      processKey,
                      processName,
                      startable,
                    }) => (
                      <div key={deploymentId} className="w-100">
                        <div
                          className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                        >
                          <p>{processName || "بدون نام"}</p>
                          <p>نام فرآیند </p>
                        </div>
                        <div
                          className={`${styles.tableRow} ${
                            isNightOn ? "nightBg2" : ""
                          } w-100 d-flex align-items-center justify-content-between `}
                        >
                          <p>
                            <span>دارای فرم اولیه</span>
                            <span>
                              {hasStartingForm ? (
                                <MdDone
                                  style={{
                                    fontSize: "2rem",
                                  }}
                                />
                              ) : (
                                <BsDashLg
                                  style={{
                                    fontSize: "2rem",
                                  }}
                                />
                              )}
                            </span>
                          </p>
                          <p>
                            <span>قابل شروع</span>
                            <span>
                              {startable ? (
                                <MdDone
                                  style={{
                                    fontSize: "2rem",
                                  }}
                                />
                              ) : (
                                <BsDashLg
                                  style={{
                                    fontSize: "2rem",
                                  }}
                                />
                              )}
                            </span>
                          </p>
                          <p className="w-100 d-flex flex-row align-items-center justify-content-between">
                            <span>{processKey}</span>
                            <span> کلید یکتا</span>
                          </p>

                          <div
                            className="d-flex flex-row flex-wrap align-items-center justify-content-around"
                            style={{
                              display: "grid",
                              gridTemplateRows: "auto",
                              gridTemplateColumns: "1fr 1fr 1fr",
                              rowGap: "4px",
                              columnGap: "4px",
                            }}
                          >
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Process_Full_Manager",
                              ],
                              isFullRenderd
                            ) && (
                              <button
                                onClick={() => {
                                  if (
                                    !permissionChecker(
                                      allGroups,
                                      [
                                        "Tenant_Full_Manager",
                                        "Tenant_Basic_Manager",
                                        "Process_Full_Manager",
                                        "Running_Process_Manager",
                                      ],
                                      isFullRenderd
                                    )
                                  )
                                    return;
                                  if (formsData.tenant !== "") {
                                    navigate(
                                      `/processes/view-running-instances/${processKey}/${
                                        processName || "بدون نام"
                                      }/${formsData.tenant}`
                                    );
                                    return;
                                  }
                                  navigate(
                                    `/processes/view-running-instances/${processKey}/${
                                      processName || "بدون نام"
                                    }`
                                  );
                                }}
                                className={` operationEvent  runningInstancesBtn`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={currLang.runningInstances}
                              >
                                <span className="icon-counter"></span>
                              </button>
                            )}
                            {/* <button
                            className={` operationEvent  xmlBtn`}
                            onClick={() => {
                              dispatch(
                                getAsyncProcessXML({
                                  processKey,
                                  userToken,
                                  tenantId: formsData.tenant,
                                  onDone: (data) => {
                                    downloadAsXml([data]);
                                    downloadAsBpmnFile([data]);
                                  },
                                })
                              ).then(() => {
                                dispatch(processesSlice.actions.resetXmlData());
                              });
                            }}
                          >
                            <span
                              className="icon-xml"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="xml"
                            ></span>
                          </button> */}
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Process_Full_Manager",
                                "Process_Starter",
                              ],
                              isFullRenderd
                            ) &&
                              startable && (
                                <button
                                  onClick={() => {
                                    console.log("start click");
                                    // Swal.fire({
                                    //   icon: "question",
                                    //   title:
                                    //     "آیا از شروع کردن فرآیند اطمینان دارید ؟",
                                    //   text: "عملیات غیر قابل بازگشت میباشد",
                                    //   showCancelButton: true,
                                    //   cancelButtonText: "لغو",
                                    //   confirmButtonText: "شروع",
                                    // }).then(({ isConfirmed }) => {
                                    //   if (!isConfirmed) return;

                                    // });

                                    if (!startable) {
                                      customizedToast(
                                        "فرآیند غیر قابل شروع می باشد",
                                        "info"
                                      );
                                      return;
                                    }
                                    if (hasStartingForm) {
                                      navigate(
                                        `/processes/complete-starting-form-by-processKey/${processKey}/${
                                          processName || "بدون نام"
                                        }/${formsData.tenant}`
                                      );
                                      return;
                                    }

                                    setIsStartModalOpen(true);
                                    setSelectedProcessKey(processKey);
                                  }}
                                  className={` operationEvent  startBtn`}
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={currLang.start}
                                >
                                  <span className="icon-start"></span>
                                </button>
                              )}
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Process_Form_Editor",
                                "Process_Full_Manager",
                              ],
                              isFullRenderd
                            ) && (
                              <button
                                className={` operationEvent  relatedForms`}
                                onClick={() => {
                                  if (
                                    permissionChecker(
                                      allGroups,
                                      [
                                        "Tenant_Full_Manager",
                                        "Tenant_Basic_Manager",
                                        "Process_Form_Editor",
                                        "Process_Full_Manager",
                                      ],
                                      isFullRenderd
                                    ) === false
                                  )
                                    return;
                                  localStorage.setItem(
                                    "processKey",
                                    JSON.stringify(processKey)
                                  );
                                  navigate(
                                    `/forms/show-form-by-process-key/${processKey}/${
                                      processName || "بدون نام"
                                    }`,
                                    {
                                      state: {
                                        processKey,
                                      },
                                    }
                                  );
                                }}
                                data-tooltip-content={currLang.forms}
                                data-tooltip-id="my-tooltip"
                              >
                                <span className="icon-group"></span>
                              </button>
                            )}
                            <button
                              className={` operationEvent  viewBtn`}
                              onClick={() => {
                                if (!startable) {
                                  customizedToast(
                                    "فرایند قابل شروع نیست",
                                    "info"
                                  );
                                  return;
                                }

                                localStorage.setItem(
                                  "processKey",
                                  JSON.stringify(processKey)
                                );
                                navigate(
                                  `/processes/show-process-by-processKey/${processKey}/${
                                    processName || "بدون نام"
                                  }/${formsData.tenant}`,
                                  {
                                    state: {
                                      processKey,
                                    },
                                  }
                                );
                              }}
                              style={{ cursor: "pointer" }}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={currLang.seeDetails}
                            >
                              <AiOutlineEye />
                            </button>
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Process_Full_Manager",
                              ],
                              isFullRenderd
                            ) && (
                              <button
                                className={` operationEvent  taskBtn`}
                                onClick={() => {
                                  if (
                                    !permissionChecker(
                                      allGroups,
                                      [
                                        "Tenant_Full_Manager",
                                        "Tenant_Basic_Manager",
                                        "Process_Full_Manager",
                                        "Running_Process_Task_Manager",
                                      ],
                                      isFullRenderd
                                    )
                                  )
                                    return;
                                  navigate(
                                    `/processes/show-tasks-by-processKey/${processKey}/${
                                      processName || "بدون نام"
                                    }`
                                  );
                                }}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={currLang.seeTasks}
                              >
                                <span className="icon-tasks"></span>
                              </button>
                            )}
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Process_Full_Manager",
                                "Process_Editor",
                              ],
                              isFullRenderd
                            ) && (
                              <button
                                className={`operationEvent editBtn`}
                                onClick={() => {
                                  if (
                                    !permissionChecker(
                                      allGroups,
                                      [
                                        "Tenant_Full_Manager",
                                        "Tenant_Basic_Manager",
                                        "Process_Full_Manager",
                                        "Process_Editor",
                                      ],
                                      isFullRenderd
                                    )
                                  )
                                    return;
                                  localStorage.setItem(
                                    "fileName",
                                    JSON.stringify(processFileName)
                                  );
                                  localStorage.setItem(
                                    "processKey",
                                    JSON.stringify(processKey)
                                  );
                                  localStorage.setItem(
                                    "tenantId",
                                    JSON.stringify(formsData.tenant)
                                  );
                                  if (formsData.tenant) {
                                    navigate(
                                      `/processes/edit-process-by-processKey/${processFileName}/${processKey}/${formsData.tenant}`
                                    );
                                    return;
                                  }
                                  navigate(
                                    `/processes/edit-process-by-processKey/${processFileName}/${processKey}`
                                  );
                                }}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={currLang.edit}
                              >
                                <span className="icon-edit"></span>
                              </button>
                            )}
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Process_Full_Manager",
                                "Process_Editor",
                              ],
                              isFullRenderd
                            ) && (
                              <button
                                onClick={() => {
                                  Swal.fire({
                                    icon: "question",
                                    title: "آیا از حذف فرآیند اطمینان دارید ؟",
                                    text: "این عملیات بدون بازگشت میباشد",
                                    cancelButtonText: "لغو",
                                    confirmButtonText: "پاک کن",
                                    showCancelButton: true,
                                    customClass: isNightOn ? "swalNight" : "",
                                  }).then(({ isConfirmed }) => {
                                    if (isConfirmed === false) return;
                                    if (
                                      !permissionChecker(
                                        allGroups,
                                        [
                                          "Tenant_Full_Manager",
                                          "Tenant_Basic_Manager",
                                          "Process_Full_Manager",
                                          "Process_Editor",
                                        ],
                                        isFullRenderd
                                      )
                                    )
                                      return;
                                    customizedToast(
                                      "درخواست حذف ارسال شد ",
                                      "info"
                                    );
                                    dispatch(
                                      deleteAsyncSingleProcess({
                                        userToken,
                                        processKey,
                                        tenantId: formsData.tenant,
                                      })
                                    ).then(() => {
                                      if (
                                        !permissionChecker(
                                          allGroups,
                                          [
                                            "Tenant_Full_Manager",
                                            "Tenant_Basic_Manager",
                                            "Process_Full_Manager",
                                            "Process_Staff_Editor",
                                            "Process_Editor",
                                            "Process_Starter",
                                            "Process_Schema_Analyzer",
                                            "Process_Form_Editor",
                                          ],
                                          isFullRenderd
                                        )
                                      )
                                        return;
                                      dispatch(
                                        getAsyncProcessesList({
                                          _data: formsData,
                                          userToken,
                                        })
                                      );
                                    });
                                  });
                                }}
                                className={` operationEvent  deleteBtn`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={currLang.delete}
                              >
                                <span className="icon-trash"></span>
                              </button>
                            )}
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Process_Full_Manager",
                              ],
                              isFullRenderd
                            ) && (
                              <button
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={currLang.viewReports}
                                onClick={() => {
                                  if (
                                    !permitionCheckerWithoutToast(
                                      allGroups,
                                      [
                                        "Tenant_Full_Manager",
                                        "Tenant_Basic_Manager",
                                        "Process_Full_Manager",
                                      ],
                                      isFullRenderd
                                    )
                                  )
                                    return;

                                  navigate(
                                    `/processes/reports/${processKey}/${
                                      processName || "بدون نام"
                                    }`
                                  );
                                }}
                                className="operationEvent editBtn"
                              >
                                <HiOutlineDocumentReport />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </>
              </div>
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/*  */}
              {/* Mobile */}
            </>
          )}
        </>
      )}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/* DATA */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}

      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}
      <Pagination
        onPageNumberChange={(page) => {
          setFormsData((prevState) => ({
            ...prevState,
            page,
          }));
        }}
        onPageSizeChange={(size) => {
          setFormsData((prevState) => ({
            ...prevState,
            size: Number(size),
          }));
        }}
        onPageNumbersClick={(page: number) => {
          setFormsData((prevState) => ({
            ...prevState,
            page,
          }));
        }}
        dataForPagination={{
          currPage: formsData.page,
          size: formsData.size,
          totalPages: processesRecevedData.totalPages,
        }}
        search={formsData.search}
      />

      {removeVersionModalData.isInView ? (
        <RemoveProcessByVersionModal
          removeVersionModalData={removeVersionModalData}
          setRemoveVersionModalData={setRemoveVersionModalData}
          onDone={dataGetter}
        />
      ) : null}
    </div>
  );
};

export default Processes;
