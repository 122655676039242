import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
import { I_Message } from "../../../Models/interfaces";

export const submitFormFromSpecificTask = async (_data: {
  userToken: string;
  taskId: string;
  formData: any;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {};
  }>
> => {
  return _axios.post(
    `/processes/tasks/${_data.taskId}/submit-form`,
    {
      formData: _data.formData,
    },
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
