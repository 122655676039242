import {
  complexPersianDatePicker,
  customEmailComponent,
  imageComponent,
  mapComponent,
  mapComponentWithInput,
  persianDatePicker,
} from "../CustomFormioComponents/Data/formioCustomComponentData";

export const formioCustomComponentAdderFunction = (
  data: any,
  setForm: React.Dispatch<React.SetStateAction<any>>,
  mapDataRef?: React.MutableRefObject<{
    lat: string | number;
    lon: string | number;
  }>
) => {
  if (data.modalType === "CUSTOM_IMAGE_COMPONENT") {
    setForm((prevState: any) => ({
      ...prevState,
      components: [
        ...prevState.components,
        imageComponent(data.data, data.className),
      ],
    }));
    return;
  }
  if (data.modalType === "CUSTOM_PERSIAN_DATE_PICKER") {
    setForm((prevState: any) => ({
      ...prevState,
      components: [...prevState.components, persianDatePicker(data.className)],
    }));
    return;
  }
  if (data.modalType === "CUSTOM_FORMIO_COMPLEX_PERSIAN_DATE_PICKER") {
    alert(JSON.stringify(data.data));
    setForm((prevState: any) => ({
      ...prevState,
      components: [
        ...prevState.components,
        complexPersianDatePicker(data.className, data.data),
      ],
    }));
    return;
  }
  if (data.modalType === "CUSTOM_EMAIL_COMPONENT") {
    setForm((prevState: any) => ({
      ...prevState,
      components: [
        ...prevState.components,
        customEmailComponent(data.className),
      ],
    }));
    return;
  }
  if (data.modalType === "CUSTOM_MAP_COMPONENT") {
    const {
      moreData: {
        mapDetails: { lat, lon },
      },
    } = data;
    let mapId: string = "";
    console.log(mapId);
    setForm((prevState: any) => ({
      ...prevState,
      components: [
        ...prevState.components,
        mapComponent(data.moreData, " ", (_mapId) => {
          mapId = _mapId;
          if (!mapDataRef) return;
          mapDataRef.current = {
            lat,
            lon,
          };
        }),
      ],
    }));
    return;
  }
  if (data.modalType === "CUSTOM_MAP_COMPONENT_WITH_INPUT") {
    setForm((prevState: any) => ({
      ...prevState,
      components: [
        ...prevState.components,
        mapComponentWithInput(data.className),
      ],
    }));
    return;
  }
};
