// Axios
import axios from "axios";
// Axios

// Urls
import { baseUrl as baseURL } from "../urls";
// Urls

export const _axios = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${JSON.parse(
      !!localStorage.getItem("accessToken") === true
        ? localStorage.getItem("accessToken")!
        : JSON.stringify(null)
    )}`,
  },
});

export const isCookieMode: boolean = true;
// window.location.hostname
//    .toLowerCase()
//   .includes("samfona");

_axios.interceptors.request.use(
  (config) => {
    if (isCookieMode) {
      const { Authorization, ...headersWithoutAuth } = config.headers as any;
      delete config.headers.Authorization;
      console.log("cookie mode ....");
      return { ...config, headers: headersWithoutAuth };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (err?.response.status === 401 || err?.response.status === 403) {
      if (isCookieMode) {
        if (!window.location.href.includes("login")) {
          window.location.href = window.location.origin + "/login";
        }
        return Promise.reject(err);
      } else {
        if (
          Boolean(localStorage.getItem("accessToken")) === false ||
          Boolean(localStorage.getItem("refreshToken")) === false
        ) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          if (!window.location.href.includes("login")) {
            window.location.href = window.location.origin + "/login";
          }
          return;
        }

        console.log("Encountered Auth error. Requesting new token...");

        try {
          const response = await axios.post(
            `${baseURL}/authorization/refresh-token`,
            {},
            {
              headers: {
                "Auth-Refresh": `Bearer ${JSON.parse(
                  localStorage.getItem("refreshToken")!
                )}`,
              },
            }
          );

          const { accessToken, refreshToken } = response.data.result;
          localStorage.setItem("accessToken", JSON.stringify(accessToken));
          localStorage.setItem("refreshToken", JSON.stringify(refreshToken));

          localStorage.setItem(
            "pathName",
            JSON.stringify(window.location.pathname)
          );

          window.location.reload();

          return _axios(err.config);
        } catch (err) {
          console.error("Failed to get a new token:", err);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          if (!window.location.href.includes("login")) {
            window.location.href = window.location.origin + "/login";
          }
        }
      }
    }

    return Promise.reject(err);
  }
);

// _axios.interceptors.response.use(
//   (response: AxiosResponse) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     if (error.response?.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       try {
//         const {
//           data: {
//             result: { accessToken, refreshToken },
//           },
//         } = await getRefreshTokenService(
//           `Bearer ${JSON.parse(localStorage.getItem("refreshToken")!)}`
//         );
//         localStorage.setItem("accessToken", JSON.stringify(accessToken));
//         localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
//         window.location.reload();
//         originalRequest.headers.Authorization = `Bearer ${accessToken}`;
//         return _axios(originalRequest);
//       } catch (refreshError) {
//         localStorage.removeItem("accessToken");
//         localStorage.removeItem("refreshToken");
//         window.location.reload();
//       }
//     }
//     return Promise.reject(error);
//   }
// );
