// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// React

// CSS
import styles from "./EditFormsByProcessKey.module.css";
// CSS

// Modules
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { permissionChecker } from "../../../Modules/permissionChecker";
// Modules

// Components
import { FormBuilder } from "@formio/react";
// Components

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  editAsyncForm,
  formsSlice,
} from "../../../Features/FormsSlice/FormsSlice";
import AddFormSampleModal from "../../FormSamples/AddNewFormSample/AddFormSampleModal/AddFormSampleModal";
import {
  formSamplesSlice,
  getAsyncFormSamples,
} from "../../../Features/FormSamples/formSamplesSlice";
import { searchAndBackSlice } from "../../../Features/SearchAndBackSlice/searchAndBackSlice";
// Redux

// Utils
import { Spinner } from "reactstrap";
import CustomFormioComponents from "../../../Utils/CustomFormioComponents/CustomFormioComponents";
import { formioCustomComponentAdderFunction } from "../../../Utils/FormioUtils/formioCustomComponentAdderFunction";
// Utils

// Data
import { formIoLanguagePack } from "../../../Language/formioLanguage";
// Data

// Contexts
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Urls
import { mainUrl } from "../../../Services/urls";
// Urls

// Map
import "leaflet/dist/leaflet.css";
import LoadFormFROMsampleOrAddToCurrentForm from "../../../Components/LoadFormFROMsampleOrAddToCurrentForm/LoadFormFROMsampleOrAddToCurrentForm";
// Map

// Hooks
import useReRenderMap from "../../../Hooks/useReRenderMap";
import { useMapRenderInLittleModal } from "../../../Hooks/MapHooks/useMapRenderInLittleModal";
import { useFormioDialogChanger } from "../../../Hooks/useFormioDialogChanger";
import { formioDialogForDatePicker } from "../../../Constants/constants";
import { resetReferenceOfObject } from "../../../Utils/resetReferenceOfObject";
import { getFormsByProcessKeyService } from "../../../Services/FormManagement/get/getFormsByProcessKeyService";
import Pending from "../../../Components/Pending/Pending";
// Hooks

const EditFormsByProcessKey = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [form, setForm] = useState<any>({});
  const [nForm, setNForm] = useState({});

  const [isPending, setIsPending] = useState<boolean>(true);
  const [isFormError, setIsFromError] = useState<boolean>(false);

  const { processKey, formId, persianProcessName } = useParams();

  const mapDataRef = useRef<{
    lat: number;
    lon: number;
  }>({
    lat: 35.7219,
    lon: 51.3347,
  });

  const { isEditFormDone } = useAppSelector((state) => state.forms);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { isFormEditPending } = useAppSelector((state) => state.forms);
  const currLanguage = useAppSelector(
    (state) => state.currLanguage.currLanguage
  );

  const [searchParams] = useSearchParams();
  const isNightModeOn = useContext(IsNightModeOnContext);
  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);

  const [modalData, setModalData] = useState<{
    selectOptionValue: string;
    formUrl: string;
  }>({
    selectOptionValue: "",
    formUrl: "",
  });
  const [forceReRender, setForceReRender] = useState<boolean>(false);

  const [isModalInScreen, setIsModalInScreen] = useState<boolean>(false);

  const [isReadInputPending, setIsReadInputPending] = useState<boolean>(false);

  const { selectedTenant } = useAppSelector((state) => state.tenants);

  const handleShow = useCallback(() => {
    if (typeof searchParams.get("id") !== "string") {
      navigate("/processes");
      return;
    }
    getFormsByProcessKeyService({
      id: String(searchParams.get("id")),
      processKey: String(processKey),
      tenant: selectedTenant.tenantId,
      userToken,
      page: 1,
      search: "",
      size: 1,
    })
      .then(({ data }) => data.result.content[0])
      .then((data) => {
        fetch(`${mainUrl}${data.url}`)
          .then((data) => data.json())
          .then((data) => {
            setForm(data);
            setNForm(data);
          })
          .catch((err) => {
            console.log(err);
            setIsFromError(true);
          })
          .finally(() => {
            setIsPending(false);
          });
      })
      .then(() => setIsPending(false))
      .catch((err) => {
        console.log(err);
        setIsFromError(true);
      })
      .finally(() => {
        setIsPending(false);
      });
    return;
  }, [searchParams, navigate, processKey, selectedTenant.tenantId, userToken]);

  useEffect(handleShow, [handleShow]);

  useEffect(() => {
    console.log(nForm);
  });

  useEffect(() => {
    if (isEditFormDone) {
      navigate(
        `/forms/show-form-by-process-key/${processKey}/${persianProcessName}`
      );
      return;
    }
    return () => {
      dispatch(formsSlice.actions.resetFormEditDone());
    };
  }, [
    dispatch,
    isEditFormDone,
    navigate,
    processKey,
    formId,
    persianProcessName,
  ]);

  useEffect(() => {
    dispatch(searchAndBackSlice.actions.changeCurrLocation("ویرایش فرم"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(formSamplesSlice.actions.resetRecevedFormSamplesState());
  }, [dispatch]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (
        !permissionChecker(
          allGroups,
          [
            "Tenant_Full_Manager",
            "Tenant_Basic_Manager",
            "Form_Sample_Manager",
          ],
          isFullRenderd
        )
      )
        return;
      dispatch(
        getAsyncFormSamples({
          userToken,
          _data: {
            page: 1,
            search: "",
            size: 999,
            type: "Form-IO",
            tenant: selectedTenant.tenantId,
            id: "",
          },
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, userToken, selectedTenant, isFullRenderd, allGroups]);

  useEffect(() => {
    if (isPending) return;
  }, [isPending]);

  const formioOptions: any = {
    language: currLanguage,
    i18n: formIoLanguagePack.i18n,
  };

  const formAttacher = useMemo(
    () => (
      <LoadFormFROMsampleOrAddToCurrentForm
        form={form}
        modalData={modalData}
        setForm={setForm}
        setIsModalInScreen={setIsModalInScreen}
        setIsReadInputPending={setIsReadInputPending}
        setModalData={setModalData}
      />
    ),
    [form, modalData]
  );

  useReRenderMap({
    forceReRender,
    form,
    isPending,
  });

  useMapRenderInLittleModal(isPending);
  useFormioDialogChanger(formioDialogForDatePicker);

  return (
    <div
      className={`${styles.editFormsContainer} ${
        isNightModeOn ? "nightBg1" : ""
      }`}
    >
      {isPending && !isFormError ? <Pending className="mt-4" /> : null}

      {!isPending && !isFormError ? (
        <div className="w-100 flex-column ">
          <div
            className="w-100 d-flex flex-row align-items-center justify-content-between"
            style={{
              paddingBottom: "2rem",
            }}
          >
            <button
              className={` operationEvent   submitBtn  mb-4`}
              onClick={() => {
                if (isFormEditPending) return;
                if (
                  permissionChecker(
                    allGroups,
                    [
                      "Tenant_Full_Manager",
                      "Tenant_Basic_Manager",
                      "Process_Form_Editor",
                      "Process_Full_Manager",
                    ],
                    isFullRenderd
                  )
                )
                  dispatch(
                    editAsyncForm({
                      userToken,
                      _data: {
                        processKey: String(
                          searchParams.get("_processKey") || ""
                        ),
                        formBody: {
                          title: searchParams.get("title") || "",
                          formData: form,
                        },
                        formId: searchParams.get("id") || "",
                      },
                    })
                  );
              }}
            >
              {isFormEditPending ? <Spinner /> : <>ثبت تغییرات</>}
            </button>

            <CustomFormioComponents
              dataSetter={(data) => {
                formioCustomComponentAdderFunction(data, setForm, mapDataRef);
              }}
              containerClassName="p16"
            />
          </div>

          {formAttacher}

          {isReadInputPending ? (
            "در حال بارگذاری فرم"
          ) : (
            <FormBuilder
              form={form}
              onUpdateComponent={(data: any) => {
                setNForm((prevState) => data);
                setForceReRender((prev) => !prev);
              }}
              onSaveComponent={(data: any) => {
                setNForm((prevState) => data);
                setForceReRender((prev) => !prev);
              }}
              onDeleteComponent={(data: any) => {
                setNForm((prevState) => data);
                setForceReRender((prev) => !prev);
              }}
              onCancelComponent={(data: any) => {
                setNForm((prevState) => data);
                setForceReRender((prev) => !prev);
              }}
              onEditComponent={(data: any) => {
                setNForm((prevState) => data);
                setForceReRender((prev) => !prev);
              }}
              options={formioOptions}
            />
          )}
        </div>
      ) : null}

      {!isPending && isFormError ? (
        <div
          className="w-100 d-flex flex-column align-items-center justify-content-between"
          onClick={handleShow}
        >
          مشکلی پیش آمده
        </div>
      ) : null}

      {isModalInScreen ? (
        <AddFormSampleModal
          formUrl={modalData.formUrl}
          setIsModalInScreen={setIsModalInScreen}
          dataSetter={(data) => {
            setForm((prevState: any) => ({
              ...prevState,
              components: [
                ...prevState.components,
                ...resetReferenceOfObject(data),
              ],
            }));
          }}
        />
      ) : null}
    </div>
  );
};

export default EditFormsByProcessKey;
