// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./Reports.module.css";
// CSS

// Models
import { I_GetAllReportsSendingData } from "../../Models/sendingDataSchema";
// Models

// Redux
import { useAppDispatch, useAppSelector } from "../../app/store";
// Redux

// Modules
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  deleteAsyncSingleReport,
  getAsyncProcessReportData,
  reportManagementSlice,
  revertAsyncReportState,
} from "../../Features/ReportManagementSlice/reportManagementSlice";
import { permissionChecker } from "../../Modules/permissionChecker";
// Modules

// Components
// Components

// Utils
import { CSVLink } from "react-csv";
import CustomInput1 from "../../Utils/CustomInput1/CustomInput1";
import { Spinner } from "reactstrap";
import Pagination from "./../../Utils/Pagination/Pagination";
import Swal from "sweetalert2";
// Utils

// Contexts
import { UserGroupsContext } from "../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { permitionCheckerWithoutToast } from "../../Modules/permitionCheckerWithoutToast";
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Icon
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import CustomCheckbox from "../../Utils/CustomCheckbox/CustomCheckbox";
// Icon

const Reports = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const reportsData = useAppSelector((state) => state.reportManagement);
  const { processKey, persianProcessName } = useParams();

  const allGroups = useContext(UserGroupsContext);
  const isFullRenderd = useContext(IsFullRenderdContext);
  const isNightOn = useContext(IsNightModeOnContext);

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const [searchParam, setSearchParam] = useSearchParams();

  const [formsData, setFormsData] = useState<I_GetAllReportsSendingData>({
    page: Number(searchParam.get("page")) || 1,
    size: Number(searchParam.get("size")) || 10,
    search: searchParam.get("search") || "",
    tenant: selectedTenant.tenantId,
  });

  const [isMounted, setIsMounted] = useState<boolean>(false);

  const csvReport = {
    headers: [
      {
        label: "شمارنده",
        key: "processId",
      },
      {
        label: "نام فرآیند",
        key: "processName",
      },
      {
        label: "کلید یکتا فرآیند",
        key: "processKey",
      },
      {
        label: "فقط خواندنی",
        key: "readonly",
      },
    ],
    data: reportsData.content.map((item) => ({
      processId: item.processKey,
      processName: persianProcessName,
      processKey: item.processKey,
      readonly: item.readonly,
    })),
    filename: "reports.csv",
  };

  //
  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;

    const timeOut = setTimeout(() => {
      if (
        !permissionChecker(
          allGroups,
          [
            "Tenant_Full_Manager",
            "Tenant_Basic_Manager",
            "Process_Full_Manager",
          ],
          isFullRenderd
        )
      )
        return;
      dispatch(
        getAsyncProcessReportData({
          _data: formsData,
          processKey: String(processKey),
          userToken,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [
    formsData,
    processKey,
    userToken,
    dispatch,
    isFullRenderd,
    allGroups,
    isMounted,
  ]);

  useEffect(() => {
    setSearchParam(
      {
        page: String(formsData.page) || 1 + "",
        size: String(formsData.size) || 10 + "",
        search: formsData.search,
      },
      {
        replace: true,
      }
    );
  }, [formsData, setSearchParam, searchParam]);

  useEffect(() => {
    return () => {
      dispatch(reportManagementSlice.actions.resetReportData());
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.reportsManagementContainer} 
    ${isNightOn ? `nightBg1` : ""}
    `}
    >
      <div
        className={`${styles.topHeader} w-100 d-flex flex-row-reverse justify-content-between align-items-center`}
      >
        <p
          className={`${styles.processListName} `}
          style={{
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          گزارشات
        </p>

        <div className="d-flex align-items-center">
          <button
            className={`operationEvent submitBtn d-flex align-items-center justify-content-center flex-row-reverse`}
            onClick={() => {
              navigate(
                `/processes/reports/new-report/${processKey}/${persianProcessName}`
              );
            }}
          >
            <span className="icon-Plus" style={{ marginLeft: "6px" }}></span>
            افزودن گزارش
          </button>

          <CSVLink {...csvReport} className={"operationEvent csvBtn"}>
            csv فایل
          </CSVLink>
        </div>
      </div>
      <div
        className={`${styles.reportsInputs} mt-2 w-100 d-flex flex-row align-items-center justify-content-end flex-wrap`}
      >
        <CustomInput1
          inputType="text"
          onBlur={(e) => {
            return false;
          }}
          onChange={() => {}}
          placeHolder="نام سازمان"
          // keyForShowOnTop="نام سازمان"
          value={selectedTenant.tenantTitle}
          defaultValue={selectedTenant.tenantTitle}
          dontShowSearchIcon
          disabled
        />
        <CustomInput1
          inputType="text"
          value={String(formsData.search)}
          defaultValue={String(formsData.search)}
          onBlur={(e) => {
            if (e.target.value === formsData.search) return;
            setFormsData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          onChange={(e) => {
            if (e.target.value === formsData.search) return;
            setFormsData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          placeHolder="مقدار برای جستجو"
          // keyForShowOnTop="مقدار برای جستجو"
        />
      </div>

      {reportsData.isPending ? (
        <div className="w-100 d-flex flex-column align-items-center justify-content-center ">
          <p>در حال بارگذاری</p>
          <Spinner />
        </div>
      ) : (
        <>
          {reportsData.content.length === 0 ? (
            <div className="w-100 d-flex align-items-center justify-content-center mt-5">
              موردی وجود ندارد
            </div>
          ) : (
            <>
              {window.innerWidth > 1000 ? (
                <div
                  className={`${styles.myTable} 
                  ${isNightOn ? `nightBg2 pcNight` : ""}
                  w-100 mt-2 d-flex flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    <p className={`${styles.inputContainer}`}>فقط خواندنی</p>
                    <p className={`${styles.persianProcessNameTab}`}>کد یکتا</p>
                    <p className={`${styles.userNameTab}`}>نام فرآیند </p>
                  </div>
                  <div
                    className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                  >
                    {reportsData.content.map((item) => (
                      <div
                        key={item.id}
                        className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                      >
                        <p className={`${styles.operationTab}`}>
                          {permitionCheckerWithoutToast(
                            allGroups,
                            [
                              "Tenant_Full_Manager",
                              "Tenant_Basic_Manager",
                              "Process_Full_Manager",
                            ],
                            isFullRenderd
                          ) && (
                            <button
                              className="operationEvent deleteBtn"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={"Delete"}
                              onClick={() => {
                                Swal.fire({
                                  icon: "info",
                                  title:
                                    "آیا قصد حذف گزارش مورد نظر را دارید ؟",
                                  text: "این عملیات غیر قابل بازگشت است",
                                  showCancelButton: true,
                                  cancelButtonText: "لغو",
                                  confirmButtonText: "تایید",
                                  customClass: isNightOn ? "swalNight" : "",
                                }).then(({ isConfirmed }) => {
                                  if (!isConfirmed) return;
                                  if (
                                    !permissionChecker(
                                      allGroups,
                                      [
                                        "Tenant_Full_Manager",
                                        "Tenant_Basic_Manager",
                                        "Process_Full_Manager",
                                      ],
                                      isFullRenderd
                                    )
                                  )
                                    return;

                                  dispatch(
                                    deleteAsyncSingleReport({
                                      userToken,
                                      processKey: item.processKey,
                                      reportId: String(item.id),
                                    })
                                  ).then(() => {
                                    dispatch(
                                      getAsyncProcessReportData({
                                        _data: formsData,
                                        processKey: String(processKey),
                                        userToken,
                                      })
                                    );
                                  });
                                });
                              }}
                            >
                              <span className="icon-trash"></span>
                            </button>
                          )}
                        </p>
                        <p className={`${styles.inputContainer}`}>
                          <CustomCheckbox
                            isChecked={item.readonly}
                            onClick={() => {
                              if (
                                !permissionChecker(
                                  allGroups,
                                  [
                                    "Tenant_Full_Manager",
                                    "Tenant_Basic_Manager",
                                    "Process_Full_Manager",
                                  ],
                                  isFullRenderd
                                )
                              )
                                return;
                              customizedToast(
                                "درخواست تغییر وضعیت ارسال شد",
                                "info"
                              );
                              setTimeout(() => {
                                dispatch(
                                  revertAsyncReportState({
                                    userToken,
                                    processKey: String(processKey),
                                    reportId: String(item.id),
                                  })
                                ).then(() => {
                                  dispatch(
                                    getAsyncProcessReportData({
                                      _data: formsData,
                                      processKey: String(processKey),
                                      userToken,
                                    })
                                  );
                                });
                              }, 500);
                            }}
                            onChange={() => {}}
                            title=""
                          />
                        </p>
                        <p
                          className={`${styles.persianProcessNameTab}`}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            item.reportIdentifier.length >= 18
                              ? item.reportIdentifier
                              : ""
                          }
                        >
                          {String(item.reportIdentifier).length >= 18
                            ? item.reportIdentifier.substring(0, 17) + "..."
                            : item.reportIdentifier}
                        </p>
                        <p
                          className={`${styles.userNameTab}`}
                          style={{
                            direction: "rtl",
                          }}
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content={
                            String(persianProcessName).length >= 18
                              ? String(persianProcessName)
                              : ""
                          }
                        >
                          {String(persianProcessName).length >= 18
                            ? String(persianProcessName).substring(0, 17) +
                              "..."
                            : String(persianProcessName)}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              ) : (
                <div
                  className={`${styles.cards} mt-4 w-100 d-flex flex-column align-items-center`}
                >
                  {reportsData.content.map((item) => (
                    <div key={item.id} className="w-100">
                      <div
                        className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>{item.id}</p>
                        <p>شمارنده</p>
                      </div>
                      <div
                        className={`${styles.tableRow} ${
                          isNightOn ? "nightBg2" : ""
                        } w-100 d-flex align-items-center justify-content-between `}
                      >
                        <p>
                          <span>فقط خواندنی</span>
                          <span>
                            <CustomCheckbox
                              isChecked={item.readonly}
                              onClick={() => {
                                if (
                                  !permissionChecker(
                                    allGroups,
                                    [
                                      "Tenant_Full_Manager",
                                      "Tenant_Basic_Manager",
                                      "Process_Full_Manager",
                                    ],
                                    isFullRenderd
                                  )
                                )
                                  return;
                                customizedToast(
                                  "درخواست تغییر وضعیت ارسال شد",
                                  "info"
                                );
                                setTimeout(() => {
                                  dispatch(
                                    revertAsyncReportState({
                                      userToken,
                                      processKey: String(processKey),
                                      reportId: String(item.id),
                                    })
                                  ).then(() => {
                                    dispatch(
                                      getAsyncProcessReportData({
                                        _data: formsData,
                                        processKey: String(processKey),
                                        userToken,
                                      })
                                    );
                                  });
                                }, 500);
                              }}
                              onChange={() => {}}
                              title=""
                            />
                          </span>
                        </p>
                        <p>
                          <span>نام فرآیند </span>
                          <span>{String(persianProcessName)}</span>
                        </p>
                        <p>
                          <span>عملیات</span>
                          {permitionCheckerWithoutToast(
                            allGroups,
                            [
                              "Tenant_Full_Manager",
                              "Tenant_Basic_Manager",
                              "Process_Full_Manager",
                            ],
                            isFullRenderd
                          ) && (
                            <button
                              className="operationEvent deleteBtn"
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={"Delete"}
                              onClick={() => {
                                Swal.fire({
                                  icon: "info",
                                  title:
                                    "آیا قصد حذف گزارش مورد نظر را دارید ؟",
                                  text: "این عملیات غیر قابل بازگشت است",
                                  showCancelButton: true,
                                  cancelButtonText: "لغو",
                                  confirmButtonText: "تایید",
                                  customClass: isNightOn ? "swalNight" : "",
                                }).then(({ isConfirmed }) => {
                                  if (!isConfirmed) return;
                                  if (
                                    !permissionChecker(
                                      allGroups,
                                      [
                                        "Tenant_Full_Manager",
                                        "Tenant_Basic_Manager",
                                        "Process_Full_Manager",
                                      ],
                                      isFullRenderd
                                    )
                                  )
                                    return;

                                  dispatch(
                                    deleteAsyncSingleReport({
                                      userToken,
                                      processKey: item.processKey,
                                      reportId: String(item.id),
                                    })
                                  ).then(() => {
                                    dispatch(
                                      getAsyncProcessReportData({
                                        _data: formsData,
                                        processKey: String(processKey),
                                        userToken,
                                      })
                                    );
                                  });
                                });
                              }}
                            >
                              <span className="icon-trash"></span>
                            </button>
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}
      <Pagination
        dataForPagination={{
          currPage: formsData.page,
          size: formsData.size,
          totalPages: reportsData.totalPages,
        }}
        onPageNumberChange={(page) => {
          setFormsData((prevState) => ({ ...prevState, page }));
        }}
        onPageNumbersClick={(page) => {
          setFormsData((prevState) => ({ ...prevState, page }));
        }}
        onPageSizeChange={(size) => {
          setFormsData((prevState) => ({ ...prevState, size }));
        }}
        search={formsData.search}
      />
    </div>
  );
};

export default Reports;
