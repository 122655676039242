// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./EditSinglePost.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  editAsyncSinglePost,
  postsSlice,
} from "../../../Features/PostsSlice/postsSlice";
// Redux

// Modules
import { useNavigate, useSearchParams } from "react-router-dom";
// Modules

// Utils
import { Spinner } from "reactstrap";
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
// Utils

// Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// Editor

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import CustomSelectOption2 from "../../../Utils/CustomSelectOption2/CustomSelectOption2";
// Contexts

// Formats
import { scriptFormat } from "../../../Formats/formats";
// Formats

// Constants
import { scriptTagNameInExportOfEditor } from "../../../Constants/constants";
// Constants

const EditSinglePost = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [formsData, setFormsData] = useState<{
    placementType: string;
    active: boolean;
    postData: string;
    id: string | number;
  }>({
    placementType: searchParams.get("placementType") || "LOGIN_PAGE",
    active: Boolean(searchParams.get("active") === "true"),
    postData: JSON.parse(searchParams.get("data") || JSON.stringify("")),
    id: searchParams.get("id") || "",
  });

  const isNightModeOn = useContext(IsNightModeOnContext);

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { selectedTenant } = useAppSelector((state) => state.tenants);
  const { postPlacementTypes } = useAppSelector((state) => state.allRoles.data);

  const { isPending, isDone } = useAppSelector(
    (state) => state.posts.editingSinglePost
  );

  useEffect(() => {
    if (!isDone) return;

    navigate(`/posts`);
  }, [isDone, navigate]);

  useEffect(() => {
    return () => {
      dispatch(postsSlice.actions.resetEditingPostState());
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.editPostContainer} ${
        isNightModeOn ? "nightBg1" : ""
      } w-100 d-flex flex-column align-items-center`}
    >
      <div className="w-100 d-flex flex-row align-items-center justify-content-between ">
        <button
          className="operationEvent submitBtn"
          onClick={() => {
            dispatch(
              editAsyncSinglePost({
                userToken,
                _data: {
                  forSending: {
                    active: formsData.active,
                    data: formsData.postData.replaceAll(
                      scriptFormat,
                      scriptTagNameInExportOfEditor
                    ),
                    placementType: formsData.placementType,
                  },
                  postId: formsData.id,
                },
              })
            );
          }}
        >
          {isPending ? <Spinner /> : "تایید"}
        </button>
        <p
          className=""
          style={{
            color: "#111D64",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          ویرایش اعلان
        </p>
      </div>
      <div
        className={`w-100 d-flex flex-row-reverse align-items-center justify-content-between flex-wrap mt-5`}
        style={{
          gap: "8px",
        }}
      >
        <CustomInput1
          inputType="text"
          value={selectedTenant.tenantTitle}
          defaultValue={selectedTenant.tenantTitle}
          onChange={() => false}
          onBlur={() => false}
          placeHolder="نام سازمان"
          dontShowSearchIcon
          containerStyle={{
            margin: "unset",
          }}
          disabled
        />

        <CustomSelectOption2
          getSelectedOption={(data) => {
            setFormsData((prevState) => ({
              ...prevState,
              active: data === "true" ? true : false,
            }));
          }}
          initialTitle={formsData.active ? "فعال" : "غیرفعال"}
          initialValue={formsData.active ? "true" : "false"}
          options={[
            {
              title: "فعال",
              value: "true",
            },
            {
              title: "غیر فعال",
              value: "false",
            },
          ]}
        />

        <CustomSelectOption2
          getSelectedOption={(data) => {
            setFormsData((prevState) => ({
              ...prevState,
              placementType: String(data),
            }));
          }}
          initialTitle="محل قرارگیری : صفحه لاگین"
          initialValue={"LOGIN_PAGE"}
          options={Object.keys(postPlacementTypes).map((_item) => ({
            title: postPlacementTypes[_item].title,
            value: postPlacementTypes[_item].value,
          }))}
        />
      </div>
      <ReactQuill
        theme="snow"
        value={formsData.postData}
        onChange={(value: any) =>
          setFormsData((prevState) => ({
            ...prevState,
            postData: value,
          }))
        }
        className={`${styles.quill} w-100 mt-4`}
      />
    </div>
  );
};

export default EditSinglePost;
