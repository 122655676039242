// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./AccessibleGroups.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { getAsyncAccessibleSingleUserGroup } from "../../../../Features/SingleUserAccessibleGroupSlice/singleUserAccessibleGroupSlice";
// Redux

// Utils
import { useParams } from "react-router-dom";
import Pagination from "../../../../Utils/Pagination/Pagination";
import { Spinner } from "reactstrap";
import CustomInput1 from "../../../../Utils/CustomInput1/CustomInput1";
// Utils

// Models
import { I_GetSingleUserAccessibleGroupsSendingData } from "../../../../Models/sendingDataSchema";
import { permissionChecker } from "../../../../Modules/permissionChecker";
// Models

// Modules
import { permitionCheckerWithoutToast } from "../../../../Modules/permitionCheckerWithoutToast";
// Modules

// Contexts
import { UserGroupsContext } from "../../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { ExecutingPermissionsContext } from "../../../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
import { IsNightModeOnContext } from "../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import CustomCheckbox from "../../../../Utils/CustomCheckbox/CustomCheckbox";
// Contexts

const AccessibleGroups = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const allGroups = useContext(UserGroupsContext);
  const isNightOn = useContext(IsNightModeOnContext);

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const [formsData, setFormsData] =
    useState<I_GetSingleUserAccessibleGroupsSendingData>({
      page: 1,
      size: 10,
      search: "",
      tenantId: selectedTenant.tenantId,
    });

  const singleUserAccessibleGroup = useAppSelector(
    (state) => state.singleUserAccessibleGroup
  );

  const isFullRenderd = useContext(IsFullRenderdContext);
  const permitions = useContext(ExecutingPermissionsContext);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (
        !permissionChecker(
          allGroups,
          [
            "Full_Manager",
            "Tenant_Full_Manager",
            "Tenant_Basic_Manager",
            "User_Editor",
          ],
          isFullRenderd
        )
      )
        return;
      dispatch(
        getAsyncAccessibleSingleUserGroup({
          userToken,
          username: String(params.userId),
          searchQuery: formsData,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, userToken, params.userId, formsData, allGroups, isFullRenderd]);

  return (
    <div
      className={`${styles.accessibleGroupsContainer} ${
        isNightOn ? "nightBg1" : ""
      }`}
    >
      <>
        <p
          style={{
            width: "100%",
            textAlign: "right",
            color: "#111D64",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          مشاهده اطلاعات کاربر
        </p>
        <p
          style={{
            width: "100%",
            textAlign: "right",
            color: "#8392AB",
            fontSize: "16px",
          }}
        >
          گروه هایی که کاربر دسترسی مدیریتی روی آن دارد
        </p>
      </>
      <div
        className={`${styles.formsDataContainer} w-100 d-flex flex-row flex-wrap align-items-center justify-content-between mt-4`}
      >
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={formsData.search}
          defaultValue={formsData.search}
          onChange={(e) => {
            setFormsData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          //  keyForShowOnTop=""
          containerStyle={{
            width: "45%",
          }}
          placeHolder="جستجو کنید ..."
        />
        {permitionCheckerWithoutToast(
          allGroups,
          permitions.canSeeTenantTextBox,
          isFullRenderd
        ) && (
          <CustomInput1
            inputType="text"
            onBlur={() => {
              return false;
            }}
            onChange={(e) => {
              return false;
            }}
            placeHolder="نام سازمان ..."
            //   keyForShowOnTop=""
            containerStyle={{
              width: "45%",
            }}
            value={selectedTenant.tenantTitle}
            defaultValue={selectedTenant.tenantTitle}
            dontShowSearchIcon
            disabled
          />
        )}
      </div>
      <div className={`${styles.accessibleGroupTableContainer}`}>
        {singleUserAccessibleGroup.isPending ? (
          <div className="w-100 d-flex flex-column align-items-center justify-content-center">
            <p>در حال بارگیری</p>

            <Spinner />
          </div>
        ) : (
          <>
            {singleUserAccessibleGroup.content.length === 0 ? (
              <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                مودی وجود ندارد
              </div>
            ) : (
              <div className="w-100 d-flex flex-row align-items-center justify-contetn-between">
                {singleUserAccessibleGroup.content.map((item) => (
                  <CustomCheckbox
                    isChecked
                    onChange={() => {}}
                    onClick={() => {}}
                    title={item.title}
                    key={item.value}
                  />
                ))}
              </div>
            )}
          </>
        )}
        <Pagination
          dataForPagination={{
            currPage: formsData.page,
            size: formsData.size,
            totalPages: singleUserAccessibleGroup.totalPages,
          }}
          onPageNumberChange={(page) =>
            setFormsData((prevState) => ({ ...prevState, page }))
          }
          onPageNumbersClick={(page) =>
            setFormsData((prevState) => ({ ...prevState, page }))
          }
          onPageSizeChange={(size) =>
            setFormsData((prevState) => ({ ...prevState, size }))
          }
          search={formsData.search}
        />
      </div>
    </div>
  );
};

export default AccessibleGroups;
