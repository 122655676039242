// React
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// React

type InnerRedirectorProps = {
  to: string;
};

const InnerRedirector: React.FunctionComponent<InnerRedirectorProps> = ({
  to,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, [navigate, to]);
  return <></>;
};

export default InnerRedirector;
