// Formats
import { mapFinderFormat } from "../../Formats/formats";
import { removeDuplicateItemsInArray__NO_MAIN_ARRAY_MUTATE } from "../removeDuplicateItemsInArray__NO_MAIN_ARRAY_MUTATE";
// Formats

export const mapDataExportedFromFormio = (
  data: any[] /* COMPONENTS OF FORMIO */
) => {
  const maps: any[] = [];

  if (!JSON.stringify(data)) return [];
  JSON.stringify(data).replaceAll(mapFinderFormat, (str) => {
    maps.push(str);
    return str;
  });

  // Test1
  // for (let i = 0; i <= maps.length - 1; i++) {
  //   maps[i] = maps[i] + "]}";
  // }
  // const _maps = maps.map((item) => JSON.parse(item).attrs).flat();
  // console.log(_maps);
  // Test1

  // TEST2
  // const __maps = maps.map((item) => item);
  // for (let i = 0; i <= __maps.length - 1; i++) {
  //   __maps[i] = JSON.parse(__maps[i]);
  // }
  // console.log(__maps);
  // TEST2

  // JSON.parse(maps[0]);
  console.log(
    removeDuplicateItemsInArray__NO_MAIN_ARRAY_MUTATE(maps).map((item) =>
      JSON.parse(item)
    )
  );

  return removeDuplicateItemsInArray__NO_MAIN_ARRAY_MUTATE(maps).map((item) =>
    JSON.parse(item)
  );
};
