// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// React

// CSS
import styles from "./StartAllocatedProcessModal.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  allocatedProcessesSlice,
  startAsyncNewAllocatedProcessInstance,
} from "../../../Features/AllocatedProcessesSlice/allocatedProcessesSlice";
// Redux

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Utils
import { Spinner } from "reactstrap";
import Swal from "sweetalert2";
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
// Utils

// Formats
import { latinLetterWithoutSpaceFormat } from "../../../Formats/formats";
// Formats

// Modules
import { useNavigate } from "react-router-dom";
import { convertToGeorgianDateFormPersianDateInString } from "../../../Utils/FormioUtils/convertToGeorgianDateFormPersianDateInString";
// Modules

type StartAllocatedProcessModalProps = {
  processKey: string;
  setIsModalInScreen: React.Dispatch<React.SetStateAction<boolean>>;
  formData: {};
};

const StartAllocatedProcessModal: React.FunctionComponent<
  StartAllocatedProcessModalProps
> = ({ processKey, setIsModalInScreen, formData }) => {
  const dispatch = useAppDispatch();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const [_formData, set_formData] = useState<{
    businessKey: string;
    title: string;
  }>({
    businessKey: `${processKey}_businessKey_${Date.now()}`,
    title: `${processKey}_title_${Date.now()}`,
  });

  const outerContentRef = useRef<HTMLDivElement>(null);
  const innerContentRef = useRef<HTMLDivElement>(null);

  const isNightOn = useContext(IsNightModeOnContext);

  const navigate = useNavigate();

  const closeModal = useCallback(() => {
    if (!outerContentRef.current) return;
    if (!innerContentRef.current) return;

    outerContentRef.current.style.opacity = "0";
    innerContentRef.current.style.top = "100vh";

    setTimeout(() => {
      setIsModalInScreen(false);
    }, 500);
  }, [setIsModalInScreen]);

  const {
    isPending,
    isDone,
    data: { trackingCode },
  } = useAppSelector(
    (state) => state.allocatedProcesses.allocatedProcessStartingNewInstanceState
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!outerContentRef.current) return;
      if (!innerContentRef.current) return;

      outerContentRef.current.style.opacity = "0.75";
      innerContentRef.current.style.top = "0px";
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    if (!isDone) return;

    Swal.fire({
      icon: "success",
      title: "عملیات موفق",
      text: `کد رهگیری : ${trackingCode}`,
    }).then(() => {
      closeModal();
      navigate("/allocated-processes");
    });
  }, [isDone, closeModal, trackingCode, navigate]);

  useEffect(() => {
    return () => {
      dispatch(
        allocatedProcessesSlice.actions.resetStartingNewAllocatedProcessInstance()
      );
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.startFomModal}  w-100 d-flex flex-column align-items-center`}
    >
      <div
        className={`${styles.outerContent}`}
        ref={outerContentRef}
        onClick={closeModal}
      ></div>
      <div
        className={`${
          styles.innerContent
        } d-flex flex-column align-items-center ${
          isNightOn ? "nightBg1" : ""
        } `}
        ref={innerContentRef}
      >
        <CustomInput1
          inputType="text"
          value={_formData.businessKey}
          defaultValue={_formData.businessKey}
          onBlur={() => {}}
          onChange={(e) => {
            set_formData((prevState) => ({
              ...prevState,
              businessKey: e.target.value,
            }));
          }}
          placeHolder="کد کاری"
          dontShowSearchIcon
          containerClassName=""
          required
        />
        <CustomInput1
          inputType="text"
          value={_formData.title}
          defaultValue={_formData.title}
          onBlur={() => {}}
          onChange={(e) => {
            set_formData((prevState) => ({
              ...prevState,
              title: e.target.value,
            }));
          }}
          placeHolder="نام فرآیند"
          dontShowSearchIcon
          containerClassName="mt-3"
          required
        />

        <button
          className="operationEvent submitBtn mt-4 align-self-start"
          onClick={() => {
            if (
              !latinLetterWithoutSpaceFormat.test(_formData.businessKey) ||
              _formData.businessKey.includes(" ")
            ) {
              customizedToast("کد فرآیند باید لاتین و بدون فاصله باشد", "info");
              return;
            }
            if (_formData.title.length <= 4) {
              customizedToast("طول عنوان فرآیند کمتر از 5 کاراکتر است", "info");
              return;
            }
            console.log(
              convertToGeorgianDateFormPersianDateInString(
                JSON.stringify(formData)
              )
            );
            dispatch(
              startAsyncNewAllocatedProcessInstance({
                userToken,
                _data: {
                  _data: {
                    ..._formData,
                    formData: convertToGeorgianDateFormPersianDateInString(
                      JSON.stringify(formData)
                    )
                      ? JSON.parse(
                          convertToGeorgianDateFormPersianDateInString(
                            JSON.stringify(formData)
                          )
                        )
                      : formData,
                  },
                  processKey,
                },
              })
            );
          }}
        >
          {isPending ? <Spinner /> : `شروع`}
        </button>
      </div>
    </div>
  );
};

export default StartAllocatedProcessModal;
