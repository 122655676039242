// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Models
import { I_SecurityEvent } from "../../Models/interfaces";
// Models

// Modules
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { getAllSecurityEventsService } from "../../Services/SecurityEvents/get/getAllSecurityEventsService";
import { I_SecurityEventRequestInterface } from "../../Models/sendingDataSchema";
import { changeEffectOfSingleSecurityEventService } from "../../Services/SecurityEvents/post/changeEffectOfSingleSecurityEventService";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Modules

const initialState: {
  securityEventData: {
    isPending: boolean;
    isError: boolean;
    data: I_SecurityEvent;
  };
} & {
  changeEffectStatus: {
    isPending: boolean;
    isDone: boolean;
  };
} = {
  securityEventData: {
    data: {
      content: [],
      hasNextPage: false,
      pageNumber: 1,
      pageSize: 10,
      totalElements: 1,
      totalPages: 1,
    },
    isPending: true,
    isError: false,
  },
  changeEffectStatus: {
    isPending: false,
    isDone: false,
  },
};

export const getAsyncSecurityEvent = createAsyncThunk(
  "securityEvent/getAll",
  async (_data: {
    userToken: string;
    _data: I_SecurityEventRequestInterface;
  }) => {
    try {
      const { data } = await getAllSecurityEventsService(_data);

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const changeAsyncEffectTypeOfSecurityEvent = createAsyncThunk(
  "securityEvent/changeEffect",
  async (_data: {
    userToken: string;
    _data: {
      eventIds: string[] | number[];
      effectType: string;
    };
  }) => {
    const { data } = await changeEffectOfSingleSecurityEventService(_data);

    return data;
  }
);

export const securityEventSlice = createSlice({
  name: "securityEvent",
  initialState,
  reducers: {
    resetSecurityEvent: (state) => {
      state.securityEventData.data = initialState.securityEventData.data;
      state.securityEventData.isPending = true;
      state.securityEventData.isError = false;
    },
    resetEffectStatus: (state) => {
      state.changeEffectStatus.isDone = false;
      state.changeEffectStatus.isPending = false;
    },
  },
  extraReducers: (builder) => {
    //
    //
    //
    //
    //
    builder.addCase(getAsyncSecurityEvent.pending, (state, action) => {
      state.securityEventData.isPending = true;
      state.securityEventData.isError = false;
    });
    builder.addCase(getAsyncSecurityEvent.fulfilled, (state, action) => {
      state.securityEventData.isPending = false;
      state.securityEventData.data = action.payload!.result;

      for (let i: number = 0; i <= action.payload!.messages.length! - 1; i++) {
        customizedToast(action.payload!.messages[i].message, "success");
      }
      state.securityEventData.isError = false;
    });
    builder.addCase(getAsyncSecurityEvent.rejected, (state, action) => {
      state.securityEventData.isPending = false;
      state.securityEventData.isError = true;
    });
    //
    //
    //
    //
    //
    builder.addCase(
      changeAsyncEffectTypeOfSecurityEvent.pending,
      (state, action) => {
        state.changeEffectStatus.isDone = false;
        state.changeEffectStatus.isPending = true;
      }
    );
    builder.addCase(
      changeAsyncEffectTypeOfSecurityEvent.fulfilled,
      (state, action) => {
        state.changeEffectStatus.isDone = true;
        state.changeEffectStatus.isPending = false;
        for (let i: number = 0; i <= action.payload?.messages.length - 1; i++) {
          customizedToast(action.payload?.messages[i].message, "success");
        }
      }
    );
    builder.addCase(
      changeAsyncEffectTypeOfSecurityEvent.rejected,
      (state, action) => {
        state.changeEffectStatus.isDone = false;
        state.changeEffectStatus.isPending = false;
      }
    );
    //
    //
    //
    //
    //
  },
});
