export const permitionCheckerWithoutToast = (
  permissionArray: string[],
  selectedArray: string[],
  canCheck?: boolean
): boolean => {
  // Convert both arrays to lowercase for case-insensitive comparison
  const mainPermissionsLower = permissionArray.map((permission) =>
    permission.toLowerCase()
  );
  const userPermissionsLower = selectedArray.map((permission) =>
    permission.toLowerCase()
  );

  // Check if there's any intersection between mainPermissionsLower and userPermissionsLower
  const hasPermission = mainPermissionsLower.some((permission) =>
    userPermissionsLower.includes(permission)
  );

  return hasPermission;
};
