import { customizedToast } from "../Utils/CustomizedToast/customizedToast";

export const permissionChecker = (
  permissionArray: string[],
  selectedArray: string[],
  canCheck: boolean
): boolean => {
  const lowercasePermissionArray = permissionArray.map((item) =>
    item.toLowerCase()
  );

  const result: boolean = selectedArray
    .map((item) => item.toLowerCase())
    .some((item) => lowercasePermissionArray.includes(item));

  if (!canCheck) return false;

  if (result === false) {
    customizedToast("شما دسترسی لازم به این بخش را ندارید", "info");
  }
  return result;
};
