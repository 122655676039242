import { useCallback, useEffect } from "react";
import L from "leaflet";
import { mapDataExportedFromFormio } from "../Utils/FormioUtils/mapDataExportedFromFormio";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { themeColor } from "../Constants/constants";
import { customizedToast } from "../Utils/CustomizedToast/customizedToast";

const useReRenderMap = ({
  forceReRender,
  form,
  isPending,
}: {
  form: any;
  forceReRender: boolean;
  isPending: boolean;
}) => {
  const reRenderFunctionForMaps = useCallback(() => {
    console.log(forceReRender);
    console.log(form);
    if (isPending) return;
    const maps = mapDataExportedFromFormio(form.components);
    if (maps.length === 0) return;
    try {
      maps.forEach((item: any) => {
        console.log(item);
        const map = L.map(item["value"]).setView(
          [Number(item["data-lat"]), Number(item["data-lon"])],
          10
        );

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: "",
        }).addTo(map);

        const customIcon = L.icon({
          iconUrl: markerIconPng,
          iconSize: [32, 52],
          iconAnchor: [16, 32],
          popupAnchor: [0, -32],
        });

        if (Boolean(item["data-marker-text"]) === false) {
          L.marker([Number(item["data-lat"]), Number(item["data-lon"])], {
            icon: customIcon,
          }).addTo(map);
        } else {
          L.marker([Number(item["data-lat"]), Number(item["data-lon"])], {
            icon: customIcon,
          })
            .addTo(map)
            .bindPopup(item["data-marker-text"]);
        }

        if (
          Boolean(item["data-is-circle-active"]) &&
          Boolean(item["data-circle-lat"])
        ) {
          const circle = L.circle(
            [Number(item["data-circle-lat"]), Number(item["data-circle-lon"])],
            {
              color: "red", // Circle outline color
              fillColor: themeColor, // Circle fill color
              fillOpacity: 0.5, // Opacity of the fill color
              radius: Number(item["data-circle-radius"]) || 500, // Radius of the circle in meters
            }
          ).addTo(map);

          console.log(circle);
        }
      });

      if (maps.length === 1) {
        customizedToast("نقشه با موفقیت بارگذاری شد", "success");
      } else {
        customizedToast("نقشه ها با موفقیت بارگذاری شدند", "success");
      }
    } catch (err: any) {
      console.log();
      if (err.message === "Map container is already initialized.") {
        // customizedToast("نقشه قبلا لود شده", "error");
      }
    }
    //
  }, [forceReRender, form, isPending]);

  useEffect(() => {
    if (isPending) return;
    console.log(forceReRender);
    console.log(form);

    const maps = mapDataExportedFromFormio(form.components);

    maps.forEach((item) => {
      const el = document.getElementById(item["value"]);
      if (!el) return;
      el.innerText = "در حال بارگذاری نقشه";
      el.classList.add("top-16");
    });

    const timeOut = setTimeout(reRenderFunctionForMaps, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [forceReRender, form, reRenderFunctionForMaps, isPending]);

  return null;
};

export default useReRenderMap;
