// React
import React, { useState } from "react";
// React

// CSS
import styles from "./Accordion.module.css";
// CSS

type AccordionProps = {
  children: React.ReactNode | React.ReactElement;
  topName: string;
  containerClassName?: string;
};

const Accordion: React.FunctionComponent<AccordionProps> = ({
  children,
  topName,
  containerClassName,
}) => {
  const [isFull, setIsFull] = useState<boolean>(true);

  return (
    <div className={`${styles.accordionContainer} w-100 ${containerClassName}`}>
      <p
        className={`${styles.topName} w-100 d-flex flex-row-reverse px-4 py-2  align-items-center justify-content-between`}
        onClick={() => setIsFull((prevState) => !prevState)}
      >
        {topName}
        {isFull ? (
          <span className={`${styles.icon} icon-arrow`}></span>
        ) : (
          <span className={`${styles.icon} rotate-180 icon-arrow`}></span>
        )}
      </p>
      {isFull && children}
    </div>
  );
};

export default Accordion;
