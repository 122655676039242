// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./CallOfRegistration.module.css";
// CSS

// Models
import { I_GetCallOfRegistrationSendingData } from "../../Models/sendingDataSchema";
// Models

// Redux
import {
  callOfRegistrationSlice,
  getAsyncAllCallOfRegistrations,
} from "../../Features/CallOfRegistrationSlice/callOfRegistrationSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
// Redux

// Modules
import { useNavigate, useSearchParams } from "react-router-dom";
// Modules

// Utils
import CustomInput1 from "../../Utils/CustomInput1/CustomInput1";
import Pagination from "../../Utils/Pagination/Pagination";
// Utils

// Icons
import { MdDone } from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
import { CgDetailsMore } from "react-icons/cg";
import { HiOutlineDocumentReport } from "react-icons/hi";
// Icons

// Modules
import { permitionCheckerWithoutToast } from "../../Modules/permitionCheckerWithoutToast";
// Modules

// Contexts
import { IsFullRenderdContext } from "../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { UserGroupsContext } from "../../Contexts/UserGroupsContext/UserGroupsContext";
import { AiOutlinePullRequest } from "react-icons/ai";
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import { searchAndBackSlice } from "../../Features/SearchAndBackSlice/searchAndBackSlice";
import CustomCheckbox from "../../Utils/CustomCheckbox/CustomCheckbox";
import Pending from "../../Components/Pending/Pending";
// Contexts

const CallOfRegistration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { selectedTenant } = useAppSelector((state) => state.tenants);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { callOfRegistrationData } = useAppSelector(
    (state) => state.callOfRegistration
  );
  const language = useAppSelector((state) => state.currLanguage.language);

  const isFullRendered = useContext(IsFullRenderdContext);
  const allGroups = useContext(UserGroupsContext);
  const isNightOn = useContext(IsNightModeOnContext);

  const [searchParam, setSearchParam] = useSearchParams();

  const [isMounted, setIsMounted] = useState<boolean>(false);

  const [searchData, setSearchData] =
    useState<I_GetCallOfRegistrationSendingData>({
      page: Number(searchParam.get("page")) || 1,
      size: Number(searchParam.get("size")) || 10,
      active: Boolean(searchParam.get("active") === "true"),
      forEditing: Boolean(searchParam.get("forEditing") === "true"),
      search: searchParam.get("search") || "",
      tenant: selectedTenant.tenantId,
    });

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncAllCallOfRegistrations({
          userToken,
          _data: searchData,
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, searchData, userToken, isMounted]);

  useEffect(() => {
    return () => {
      dispatch(
        callOfRegistrationSlice.actions.resetRecevedCallOfRegistrationData()
      );
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      searchAndBackSlice.actions.setCallOfRegistrationDataForPages({
        pageNumber: searchData.page,
        size: searchData.size,
      })
    );
  }, [dispatch, searchData]);

  useEffect(() => {
    setSearchParam(
      {
        page: String(searchData.page) || 1 + "",
        size: String(searchData.size) || 10 + "",
        active: String(searchData.active),
        forEditing: String(searchData.forEditing),
        search: searchData.search,
      },
      {
        replace: true,
      }
    );
  }, [searchData, setSearchParam, searchParam]);

  return (
    <div
      className={`${styles.callOfRegistrationContainer} ${
        isNightOn ? "nightBg1" : ""
      } d-flex flex-column align-items-center`}
    >
      <div className="w-100 d-flex flex-row-reverse flex-wrap align-items-center justify-content-between">
        <p
          style={{
            color: "#111D64",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          لیست فراخوان ها
        </p>

        <div
          className={`d-flex flex-row align-items-center ${
            window.innerWidth > 1000
              ? ""
              : "w-100 d-flex flex-row align-items-center justify-content-between"
          }`}
        >
          {permitionCheckerWithoutToast(
            allGroups,
            ["Tenant_Full_Manager", "Tenant_Basic_Manager", "Post_Manager"],
            isFullRendered
          ) ? (
            <button
              className={`operationEvent submitBtn`}
              onClick={() => {
                navigate("/call-of-registration/add-new");
              }}
            >
              افزودن فراخوان
              <span
                className="icon-Plus "
                style={{
                  marginLeft: "0.3rem",
                }}
              ></span>
            </button>
          ) : (
            <span></span>
          )}
          <button
            className={`operationEvent ${
              window.innerWidth > 1000 ? "editBtn" : "submitBtn"
            }`}
            onClick={() => {
              navigate(
                "/call-of-registration/check-current-state-of-registration"
              );
            }}
          >
            برسی وضعیت ثبت نامی
          </button>
        </div>
      </div>
      <div
        className={`${styles.topItems} w-100 d-flex flex-row flex-wrap-reverse align-items-center justify-content-between mt-4`}
      >
        <CustomCheckbox
          isChecked={searchData.forEditing}
          onChange={(e) => {
            e.preventDefault();
            setSearchData((prevState) => ({
              ...prevState,
              forEditing: !prevState.forEditing,
            }));
          }}
          onClick={(e) => {
            setSearchData((prevState) => ({
              ...prevState,
              forEditing: !prevState.forEditing,
            }));
          }}
          title="فقط موارد قابل ویرایش"
          containerClassName={`  d-flex justify-between ${
            window.innerWidth > 1000 ? "" : "w-100 mt-2"
          }`}
          titleClassName={"mb-0"}
        />

        <CustomCheckbox
          onChange={(e) => {
            e.preventDefault();
            setSearchData((prevState) => ({
              ...prevState,
              active: !prevState.active,
            }));
          }}
          onClick={(e) => {
            setSearchData((prevState) => ({
              ...prevState,
              active: !prevState.active,
            }));
          }}
          title="فقط موارد فعال"
          isChecked={searchData.active}
          containerClassName={`  d-flex justify-between ${
            window.innerWidth > 1000 ? "" : "w-100 "
          }`}
          titleClassName={"mb-0"}
        />

        <CustomInput1
          inputType="text"
          value={searchData.search}
          defaultValue={searchData.search}
          onBlur={() => {}}
          onChange={(e) => {
            setSearchData((prevState) => ({
              ...prevState,
              search: e.target.value,
            }));
          }}
          placeHolder="جستجو"
          dontShowSearchIcon
        />
      </div>

      {callOfRegistrationData.isPending ? <Pending className="mt-4" /> : null}

      {!callOfRegistrationData.isPending &&
      callOfRegistrationData.data.content.length === 0 ? (
        <p className="mt-4">موردی وجود ندارد</p>
      ) : null}

      {!callOfRegistrationData.isPending &&
      !callOfRegistrationData.isError &&
      callOfRegistrationData.data.content.length !== 0 ? (
        <>
          {window.innerWidth > 1000 ? (
            <div
              className={`${styles.myTable} ${
                isNightOn ? "nightBg2 pcNight" : ""
              } w-100 d-flex flex-column align-items-center align-items-center mt-5`}
            >
              <div
                className={`${styles.tableHead} w-100 d-flex flex-row-reverse align-items-center justify-content-between align-items-center`}
              >
                <p className={`${styles.id}`}>شمارنده</p>
                <p className={`${styles.title}`}>نام</p>
                <p className={`${styles.description}`}>توضیحات </p>
                <p className={`${styles.isActive}`}>فعال</p>
                <p className={`${styles.start}`}>شروع</p>
                <p className={`${styles.end}`}>پایان</p>
                <p className={`${styles.operationTab}`}>عملیات</p>
              </div>
              <div
                className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
              >
                {callOfRegistrationData.data.content.map((item) => (
                  <div
                    key={item.id}
                    className={`${styles.tableData} w-100 d-flex flex-row-reverse align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.id}`}>{item.id}</p>
                    <p className={`${styles.title}`}>{item.title}</p>
                    <p className={`${styles.description}`}>
                      {item.description}
                    </p>
                    <p className={`${styles.isActive}`}>
                      {item.active ? (
                        <MdDone
                          style={{
                            fontSize: "2rem",
                          }}
                        />
                      ) : (
                        <BsDashLg
                          style={{
                            fontSize: "2rem",
                          }}
                        />
                      )}
                    </p>
                    <p className={`${styles.start}`}>
                      {`${item.startTime.jalaliDate}`}
                    </p>
                    <p
                      className={`${styles.end}`}
                    >{`${item.endTime.jalaliDate}`}</p>
                    <p
                      className={`${styles.operationTab} d-flex flex-row align-items-center flex-wrap`}
                    >
                      {permitionCheckerWithoutToast(
                        allGroups,
                        [
                          "Tenant_Full_Manager",
                          "Tenant_Basic_Manager",
                          "CALL_OF_REGISTRATIONS_MANAGER",
                        ],
                        isFullRendered
                      ) && (
                        <button
                          className="operationEvent editBtn"
                          data-tooltip-content={language.identities}
                          data-tooltip-id="my-tooltip"
                          onClick={() => {
                            navigate(
                              `/call-of-registration/identities/details/${item.id}`
                            );
                          }}
                        >
                          <HiOutlineDocumentReport />
                        </button>
                      )}

                      <button
                        className="operationEvent taskBtn"
                        data-tooltip-content={language.more}
                        data-tooltip-id="my-tooltip"
                        onClick={() => {
                          navigate(
                            `/call-of-registration/single-callOf-registration/details/${item.id}`
                          );
                        }}
                      >
                        <CgDetailsMore />
                      </button>

                      <button
                        className="operationEvent editBtn"
                        data-tooltip-content={language.edit}
                        data-tooltip-id="my-tooltip"
                        onClick={() => {
                          navigate(
                            `/call-of-registration/edit/edit-single/${
                              item.id
                            }?startTime=${
                              item.startTime.jalaliDate
                                .replace(" ", "***")
                                .split("***")[1]
                            }&endTime=${
                              item.endTime.jalaliDate
                                .replace(" ", "***")
                                .split("***")[1]
                            }&title=${item.title}&active=${
                              item.active
                            }&description=${item.description}
                            &registrationDate=${
                              item.startTime.jalaliDate
                                .replace(" ", "***")
                                .split("***")[0] +
                              "***" +
                              item.endTime.jalaliDate
                                .replace(" ", "***")
                                .split("***")[0]
                            }`
                          );
                        }}
                      >
                        <span className="icon-edit"></span>
                      </button>

                      <button
                        className="operationEvent relatedForms"
                        data-tooltip-content={language.request}
                        data-tooltip-id="my-tooltip"
                        onClick={() => {
                          navigate(
                            `/call-of-registration/requests/details/${item.id}`
                          );
                        }}
                      >
                        <AiOutlinePullRequest />
                      </button>
                    </p>
                  </div>
                ))}
              </div>
              <div className={`${styles.tableFooter}`}></div>
            </div>
          ) : (
            <div
              className={`${styles.cards} mt-5 w-100 d-flex flex-column align-items-center`}
            >
              {callOfRegistrationData.data.content.map((item) => (
                <div key={item.id} className="w-100">
                  <div
                    className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                  >
                    <p>{item.id}</p>
                    <p>شمارنده </p>
                  </div>
                  <div
                    className={`${styles.tableRow} ${
                      isNightOn ? "nightBg2" : ""
                    } w-100 d-flex align-items-center justify-content-between `}
                  >
                    <p>
                      <span>نام</span>
                      <span>{item.title}</span>
                    </p>
                    <p>
                      <span>توضیحات</span>
                      <span>{item.description}</span>
                    </p>
                    <p>
                      <span>فعال</span>
                      <span>
                        {item.active ? (
                          <MdDone
                            style={{
                              fontSize: "2rem",
                            }}
                          />
                        ) : (
                          <BsDashLg
                            style={{
                              fontSize: "2rem",
                            }}
                          />
                        )}
                      </span>
                    </p>
                    <p>
                      <span>شروع</span>
                      <span>{item.startTime.jalaliDate}</span>
                    </p>
                    <p>
                      <span>پایان</span>
                      <span>{item.endTime.jalaliDate}</span>
                    </p>
                    <p>
                      <span>عملیات</span>
                      <span className="d-flex flex-row align-items-center">
                        {permitionCheckerWithoutToast(
                          allGroups,
                          [
                            "Tenant_Full_Manager",
                            "Tenant_Basic_Manager",
                            "CALL_OF_REGISTRATIONS_MANAGER",
                          ],
                          isFullRendered
                        ) && (
                          <button
                            className="operationEvent editBtn"
                            data-tooltip-content={language.identities}
                            data-tooltip-id="my-tooltip"
                            onClick={() => {
                              navigate(
                                `/call-of-registration/identities/details/${item.id}`
                              );
                            }}
                          >
                            <HiOutlineDocumentReport />
                          </button>
                        )}

                        <button
                          className="operationEvent taskBtn"
                          data-tooltip-content={language.more}
                          data-tooltip-id="my-tooltip"
                          onClick={() => {
                            navigate(
                              `/call-of-registration/single-callOf-registration/details/${item.id}`
                            );
                          }}
                        >
                          <CgDetailsMore />
                        </button>

                        <button
                          className="operationEvent editBtn"
                          data-tooltip-content={language.edit}
                          data-tooltip-id="my-tooltip"
                          onClick={() => {
                            navigate(
                              `/call-of-registration/edit/edit-single/${
                                item.id
                              }?startTime=${
                                item.startTime.jalaliDate
                                  .replace(" ", "***")
                                  .split("***")[1]
                              }&endTime=${
                                item.endTime.jalaliDate
                                  .replace(" ", "***")
                                  .split("***")[1]
                              }&title=${item.title}&active=${
                                item.active
                              }&description=${item.description}
                              &registrationDate=${
                                item.startTime.jalaliDate
                                  .replace(" ", "***")
                                  .split("***")[0] +
                                "***" +
                                item.endTime.jalaliDate
                                  .replace(" ", "***")
                                  .split("***")[0]
                              }`
                            );
                          }}
                        >
                          <span className="icon-edit"></span>
                        </button>

                        <button
                          className="operationEvent relatedForms"
                          data-tooltip-content={language.request}
                          data-tooltip-id="my-tooltip"
                          onClick={() => {
                            navigate(
                              `/call-of-registration/requests/details/${item.id}`
                            );
                          }}
                        >
                          <AiOutlinePullRequest />
                        </button>
                      </span>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      ) : null}

      <Pagination
        dataForPagination={{
          currPage: searchData.page,
          size: searchData.size,
          totalPages: callOfRegistrationData.data.totalPages,
        }}
        onPageNumberChange={(page) => {
          setSearchData((prevState) => ({ ...prevState, page }));
        }}
        onPageNumbersClick={(page) => {
          setSearchData((prevState) => ({ ...prevState, page }));
        }}
        onPageSizeChange={(size) => {
          setSearchData((prevState) => ({ ...prevState, size }));
          setSearchData((prevState) => ({ ...prevState, page: 1 }));
        }}
        search={searchData.search}
      />
    </div>
  );
};

export default CallOfRegistration;
