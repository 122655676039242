// React
import React, { useCallback, useContext, useEffect, useRef } from "react";
// React

// CSS
import styles from "./RemoveProcessByVersionModal.module.css";
// CSS

// Models
import { T_RemoveProcessByVersionNeededData } from "../Processes";
// Models

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Utils
import CustomSelectOption2 from "../../../Utils/CustomSelectOption2/CustomSelectOption2";
// Utils

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncSingleProcessVersions,
  processesSlice,
  removeAsyncVersionOfProcess,
} from "../../../Features/ProcessesSlice/ProcessesSlice";
import Spinner from "../../../Utils/Spinner/Spinner";
// Redux

type RemoveProcessByVersionModalProps = {
  removeVersionModalData: T_RemoveProcessByVersionNeededData;

  setRemoveVersionModalData: React.Dispatch<
    React.SetStateAction<T_RemoveProcessByVersionNeededData>
  >;

  onDone: () => void;
};

const RemoveProcessByVersionModal: React.FunctionComponent<
  RemoveProcessByVersionModalProps
> = ({ removeVersionModalData, setRemoveVersionModalData, onDone }) => {
  const dispatch = useAppDispatch();
  const outerContentRef = useRef<HTMLDivElement>(null);
  const innerContentRef = useRef<HTMLDivElement>(null);

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );
  const { isPending } = useAppSelector(
    (state) => state.processes.processVersionRemovingStatus
  );

  const isNightOn = useContext(IsNightModeOnContext);

  const versionData = useAppSelector((state) => state.processes.processVersion);

  const deleteTypes = useAppSelector(
    (state) => state.allRoles.data.processVersionDeleteType
  );

  const versionsGetter = useCallback(() => {
    dispatch(
      getAsyncSingleProcessVersions({
        userToken,
        _data: {
          processKey: removeVersionModalData.processKey,
          tenant: selectedTenant.tenantId,
        },
      })
    );
  }, [
    dispatch,
    removeVersionModalData.processKey,
    selectedTenant.tenantId,
    userToken,
  ]);

  const closeModal = useCallback(() => {
    if (!outerContentRef.current) return;
    if (!innerContentRef.current) return;

    outerContentRef.current.style.opacity = "0";
    innerContentRef.current.style.top = "100vh";

    setTimeout(() => {
      setRemoveVersionModalData({
        deletingType: "",
        isInView: false,
        processKey: "",
        version: 0,
      });
    }, 500);
  }, [setRemoveVersionModalData]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!outerContentRef.current) return;
      if (!innerContentRef.current) return;

      outerContentRef.current.style.opacity = "0.75";
      innerContentRef.current.style.top = "0px";
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    const timeOut = setTimeout(versionsGetter, 500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [versionsGetter]);

  useEffect(() => {
    return () => {
      dispatch(processesSlice.actions.resetProcessVersions());
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.startFomModal}  w-100 d-flex flex-column align-items-center`}
    >
      <div
        className={`${styles.outerContent}`}
        ref={outerContentRef}
        onClick={closeModal}
      ></div>
      <div
        className={`${
          styles.innerContent
        } d-flex flex-column align-items-center ${
          isNightOn ? "nightBg1" : ""
        } `}
        ref={innerContentRef}
      >
        <div className="w-100 d-flex flex-column align-items-center rtl gap-3">
          <p
            className="w-100 d-flex flex-row align-items-center
                 justify-content-between
"
          >
            نام فرآیند : <span>{removeVersionModalData.processKey}</span>
          </p>
          <p
            className="w-100 d-flex flex-row align-items-center
                 justify-content-between
"
          >
            نسخه انتخابی {"( پیشفرض آخرین نسخه )"} :{" "}
            <span>{removeVersionModalData.version}</span>
          </p>

          <CustomSelectOption2
            getSelectedOption={(data) => {
              setRemoveVersionModalData((prevState) => ({
                ...prevState,
                version: Number(data),
              }));
            }}
            initialTitle="نسخه مبدا"
            initialValue={`${removeVersionModalData.version}`}
            options={(() => {
              if (versionData.isPending)
                return [{ title: "درحال بارگذاری", value: "" }];

              return versionData.data.result.map((_i) => ({
                title: `نسخه ${_i.version}`,
                value: `${_i.version}`,
              }));
            })()}
          />

          <CustomSelectOption2
            getSelectedOption={(data) => {
              setRemoveVersionModalData((prevState) => ({
                ...prevState,
                deletingType: data as string,
              }));
            }}
            initialTitle="نوع حذف"
            initialValue={""}
            options={(() => {
              const keys = Object.keys(deleteTypes);
              return keys.map((_i) => ({
                ...deleteTypes[_i],
              }));
            })()}
          />

          <p className="text-danger">* این عملیات غیرقابل بازگشت میباشد * </p>
          <button
            className="w-100 submitBtn py-2 rounded-2"
            onClick={() => {
              if (isPending) return;
              const { isInView, ...others } = removeVersionModalData;
              dispatch(
                removeAsyncVersionOfProcess({
                  userToken,
                  _data: { ...others, tenantId: selectedTenant.tenantId },
                  onDone: () => {
                    onDone();
                    closeModal();
                  },
                })
              );
            }}
          >
            {isPending ? <Spinner /> : `تایید`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveProcessByVersionModal;
