export const downloadAsXml = (data: BlobPart[], name: string) => {
  const blob = new Blob(data, {
    type: "application/xml",
  });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = `${name}.xml`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
