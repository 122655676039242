// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_AccessibleUserProcess, I_Message } from "../../../Models/interfaces";
// Models

export const getUserAccessibleProcessesService = async (
  userToken: string,
  username: string,
  search: string,
  page: number | string,
  size: number | string
): Promise<
  AxiosResponse<{
    error: number;
    message: I_Message[];
    result: I_AccessibleUserProcess;
  }>
> => {
  return _axios.get(
    `/users/${username}/accessible-processes?page=${page}&size=${size}&search=${search}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
