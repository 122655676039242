// React
import { useEffect } from "react";
// React

// Event Functions
import { onMapModalOpen } from "../../Events/MapEvents/documentEvents";
// Event Functions

export const useMapRenderInLittleModalInTasks = (isFormPending?: boolean) => {
  const els = document.querySelectorAll("[data-map-component]");

  useEffect(() => {
    const els = document.querySelectorAll("[data-map-component]");

    console.log(els);

    els.forEach((item) => {
      const rect = item.getBoundingClientRect();
      item.addEventListener("click", () => {
        Promise.resolve()
          .then(() => {
            localStorage.setItem("mapId", JSON.stringify(item.id));
            localStorage.setItem("mapRect", JSON.stringify(rect));
          })
          .then(() => {
            localStorage.setItem("mapRect", JSON.stringify(rect));
          })
          .then(() => {
            localStorage.setItem("mapId", JSON.stringify(item.id));
          })
          .then(() => {
            dispatchEvent(onMapModalOpen);
          });
      });
    });
  });

  return els;
};
