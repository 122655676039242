// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import {
  I_Message,
  I_SingleCallOfRegistrationRecevedData,
} from "../../../Models/interfaces";
// Models

export const getAllDetailsOfSingleCallOfRegistrationService = (_data: {
  userToken: string;
  callOfRegistrationId: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_SingleCallOfRegistrationRecevedData;
  }>
> => {
  return _axios.get(
    `/call-of-registrations/${_data.callOfRegistrationId}/detail`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
