// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// React

// CSS
import styles from "./EditSingleFormSample.module.css";
// CSS

// Modules
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// Modules

// Urls
import { mainUrl } from "../../../Services/urls";
// Urls

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  editAsyncSingleFormSample,
  formSamplesSlice,
  getAsyncFormSamples,
} from "../../../Features/FormSamples/formSamplesSlice";
// Redux

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Utils
import { Spinner } from "reactstrap";
import CustomFormioComponents from "../../../Utils/CustomFormioComponents/CustomFormioComponents";
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import { formioCustomComponentAdderFunction } from "../../../Utils/FormioUtils/formioCustomComponentAdderFunction";
// Utils

// Models
import { I_EditFormSampleSendingData } from "../../../Models/sendingDataSchema";
// Models

// Language
import { formIoLanguagePack } from "../../../Language/formioLanguage";
// Language

// Components
import { FormBuilder } from "@formio/react";
import AddFormSampleModal from "../AddNewFormSample/AddFormSampleModal/AddFormSampleModal";
import LoadFormFROMsampleOrAddToCurrentForm from "../../../Components/LoadFormFROMsampleOrAddToCurrentForm/LoadFormFROMsampleOrAddToCurrentForm";
import { MapDataRefType } from "../../Forms/AddNewForm/AddNewForm";
// Components

// Hooks
import useReRenderMap from "../../../Hooks/useReRenderMap";
import { useMapRenderInLittleModal } from "../../../Hooks/MapHooks/useMapRenderInLittleModal";
import { useFormioDialogChanger } from "../../../Hooks/useFormioDialogChanger";
// Hooks

// Constants
import { formioDialogForDatePicker } from "../../../Constants/constants";
import { customizedToast } from "../../../Utils/CustomizedToast/customizedToast";
import { getAllFormSamplesService } from "../../../Services/FormSamples/get/getAllFormSamplesService";
// Constants

const EditSingleFormSample = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParam] = useSearchParams();
  const { persianFormTemplateName, formId } = useParams();

  const [form, setForm] = useState({
    display: "form",
    components: [],
  });
  const [nForm, setNForm] = useState({
    display: "form",
    components: [],
  });

  const [isFormPending, setIsFormPending] = useState<boolean>(true);
  const [isErrorInFormLoading, setIsErrorInFormLoading] =
    useState<boolean>(false);
  const [isModalInScreen, setIsModalInScreen] = useState<boolean>(false);
  console.log(nForm);
  const [isInputReadingPending, setIsReadInputPending] =
    useState<boolean>(false);
  console.log(isInputReadingPending);
  const [editFormSampleSendingData, setEditFormSampleSendingData] =
    useState<I_EditFormSampleSendingData>({
      title: "",
      description: "",
      formData: {},
    });
  const [modalData, setModalData] = useState<{
    selectOptionValue: string;
    formUrl: string;
  }>({
    selectOptionValue: "",
    formUrl: "",
  });
  const [forceReRender, setForceReRender] = useState<boolean>(false);

  /************************** */
  /************************** */
  /************************** */
  /************************** */
  const currLanguage = useAppSelector(
    (state) => state.currLanguage.currLanguage
  );
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { isDone, isPending } = useAppSelector(
    (state) => state.formSamples.editFormSample
  );

  const { selectedTenant } = useAppSelector((state) => state.tenants);
  /************************** */
  /************************** */
  /************************** */
  /************************** */

  const formAttacher = useMemo(
    () => (
      <LoadFormFROMsampleOrAddToCurrentForm
        form={form}
        modalData={modalData}
        setForm={setForm}
        setIsModalInScreen={setIsModalInScreen}
        setIsReadInputPending={setIsReadInputPending}
        setModalData={setModalData}
      />
    ),
    [form, modalData]
  );

  const getFormJSON = useCallback(
    function getFormJSON() {
      if (!Boolean(searchParam.get("id"))) {
        customizedToast("مقدار های مورد نظر یافن نشد", "error");
        return;
      }

      getAllFormSamplesService({
        userToken,
        _data: {
          id: String(formId) || String(searchParam.get("id")),
          page: 1,
          search: "",
          size: 1,
          tenant: selectedTenant.tenantId,
          type: "Form-IO",
        },
      })
        .then(({ data }) => data.result.content[0])
        .then((data) => {
          setIsFormPending(true);
          setIsErrorInFormLoading(false);
          fetch(`${mainUrl}${data.url}`)
            .then((res) => res.json())
            .then((res) => {
              console.log(res);
              setForm(res);
            })
            .catch((err) => {
              console.log(err);
              setIsErrorInFormLoading(true);
            })
            .finally(() => {
              setIsFormPending(false);
            });
        })
        .catch((err) => console.log(err));
    },

    [searchParam, userToken, selectedTenant.tenantId, formId]
  );
  /************************** */
  /************************** */
  /************************** */
  /************************** */
  // CONTEXTS
  const isNightMode = useContext(IsNightModeOnContext);
  // CONTEXTS
  /************************** */
  /************************** */
  /************************** */
  /************************** */
  // CONSTANTS
  const formioOptions: any = {
    language: currLanguage,
    i18n: formIoLanguagePack.i18n,
  };
  const mapDataRef = useRef<MapDataRefType>({
    lat: "",
    lon: "",
  });
  // CONSTANTS
  /************************** */
  /************************** */
  /************************** */
  /************************** */

  useEffect(() => {
    if (!Boolean(searchParam.get("id"))) {
      navigate("/form-samples");
      return;
    }
    getFormJSON();
  }, [getFormJSON, navigate, searchParam]);
  //
  useEffect(() => {
    dispatch(
      getAsyncFormSamples({
        userToken,
        _data: {
          page: 1,
          search: "",
          size: 999,
          type: "Form-IO",
          tenant: selectedTenant.tenantId,
          id: "",
        },
      })
    );
  }, [dispatch, userToken, selectedTenant, searchParam]);
  //
  useEffect(() => {
    return () => {
      dispatch(formSamplesSlice.actions.resetEditingFormSampleState());
    };
  }, [dispatch]);
  //
  useEffect(() => {
    if (!isDone) return;
    navigate("/form-samples");
  }, [isDone, navigate]);
  //
  useEffect(() => {
    return () => {
      dispatch(formSamplesSlice.actions.resetRecevedFormSamplesState());
    };
  }, [dispatch]);
  //

  useReRenderMap({
    forceReRender,
    form,
    isPending: false,
  });

  useMapRenderInLittleModal(isFormPending);
  useFormioDialogChanger(formioDialogForDatePicker);

  return (
    <div
      className={`w-100 d-flex flex-column align-items-center ${
        styles.editSingleFormSampleContainer
      } ${isNightMode ? "nightBg1" : ""}`}
    >
      <div className="w-100 d-flex flex-row align-items-center justify-content-between mb-2">
        <button
          className={` operationEvent   submitBtn   align-self-start d-flex align-items-center justify-content-center`}
          onClick={() => {
            if (isPending) return;
            dispatch(
              editAsyncSingleFormSample({
                userToken,
                _data: {
                  _data: { ...editFormSampleSendingData, formData: form },
                  formSampleId: String(formId),
                },
              })
            );
          }}
        >
          {isPending ? (
            <>
              <Spinner />
            </>
          ) : (
            "ویرایش و ثبت نهایی فرم"
          )}
        </button>

        <p
          style={{
            color: "#111D64",
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          ویرایش فرم پیش ساخته : {persianFormTemplateName}
        </p>
      </div>

      {/* FORM WILL CREATE IN HERE AND ATTACH TO THE FORM BUILDER ! */}
      {/*                   */}
      {formAttacher}
      {/*                    */}
      {/* FORM WILL CREATE IN HERE AND ATTACH TO THE FORM BUILDER ! */}

      <div
        className={`${styles.inputContainer} my-2 w-100 d-flex flex-row-reverse align-items-center mt-4 justify-content-between flex-wrap`}
      >
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={editFormSampleSendingData.title}
          defaultValue={editFormSampleSendingData.title}
          onChange={(e) => {
            setEditFormSampleSendingData((prevState) => ({
              ...prevState,
              title: e.target.value,
            }));
          }}
          placeHolder="نام فرم "
          dontShowSearchIcon
        />
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          value={editFormSampleSendingData.description}
          defaultValue={editFormSampleSendingData.description}
          onChange={(e) => {
            setEditFormSampleSendingData((prevState) => ({
              ...prevState,
              description: e.target.value,
            }));
          }}
          placeHolder="توضیحات"
          dontShowSearchIcon
        />
      </div>

      {isFormPending && !isErrorInFormLoading ? "در حال بارگذاری فرم " : null}

      {!isFormPending && isErrorInFormLoading ? (
        <button onClick={getFormJSON}>
          خطا در بارگذاری فرم برای بارگذاری مجدد کلیک کنید
        </button>
      ) : null}

      {!isFormPending && !isErrorInFormLoading ? (
        <div className="w-100">
          <div className="w-100 d-flex flex-column align-items-end mt-2">
            <CustomFormioComponents
              dataSetter={(data) => {
                formioCustomComponentAdderFunction(data, setForm, mapDataRef);
              }}
              containerClassName={`${styles.bg2} p-2`}
            />
          </div>
          <FormBuilder
            form={form}
            onUpdateComponent={(data: any) => {
              setNForm((prevState) => data);
              setForceReRender((prev) => !prev);
            }}
            onSaveComponent={(data: any) => {
              setNForm((prevState) => data);
              setForceReRender((prev) => !prev);
            }}
            onDeleteComponent={(data: any) => {
              setNForm((prevState) => data);
              setForceReRender((prev) => !prev);
            }}
            onCancelComponent={(data: any) => {
              setNForm((prevState) => data);
              setForceReRender((prev) => !prev);
            }}
            onEditComponent={(data: any) => {
              setNForm((prevState) => data);
              setForceReRender((prev) => !prev);
            }}
            options={formioOptions}
          />
        </div>
      ) : null}

      {isModalInScreen ? (
        <AddFormSampleModal
          formUrl={modalData.formUrl}
          setIsModalInScreen={setIsModalInScreen}
          dataSetter={(data) => {
            setForm((prevState: any) => ({
              ...prevState,
              components: [...prevState.components, ...data],
            }));
          }}
        />
      ) : null}
    </div>
  );
};

export default EditSingleFormSample;
