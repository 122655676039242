// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./CheckCurrStateOfRegistration.module.css";
// CSS

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import { Spinner } from "reactstrap";
// Utils

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import {
  callOfRegistrationSlice,
  checkAsyncSingleRegistrationState,
} from "../../../Features/CallOfRegistrationSlice/callOfRegistrationSlice";
// Redux

const CheckCurrStateOfRegistration = () => {
  const dispatch = useAppDispatch();

  const {
    isPending,
    isDone,
    data: currStateData,
  } = useAppSelector(
    (state) => state.callOfRegistration.checkStateOfRegistrationRequest
  );

  const [formData, setFormData] = useState<{
    username: string;
    trackingCode: string;
  }>({ username: "", trackingCode: "" });

  const isNightModeOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    return () => {
      dispatch(
        callOfRegistrationSlice.actions.resetCheckingRequestOfSingleRegistration()
      );
    };
  }, [dispatch]);

  return (
    <div
      className={`${
        styles.currentStateOfRegistrationContainer
      } d-flex flex-column align-items-center ${
        isNightModeOn ? "nightBg1" : ""
      }`}
    >
      <div className="w-100 d-flex flex-row-reverse align-items-center justify-content-between flex-wrap">
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              username: e.target.value,
            }));
          }}
          placeHolder="نام کاربر"
          value={formData.username}
          defaultValue={formData.username}
          dontShowSearchIcon
        />
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          onChange={(e) => {
            setFormData((prevState) => ({
              ...prevState,
              trackingCode: e.target.value,
            }));
          }}
          placeHolder="کد رهگیری"
          value={formData.trackingCode}
          defaultValue={formData.trackingCode}
          dontShowSearchIcon
        />
      </div>

      <button
        className="operationEvent submitBtn align-self-start mt-4"
        onClick={() => {
          dispatch(
            callOfRegistrationSlice.actions.resetCheckingRequestOfSingleRegistration()
          );
          dispatch(
            checkAsyncSingleRegistrationState({
              _data: formData,
            })
          );
        }}
      >
        {!isPending ? <>تایید</> : <Spinner />}
      </button>

      {isPending ? (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <Spinner />
          <p>در حال بارگیری</p>
        </div>
      ) : (
        <>
          {isDone ? (
            <div
              className={`${styles.dataContainer} w-100 d-flex flex-column align-items-center mt-5`}
            >
              <p>اطلاعات فرد ثبت نامی</p>
              <div
                className={`${styles.dataShower} w-100 d-flex flex-row align-items-center justify-content-between `}
              >
                <p>نام کاربری :</p>
                <p>{currStateData.registeredUsername || "تعریف نشده"}</p>
              </div>
              <div
                className={`${styles.dataShower} w-100 d-flex flex-row align-items-center justify-content-between `}
              >
                <p>نام :</p>
                <p>{currStateData.firstname || "تعریف نشده"}</p>
              </div>
              <div
                className={`${styles.dataShower} w-100 d-flex flex-row align-items-center justify-content-between `}
              >
                <p>نام خانوادگی :</p>
                <p>{currStateData.lastname || "تعریف نشده"}</p>
              </div>
              <div
                className={`${styles.dataShower} w-100 d-flex flex-row align-items-center justify-content-between `}
              >
                <p>وضعیت :</p>
                <p>{currStateData.state.title || "تعریف نشده"}</p>
              </div>

              <p className="mt-4">اطلاعات فراخوان مربوطه</p>
              <div
                className={`${styles.dataShower} w-100 d-flex flex-row align-items-center justify-content-between `}
              >
                <p>نام فراخوان :</p>
                <p>{currStateData.callOfRegistration.title || "تعریف نشده"}</p>
              </div>
              <div
                className={`${styles.dataShower} w-100 d-flex flex-row align-items-center justify-content-between `}
              >
                <p>توضیحات فراخوان :</p>
                <p>
                  {currStateData.callOfRegistration.description || "تعریف نشده"}
                </p>
              </div>
              <div
                className={`${styles.dataShower} w-100 d-flex flex-row align-items-center justify-content-between `}
              >
                <p>وضعیت فراخوان :</p>
                <p>
                  {currStateData.callOfRegistration.active
                    ? "فعال"
                    : "غیر فعال"}
                </p>
              </div>
              <div
                className={`${styles.dataShower} w-100 d-flex flex-row align-items-center justify-content-between `}
              >
                <p>تاریخ شروع فراخوان :</p>
                <p>
                  {currStateData.callOfRegistration.startTime.jalaliDate ||
                    "تعریف نشده"}
                </p>
              </div>
              <div
                className={`${styles.dataShower} w-100 d-flex flex-row align-items-center justify-content-between `}
              >
                <p>تاریخ پایان فراخوان :</p>
                <p>
                  {currStateData.callOfRegistration.endTime.jalaliDate ||
                    "تعریف نشده"}
                </p>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default CheckCurrStateOfRegistration;
