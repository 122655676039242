// React
import React, { useContext, useEffect, useMemo, useState } from "react";
// React

// CSS
import styles from "./StepsAndHistoryViewer.module.css";
// CSS

// Components
import TableTab from "./TableTab/TableTab";
import HistoryTab from "./HistoryTab/HistoryTab";
import HistoryDetail from "./HistoryDetail/HistoryDetail";
import UsersDetail from "./UsersDetail/UsersDetail";
import GroupsTab from "./GroupsTab/GroupsTab";
// Components

// Contexts
import {
  ProcessHistoryContext,
  SetProcessHistoryContext,
  T_HistoryType,
} from "../../../Contexts/ProcessHistoryContext/ProcessHistoryContext";
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
// Contexts

// Redux
import { processHistoryResetEvent } from "../../../App";
// Redux

// Modules
import { permissionChecker } from "../../../Modules/permissionChecker";
import { permissionCheckerFull } from "../../../Modules/permissionCheckerFull";
// Modules

// Icons
import { useAppDispatch, useAppSelector } from "../../../app/store";
import StepsTab from "./StepsTab/StepsTab";
// Icons

type StepsAndHistoryViewerProps = {
  selectedStep: string;
  instanceId: string;
  setSelectedStep: React.Dispatch<React.SetStateAction<string>>;
};

export type T_TableTab = {
  name: "Levels" | "History" | "History Detail" | "Users" | "Groups";
  title: string;
  isActive: boolean;
  requiredPermission: {
    oneOf: string[];
    multiAndAllBe: string[];
  };
  extraCheck?: (
    selectedHistoryId: string,
    currentHistoryType?: T_HistoryType,
    length?: number,
    _nameLength?: number
  ) => boolean;
  onClick?: (fn?: () => void) => void;
};

export const tabs: T_TableTab[] = [
  {
    isActive: false,
    name: "Levels",
    requiredPermission: {
      oneOf: [
        "Tenant_Full_Manager",
        "Tenant_Basic_Manager",
        "Process_Full_Manager",
      ],
      multiAndAllBe: ["Running_Process_Manager", "Process_Schema_Analyzer"],
    },
    title: "انتخاب مرحله",
    onClick: () => {
      dispatchEvent(processHistoryResetEvent);
    },
    extraCheck: (_, __, length) =>
      typeof length === "number" ? (length <= 1 ? false : true) : true,
  },
  {
    isActive: false,
    name: "History",
    title: "انتخاب تاریخچه",
    requiredPermission: {
      oneOf: [
        "Tenant_Full_Manager",
        "Tenant_Basic_Manager",
        "Process_Full_Manager",
      ],
      multiAndAllBe: ["Running_Process_Manager", "Process_Schema_Analyzer"],
    },
    onClick: () => {
      // dispatchEvent(processTableSelect);
    },
    extraCheck: (_, __, length) =>
      typeof length === "number" ? (length <= 1 ? false : true) : true,
  },
  {
    isActive: false,
    name: "History Detail",
    requiredPermission: {
      oneOf: [
        "Tenant_Full_Manager",
        "Tenant_Basic_Manager",
        "Process_Full_Manager",
      ],
      multiAndAllBe: ["Running_Process_Manager", "Process_Schema_Analyzer"],
    },
    title: "جزئیات تاریخچه",
    extraCheck: (selectedHistoryId, _, length, _nameLength) =>
      typeof length === "number"
        ? length <= 0
          ? false
          : typeof _nameLength === "number"
          ? _nameLength <= 0
            ? false
            : true
          : false
        : Boolean(
            typeof selectedHistoryId === "string" &&
              selectedHistoryId.length !== 0
          ),
    onClick: () => {
      // dispatchEvent(processTableSelect);
    },
  },
  {
    isActive: false,
    name: "Users",
    requiredPermission: {
      oneOf: [
        "Full_Manager",
        "User_Editor",
        "User_Information_Reader",
        "Running_Process_Manager",
        "Process_Schema_Analyzer",
        "Tenant_Full_Manager",
        "Tenant_Basic_Manager",
        "Full_Manager",
        "User_Editor",
        "User_Information_Reader",
        "Process_Full_Manager",
      ],
      multiAndAllBe: [],
    },
    title: "کاربران اختصاص یافته",
    extraCheck: (selectedHistoryId, currentHistoryType) =>
      Boolean(
        typeof selectedHistoryId === "string" && selectedHistoryId.length !== 0
      ) &&
      typeof currentHistoryType === "string" &&
      currentHistoryType === "userTask",
    onClick: () => {},
  },
  {
    isActive: false,
    name: "Groups",
    requiredPermission: {
      oneOf: [
        "Tenant_Full_Manager",
        "Tenant_Basic_Manager",
        "Process_Full_Manager",
      ],
      multiAndAllBe: ["Running_Process_Manager", "Process_Schema_Analyzer"],
    },
    title: "گروه های اختصاص یافته",
    extraCheck: (selectedHistoryId, currentHistoryType) =>
      Boolean(
        typeof selectedHistoryId === "string" && selectedHistoryId.length !== 0
      ) &&
      typeof currentHistoryType === "string" &&
      currentHistoryType === "userTask",
    onClick: () => {},
  },
];

const initialTab: T_TableTab = {
  isActive: false,
  name: "History",
  title: "انتخاب تاریخچه",
  requiredPermission: {
    oneOf: [
      "Tenant_Full_Manager",
      "Tenant_Basic_Manager",
      "Process_Full_Manager",
    ],
    multiAndAllBe: ["Running_Process_Manager", "Process_Schema_Analyzer"],
  },
  onClick: () => {
    // dispatchEvent(processTableSelect);
  },
};

const StepsAndHistoryViewer: React.FunctionComponent<
  StepsAndHistoryViewerProps
> = ({ instanceId, selectedStep, setSelectedStep }) => {
  const dispatch = useAppDispatch();

  const allGroups = useContext(UserGroupsContext);
  const isFullRendered = useContext(IsFullRenderdContext);

  const [selectedTab, setSelectedTab] = useState<T_TableTab>(initialTab);

  const [isTablesInView, setIsTableInView] = useState<boolean>(true);

  const [extraChild, setExtraChild] = useState<React.ReactElement>(<></>);

  const historyData = useContext(ProcessHistoryContext);

  const setHistoryData = useContext(SetProcessHistoryContext);

  const { data: index1Data } = useAppSelector(
    (state) => state.processInstanceHistorySlice.activityInformation
  );

  const _tabs = useMemo(
    () =>
      tabs.filter((item) => {
        const canShowWithPermission1 = permissionChecker(
          allGroups,
          item.requiredPermission.oneOf,
          isFullRendered
        );
        const canShowWithPermission2 = permissionCheckerFull(
          allGroups,
          item.requiredPermission.multiAndAllBe,
          isFullRendered
        );

        if (canShowWithPermission1) return true;
        if (canShowWithPermission2) return true;

        return false;
      }),
    [allGroups, isFullRendered]
  );

  useEffect(() => {
    const ev = () => {
      // setHistoryData(initialProcessHistoryContextData);
    };

    const ev5 = () => {
      setHistoryData((prevState) => ({ ...prevState, allHistory: [] }));
      setSelectedTab(tabs[1]);
    };

    const ev6 = () => {
      setSelectedTab(tabs[0]);
    };

    window.addEventListener("processHistoryReset", ev);
    window.addEventListener(
      "nextAndPrevButtonClickEventInhProcessHistory",
      ev5
    );
    window.addEventListener("diagramClickEvent", ev6);

    return () => {
      window.removeEventListener("processHistoryReset", ev);
      window.removeEventListener(
        "nextAndPrevButtonClickEventInhProcessHistory",
        ev5
      );
      window.addEventListener("diagramClickEvent", ev6);
    };
  }, [setHistoryData, dispatch]);

  return (
    <div
      className={`${styles.stepsAndHistoryViewerContainer}  px-2 py-2 d-flex flex-column justify-content-between w-100 align-items-center`}
    >
      <div
        className={`d-flex flex-row justify-content-between w-100 align-items-center gap-2`}
      >
        <div className="d-flex flex-row align-items-center gap-2">
          <button
            className={`bgTransparent meloBorder ${
              !isTablesInView && "rotate-180"
            } px-1 py-1 rounded-2`}
            onClick={() => {
              setIsTableInView((prevState) => !prevState);
            }}
          >
            {isTablesInView ? (
              <span
                className={`${styles.icon}  bgTransparent beforeWhite icon-arrow`}
              ></span>
            ) : (
              <span
                className={`${styles.icon} bgTransparent beforeWhite icon-arrow`}
              ></span>
            )}
          </button>
          {extraChild}
        </div>

        <div
          className={`${styles.tableTabsContainer} rounded-2 d-flex flex-row-reverse align-items-center py-1 `}
        >
          {_tabs.length === 0
            ? "در حال بارگذاری"
            : _tabs.map((item, index) =>
                index === 0 ? (
                  historyData.isTopItemsDisabled ? (
                    <TableTab
                      key={item.name}
                      selectedTab={selectedTab}
                      data={item}
                      setSelectedTab={(selectedTab) => {}}
                      onClick={item.onClick}
                      willHidden={historyData.allSteps.length <= 1}
                    />
                  ) : null
                ) : (
                  <TableTab
                    key={item.name}
                    selectedTab={selectedTab}
                    data={item}
                    setSelectedTab={(selectedTab) => {
                      if (typeof item.extraCheck === "function") {
                      }
                      setSelectedTab(selectedTab);
                    }}
                    willHidden={
                      typeof item.extraCheck === "function"
                        ? !item.extraCheck(
                            historyData.selectedHistoryId,
                            historyData.selectedHistoryType,
                            index === 1
                              ? index1Data.content.length
                              : index === 2
                              ? historyData.allHistory.length
                              : 0,
                            index === 1 || index === 2
                              ? historyData.selectedHistoryId.length
                              : 0
                          )
                        : false
                    }
                    onClick={item.onClick}
                  />
                )
              )}
        </div>
      </div>
      {isTablesInView ? (
        <>
          {tabs
            .filter((item) => {
              const canShowWithPermission1 = permissionChecker(
                allGroups,
                item.requiredPermission.oneOf,
                isFullRendered
              );
              const canShowWithPermission2 = permissionCheckerFull(
                allGroups,
                item.requiredPermission.multiAndAllBe,
                isFullRendered
              );

              if (canShowWithPermission1) return true;
              if (canShowWithPermission2) return true;

              return false;
            })
            .map((_tabItem) => {
              if (selectedTab.name === "Levels" && _tabItem.name === "Levels") {
                return (
                  <StepsTab
                    instanceId={instanceId}
                    setSelectedStep={setSelectedStep}
                    selectedStep={selectedStep}
                    setSelectedTab={setSelectedTab}
                  />
                );
              } else if (
                selectedTab.name === "History" &&
                _tabItem.name === "History"
              ) {
                return (
                  <HistoryTab
                    instanceId={instanceId}
                    setSelectedStep={setSelectedStep}
                    selectedStep={selectedStep}
                    setExtraChild={setExtraChild}
                    setSelectedTab={setSelectedTab}
                  />
                );
              } else if (
                selectedTab.name === "History Detail" &&
                _tabItem.name === "History Detail"
              ) {
                return (
                  <HistoryDetail
                    instanceId={instanceId}
                    setSelectedStep={setSelectedStep}
                    selectedStep={selectedStep}
                    setExtraChild={setExtraChild}
                  />
                );
              } else if (
                selectedTab.name === "Users" &&
                _tabItem.name === "Users"
              ) {
                return (
                  <UsersDetail
                    instanceId={instanceId}
                    setExtraChild={setExtraChild}
                  />
                );
              } else if (
                selectedTab.name === "Groups" &&
                _tabItem.name === "Groups"
              ) {
                return (
                  <GroupsTab
                    instanceId={instanceId}
                    setExtraChild={setExtraChild}
                  />
                );
              }

              return null;
            })}

          {/* {selectedTab.name === "Levels" ? (
            <StepsTab
              instanceId={instanceId}
              setSelectedStep={setSelectedStep}
              selectedStep={selectedStep}
              setSelectedTab={setSelectedTab}
            />
          ) : null} */}
          {/* {selectedTab.name === "History" ? (
            <HistoryTab
              instanceId={instanceId}
              setSelectedStep={setSelectedStep}
              selectedStep={selectedStep}
              setExtraChild={setExtraChild}
              setSelectedTab={setSelectedTab}
            />
          ) : null} */}
          {/* {selectedTab.name === "History Detail" ? (
            <HistoryDetail
              instanceId={instanceId}
              setSelectedStep={setSelectedStep}
              selectedStep={selectedStep}
              setExtraChild={setExtraChild}
            />
          ) : null} */}
          {/* {selectedTab.name === "Users" ? (
            <UsersDetail
              instanceId={instanceId}
              setExtraChild={setExtraChild}
            />
          ) : null}
          {selectedTab.name === "Groups" ? (
            <GroupsTab instanceId={instanceId} setExtraChild={setExtraChild} />
          ) : null} */}
        </>
      ) : null}
    </div>
  );
};

export default StepsAndHistoryViewer;
