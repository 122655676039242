// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../Base_Axios_Config/_axios";
// Axios

// Models
import {
  I_Message,
  I_ResultOfEditSingleUserIdentity,
} from "../../Models/interfaces";
import { I_EditSingleAttribute } from "../../Models/sendingDataSchema";
// Models

export const editSingleUserIdentityService = async (_data: {
  userToken: string;
  attributeIdOrKey: string;
  _data: I_EditSingleAttribute;
  tenant: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: I_ResultOfEditSingleUserIdentity;
  }>
> => {
  return _axios.post(
    `/users/identity/attributes/${_data.attributeIdOrKey}/edit?tenant=${_data.tenant}`,
    _data._data,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
