import { AxiosResponse } from "axios";
import { I_EditingSingleCallOfRegistration } from "../../../Models/sendingDataSchema";
import { _axios } from "../../Base_Axios_Config/_axios";
import { I_Message } from "../../../Models/interfaces";

export const editSingleCallOfRegistrationService = async (_data: {
  userToken: string;
  callOfRegistrationId: string | number;
  _data: I_EditingSingleCallOfRegistration;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: {
      id: number;
      title: string;
      description: string;
      active: true;
      startTime: string;
      endTime: string;
      groups: {
        value: string;
        title: string;
        categoryType: {
          value: string;
          title: string;
        };
      }[];
    };
  }>
> => {
  return _axios.post(
    `/call-of-registrations/${_data.callOfRegistrationId}/edit`,
    _data._data,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
