import { _axios } from "../../Base_Axios_Config/_axios";

export const deleteSingleReportService = async (
  userToken: string,
  processKey: string,
  reportId: string
) => {
  return _axios.post(
    `/processes/${processKey}/reports/${reportId}/delete`,
    {},
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
