import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
import { I_AddForm, I_Message } from "../../../Models/interfaces";

export const addNewFormService = async (_data: {
  _data: {
    tenantId: string;
    title: string;
    formType: "Form_Io";
    key: string;
    formData: any;
    processKey: string;
  };
  userToken: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message;
    result: I_AddForm;
  }>
> => {
  //

  console.log(_data._data.formData);
  return _axios.post(
    `/processes/${_data._data.processKey}/forms/add`,
    _data._data,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
