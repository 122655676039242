// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Models
import {
  I_ActivityInformation,
  I_CandidateGroups,
  I_CandidateUsers,
  I_HistoryDetail,
  I_ProcessActivitySteps,
  I_SingleActivity,
} from "../../../Models/interfaces";
import { resetReferenceOfObject } from "../../../Utils/resetReferenceOfObject";
import { getProcessActivityStepsService } from "../../../Services/Processes/ProcessHistory/get/getProcessActivityStepsService";
import { getStepsOfSingleActivityBySchemaIdService } from "../../../Services/Processes/ProcessHistory/get/getStepsOfSingleActivityBySchemaIdService";
import { getSingleActivityHistoryDetailWithProcessInstanceService } from "../../../Services/Processes/ProcessHistory/get/getSingleActivityHistoryDetailWithProcessInstanceService";
import { getActivitiesInformationListByRunningInstanceIdService } from "../../../Services/Processes/ProcessHistory/get/getActivitiesInformationListByRunningInstanceIdService";
import { getCandidateUsersOfActivityHistoryByProcessInstanceIdService } from "../../../Services/Processes/ProcessHistory/get/getCandidateUsersOfActivityHistoryByProcessInstanceIdService";
import { getCandidateGroupsOfActivityHistoryByProcessInstanceIdService } from "../../../Services/Processes/ProcessHistory/get/getCandidateGroupsOfActivityHistoryByProcessInstanceIdService";
import { reduxCatchHandler } from "../../../Utils/ErrorHandlers/reduxCatchHandler";
import { T_AddFunctionToExistObject } from "../../../Models/sendingDataSchema";
import { baseUrl } from "../../../Services/urls";
// Models

const initialState: {
  processActivitySteps: {
    isPending: boolean;
    isError: boolean;
    isDone: boolean;
    data: I_ProcessActivitySteps;
    withOutPendingData: {
      isPending: boolean;
      isError: boolean;
      isDone: boolean;
      data: I_ProcessActivitySteps;
    };
  };
} & {
  activitySteps: {
    isPending: boolean;
    isError: boolean;
    isDone: boolean;
    data: I_SingleActivity;
  };
} & {
  activityInformation: {
    isPending: boolean;
    isError: boolean;
    isDone: boolean;
    data: I_ActivityInformation;
  };
} & {
  historyDetail: {
    isPending: boolean;
    isError: boolean;
    isDone: boolean;
    data: I_HistoryDetail;
  };
} & {
  candidateUsers: {
    isPending: boolean;
    isError: boolean;
    isDone: boolean;
    data: I_CandidateUsers;
  };
} & {
  candidateGroups: {
    isPending: boolean;
    isError: boolean;
    isDone: boolean;
    data: I_CandidateGroups;
  };
} = {
  processActivitySteps: {
    isDone: false,
    isError: false,
    isPending: true,
    data: {
      stepsAfter: {
        hasNextPage: false,
        steps: [],
        totalElements: 1,
      },
      stepsBefore: {
        hasNextPage: false,
        steps: [],
        totalElements: 1,
      },
    },
    withOutPendingData: {
      isDone: false,
      isError: false,
      isPending: true,
      data: {
        stepsAfter: {
          hasNextPage: false,
          steps: [],
          totalElements: 1,
        },
        stepsBefore: {
          hasNextPage: false,
          steps: [],
          totalElements: 1,
        },
      },
    },
  },
  activityInformation: {
    data: {
      content: [],
      hasNextPage: false,
      pageNumber: 1,
      pageSize: 5,
      totalElements: 1,
      totalPages: 1,
    },
    isDone: false,
    isError: false,
    isPending: true,
  },
  activitySteps: {
    isDone: false,
    isError: false,
    isPending: false,
    data: {
      content: [],
      hasNextPage: false,
      pageNumber: 1,
      pageSize: 5,
      totalElements: 1,
      totalPages: 1,
    },
  },
  candidateGroups: {
    isDone: false,
    isError: false,
    isPending: true,
    data: {
      content: [],
      hasNextPage: false,
      pageNumber: 1,
      pageSize: 5,
      totalElements: 1,
      totalPages: 1,
    },
  },
  candidateUsers: {
    isDone: false,
    isError: false,
    isPending: true,
    data: {
      content: [],
      hasNextPage: false,
      pageNumber: 1,
      pageSize: 5,
      totalElements: 1,
      totalPages: 1,
    },
  },
  historyDetail: {
    isDone: false,
    isError: false,
    isPending: true,
    data: {
      activityType: {
        title: "",
        value: "",
      },
      assignee: "",
      callActivityDetail: {
        accessible: false,
        businessKey: "",
        endTime: {
          gregorianDate: "",
          jalaliDate: "",
        },
        instanceId: "",
        process: {
          accessType: {
            title: "",
            value: "",
          },
          deploymentId: "",
          hasStartingForm: false,
          processFileName: "",
          processId: "",
          processKey: "",
          processName: "",
          startable: false,
        },
        startTime: {
          gregorianDate: "",
          jalaliDate: "",
        },
        state: {
          title: "",
          value: "",
        },
      },
      endDate: {
        gregorianDate: "",
        jalaliDate: "",
      },
      finished: false,
      id: "",
      schemaId: "",
      startDate: {
        gregorianDate: "",
        jalaliDate: "",
      },
      stepNumber: 1,
      taskDetail: {
        taskId: "",
        taskTitle: "",
        candidateGroupCount: 1,
        candidateUserCount: 1,
        description: "",
        dueDate: {
          gregorianDate: "",
          jalaliDate: "",
        },
        endTime: {
          gregorianDate: "",
          jalaliDate: "",
        },
        followUpDate: {
          gregorianDate: "",
          jalaliDate: "",
        },
        priority: 1,
        priorityType: {
          maxValue: 1,
          minValue: 1,
          title: "",
        },
        startTime: {
          gregorianDate: "",
          jalaliDate: "",
        },
      },
      title: "",
    },
  },
};

export const getAsyncProcessActivitySteps = createAsyncThunk(
  "getProcessActivity",
  async (_data: {
    userToken: string;
    _data: T_AddFunctionToExistObject<
      T_AddFunctionToExistObject<
        {
          processInstanceId: string;
          stepNumber: string | number;
          size: string | number;
        },
        "onBeforeRequest",
        () => void
      >,
      "onDone",
      (data: any) => void
    >;
  }) => {
    try {
      if (typeof _data._data.onBeforeRequest === "function") {
        _data._data.onBeforeRequest();
      }
      const { data } = await getProcessActivityStepsService(_data);
      if (typeof _data._data.onDone === "function") {
        _data._data.onDone(data.result);
      }
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
      throw err;
    }
  }
);

export const getAsyncProcessActivityStepsWithoutPendingWithSameDataFlow =
  createAsyncThunk(
    "getProcessActivityWithoutPendingWithSameDataFlow",
    async (_data: {
      userToken: string;
      _data: T_AddFunctionToExistObject<
        T_AddFunctionToExistObject<
          {
            processInstanceId: string;
            stepNumber: string | number;
            size: string | number;
          },
          "onBeforeRequest",
          () => void
        >,
        "onDone",
        (data: any) => void
      >;
    }) => {
      try {
        if (typeof _data._data.onBeforeRequest === "function") {
          _data._data.onBeforeRequest();
        }
        const { data } = await getProcessActivityStepsService(_data);
        if (typeof _data._data.onDone === "function") {
          _data._data.onDone(data.result);
        }
        return data;
      } catch (err: any) {
        reduxCatchHandler(err);
        throw err;
      }
    }
  );

export const getAsyncProcessActivityStepsWithoutPending = createAsyncThunk(
  "getProcessActivityWithoutPending",
  async (_data: {
    userToken: string;
    _data: T_AddFunctionToExistObject<
      {
        processInstanceId: string;
        stepNumber: string | number;
        size: string | number;
      },
      "onDone",
      (data: any) => void
    >;
  }) => {
    try {
      const req = await fetch(
        `${baseUrl}/processes/running-instances/${_data._data.processInstanceId}/history/steps?size=${_data._data.size}&step=${_data._data.stepNumber}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${_data.userToken}`,
          },
        }
      );

      const res = await req.json();

      console.clear();
      console.log(res);

      const data: { result: I_ProcessActivitySteps } = res;
      // const { data } = await getProcessActivityStepsService(_data);
      if (typeof _data._data.onDone === "function") {
        _data._data.onDone(data.result);
      }
      return data;
    } catch (err: any) {
      console.clear();
      console.log("err");
      console.log(err);
      // reduxCatchHandler(err);
      throw err;
    }
  }
);

export const getAsyncSingleActivity = createAsyncThunk(
  "getSingleActivity",
  async (_data: {
    userToken: string;
    _data: T_AddFunctionToExistObject<
      T_AddFunctionToExistObject<
        {
          processInstanceId: string | number;
          activitySchemaId: string | number;
          page: string | number;
          size: string | number;
        },
        "onDone",
        <T = I_SingleActivity>(_data: T) => void
      >,
      "onBeforeRequest",
      () => void
    >;
  }) => {
    try {
      if (typeof _data._data.onBeforeRequest === "function") {
        _data._data.onBeforeRequest();
      }
      const { data } = await getStepsOfSingleActivityBySchemaIdService(_data);
      if (typeof _data._data.onDone === "function") {
        _data._data.onDone(data.result);
      }
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
      throw err;
    }
  }
);

export const getAsyncActivityInformation = createAsyncThunk(
  "getActivityInformation",
  async (_data: {
    userToken: string;
    _data: T_AddFunctionToExistObject<
      {
        processInstanceId: string | number;
        search: string;
        page: string | number;
        size: string | number;
        step: string | number;
      },
      "onDone",
      <T = I_ActivityInformation>(data: T) => void
    >;
  }) => {
    try {
      const { data } =
        await getActivitiesInformationListByRunningInstanceIdService(_data);

      if (typeof _data._data.onDone === "function") {
        _data._data.onDone(data.result);
      }

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
      throw err;
    }
  }
);

export const getAsyncHistoryDetail = createAsyncThunk(
  "getHistoryDetail",
  async (_data: {
    userToken: string;
    _data: { processInstanceId: string | number; historyId: string };
  }) => {
    try {
      const { data } =
        await getSingleActivityHistoryDetailWithProcessInstanceService(_data);

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
      throw err;
    }
  }
);

export const getAsyncCandidateUsersForProcessHistory = createAsyncThunk(
  "getCandidateUsersProcessHistory",
  async (_data: {
    userToken: string;
    _data: {
      processInstanceId: string | number;
      historyId: string | number;
      search: string;
      page: string | number;
      size: string | number;
    };
  }) => {
    try {
      const { data } =
        await getCandidateUsersOfActivityHistoryByProcessInstanceIdService(
          _data
        );
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
      throw err;
    }
  }
);

export const getAsyncCandidateGroupsForProcessHistory = createAsyncThunk(
  "getCandidateGroupsForProcessHistory",
  async (_data: {
    userToken: string;
    _data: {
      processInstanceId: string;
      historyId: string;
      page: string | number;
      size: string | number;
      search: string;
    };
  }) => {
    try {
      const { data } =
        await getCandidateGroupsOfActivityHistoryByProcessInstanceIdService(
          _data
        );
      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
      throw err;
    }
  }
);

export const processInstanceHistorySlice = createSlice({
  name: "processInstanceHistorySlice",
  initialState,
  reducers: {
    resetProcessActivitySteps: (state) => {
      state.processActivitySteps.data = resetReferenceOfObject(
        initialState.processActivitySteps.data
      );
      state.processActivitySteps.isDone = false;
      state.processActivitySteps.isError = false;
      state.processActivitySteps.isPending = true;
    },
    resetActivitySteps: (state) => {
      state.activitySteps.data = resetReferenceOfObject(
        initialState.activitySteps.data
      );
      state.activitySteps.isDone = false;
      state.activitySteps.isError = false;
      state.activitySteps.isPending = true;
    },
    resetCandidateGroups: (state) => {
      state.candidateGroups.data = resetReferenceOfObject(
        initialState.candidateGroups.data
      );
      state.candidateGroups.isDone = false;
      state.candidateGroups.isError = false;
      state.candidateGroups.isPending = true;
    },
    resetCandidateUsers: (state) => {
      state.candidateUsers = resetReferenceOfObject(
        initialState.candidateUsers
      );
    },
    resetProcessHistoryDetails: (state) => {
      state.historyDetail.data = resetReferenceOfObject(
        initialState.historyDetail.data
      );
      state.historyDetail.isDone = false;
      state.historyDetail.isError = false;
      state.historyDetail.isPending = true;
    },
    resetStepsTab: (state) => {
      state.processActivitySteps.withOutPendingData = resetReferenceOfObject(
        initialState.processActivitySteps.withOutPendingData
      );
    },
    resetStepActivity: (state) => {
      state.activitySteps = resetReferenceOfObject(initialState.activitySteps);
    },
    resetActivityInformation: (state) => {
      state.activityInformation = resetReferenceOfObject(
        initialState.activityInformation
      );
    },
    resetActivityInformationPending: (state) => {
      state.activityInformation.isPending = true;
      state.activityInformation.isDone = false;
      state.activityInformation.isError = false;
    },
    resetHistoryDetail: (state) => {
      state.historyDetail = resetReferenceOfObject(initialState.historyDetail);
    },
    resetGroupsHistory: (state) => {
      state.candidateGroups = resetReferenceOfObject(
        initialState.candidateGroups
      );
    },
  },
  extraReducers: (builder) => {
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    builder.addCase(getAsyncProcessActivitySteps.pending, (state, action) => {
      state.processActivitySteps.isPending = true;
      state.processActivitySteps.isError = false;
      state.processActivitySteps.isDone = false;
    });
    builder.addCase(getAsyncProcessActivitySteps.fulfilled, (state, action) => {
      state.processActivitySteps.isPending = false;
      state.processActivitySteps.isError = false;
      state.processActivitySteps.isDone = true;
      state.processActivitySteps.data = action.payload.result;
    });
    builder.addCase(getAsyncProcessActivitySteps.rejected, (state, action) => {
      state.processActivitySteps.isPending = false;
      state.processActivitySteps.isError = true;
      state.processActivitySteps.isDone = false;
    });
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    builder.addCase(
      getAsyncProcessActivityStepsWithoutPendingWithSameDataFlow.pending,
      (state, action) => {}
    );
    builder.addCase(
      getAsyncProcessActivityStepsWithoutPendingWithSameDataFlow.fulfilled,
      (state, action) => {
        state.processActivitySteps.data = action.payload.result;
      }
    );
    builder.addCase(
      getAsyncProcessActivityStepsWithoutPendingWithSameDataFlow.rejected,
      (state, action) => {}
    );
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    builder.addCase(
      getAsyncProcessActivityStepsWithoutPending.pending,
      (state, action) => {
        state.processActivitySteps.withOutPendingData.isPending = true;
        state.processActivitySteps.withOutPendingData.isError = false;
        state.processActivitySteps.withOutPendingData.isDone = false;
      }
    );
    builder.addCase(
      getAsyncProcessActivityStepsWithoutPending.fulfilled,
      (state, action) => {
        state.processActivitySteps.withOutPendingData.data =
          action.payload.result;
        state.processActivitySteps.withOutPendingData.isPending = false;
        state.processActivitySteps.withOutPendingData.isError = false;
        state.processActivitySteps.withOutPendingData.isDone = true;
      }
    );
    builder.addCase(
      getAsyncProcessActivityStepsWithoutPending.rejected,
      (state, action) => {
        state.processActivitySteps.withOutPendingData.isPending = false;
        state.processActivitySteps.withOutPendingData.isError = true;
        state.processActivitySteps.withOutPendingData.isDone = false;
      }
    );
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    builder.addCase(getAsyncSingleActivity.pending, (state, action) => {
      state.activitySteps.isPending = true;
      state.activitySteps.isError = false;
      state.activitySteps.isDone = false;
    });
    builder.addCase(getAsyncSingleActivity.fulfilled, (state, action) => {
      state.activitySteps.isPending = false;
      state.activitySteps.isError = false;
      state.activitySteps.isDone = true;
      state.activitySteps.data = action.payload.result;
    });
    builder.addCase(getAsyncSingleActivity.rejected, (state, action) => {
      state.activitySteps.isPending = false;
      state.activitySteps.isError = true;
      state.activitySteps.isDone = false;
      alert(JSON.stringify(action.payload));
    });
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    builder.addCase(getAsyncActivityInformation.pending, (state, action) => {
      state.activityInformation.isPending = true;
      state.activityInformation.isError = false;
      state.activityInformation.isDone = false;
    });
    builder.addCase(getAsyncActivityInformation.fulfilled, (state, action) => {
      state.activityInformation.isPending = false;
      state.activityInformation.isError = false;
      state.activityInformation.isDone = true;
      state.activityInformation.data = action.payload.result;
    });
    builder.addCase(getAsyncActivityInformation.rejected, (state, action) => {
      state.activityInformation.isPending = false;
      state.activityInformation.isError = true;
      state.activityInformation.isDone = false;
    });
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    builder.addCase(getAsyncHistoryDetail.pending, (state, action) => {
      state.historyDetail.isPending = true;
      state.historyDetail.isError = false;
      state.historyDetail.isDone = false;
    });
    builder.addCase(getAsyncHistoryDetail.fulfilled, (state, action) => {
      state.historyDetail.isPending = false;
      state.historyDetail.isError = false;
      state.historyDetail.isDone = true;
      state.historyDetail.data = action.payload.result;
    });
    builder.addCase(getAsyncHistoryDetail.rejected, (state, action) => {
      state.historyDetail.isPending = false;
      state.historyDetail.isError = true;
      state.historyDetail.isDone = false;
    });
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    builder.addCase(
      getAsyncCandidateUsersForProcessHistory.pending,
      (state, action) => {
        state.candidateUsers.isPending = true;
        state.candidateUsers.isError = false;
        state.candidateUsers.isDone = false;
      }
    );
    builder.addCase(
      getAsyncCandidateUsersForProcessHistory.fulfilled,
      (state, action) => {
        state.candidateUsers.isPending = false;
        state.candidateUsers.isError = false;
        state.candidateUsers.isDone = true;
        state.candidateUsers.data = action.payload.result;
      }
    );
    builder.addCase(
      getAsyncCandidateUsersForProcessHistory.rejected,
      (state, action) => {
        state.candidateUsers.isPending = false;
        state.candidateUsers.isError = true;
        state.candidateUsers.isDone = false;
      }
    );
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    /////////////////////////////
    builder.addCase(
      getAsyncCandidateGroupsForProcessHistory.pending,
      (state, action) => {
        state.candidateGroups.isPending = true;
        state.candidateGroups.isError = false;
        state.candidateGroups.isDone = false;
      }
    );
    builder.addCase(
      getAsyncCandidateGroupsForProcessHistory.fulfilled,
      (state, action) => {
        state.candidateGroups.isPending = false;
        state.candidateGroups.isError = false;
        state.candidateGroups.isDone = true;
        state.candidateGroups.data = action.payload.result;
      }
    );
    builder.addCase(
      getAsyncCandidateGroupsForProcessHistory.rejected,
      (state, action) => {
        state.candidateGroups.isPending = false;
        state.candidateGroups.isError = true;
        state.candidateGroups.isDone = false;
      }
    );
  },
});
