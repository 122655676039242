// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../../Base_Axios_Config/_axios";
// Axios

// Models
import { I_PossibleGroupsForCurrUserSendingData } from "../../../Models/sendingDataSchema";
import {
  I_Message,
  I_PossibleAccessibleGroupForCurrUser,
} from "../../../Models/interfaces";
// Models

export const getPossibleGroupsForCurrUserToSubscribeOtherInItService = async (
  userToken: string,
  _data: I_PossibleGroupsForCurrUserSendingData
): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message;
    result: I_PossibleAccessibleGroupForCurrUser;
  }>
> => {
  return _axios.get(
    `/users/accessibility/possible-memberships?page=${_data.page}&size=${_data.size}&search=${_data.search}&tenant=${_data.tenantId}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
  );
};
