export function convertPersianNumbersToEnglish(inputText: string) {
  const persianNumbers = "۰۱۲۳۴۵۶۷۸۹";
  const englishNumbers = "0123456789";

  if (!inputText) return "";

  for (let i = 0; i < persianNumbers.length; i++) {
    const persianNumber = persianNumbers[i];
    const englishNumber = englishNumbers[i];
    const regex = new RegExp(persianNumber, "g");
    inputText = inputText.replace(regex, englishNumber);
  }

  return inputText;
}
