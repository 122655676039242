// React
import React, { useContext, useEffect, useRef } from "react";
// React

// CSS
import styles from "./MapModal.module.css";
// CSS

// Contexts
import { IsNightModeOnContext } from "../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Map
import { MapContainer, TileLayer } from "react-leaflet";
import { defaultLat, defaultLon } from "../../../../Constants/constants";
import { LocationMarker } from "./LocationMarker/LocationMarker";
import CustomInput1 from "../../../CustomInput1/CustomInput1";
// Map

type MapModalProps = {
  setThisModalInScreen: React.Dispatch<React.SetStateAction<boolean>>;
  parentCloseModal: () => void;
  setMapDetails: React.Dispatch<
    React.SetStateAction<{
      circleData: {
        isActive: boolean;
        lat: string;
        lon: string;
        circleRadius: number;
      };
      mapData: {
        lat: string;
        lon: string;
        markerText: string;
      };
    }>
  >;
  mapDetails: {
    circleData: {
      isActive: boolean;
      lat: string;
      lon: string;
      circleRadius: number;
    };
    mapData: {
      lat: string;
      lon: string;
      markerText: string;
    };
  };
};

const MapModal: React.FunctionComponent<MapModalProps> = ({
  setThisModalInScreen,
  // parentCloseModal,
  setMapDetails,
  mapDetails,
}) => {
  const bgRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const isNightModeOn = useContext(IsNightModeOnContext);

  // const closeModalAll = () => {
  //   if (!bgRef.current) return;
  //   if (!contentRef.current) return;

  //   bgRef.current.style.opacity = "0";
  //   contentRef.current.style.top = "100vh";

  //   setTimeout(() => {
  //     setThisModalInScreen(false);

  //     setTimeout(() => {
  //       parentCloseModal();
  //     });
  //   }, 500);
  // };

  const closeModal = () => {
    if (!bgRef.current) return;
    if (!contentRef.current) return;

    bgRef.current.style.opacity = "0";
    contentRef.current.style.top = "100vh";

    setTimeout(() => {
      setTimeout(() => {
        setThisModalInScreen(false);
      });
    }, 500);
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!bgRef.current) return;
      if (!contentRef.current) return;

      bgRef.current.style.opacity = "0.7";
      contentRef.current.style.top = "0px";
    }, 50);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <div
      className={`${styles.mapModalComponentContainer} d-flex flex-row align-items-center justify-content-center`}
    >
      <div
        className={`${styles.bgRef}`}
        ref={bgRef}
        onClick={() => {
          closeModal();
        }}
      ></div>
      <div
        className={`${styles.content} d-flex flex-column align-items-center ${
          isNightModeOn ? "nightBg2" : ""
        }`}
        ref={contentRef}
      >
        <CustomInput1
          defaultValue={mapDetails.mapData.markerText}
          inputType="text"
          onBlur={() => {}}
          onChange={(e) => {
            setMapDetails((prevState) => ({
              ...prevState,
              mapData: {
                ...prevState.mapData,
                markerText: e.target.value,
              },
            }));
          }}
          placeHolder="نوشته در Marker"
          value={mapDetails.mapData.markerText}
          containerClassName="w-100"
          dontShowSearchIcon
        />

        {mapDetails.circleData.isActive ? (
          <CustomInput1
            defaultValue={String(mapDetails.circleData.circleRadius)}
            inputType="number"
            onBlur={() => {}}
            onChange={(e) => {
              setMapDetails((prevState) => ({
                ...prevState,
                circleData: {
                  ...prevState.circleData,
                  circleRadius: Number.isNaN(e.target.valueAsNumber)
                    ? 500
                    : e.target.valueAsNumber,
                },
              }));
            }}
            placeHolder="بزرگی دایره"
            value={String(mapDetails.circleData.circleRadius)}
            containerClassName="w-100 mt-3"
            dontShowSearchIcon
          />
        ) : null}

        <MapContainer
          zoom={13}
          center={[
            Number(mapDetails.mapData.lat) || defaultLat,
            Number(mapDetails.mapData.lon) || defaultLon,
          ]}
          className={`${styles.mapContainer} `}
          dragging
          fadeAnimation
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <LocationMarker
            setMapDetails={setMapDetails}
            mapDetails={mapDetails}
          />
        </MapContainer>

        <button
          className="operationEvent submitBtn w-100 d-flex flex-row align-items-center justify-content-center"
          onClick={closeModal}
        >
          تایید
        </button>
      </div>
    </div>
  );
};

export default MapModal;
