// React
import React, { useMemo } from "react";
// React

// CSS
import styles from "./../../Components/Sidebar/Sidebar.module.css";
import { LanguageType } from "../../Models/types";
import { sidebarLanguage } from "../../Language/sidebarLanguage";
// CSS

// Redux
// import { useAppSelector } from "../app/store";
// Redux

const useSidebarItems = (language: LanguageType) => {
  // const userParams = useAppSelector((state) => state.searchAndBack.usersPage);
  // const {
  //   tasksPage: taskParams,
  //   processPage,
  //   allocatedProcesses,
  //   formSample: formSamples,
  //   serverManagement,
  //   callOfRegistration,
  //   posts,
  //   securityEvents,
  // } = useAppSelector((state) => state.searchAndBack);

  const _lang = sidebarLanguage[language];

  const sidebarItems: {
    path: string;
    logo: React.ReactElement | React.ReactNode;
    title: string;
    requierdPerimitions: string[];
  }[] = useMemo(
    () => [
      {
        logo: (
          <span
            className="icon-home"
            style={{
              fontSize: "2rem",
            }}
          ></span>
        ),
        path: "/",
        title: _lang.home,
        requierdPerimitions: ["Active_Users"],
      },
      {
        logo: (
          <span
            className="icon-nametenants"
            style={{
              fontSize: "2rem",
            }}
          ></span>
        ),
        path: "/accessible-tenants?page=1&size=10",
        title: _lang.tenant,
        requierdPerimitions: ["Tenant_Full_Manager", "Tenant_Basic_Manager"],
      },
      {
        logo: (
          <span
            className="icon-users"
            style={{
              fontSize: "2rem",
            }}
          ></span>
        ),
        ///?page=${userParams.page}&size=${userParams.size}
        path: `/users`,
        title: _lang.users,
        requierdPerimitions: [
          "Full_Manager",
          "Tenant_Full_Manager",
          "Tenant_Basic_Manager",
          "User_Editor",
          "User_Information_Reader",
        ],
      },
      {
        logo: (
          <span
            className="icon-group"
            style={{
              fontSize: "2rem",
            }}
          ></span>
        ),
        path: "/groups",
        title: _lang.groups,
        requierdPerimitions: ["Active_Users"],
      },
      ///?page=${taskParams.pageNumber}&size=${taskParams.size}
      {
        logo: <span className={`icon-tasks ${styles["icon-tasks"]}`}></span>,
        path: `/tasks`,
        title: _lang.tasks,
        requierdPerimitions: ["Active_Users"],
      },
      {
        logo: (
          <span
            className="icon-flow"
            style={{
              fontSize: "2rem",
            }}
          ></span>
        ),
        ///?page=${processPage.pageNumber}&size=${processPage.size}
        path: `/processes`,
        title: _lang.processes,
        requierdPerimitions: [
          "Tenant_Full_Manager",
          "Tenant_Basic_Manager",
          "Process_Full_Manager",
          "Process_Staff_Editor",
          "Process_Editor",
          "Process_Starter",
          "Process_Schema_Analyzer",
          "Process_Form_Editor",
          "Running_Process_Manager",
          "Running_Process_Task_Manager",
        ],
      },
      {
        logo: (
          <span
            className="icon-nameallocated-processes"
            style={{
              fontSize: "2rem",
            }}
          ></span>
        ),
        ///?page=${allocatedProcesses.pageNumber}&size=${allocatedProcesses.size}
        path: `/allocated-processes`,
        title: _lang.allocatedProcesses,
        requierdPerimitions: ["Active_Users"],
      },
      {
        logo: (
          <span
            className="icon-nameform-samples "
            style={{
              fontSize: "2rem",
            }}
          ></span>
        ),
        ///?page=${formSamples.pageNumber}&size=${formSamples.size}
        path: `/form-samples`,
        title: _lang.formTemplates,
        requierdPerimitions: [
          "Tenant_Full_Manager",
          "Tenant_Basic_Manager",
          "Process_Form_Editor",
          "Process_Full_Manager",
          "Form_Sample_Manager",
        ],
      },
      {
        logo: (
          <span
            className="icon-data-base"
            style={{
              fontSize: "2rem",
            }}
          ></span>
        ),
        ///?page=${serverManagement.pageNumber}&size=${serverManagement.size}
        path: `/servers`,
        title: _lang.servers,
        requierdPerimitions: [
          "Tenant_Full_Manager",
          "Tenant_Basic_Manager",
          "Settings_Editor",
        ],
      },
      {
        logo: (
          <span
            className="icon-nameuser-identities"
            style={{
              fontSize: "2rem",
            }}
          ></span>
        ),
        path: "/user-identities",
        title: _lang.userIdentities,
        requierdPerimitions: [
          "Tenant_Full_Manager",
          "Tenant_Basic_Manager",
          "Settings_Editor",
        ],
      },
      {
        logo: (
          <span
            className="icon-namecall-of-registration"
            style={{
              fontSize: "2rem",
            }}
          ></span>
        ),
        ///?page=${callOfRegistration.pageNumber}&size=${callOfRegistration.size}
        path: `/call-of-registration`,
        title: _lang.callOfRegistration,
        requierdPerimitions: ["Active_Users"],
      },
      {
        logo: (
          <span
            className="icon-nameposts"
            style={{
              fontSize: "2rem",
            }}
          ></span>
        ),
        ///?page=${posts.pageNumber}&size=${posts.size}
        path: `/posts`,
        title: _lang.posts,
        requierdPerimitions: ["Active_Users"],
      },
      {
        logo: (
          <span
            className="icon-namesecurity-events"
            style={{
              fontSize: "2rem",
            }}
          ></span>
        ),
        ///?page=${securityEvents.pageNumber}&size=${securityEvents.size}
        path: `/security-events`,
        title: _lang.securityEvents,
        requierdPerimitions: [
          "Security_Event_Reader",
          "Security_Event_Manager",
        ],
      },
    ],
    [
      // userParams,
      // taskParams,
      // processPage,
      // allocatedProcesses,
      // formSamples,
      // serverManagement,
      // callOfRegistration,
      // posts,
      // securityEvents,
      _lang,
    ]
  );
  return sidebarItems;
};

export default useSidebarItems;
