// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../Base_Axios_Config/_axios";
// Axios

// Models
import { I_Message } from "../../Models/interfaces";
import { UserIdentity } from "../../Models/types";
// Models

export const getAllUserIdentitiesService = async (_data: {
  userToken: string;
  tenantId: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: UserIdentity[];
  }>
> => {
  return _axios.get(`/users/identity/attributes?tenant=${_data.tenantId}`, {
    headers: {
      Authorization: `Bearer ${_data.userToken}`,
    },
  });
};
