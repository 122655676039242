import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserAccessibleProcessesService } from "../../Services/Users/get/getUserAccessibleProcessesService";
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { I_AccessibleUserProcess } from "../../Models/interfaces";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";

const initialState: I_AccessibleUserProcess & {
  isGettingUserProcessPending: boolean;
  isError: boolean;
} = {
  pageNumber: 1,
  pageSize: 0,
  totalPages: 0,
  totalElements: 0,
  hasNextPage: false,
  content: [],

  // add by Developer
  isGettingUserProcessPending: false,
  isError: false,
  // add by Developer
};

// Thunks
export const getAsyncAccessibleUserProcesses = createAsyncThunk(
  "singleUserAccessibleProcess/getAccessibleUserProcesses",
  async (_data: {
    userToken: string;
    userName: string;
    search: string;
    page: number | string;
    size: number | string;
  }) => {
    try {
      const { data } = await getUserAccessibleProcessesService(
        _data.userToken,
        _data.userName,
        _data.search,
        _data.page,
        _data.size
      );
      //
      return data;
      //
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);
//
// Thunks

export const singleUserAccessibleProcessSlice = createSlice({
  name: "singleUserAccessibleProcess",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getAsyncAccessibleUserProcesses.pending,
      (state, action) => {
        state.isGettingUserProcessPending = true;
        state.isError = false;
      }
    );
    builder.addCase(
      getAsyncAccessibleUserProcesses.fulfilled,
      (state, action) => {
        state.isGettingUserProcessPending = false;

        const err: any = action.payload;

        if (err?.error !== 0) {
          for (
            let i: number = 0;
            i <= err?.response.data.messages.length - 1;
            i++
          ) {
            customizedToast(err?.response.data.messages[i].message, "error");
          }
          return;
        } else {
          state.pageNumber = action.payload!.result.pageNumber;
          state.pageSize = action.payload!.result.pageSize;
          state.totalPages = action.payload!.result.totalPages;
          state.totalElements = action.payload!.result.totalElements;
          state.hasNextPage = action.payload!.result.hasNextPage;
          state.content = action.payload!.result.content;
          state.isError = false;
        }
      }
    );
    builder.addCase(
      getAsyncAccessibleUserProcesses.rejected,
      (state, action) => {
        state.isGettingUserProcessPending = false;
        state.isError = true;
      }
    );
  },
});
