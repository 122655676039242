// Redux
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// Redux

// Services
import { submitTaskFormService } from "../../Services/Tasks/post/submitTaskFormService";
import { getAllTasksService } from "../../Services/Tasks/get/getAllTasksService";
// Services

// Utils
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
// Utils

// Models
import { I_TaskDetail, I_TasksData } from "../../Models/interfaces";
import { I_SendingDataForGettingTasks } from "../../Models/sendingDataSchema";
import { getTaskDetailsByTaskIdInTasksService } from "../../Services/Tasks/get/getTaskDetailsByTaskIdInTasksService";
import { resetReferenceOfObject } from "../../Utils/resetReferenceOfObject";
import { reduxCatchHandler } from "../../Utils/ErrorHandlers/reduxCatchHandler";
// Models

const initialState: { taskData: I_TasksData } & {
  submitTaskForm: {
    isPending: boolean;
    isFormSubmited: boolean;
  };
} & {
  singleTaskDetail: {
    isSingleTaskDetailPending: boolean;
    isError: boolean;
    singleTaskDetail: I_TaskDetail;
  };
} = {
  taskData: {
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
    totalElements: 1,
    hasNextPage: false,
    content: [],
    isPending: true,
  },
  submitTaskForm: {
    isPending: true,
    isFormSubmited: false,
  },
  singleTaskDetail: {
    isSingleTaskDetailPending: true,
    isError: false,
    singleTaskDetail: {
      taskId: "",
      processId: "",
      processInstanceId: "",
      taskTitle: "",
      dueDate: {
        gregorianDate: "",
        jalaliDate: "",
      },
      followUpDate: {
        gregorianDate: "",
        jalaliDate: "",
      },
      priority: 0,
      priorityType: {
        minValue: 0,
        maxValue: 0,
        title: "",
      },
      schemaId: "",
      description: "",
      form: {
        id: 0,
        title: "",
        formType: {
          value: "",
          title: "",
        },
        key: "",
        processKey: "",
        url: "",
      },
    },
  },
};

export const postAsyncTaskForm = createAsyncThunk(
  "tasks/sendTask",
  async (_data: { userToken: string; formData: any; taskId: string }) => {
    try {
      const { data } = await submitTaskFormService(
        _data.userToken,
        _data.taskId,
        _data.formData
      );
      return data.messages;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncTasks = createAsyncThunk(
  "tasks/getTasks",
  async (_data: { _data: I_SendingDataForGettingTasks; userToken: string }) => {
    try {
      const { data } = await getAllTasksService(_data);

      return data;
    } catch (err: any) {
      reduxCatchHandler(err);
    }
  }
);

export const getAsyncTaskDetailByTaskIdInTasks = createAsyncThunk(
  "tasks/getTaskDetailByTaskIdInTasks",
  async (_data: { userToken: string; taskId: string; onError: () => void }) => {
    try {
      const { data } = await getTaskDetailsByTaskIdInTasksService(
        _data.userToken,
        _data.taskId
      );
      return data;
    } catch (err: any) {
      if (_data.onError) {
        _data.onError();
      }
      reduxCatchHandler(err);
    }
  }
);

export const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    resetTaskDetail: (state) => {
      state.singleTaskDetail = JSON.parse(
        JSON.stringify(state.singleTaskDetail)
      );
      state.singleTaskDetail.isSingleTaskDetailPending = true;
    },
    resetFormSubmitStatus: (state) => {
      state.submitTaskForm.isPending = false;
      state.submitTaskForm.isFormSubmited = false;
    },
    resetTasks: (state) => {
      state.taskData.content = [];
      state.taskData = resetReferenceOfObject(state.taskData);
      state.taskData.isPending = true;
    },
    sortTaskData: (state, action) => {
      state.taskData.content = action.payload;
    },
    resetTaskPending: (state) => {
      state.taskData.isPending = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postAsyncTaskForm.pending, (state) => {
      state.submitTaskForm.isPending = true;
      state.submitTaskForm.isFormSubmited = false;
    });
    builder.addCase(postAsyncTaskForm.fulfilled, (state) => {
      state.submitTaskForm.isPending = false;
      state.submitTaskForm.isFormSubmited = true;
      customizedToast("فرم با موفقیت ارسال شد", "success");
    });
    builder.addCase(postAsyncTaskForm.rejected, (state) => {
      state.submitTaskForm.isPending = false;
      state.submitTaskForm.isFormSubmited = false;
    });
    //
    //
    //
    //
    //
    //
    //
    //
    builder.addCase(getAsyncTasks.pending, (state, action) => {
      state.taskData.isPending = true;
    });
    builder.addCase(getAsyncTasks.fulfilled, (state, action) => {
      state.taskData.isPending = false;

      if (!action.payload?.result) {
        return;
      }

      if (action.payload?.error === 0 || Boolean(action.payload) === true) {
        for (let i: number = 0; i <= action.payload!.messages.length - 1; i++) {
          customizedToast(action.payload!.messages[i].message, "success");
        }
      }
      state.taskData.isPending = action.payload!.result.isPending;

      state.taskData.pageNumber = action.payload!.result.pageNumber;
      state.taskData.pageSize = action.payload!.result.pageSize;
      state.taskData.totalPages = action.payload!.result.totalPages;
      state.taskData.totalElements = action.payload!.result.totalElements;
      state.taskData.hasNextPage = action.payload!.result.hasNextPage;
      state.taskData.content = action.payload!.result.content;
    });
    builder.addCase(getAsyncTasks.rejected, (state, action) => {
      state.taskData.isPending = false;
    });
    //
    //
    //
    //
    //
    //
    //
    //
    builder.addCase(
      getAsyncTaskDetailByTaskIdInTasks.pending,
      (state, action) => {
        state.singleTaskDetail.isSingleTaskDetailPending = true;
        state.singleTaskDetail.isError = false;
      }
    );
    builder.addCase(
      getAsyncTaskDetailByTaskIdInTasks.fulfilled,
      (state, action) => {
        state.singleTaskDetail.isSingleTaskDetailPending = false;

        if (action.payload?.error !== 0) return;
        for (let i: number = 0; i <= action.payload?.messages.length - 1; i++) {
          customizedToast(action.payload?.messages[i].message, "success");
        }

        state.singleTaskDetail.singleTaskDetail = action.payload.result;
        state.singleTaskDetail.singleTaskDetail.form =
          action.payload.result.form;
        state.singleTaskDetail.isError = false;
      }
    );
    builder.addCase(
      getAsyncTaskDetailByTaskIdInTasks.rejected,
      (state, action) => {
        state.singleTaskDetail.isSingleTaskDetailPending = false;
        state.singleTaskDetail.isError = true;
      }
    );
  },
});
