// Types
import { LanguageType } from "../../Models/types";
// Types

// Language Data
import { breadCrumbsLanguage } from "../../Language/breadCrumbsLanguage";
// Language Data

export const useBreadCrumbsItems = (language: LanguageType) => {
  const _lang = breadCrumbsLanguage[language];

  const data = [
    { path: "/", breadcrumb: _lang.home },
    //
    //
    //
    //
    //
    // USERS
    { path: "/users", breadcrumb: _lang.users },
    {
      path: "/users/add-user",
      breadcrumb: _lang.addUsers,
    },
    { path: "/users/single-user", breadcrumb: null },
    {
      path: "/users/single-user/:userId",
      breadcrumb: _lang.singleUser,
    },
    // USERS
    //
    //
    //
    //
    //
    // Profile
    { path: "/profile", breadcrumb: _lang.profile },
    // Profile
    //
    //
    //
    //
    //
    // Forms
    { path: "/forms", breadcrumb: null },
    { path: "/forms/show-form-by-process-key", breadcrumb: null },
    { path: "/forms/show-form-by-process-key/:processKey", breadcrumb: null },
    {
      path: "/forms/show-form-by-process-key/:processKey/:persianProcessName",
      breadcrumb: _lang.showRelatedForms,
    },
    { path: "/forms/show-form-by-process-key", breadcrumb: null },
    { path: "/forms/show-form-by-process-key/new-form", breadcrumb: null },
    {
      path: "/forms/show-form-by-process-key/new-form/:processKey",
      breadcrumb: null,
    },
    {
      path: "/forms/show-form-by-process-key/new-form/:processKey/:persianProcessName",
      breadcrumb: _lang.addForm,
    },
    {
      path: "/forms/show-form-by-process-key/new-form/:processKey/:persianProcessName/:tenantId",
      breadcrumb: "",
    },
    { path: "/forms/show-form-by-process-key", breadcrumb: null },
    {
      path: "/forms/show-form-by-process-key/show-selected-form",
      breadcrumb: _lang.showForm,
    },
    { path: "/forms/show-form-by-process-key", breadcrumb: null },
    {
      path: "/forms/show-form-by-process-key/edit-selected-form",
      breadcrumb: null,
    },
    {
      path: "/forms/show-form-by-process-key/edit-selected-form/:processKey",
      breadcrumb: null,
    },
    {
      path: "/forms/show-form-by-process-key/edit-selected-form/:processKey/:formId",
      breadcrumb: null,
    },

    {
      path: "/forms/show-form-by-process-key/edit-selected-form/:processKey/:formId/:persianProcessName",
      breadcrumb: _lang.editSelectedForm,
    },
    // Forms
    //
    //
    //
    //
    //
    // Process
    { path: "/processes", breadcrumb: _lang.processes },
    {
      path: "/processes/show-process-by-processKey",
      breadcrumb: null,
    },
    {
      path: "/processes/show-process-by-processKey/:processKey/",
      breadcrumb: null,
    },
    {
      path: "/processes/show-process-by-processKey/:processKey/:persianProcessKey",
      breadcrumb: _lang.showSelectedProcess,
    },
    {
      path: "/processes/show-process-by-processKey/:processKey/:persianProcessKey/:tanentId",
      breadcrumb: _lang.showSelectedProcess,
    },
    { path: "/processes/show-tasks-by-processKey", breadcrumb: null },
    {
      path: "/processes/show-tasks-by-processKey/:processKey",
      breadcrumb: null,
    },
    {
      path: "/processes/show-tasks-by-processKey/:processKey/:persianProcessName",
      breadcrumb: _lang.showProcessTasks,
    },
    {
      path: "/processes/view-more-about-selected-task",
      breadcrumb: null,
    },
    {
      path: "/processes/view-more-about-selected-task/:processKey",
      breadcrumb: null,
    },
    {
      path: "/processes/view-more-about-selected-task/:processKey/:taskId",
      breadcrumb: _lang.showProcessTask,
    },
    { path: "/processes/view-running-instances", breadcrumb: null },
    { path: "/processes/view-running-instances/:processKey", breadcrumb: null },
    {
      path: "/processes/view-running-instances/:processKey/:presianProcessName",
      breadcrumb: _lang.showRunningInstances,
    },
    {
      path: "/processes/view-running-instances/:processKey/:presianProcessName/:tenantId",
      breadcrumb: _lang.showRunningInstances,
    },

    { path: "/processes/edit-process-by-processKey", breadcrumb: null },
    {
      path: "/processes/edit-process-by-processKey/:processFileName",
      breadcrumb: null,
    },
    {
      path: "/processes/edit-process-by-processKey/:processFileName/:processKey",
      breadcrumb: _lang.editProcess,
    },
    {
      path: "/processes/edit-process-by-processKey/:processFileName/:processKey/:tenantId",
      breadcrumb: _lang.editProcess,
    },

    {
      path: "/processes/new-process",
      breadcrumb: _lang.addProcess,
    },
    {
      path: "/processes/new-process/fast-create",
      breadcrumb: _lang.addProcessFastCreate,
    },

    {
      path: "/processes/complete-starting-form-by-processKey",
      breadcrumb: null,
    },
    {
      path: "/processes/complete-starting-form-by-processKey/:processKey",
      breadcrumb: null,
    },
    {
      path: "/processes/complete-starting-form-by-processKey/:processKey/:persianProcessName",
      breadcrumb: _lang.sendStartForm,
    },
    {
      path: "/processes/complete-starting-form-by-processKey/:processKey/:persianProcessName/:tenantId",
      breadcrumb: _lang.sendStartForm,
    },

    { path: "/processes/show-instance-data-by-instance-id", breadcrumb: null },
    {
      path: "/processes/show-instance-data-by-instance-id/:instanceId",
      breadcrumb: _lang.showInstanceData,
    },
    // Process
    //
    //
    //
    //
    //
    // Reports
    { path: "/processes/reports", breadcrumb: null },
    { path: "/processes/reports/:processKey", breadcrumb: null },
    {
      path: "/processes/reports/:processKey/:persianProcessName",
      breadcrumb: _lang.showProcessReport,
    },
    { path: "/processes/reports/new-report", breadcrumb: null },
    { path: "/processes/reports/new-report/:processKey", breadcrumb: null },
    {
      path: "/processes/reports/new-report/:processKey/:persianProcessName",
      breadcrumb: _lang.makeNewReport,
    },
    {
      path: "/processes/reports/new-report/:processKey/:persianProcessName",
      breadcrumb: null,
    },
    // Reports
    //
    //
    //
    //
    //
    // Groups
    { path: "/groups", breadcrumb: _lang.groups },
    {
      path: "/groups/new-group",
      breadcrumb: _lang.addIndirectMembership,
    },
    {
      path: "/groups/new-group/indirect-membership",
      breadcrumb: _lang.addGroupOnGroup,
    },
    {
      path: "/groups/new-group/group-on-group",
      breadcrumb: _lang.addGroupOnGroup,
    },
    { path: "/groups/edit-public-groups", breadcrumb: null },
    { path: "/groups/edit-public-groups/:groupId", breadcrumb: null },
    {
      path: "/groups/edit-public-groups/:groupId/:groupPersianName",
      breadcrumb: _lang.editGroup,
    },
    // Groups
    //
    //
    //
    //
    //
    // Tasks
    { path: "/tasks", breadcrumb: _lang.tasks },
    { path: "/tasks/task-detail", breadcrumb: null },
    {
      path: "/tasks/task-detail/:taskId",
      breadcrumb: _lang.taskDetails,
    },
    { path: "/tasks/submit-task-form", breadcrumb: null },
    {
      path: "/tasks/submit-task-form/:taskId",
      breadcrumb: _lang.sendTaskForm,
    },
    // Tasks
    //
    //
    //
    //
    //
    // Server Management
    {
      path: "/servers",
      breadcrumb: _lang.serverManagement,
    },
    {
      path: "/servers/new-server",
      breadcrumb: _lang.addNewServer,
    },
    // Server Management
    //
    //
    //
    //
    //
    // Tenants
    {
      path: "/accessible-tenants",
      breadcrumb: _lang.tenants,
    },
    {
      path: "/accessible-tenants/new-tenant",
      breadcrumb: _lang.addTenant,
    },
    // Tenants
    //
    //
    //
    //
    //
    // User Identities
    {
      path: "/user-identities",
      breadcrumb: _lang.userIdentities,
    },
    {
      path: "/user-identities/new-identity",
      breadcrumb: _lang.addFieldToUserIdentity,
    },
    { path: "/user-identities/show-selected-identity", breadcrumb: null },
    {
      path: "/user-identities/show-selected-identity/:identityId",
      breadcrumb: null,
    },
    {
      path: "/user-identities/show-selected-identity/:identityId/:persianName",
      breadcrumb: _lang.showFieldOfUserIdentity,
    },
    {
      path: "/user-identities/create-sub-attribute-for-single-userIdentity-by-id/",
      breadcrumb: _lang.addOptionToSingleUserIdentity,
    },

    { path: "/user-identities/edit-single-attribute", breadcrumb: null },
    {
      path: "/user-identities/edit-single-attribute/:identityId",
      breadcrumb: null,
    },
    {
      path: "/user-identities/edit-single-attribute/:identityId/:persianName",
      breadcrumb: _lang.editFieldOfUserIdentity,
    },
    {
      path: "/user-identities/add-option-to-single-attribute",
      breadcrumb: null,
    },
    {
      path: "/user-identities/add-option-to-single-attribute/:identityId",
      breadcrumb: null,
    },
    {
      path: "/user-identities/add-option-to-single-attribute/:identityId/:persianName",
      breadcrumb: _lang.addOptionToSingleAttribute,
    },
    {
      path: "/user-identities/add-option-to-single-attribute/edit-selected-attribute",
      breadcrumb: null,
    },
    {
      path: "/user-identities/add-option-to-single-attribute/edit-selected-attribute/:attributeId",
      breadcrumb: null,
    },
    {
      path: "/user-identities/add-option-to-single-attribute/edit-selected-attribute/:attributeId/:optionId",
      breadcrumb: null,
    },
    {
      path: "/user-identities/add-option-to-single-attribute/edit-selected-attribute/:attributeId/:optionId/:persianName",
      breadcrumb: _lang.connectedIdentities,
    },
    // User Identities
    //
    //
    //
    //
    //
    // Call Of Registration
    {
      path: "/call-of-registration",
      breadcrumb: _lang.callOfRegistration,
    },
    { path: "/call-of-registration/identities", breadcrumb: null },
    { path: "/call-of-registration/identities/details", breadcrumb: null },
    {
      path: "/call-of-registration/identities/details/:callOfRegistrationId",
      breadcrumb: _lang.showDetailsOfCallOfRegistration,
    },

    {
      path: "/call-of-registration/single-callOf-registration",
      breadcrumb: null,
    },
    {
      path: "/call-of-registration/single-callOf-registration/details",
      breadcrumb: null,
    },
    {
      path: "/call-of-registration/single-callOf-registration/details/:callOfRegistrationId",
      breadcrumb: _lang.showDetailsOfCallOfRegistration,
    },

    { path: "call-of-registration/requests", breadcrumb: null },
    { path: "call-of-registration/requests/details", breadcrumb: null },
    {
      path: "call-of-registration/requests/details/:callOfRegistrationId",
      breadcrumb: _lang.showRequestsOfCallOfRegistration,
    },

    {
      path: "/call-of-registration/add-new",
      breadcrumb: _lang.addCallOfRegistration,
    },
    { path: "/call-of-registration/edit", breadcrumb: null },
    { path: "/call-of-registration/edit/edit-single", breadcrumb: null },
    {
      path: "/call-of-registration/edit/edit-single/:callOfRegistrationId",
      breadcrumb: _lang.editCallOfRegistration,
    },
    // Call Of Registration
    //
    //
    //
    //
    //
    // Posts
    { path: "/posts", breadcrumb: _lang.posts },
    {
      path: "/posts/edit-single-post",
      breadcrumb: _lang.editPost,
    },
    {
      path: "/posts/show-details-of-single-post",
      breadcrumb: _lang.seePost,
    },
    {
      path: "/posts/add-new",
      breadcrumb: _lang.addPost,
    },
    // Posts
    //
    //
    //
    //
    //
    // Security-events
    {
      path: "/security-events",
      breadcrumb: _lang.securityEvent,
    },
    // Security-events
    //
    //
    //
    //
    //
    // form-samples
    {
      path: "/form-samples",
      breadcrumb: _lang.formSamples,
    },
    { path: "/form-samples/edit-single-form", breadcrumb: null },
    {
      path: "/form-samples/edit-single-form/:formId",
      breadcrumb: null,
    },
    {
      path: "/form-samples/edit-single-form/:formId/:persianFromName",
      breadcrumb: _lang.editFormSamples,
    },
    {
      path: "/form-samples/view-selected-form-sample",
      breadcrumb: _lang.editFormSamples,
    },
    {
      path: "/form-samples/add-new-sample",
      breadcrumb: _lang.addFormSamples,
    },
    // form-samples
    //
    //
    //
    //
    //
    // allocated-processes
    {
      path: "/allocated-processes",
      breadcrumb: _lang.allocatedProcesses,
    },
    {
      path: "/allocated-processes/start-new-instance-with-form",
      breadcrumb: null,
    },
    {
      path: "/allocated-processes/start-new-instance-with-form/:processName",
      breadcrumb: null,
    },
    {
      path: "/allocated-processes/start-new-instance-with-form/:processName/:persianProcessName",
      breadcrumb: _lang.showFormOfAllocatedProcesses,
    },

    // allocated-processes
    //
    //
    //
    //
    //
  ];
  return data;
};
