// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./EditSingleCallOfRegistrationRequest.module.css";
// CSS

// Modules
import { useLocation, useNavigate, useParams } from "react-router-dom";
// Modules

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  callOfRegistrationSlice,
  editAsyncRegistrationRequest,
} from "../../../Features/CallOfRegistrationSlice/callOfRegistrationSlice";
// Redux

// Context
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Context

// Models
import { I_ChangingStateOfARequestSendingData } from "../../../Models/sendingDataSchema";
// Models

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import { Spinner } from "reactstrap";
import CustomSelectOption2 from "../../../Utils/CustomSelectOption2/CustomSelectOption2";
// Utils

const EditSingleCallOfRegistrationRequest = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { requestId } = useParams();

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { registrationRequestState } = useAppSelector(
    (state) => state.allRoles.data
  );
  const { isPending, isDone } = useAppSelector(
    (state) => state.callOfRegistration.editSingleRegistrationRequest
  );

  const isNightModeOn = useContext(IsNightModeOnContext);

  const [editData, setEditData] = useState<
    {
      state: number | string;
    } & I_ChangingStateOfARequestSendingData
  >({
    state: "",
    rejectingDescription: "",
    firstname: "",
    lastname: "",
    email: "",
    username: "",
    password: "",
    extraIdentityInformation: {},
  });

  useEffect(() => {
    if (Boolean(state.id)) return;
    if (Boolean(state.trackingCode)) return;

    navigate("/call-of-registration");
  }, [state, navigate]);

  useEffect(() => {
    if (!Boolean(state.id)) return;

    setEditData(state);
  }, [state]);

  useEffect(() => {
    if (!isDone) return;

    navigate("/call-of-registration");
  }, [isDone, navigate]);

  useEffect(() => {
    return () => {
      dispatch(
        callOfRegistrationSlice.actions.resetEditingSingleRegistrationRequestState()
      );
    };
  }, [dispatch]);

  return (
    <div
      className={`${
        styles.editSingleRequestContainer
      } w-100 d-flex flex-column align-items-center ${
        isNightModeOn ? "nightBg1" : ""
      } `}
    >
      <p className="w-100 d-flex flex-row align-items-center justify-content-end">
        وضعیت
      </p>

      <CustomSelectOption2
        getSelectedOption={(data) => {
          setEditData((prevState: any) => ({
            ...prevState,
            state: data,
          }));
        }}
        initialTitle="لطفا انتخاب کنید"
        initialValue={""}
        options={Object.keys(registrationRequestState).map((item) => ({
          title: registrationRequestState[item].title,
          value: registrationRequestState[item].value,
        }))}
        containerClassName="w-100"
      />

      <div className="d-flex flex-row-reverse align-items-center justify-content-between  flex-wrap">
        {String(editData.state) === "3" ? (
          <CustomInput1
            inputType="text"
            onBlur={() => {}}
            onChange={(e) => {
              setEditData((prevState) => ({
                ...prevState,
                rejectingDescription: e.target.value,
              }));
            }}
            placeHolder="علت لغو"
            dontShowSearchIcon
            containerClassName="mt-4"
            value={editData.rejectingDescription}
            defaultValue={editData.rejectingDescription}
          />
        ) : null}
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          onChange={(e) => {
            setEditData((prevState) => ({
              ...prevState,
              firstname: e.target.value,
            }));
          }}
          placeHolder="نام"
          dontShowSearchIcon
          containerClassName="mt-4"
          value={editData.firstname}
          defaultValue={String(editData.firstname)}
        />
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          onChange={(e) => {
            setEditData((prevState) => ({
              ...prevState,
              lastname: e.target.value,
            }));
          }}
          placeHolder="نام خانوادگی"
          dontShowSearchIcon
          containerClassName="mt-4"
          value={editData.lastname}
          defaultValue={editData.lastname}
        />
        <CustomInput1
          inputType="email"
          onBlur={() => {}}
          onChange={(e) => {
            setEditData((prevState) => ({
              ...prevState,
              email: e.target.value,
            }));
          }}
          placeHolder="ایمیل"
          dontShowSearchIcon
          containerClassName="mt-4"
          value={editData.email}
          defaultValue={editData.email}
        />
        <CustomInput1
          inputType="text"
          onBlur={() => {}}
          onChange={(e) => {
            setEditData((prevState) => ({
              ...prevState,
              username: e.target.value,
            }));
          }}
          placeHolder="نام کاربری"
          dontShowSearchIcon
          containerClassName="mt-4"
          value={editData.username}
          defaultValue={editData.username}
        />
        <CustomInput1
          inputType="password"
          onBlur={() => {}}
          onChange={(e) => {
            setEditData((prevState) => ({
              ...prevState,
              password: e.target.value,
            }));
          }}
          placeHolder="رمز عبور"
          dontShowSearchIcon
          containerClassName="mt-4"
          value={editData.password}
          defaultValue={editData.password}
        />
      </div>

      <button
        className="operationEvent submitBtn align-self-start mt-4"
        onClick={() => {
          dispatch(
            editAsyncRegistrationRequest({
              userToken,
              requestId: String(requestId),
              _data: editData,
            })
          );
        }}
      >
        {isPending ? <Spinner /> : <>تایید</>}
      </button>
    </div>
  );
};

export default EditSingleCallOfRegistrationRequest;
