// Axios
import { AxiosResponse } from "axios";
import { _axios } from "../Base_Axios_Config/_axios";
// Axios

// Models
import { I_Message } from "../../Models/interfaces";
import { UserIdentityOption } from "../../Models/types";
// Models

export const getAllOptionsForSingleIdentityService = async (_data: {
  userToken: string;
  attributeId: string;
  tenant: string;
}): Promise<
  AxiosResponse<{
    error: number;
    messages: I_Message[];
    result: UserIdentityOption[];
  }>
> => {
  return _axios.get(
    `/users/identity/attributes/${_data.attributeId}/options?tenant=${_data.tenant}`,
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
