// React
import React, { useContext, useEffect } from "react";
// React

// CSS
import styles from "./FullUserAttributes.module.css";
// CSS

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import { Spinner } from "reactstrap";
import Swal from "sweetalert2";
// Utils

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncAllIdentityAttributeWithOptions,
  removeAsyncUserIdentity,
  userIdentitiesSlice,
} from "../../../Features/userIdentitiesSlice/userIdentitiesSlice";
// Redux

// Icons
import { MdDone } from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
import { CgDetailsMore } from "react-icons/cg";
// Icons

// Contexts
import { IsFullRenderdContext } from "../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { UserGroupsContext } from "../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

// Modules
import { useNavigate } from "react-router-dom";
import { permitionCheckerWithoutToast } from "../../../Modules/permitionCheckerWithoutToast";
// Modules

const FullUserAttributes = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { selectedTenant } = useAppSelector((state) => state.tenants);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const userIdentitiesData = useAppSelector(
    (state) => state.userIdentities.userIdentitiesWithOptions
  );

  const language = useAppSelector((state) => state.currLanguage.language);

  const isFullRenderd = useContext(IsFullRenderdContext);
  const allGroups = useContext(UserGroupsContext);
  const isNightOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    if (!isFullRenderd) return;
    dispatch(
      getAsyncAllIdentityAttributeWithOptions({
        userToken,
        tenantId: selectedTenant.tenantId,
      })
    );
  }, [isFullRenderd, dispatch, userToken, selectedTenant]);

  useEffect(() => {
    return () => {
      dispatch(userIdentitiesSlice.actions.resetUserIdentitiesWithOptions());
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.fullUserAttributesContainer} ${
        isNightOn ? "nightBg1" : ""
      } w-100 d-flex flex-column align-items-center`}
    >
      <div
        className={`${styles.topItems} w-100 d-flex align-items-center justify-content-between mb-4`}
      >
        <button
          className={`operationEvent submitBtn`}
          onClick={() => {
            navigate(`/user-identities/new-identity`);
          }}
        >
          افزودن شناسه جدید
          <span className="icon-Plus ml-4"></span>
        </button>
        <p
          style={{
            color: "#111D64",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          شناسه های کاربران
        </p>
      </div>
      <CustomInput1
        inputType="text"
        onBlur={() => {
          return false;
        }}
        onChange={() => {
          return false;
        }}
        value={selectedTenant.tenantTitle}
        defaultValue={selectedTenant.tenantTitle}
        placeHolder="نام سازمان"
        containerClassName="w-50 align-self-end"
        dontShowSearchIcon
        disabled
      />

      {userIdentitiesData.isPending ? (
        <div
          className={`${styles.loadingContainer} mt-4 d-flex flex-column align-items-center justify-content-between`}
        >
          <p>در حال بارگذاری</p>
          <Spinner />
        </div>
      ) : (
        <>
          {window.innerWidth > 1000 ? (
            <div
              className={`${styles.tableDataContainer} d-flex align-items-center w-100 flex-column`}
              style={{
                height: "100%",
                width: "100%",
                marginTop: "1rem",
                position: "relative",
              }}
              id="usersReport"
            >
              <div
                className={`w-100 d-flex align-items-center  justify-content-end ${styles.exportsContainer}`}
              ></div>
              {userIdentitiesData.data.length === 0 ? (
                <>موردی وجود ندارد</>
              ) : (
                <div
                  className={`${styles.myTable} ${
                    isNightOn ? "nightBg2 pcNight" : ""
                  } w-100 d-flex flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    <p className={`${styles.accessible}`}>قابل دسترس</p>
                    <p className={`${styles.searchable}`}>قابل جستجو</p>
                    <p className={`${styles.isUnique}`}>خاص</p>
                    <p className={`${styles.isRequired}`}>الزامی</p>
                    <p className={`${styles.title}`}>نوع</p>
                    <p className={`${styles.key}`}>کلید</p>
                    <p className={`${styles.title}`}>نام</p>
                    <p className={`${styles.id}`}>شمارنده</p>
                  </div>
                  <div
                    className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                  >
                    {userIdentitiesData.data.map(
                      ({
                        availableOnAccessList,
                        id,
                        key,
                        maximumValue,
                        minimumValue,
                        required,
                        searchable,
                        title,
                        type,
                        unique,
                        identityOptions,
                      }) => (
                        <div
                          key={id}
                          className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                        >
                          <p
                            className={`${styles.operationTab} d-flex flex-row align-items-center flex-wrap`}
                          >
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Identity_Attributes_Manager",
                              ],
                              isFullRenderd
                            ) && (
                              <button
                                onClick={() => {
                                  Swal.fire({
                                    icon: "question",
                                    title:
                                      "آیا از حذف این شناسه اطمینان دارید ؟",
                                    text: "این عملیات بدون بازگشت میباشد",
                                    cancelButtonText: "لغو",
                                    confirmButtonText: "پاک کن",
                                    showCancelButton: true,
                                    customClass: isNightOn ? "swalNight" : "",
                                  }).then(({ isConfirmed }) => {
                                    if (!isConfirmed) return;
                                    dispatch(
                                      removeAsyncUserIdentity({
                                        userToken,
                                        attributeIdOrKey: String(key),
                                        tenant: selectedTenant.tenantId,
                                      })
                                    ).then(() => {
                                      dispatch(
                                        getAsyncAllIdentityAttributeWithOptions(
                                          {
                                            userToken,
                                            tenantId: selectedTenant.tenantId,
                                          }
                                        )
                                      );
                                    });
                                  });
                                }}
                                className="operationEvent deleteBtn"
                                data-tooltip-content={language.delete}
                                data-tooltip-id="my-tooltip"
                              >
                                <span className="icon-trash"></span>
                              </button>
                            )}
                            {type.value === "SINGLE_SELECTION" && (
                              <button
                                className={` operationEvent  editBtn`}
                                onClick={() => {
                                  navigate(
                                    `/user-identities/show-selected-identity/${key}/${title}?identityOptions=${JSON.stringify(
                                      identityOptions
                                    )}&canShowMoreBtn=${
                                      type.value === "SINGLE_SELECTION"
                                    }`
                                  );
                                }}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={language.more}
                              >
                                <CgDetailsMore />
                              </button>
                            )}
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Identity_Attributes_Manager",
                              ],
                              isFullRenderd
                            ) && (
                              <button
                                className="operationEvent editBtn"
                                onClick={() => {
                                  navigate(
                                    `/user-identities/edit-single-attribute/${key}/${title}`,
                                    {
                                      state: {
                                        type,
                                        data: {
                                          availableOnAccessList,
                                          id,
                                          key,
                                          maximumValue,
                                          minimumValue,
                                          required,
                                          searchable,
                                          title,
                                          type,
                                          unique,
                                          identityOptions,
                                        },
                                      },
                                    }
                                  );
                                }}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={language.edit}
                              >
                                <span className="icon-edit"></span>
                              </button>
                            )}
                            {permitionCheckerWithoutToast(
                              allGroups,
                              [
                                "Tenant_Full_Manager",
                                "Tenant_Basic_Manager",
                                "Identity_Attributes_Manager",
                              ],
                              isFullRenderd
                            ) &&
                              type.value === "SINGLE_SELECTION" && (
                                <button
                                  className="operationEvent taskBtn"
                                  onClick={() => {
                                    navigate(
                                      `/user-identities/add-option-to-single-attribute/${key}/${title}`
                                    );
                                  }}
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={language.add}
                                >
                                  <span className="icon-Plus"></span>
                                </button>
                              )}
                          </p>

                          <p className={`${styles.accessible}`}>
                            {availableOnAccessList ? (
                              <MdDone
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            ) : (
                              <BsDashLg
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            )}
                          </p>
                          <p className={`${styles.searchable}`}>
                            {searchable ? (
                              <MdDone
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            ) : (
                              <BsDashLg
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            )}
                          </p>
                          <p className={`${styles.isUnique}`}>
                            {unique ? (
                              <MdDone
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            ) : (
                              <BsDashLg
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            )}
                          </p>
                          <p className={`${styles.isRequired}`}>
                            {required ? (
                              <MdDone
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            ) : (
                              <BsDashLg
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            )}
                          </p>
                          <p className={`${styles.title}`}>{type.title}</p>
                          <p className={`${styles.key}`}>{key}</p>
                          <p className={`${styles.title}`}>{title}</p>
                          <p className={`${styles.id}`}>{id}</p>
                        </div>
                      )
                    )}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              )}
            </div>
          ) : (
            <div
              className={`${styles.cards} w-100 d-flex flex-column align-items-center`}
            >
              {userIdentitiesData.data.map(
                ({
                  availableOnAccessList,
                  id,
                  key,
                  maximumValue,
                  minimumValue,
                  required,
                  searchable,
                  title,
                  type,
                  unique,
                  identityOptions,
                }) => (
                  <div key={id} className="w-100 mt-5">
                    <div
                      className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                    >
                      <p>{id}</p>
                      <p>شمارنده </p>
                    </div>
                    <div
                      className={`${styles.tableRow} ${
                        isNightOn ? "nightBg2" : ""
                      } w-100 d-flex align-items-center justify-content-between `}
                    >
                      <p>
                        <span>نام</span>
                        <span>{title}</span>
                      </p>
                      <p>
                        <span>کلید </span>
                        <span>{key}</span>
                      </p>
                      <p>
                        <span>نوع</span>
                        <span>{type.title}</span>
                      </p>
                      <p>
                        <span>الزامی </span>
                        <span>
                          {required ? (
                            <MdDone
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          ) : (
                            <BsDashLg
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          )}
                        </span>
                      </p>
                      <p>
                        <span>خاص </span>
                        <span>
                          {unique ? (
                            <MdDone
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          ) : (
                            <BsDashLg
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          )}
                        </span>
                      </p>
                      <p>
                        <span> قابل جستجو </span>
                        <span>
                          {searchable ? (
                            <MdDone
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          ) : (
                            <BsDashLg
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          )}
                        </span>
                      </p>
                      <p>
                        <span> قابل دسترس </span>
                        <span>
                          {availableOnAccessList ? (
                            <MdDone
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          ) : (
                            <BsDashLg
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          )}
                        </span>
                      </p>
                      <p>
                        <span>عملیات</span>
                        <span className="d-flex flex-row items-center">
                          {permitionCheckerWithoutToast(
                            allGroups,
                            [
                              "Tenant_Full_Manager",
                              "Tenant_Basic_Manager",
                              "Identity_Attributes_Manager",
                            ],
                            isFullRenderd
                          ) && (
                            <button
                              onClick={() => {
                                Swal.fire({
                                  icon: "question",
                                  title: "آیا از حذف این شناسه اطمینان دارید ؟",
                                  text: "این عملیات بدون بازگشت میباشد",
                                  cancelButtonText: "لغو",
                                  confirmButtonText: "پاک کن",
                                  showCancelButton: true,
                                  customClass: isNightOn ? "swalNight" : "",
                                }).then(({ isConfirmed }) => {
                                  if (!isConfirmed) return;
                                  dispatch(
                                    removeAsyncUserIdentity({
                                      userToken,
                                      attributeIdOrKey: String(key),
                                      tenant: selectedTenant.tenantId,
                                    })
                                  ).then(() => {
                                    dispatch(
                                      getAsyncAllIdentityAttributeWithOptions({
                                        userToken,
                                        tenantId: selectedTenant.tenantId,
                                      })
                                    );
                                  });
                                });
                              }}
                              className="operationEvent deleteBtn"
                              data-tooltip-content={language.delete}
                              data-tooltip-id="my-tooltip"
                            >
                              <span className="icon-trash"></span>
                            </button>
                          )}
                          {type.value === "SINGLE_SELECTION" && (
                            <button
                              className={` operationEvent  editBtn`}
                              onClick={() => {
                                navigate(
                                  `/user-identities/show-selected-identity/${key}/${title}`,
                                  {
                                    state: {
                                      identityOptions,
                                      canShowMoreBtn:
                                        type.value === "SINGLE_SELECTION",
                                    },
                                  }
                                );
                              }}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={language.more}
                            >
                              <CgDetailsMore />
                            </button>
                          )}
                          {permitionCheckerWithoutToast(
                            allGroups,
                            [
                              "Tenant_Full_Manager",
                              "Tenant_Basic_Manager",
                              "Identity_Attributes_Manager",
                            ],
                            isFullRenderd
                          ) && (
                            <button
                              className="operationEvent editBtn"
                              onClick={() => {
                                navigate(
                                  `/user-identities/edit-single-attribute/${key}/${title}`,
                                  {
                                    state: {
                                      type,
                                      data: {
                                        availableOnAccessList,
                                        id,
                                        key,
                                        maximumValue,
                                        minimumValue,
                                        required,
                                        searchable,
                                        title,
                                        type,
                                        unique,
                                        identityOptions,
                                      },
                                    },
                                  }
                                );
                              }}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={language.edit}
                            >
                              <span className="icon-edit"></span>
                            </button>
                          )}
                          {permitionCheckerWithoutToast(
                            allGroups,
                            [
                              "Tenant_Full_Manager",
                              "Tenant_Basic_Manager",
                              "Identity_Attributes_Manager",
                            ],
                            isFullRenderd
                          ) &&
                            type.value === "SINGLE_SELECTION" && (
                              <button
                                className="operationEvent taskBtn"
                                onClick={() => {
                                  navigate(
                                    `/user-identities/add-option-to-single-attribute/${key}/${title}`
                                  );
                                }}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content={language.add}
                              >
                                <span className="icon-Plus"></span>
                              </button>
                            )}
                        </span>
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FullUserAttributes;
