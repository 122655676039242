// React
import React, { useContext, useEffect, useRef, useState } from "react";
// React

// CSS
import styles from "./FastCreateSaveModal.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {
  addAsyncSingleProcess,
  processesSlice,
} from "../../../../../Features/ProcessesSlice/ProcessesSlice";
// Redux

// Moduels
import { permissionChecker } from "../../../../../Modules/permissionChecker";
import { useNavigate } from "react-router-dom";
// Moduels

// Utils
import { Spinner } from "reactstrap";
import CustomInput1 from "../../../../../Utils/CustomInput1/CustomInput1";
// Utils

// Contexts
import { UserGroupsContext } from "../../../../../Contexts/UserGroupsContext/UserGroupsContext";
import { IsFullRenderdContext } from "../../../../../Contexts/UserGroupsContext/IsFullRenderdContext";
import { permitionCheckerWithoutToast } from "../../../../../Modules/permitionCheckerWithoutToast";
import { ExecutingPermissionsContext } from "../../../../../Contexts/ExecutingPermissionsContext/ExecutingPermissionsContext";
import CustomSelectOption2 from "../../../../../Utils/CustomSelectOption2/CustomSelectOption2";
import { IsNightModeOnContext } from "../../../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Contexts

type FastCreateSaveModalProps = {
  xmlData: string;
  setIsModalInScreen: React.Dispatch<React.SetStateAction<boolean>>;
};

const FastCreateSaveModal: React.FunctionComponent<
  FastCreateSaveModalProps
> = ({ setIsModalInScreen, xmlData }) => {
  //
  const bgRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const allGroups = useContext(UserGroupsContext);

  const { isSendingProcessPending, isAddingProcessDone } = useAppSelector(
    (state) => state.processes
  );

  const isFullRenderd = useContext(IsFullRenderdContext);
  const permitions = useContext(ExecutingPermissionsContext);

  const isNightModeOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (!bgRef.current) return;
      if (!contentRef.current) return;

      bgRef.current.style.opacity = "0.7";
      contentRef.current.style.top = "0px";
    }, 50);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  const selectedTenant = useAppSelector(
    (state) => state.tenants.selectedTenant
  );

  const [formsData, setFormsData] = useState<{
    tenantId: string;
    fileName: string;
    accessType: string;
  }>({
    tenantId: selectedTenant.tenantId,
    fileName: "",
    accessType: "PUBLIC",
  });

  const closeModal = () => {
    if (!bgRef.current) return;
    if (!contentRef.current) return;

    bgRef.current.style.opacity = "0";
    contentRef.current.style.top = "100vh";

    setTimeout(() => {
      setIsModalInScreen(false);
    }, 500);
  };

  useEffect(() => {
    if (isAddingProcessDone === true) {
      navigate(`/processes`);
      return;
    }

    return () => {
      dispatch(processesSlice.actions.resetIsAddingProcessDone());
    };
  }, [isAddingProcessDone, navigate, dispatch]);

  return (
    <div
      className={`${styles.fastCreateModalContainer} w-100 d-flex align-items-center justify-content-center`}
    >
      <div
        className={`${styles.bgRef}`}
        ref={bgRef}
        onClick={() => {
          closeModal();
        }}
      ></div>
      <div
        className={`${styles.content}  ${isNightModeOn ? "nightBg1" : ""} `}
        ref={contentRef}
      >
        <div
          className={`${styles.dataReceiver} w-100 d-flex align-items-center justify-content-between flex-column`}
        >
          {permitionCheckerWithoutToast(
            allGroups,
            permitions.canSeeTenantTextBox,
            isFullRenderd
          ) && (
            <CustomInput1
              value={selectedTenant.tenantTitle}
              defaultValue={selectedTenant.tenantTitle}
              inputType="text"
              onChange={(e) => {
                return false;
              }}
              onBlur={(e) => {
                return false;
              }}
              placeHolder="نام سازمان"
              //  keyForShowOnTop="نام سازمان"
              dontShowSearchIcon
              disabled
            />
          )}
          <CustomSelectOption2
            getSelectedOption={(data) => {
              setFormsData((prevState) => ({
                ...prevState,
                accessType: String(data),
              }));
            }}
            initialTitle="نوع فرآیند (پیشفرض : عمومی )"
            initialValue={"PUBLIC"}
            options={[
              {
                title: "عمومی",
                value: "PUBLIC",
              },
              {
                title: "خصوصی",
                value: "PRIVATE",
              },
            ]}
            containerClassName="mt-3"
          />

          <CustomInput1
            onChange={(e) => {
              setFormsData((prevState) => ({
                ...prevState,
                fileName: e.target.value,
              }));
            }}
            value={formsData.fileName}
            defaultValue={formsData.fileName}
            inputType="text"
            onBlur={(e) => {
              setFormsData((prevState) => ({
                ...prevState,
                fileName: e.target.value,
              }));
            }}
            placeHolder="نام فایل"
            // keyForShowOnTop="نام فایل"
            dontShowSearchIcon
            containerStyle={{ marginTop: "16px" }}
          />

          <button
            className={` operationEvent   submitBtn `}
            style={{
              marginTop: "16px",
            }}
            onClick={() => {
              if (
                !permissionChecker(
                  allGroups,
                  [
                    "Tenant_Full_Manager",
                    "Tenant_Basic_Manager",
                    "Process_Full_Manager",
                    "Process_Editor",
                  ],
                  isFullRenderd
                )
              )
                return;
              if (isSendingProcessPending) return;
              dispatch(
                addAsyncSingleProcess({
                  _data: {
                    ...formsData,
                    bpmnXmlData: xmlData,
                  },
                  userToken,
                })
              );
            }}
          >
            {isSendingProcessPending ? <Spinner /> : <>تایید</>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FastCreateSaveModal;
