// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./EditSingleIdentityAttribute.module.css";
// CSS

// Modules
import { useNavigate, useParams } from "react-router-dom";
// Modules

// Utils
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
// Utils

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { Spinner } from "reactstrap";
import {
  editAsyncSingleExistingOptionOfUserAttribute,
  userIdentitiesSlice,
} from "../../../Features/userIdentitiesSlice/userIdentitiesSlice";
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
// Redux

const EditSingleIdentityAttribute = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const isNightOn = useContext(IsNightModeOnContext);

  const { attributeId, persianName, optionId } = useParams();
  const [newName, setNewName] = useState<string>("");

  const { isPending, isDone } = useAppSelector(
    (state) => state.userIdentities.editSingleExistingOptionOfIdentity
  );
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { selectedTenant } = useAppSelector((state) => state.tenants);

  useEffect(() => {
    if (!isDone) return;
    navigate(
      `/user-identities/add-option-to-single-attribute/${attributeId}/${persianName}`
    );
  }, [isDone, navigate, attributeId, persianName]);

  useEffect(() => {
    return () => {
      dispatch(userIdentitiesSlice.actions.resetEditingSingleOption());
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.editSingleIdentityAttributeContainer} ${
        isNightOn ? "nightBg1" : ""
      } d-flex flex-column align-items-center `}
    >
      <p
        className="d-flex flex-row align-items-center justify-content-start w-100 "
        style={{
          direction: "rtl",
        }}
      >
        <span>تعویض نام برای فیلد</span>
        &nbsp; : &nbsp;
        <span>{persianName}</span>
      </p>
      <CustomInput1
        inputType="text"
        onBlur={() => {}}
        onChange={(e) => {
          setNewName(e.target.value);
        }}
        placeHolder="نام جدید این فیلد را وارد کنید"
        containerClassName="mt-2 w-100"
        value={newName}
        defaultValue={newName}
        dontShowSearchIcon
      />
      <button
        className="operationEvent submitBtn align-self-start mt-3"
        onClick={() => {
          dispatch(
            editAsyncSingleExistingOptionOfUserAttribute({
              userToken,
              tenant: selectedTenant.tenantId,
              _data: {
                attributeIdOrKey: String(attributeId),
                optionId: String(optionId),
              },
              optionName: newName,
            })
          );
        }}
      >
        {isPending ? <Spinner /> : "تایید"}
      </button>
    </div>
  );
};

export default EditSingleIdentityAttribute;
