// React
import React from "react";
// React

// CSS
import styles from "./Pending.module.css";
// CSS

// Components
import { Spinner } from "reactstrap";
// Components

type PendingProps = {
  className?: string;
};

const Pending: React.FunctionComponent<PendingProps> = ({ className }) => {
  return (
    <div
      className={`${styles.pendingContainer} d-flex flex-column align-items-center justify-content-center w-100 ${className}`}
    >
      <p>در حال بارگذاری</p>
      <Spinner />
    </div>
  );
};

export default Pending;
