// React
import React, { useCallback, useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./AllocatedProcesses.module.css";
// CSS

// Redux
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  allocatedProcessesSlice,
  getAsyncAllocatedProcesses,
  startAsyncNewAllocatedProcessInstance,
} from "../../Features/AllocatedProcessesSlice/allocatedProcessesSlice";
// Redux

// Context
import { IsNightModeOnContext } from "../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import { IsFullRenderdContext } from "../../Contexts/UserGroupsContext/IsFullRenderdContext";
// Context

// Models
import { I_GettingAllocatedProcessesRequestData } from "../../Models/sendingDataSchema";
// Models

// Utils
import Pagination from "../../Utils/Pagination/Pagination";
import CustomInput1 from "../../Utils/CustomInput1/CustomInput1";
import { customizedToast } from "../../Utils/CustomizedToast/customizedToast";
import { CSVLink } from "react-csv";
// Utils

// Modules
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdDone } from "react-icons/md";
import { BsDashLg } from "react-icons/bs";
// Modules

// Components
import StartAllocatedProcessModal from "./StartAllocatedProcessModal/StartAllocatedProcessModal";
import { searchAndBackSlice } from "../../Features/SearchAndBackSlice/searchAndBackSlice";
import Pending from "../../Components/Pending/Pending";
import Swal from "sweetalert2";
// Components

const AllocatedProcesses = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const isNightModeOn = useContext(IsNightModeOnContext);

  const isFullRenderd = useContext(IsFullRenderdContext);

  const [searchParam, setSearchParam] = useSearchParams();

  const [formsData, setFormsData] =
    useState<I_GettingAllocatedProcessesRequestData>({
      page: Number(searchParam.get("page")) || 1,
      size: Number(searchParam.get("size")) || 10,
      search: searchParam.get("search") || "",
    });
  const [isModalInScreen, setIsModalInScreen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{
    processKey: string;
    processId: string;
  }>({
    processId: "",
    processKey: "",
  });

  const {
    data: allocatedProcessData,
    isPending,
    isError,
  } = useAppSelector((state) => state.allocatedProcesses.allocatedProcessData);

  const { accessToken: userToken } = useAppSelector((state) => state.loginData);

  const csvReport = {
    headers: [
      {
        label: "شمارنده",
        key: "id",
      },
      {
        label: "نام فرم",
        key: "title",
      },
      {
        label: "کلید فرم",
        key: "_key",
      },
      {
        label: "کلید فرآیند",
        key: "_processKey",
      },
      {
        label: "آدرس فایل",
        key: "url",
      },
    ],
    data: allocatedProcessData.content.map((item, index) => ({})),
    filename: `allocated_process.csv`,
  };

  const dataGetter = useCallback(() => {
    dispatch(
      getAsyncAllocatedProcesses({
        userToken,
        _data: formsData,
      })
    );
  }, [userToken, formsData, dispatch]);

  useEffect(() => {
    if (!isFullRenderd) return;
    const timeOut = setTimeout(dataGetter, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dataGetter, isFullRenderd]);

  useEffect(() => {
    return () => {
      dispatch(allocatedProcessesSlice.actions.resetAllocatedProcessesState());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      searchAndBackSlice.actions.setAllocatedProcessesData({
        pageNumber: formsData.page,
        size: formsData.size,
      })
    );
  }, [dispatch, formsData]);

  useEffect(() => {
    setSearchParam(
      {
        page: String(formsData.page) || 1 + "",
        size: String(formsData.size) || 10 + "",
        search: formsData.search,
      },
      {
        replace: true,
      }
    );
  }, [formsData, setSearchParam, searchParam]);

  return (
    <div
      className={`w-100 d-flex flex-column align-items-center ${
        styles.allocatedProcessesContainer
      } ${isNightModeOn ? "nightBg1" : ""}`}
    >
      <div className="w-100 d-flex flex-row-reverse align-items-center justify-content-between">
        <p
          style={{
            color: "#111D64",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          لیست رویه های کاری
        </p>

        <CSVLink
          {...csvReport}
          style={{
            textDecoration: "none",
            color: "#fff",
            background: "#3AC150",
            padding: "6px 10px",
            borderRadius: "8px",
          }}
        >
          csv خروجی
        </CSVLink>
      </div>
      <CustomInput1
        inputType="text"
        value={formsData.search}
        defaultValue={formsData.search}
        onBlur={() => {}}
        onChange={(e) => {
          setFormsData((prevState) => ({
            ...formsData,
            search: e.target.value,
          }));
        }}
        placeHolder="جستجو"
        containerClassName="align-self-end my-2"
      />

      {isPending && !isError ? <Pending className="mt-4" /> : null}
      {!isPending && isError ? (
        <button
          onClick={() => {
            dispatch(
              getAsyncAllocatedProcesses({
                userToken,
                _data: formsData,
              })
            );
          }}
        >
          مشکلی پیش آمده برای تلاش دوباره کلیک کنید
        </button>
      ) : null}
      {!isPending && !isError ? (
        <>
          {allocatedProcessData.content.length === 0 ? (
            <>موردی وجود ندارد</>
          ) : (
            <>
              {window.innerWidth > 1000 ? (
                <div
                  className={`${styles.myTable} mt-4 ${
                    isNightModeOn ? "nightBg2 pcNight" : ""
                  } w-100 d-flex flex-column align-items-center align-items-center`}
                >
                  <div
                    className={`${styles.tableHead}  w-100 d-flex align-items-center justify-content-between align-items-center`}
                  >
                    <p className={`${styles.operationTab}`}>عملیات</p>
                    <p className={`${styles.hasStartingForm}`}>فرم اولیه</p>
                    <p className={`${styles.accessType}`}> دسترسی</p>
                    <p className={`${styles.startable}`}>قابل شروع</p>
                    <p className={`${styles.processName}`}>نام فرآیند</p>
                    <p className={`${styles.processKey}`}>کد فرآیند</p>
                    <p className={`${styles.processId}`}>شمارنده</p>
                  </div>
                  <div
                    className={`${styles.tableBody} w-100 d-flex align-items-center flex-column`}
                  >
                    {allocatedProcessData.content.map(
                      ({
                        accessType,
                        deploymentId,
                        hasStartingForm,
                        processId,
                        processKey,
                        processName,
                        startable,
                        initialInformationRequired,
                      }) => (
                        <div
                          key={processId}
                          className={`${styles.tableData} w-100 d-flex align-items-center justify-content-between align-items-center`}
                        >
                          <p className={`${styles.operationTab}`}>
                            {startable ? (
                              <>
                                {hasStartingForm ? (
                                  <button
                                    className="operationEvent startBtn"
                                    onClick={() => {
                                      navigate(
                                        `/allocated-processes/start-new-instance-with-form/${processKey}/${processName}`
                                      );
                                    }}
                                  >
                                    <span className="icon-start"></span>
                                  </button>
                                ) : (
                                  <button
                                    className="operationEvent startBtn"
                                    onClick={() => {
                                      if (initialInformationRequired) {
                                        setIsModalInScreen(true);
                                        setModalData({
                                          processId,
                                          processKey,
                                        });
                                      } else {
                                        Swal.fire({
                                          icon: "question",
                                          title: `فرآیند ${processName} شروع شود ؟`,
                                          showCancelButton: true,
                                          cancelButtonText: "لغو",
                                          confirmButtonText: "تایید",
                                        }).then(({ isConfirmed }) => {
                                          if (!isConfirmed) return;
                                          customizedToast(
                                            "درخواست شروع ارسال شد",
                                            "info"
                                          );
                                          dispatch(
                                            startAsyncNewAllocatedProcessInstance(
                                              {
                                                userToken,
                                                _data: {
                                                  _data: {
                                                    businessKey: `${processKey}_businessKey_${Date.now()}`,
                                                    title: `${processKey}_title_${Date.now()}`,
                                                    formData: {},
                                                  },
                                                  processKey,
                                                },
                                              }
                                            )
                                          );
                                        });
                                      }
                                    }}
                                  >
                                    <span className="icon-start"></span>
                                  </button>
                                )}
                              </>
                            ) : (
                              <button
                                className="operationEvent startBtn"
                                onClick={() => {
                                  customizedToast(
                                    "فرآیند غیر قابل شروع است",
                                    "info"
                                  );
                                }}
                              >
                                <span className="icon-start"></span>
                              </button>
                            )}
                          </p>
                          <p className={`${styles.hasStartingForm}`}>
                            {hasStartingForm ? (
                              <MdDone
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            ) : (
                              <BsDashLg
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            )}
                          </p>
                          <p className={`${styles.accessType}`}>
                            {accessType.title}
                          </p>
                          <p className={`${styles.startable}`}>
                            {startable ? (
                              <MdDone
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            ) : (
                              <BsDashLg
                                style={{
                                  fontSize: "2rem",
                                }}
                              />
                            )}
                          </p>
                          <p
                            className={`${styles.processName}`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              String(processName).length >= 15
                                ? String(processName)
                                : ""
                            }
                          >
                            {String(processName).length >= 15
                              ? "..." + String(processName).substring(0, 14)
                              : String(processName)}
                          </p>
                          <p
                            className={`${styles.processKey}`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              String(processKey).length >= 15
                                ? String(processKey)
                                : ""
                            }
                          >
                            {String(processKey).length >= 15
                              ? "..." + String(processKey).substring(0, 14)
                              : String(processKey)}
                          </p>
                          <p
                            className={`${styles.processId}`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={
                              String(processId).length >= 15
                                ? String(processId)
                                : ""
                            }
                          >
                            {String(processKey).length >= 15
                              ? "..." + String(processKey).substring(0, 14)
                              : String(processKey)}
                          </p>
                        </div>
                      )
                    )}
                  </div>
                  <div className={`${styles.tableFooter}`}></div>
                </div>
              ) : (
                <div
                  className={`${styles.cards} mt-4 w-100 d-flex flex-column align-items-center`}
                >
                  {allocatedProcessData.content.map(
                    (
                      {
                        accessType,
                        deploymentId,
                        hasStartingForm,
                        processId,
                        processKey,
                        processName,
                        startable,
                        initialInformationRequired,
                      },
                      index
                    ) => (
                      <div key={processId} className="w-100">
                        <div
                          className={`${styles.tableHead} w-100 d-flex align-items-center justify-content-between `}
                        >
                          <p>
                            {(formsData.page - 1) * formsData.size +
                              (index + 1)}
                          </p>
                          <p>شمارنده </p>
                        </div>
                        <div
                          className={`${styles.tableRow} ${
                            isNightModeOn ? "nightBg2" : ""
                          } w-100 d-flex align-items-center justify-content-between `}
                        >
                          <p>
                            <span>نام فرآیند</span>
                            <span>{processName}</span>
                          </p>
                          <p>
                            <span>شمارنده فرآیند</span>
                            <span>{processId}</span>
                          </p>
                          <p>
                            <span>کد فرآیند</span>
                            <span>{processKey}</span>
                          </p>
                          <p>
                            <span>قابل شروع</span>
                            <span>
                              {startable ? (
                                <MdDone
                                  style={{
                                    fontSize: "2rem",
                                  }}
                                />
                              ) : (
                                <BsDashLg
                                  style={{
                                    fontSize: "2rem",
                                  }}
                                />
                              )}
                            </span>
                          </p>
                          <p>
                            <span>وضعیت دسترسی</span>
                            <span>{accessType.title}</span>
                          </p>
                          <p>
                            <span>دارای فرم شروع</span>
                            <span>
                              {hasStartingForm ? (
                                <MdDone
                                  style={{
                                    fontSize: "2rem",
                                  }}
                                />
                              ) : (
                                <BsDashLg
                                  style={{
                                    fontSize: "2rem",
                                  }}
                                />
                              )}
                            </span>
                          </p>
                          <p>
                            <span>عملیات </span>
                            <span>
                              {startable ? (
                                <>
                                  {hasStartingForm ? (
                                    <button
                                      className="operationEvent startBtn"
                                      onClick={() => {
                                        navigate(
                                          `/allocated-processes/start-new-instance-with-form/${processKey}/${processName}`
                                        );
                                      }}
                                    >
                                      <span className="icon-start"></span>
                                    </button>
                                  ) : (
                                    <button
                                      className="operationEvent startBtn"
                                      onClick={() => {
                                        if (initialInformationRequired) {
                                          setIsModalInScreen(true);
                                          setModalData({
                                            processId,
                                            processKey,
                                          });
                                        } else {
                                          Swal.fire({
                                            icon: "question",
                                            title: `فرآیند ${processName} شروع شود ؟`,
                                            showCancelButton: true,
                                            cancelButtonText: "لغو",
                                            confirmButtonText: "تایید",
                                          }).then(() => {
                                            customizedToast(
                                              "درخواست شروع ارسال شد",
                                              "info"
                                            );
                                            dispatch(
                                              startAsyncNewAllocatedProcessInstance(
                                                {
                                                  userToken,
                                                  _data: {
                                                    _data: {
                                                      businessKey: `${processKey}_businessKey_${Date.now()}`,
                                                      title: `${processKey}_title_${Date.now()}`,
                                                      formData: {},
                                                    },
                                                    processKey,
                                                  },
                                                }
                                              )
                                            );
                                          });
                                        }
                                      }}
                                    >
                                      <span className="icon-start"></span>
                                    </button>
                                  )}
                                </>
                              ) : (
                                <button
                                  className="operationEvent startBtn"
                                  onClick={() => {
                                    customizedToast(
                                      "فرآیند غیر قابل شروع است",
                                      "info"
                                    );
                                  }}
                                >
                                  <span className="icon-start"></span>
                                </button>
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
            </>
          )}
        </>
      ) : null}

      <Pagination
        dataForPagination={{
          currPage: formsData.page,
          size: formsData.size,
          totalPages: allocatedProcessData.totalPages,
        }}
        onPageNumberChange={(page) => {
          setFormsData((prevState) => ({ ...prevState, page }));
        }}
        onPageNumbersClick={(page) => {
          setFormsData((prevState) => ({ ...prevState, page }));
        }}
        onPageSizeChange={(size) => {
          setFormsData((prevState) => ({ ...prevState, size }));
        }}
        search={formsData.search}
      />

      {isModalInScreen ? (
        <StartAllocatedProcessModal
          processKey={modalData.processKey}
          setIsModalInScreen={setIsModalInScreen}
          formData={{}}
        />
      ) : null}
    </div>
  );
};

export default AllocatedProcesses;
