// React
import React, { useContext, useEffect, useState } from "react";
// React

// CSS
import styles from "./AddNewSingleCallOfRegistration.module.css";
// CSS

// Utils
import { convertPersianNumbersToEnglish } from "../../../Utils/FormioUtils/convertPersianNumbersToEnglish";
// Utils

// DatePicker
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/purple.css";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import "react-multi-date-picker/styles/colors/purple.css";
// DatePicker

// TimePicker
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import TimePicker from "react-time-picker";
import CustomInput1 from "../../../Utils/CustomInput1/CustomInput1";
import Pagination from "../../../Utils/Pagination/Pagination";
// TimePicker

// Redux
import { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  getAsyncPossibleGroupMembershipSlice,
  possibleGroupMemberShipsSlice,
} from "../../../Features/PossibleGroupMemberShipsSlice/possibleGroupMemberShipsSlice";
import {
  addAsyncSingleCallOfRegistration,
  callOfRegistrationSlice,
} from "../../../Features/CallOfRegistrationSlice/callOfRegistrationSlice";
// Redux

// Modules
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
// Modules

// Contexts
import { IsNightModeOnContext } from "../../../Contexts/IsNightModeOnContext/IsNightModeOnContext";
import CustomCheckbox from "../../../Utils/CustomCheckbox/CustomCheckbox";
// Contexts

// Constants
import { weekDaysInPersian } from "../../../Constants/constants";
// Constants

const AddNewSingleCallOfRegistration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [paginationData, setPaginationData] = useState<{
    currPage: number;
    pageSize: number;
  }>({
    currPage: 1,
    pageSize: 10,
  });
  const [formData, setFormData] = useState<{
    registrationDate: string;
    startTime: string;
    endTime: string;
    title: string;
    selectedGroups: { groupId: string }[];
    active: boolean;
    description: string;
  }>({
    registrationDate: "",
    startTime: "",
    endTime: "",
    title: "",
    selectedGroups: [],
    active: false,
    description: "",
  });

  //   JUST FOR INITIALIZING DATEPICKER
  const [values, setValues] = useState([]);
  //   JUST FOR INITIALIZING DATEPICKER

  const groupsData = useAppSelector((state) => state.possibleGroupMembership);
  const { accessToken: userToken } = useAppSelector((state) => state.loginData);
  const { selectedTenant } = useAppSelector((state) => state.tenants);
  const { isPending, isDone } = useAppSelector(
    (state) => state.callOfRegistration.addingCallOfRegistrationDetails
  );

  const isNightModeOn = useContext(IsNightModeOnContext);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(
        getAsyncPossibleGroupMembershipSlice({
          userToken,
          searchQuery: {
            page: paginationData.currPage,
            size: paginationData.pageSize,
            tenantId: selectedTenant.tenantId,
            search: "",
          },
        })
      );
    }, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, userToken, paginationData, selectedTenant]);

  useEffect(() => {
    if (!isDone) return;

    navigate("/call-of-registration");
  }, [isDone, navigate]);

  useEffect(() => {
    return () => {
      dispatch(possibleGroupMemberShipsSlice.actions.resetPossiblePermition());
      dispatch(
        callOfRegistrationSlice.actions.resetAddingSingleCallOfRegistrationState()
      );
    };
  }, [dispatch]);

  return (
    <div
      className={`${styles.addNewSingleCallOfRegistrationContainer} ${
        isNightModeOn ? "nightBg1" : ""
      }`}
    >
      <div
        className={`w-100 d-flex flex-row align-items-center justify-content-between mb-5`}
      >
        <button
          className="operationEvent submitBtn"
          onClick={() => {
            dispatch(
              addAsyncSingleCallOfRegistration({
                userToken,
                _data: {
                  active: formData.active,
                  description: formData.description,
                  endTime: `${formData.registrationDate.split("***")[1]} ${
                    formData.endTime
                  }:00`,
                  groupMembershipToAdd: formData.selectedGroups,
                  startTime: `${formData.registrationDate.split("***")[0]} ${
                    formData.startTime
                  }:00`,
                  tenantId: selectedTenant.tenantId,
                  title: formData.title,
                },
              })
            );
          }}
        >
          {isPending ? <Spinner /> : "تایید"}
        </button>

        <p>افزودن فراخوان</p>
      </div>

      <CustomInput1
        inputType="text"
        onBlur={() => {}}
        onChange={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            title: e.target.value,
          }));
        }}
        value={formData.title}
        defaultValue={formData.title}
        placeHolder="نام فراخوان را وارد کنید"
        dontShowSearchIcon
      />

      <CustomInput1
        inputType="text"
        onBlur={() => {}}
        onChange={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            description: e.target.value,
          }));
        }}
        value={formData.description}
        defaultValue={formData.description}
        placeHolder="توضیحات فرخوان را وارد کنید"
        dontShowSearchIcon
        containerClassName="mt-2"
      />

      <DatePicker
        calendar={persian}
        locale={persian_fa}
        className={`${styles.dateInputClassName}  ${
          isNightModeOn ? "purple bg-dark" : "purple"
        }`}
        value={values}
        dateSeparator="-"
        range
        weekDays={weekDaysInPersian}
        onChange={(value: any) => {
          if (!Boolean(value)) return;

          setValues(value);
          setFormData((prevState) => ({
            ...prevState,
            registrationDate: value.toString(),
          }));

          if (value.toString().split(",").length === 2) {
            const start = value.toString().split(",")[0];
            const end = value.toString().split(",")[1];

            const convertedStartToEnglish = start
              .split("/")
              .map((item: string) => convertPersianNumbersToEnglish(item))
              .join("-");

            const convertedEndToEnglish = end
              .split("/")
              .map((item: string) => convertPersianNumbersToEnglish(item))
              .join("-");

            setFormData((prevState) => ({
              ...prevState,
              registrationDate: `${convertedStartToEnglish}***${convertedEndToEnglish}`,
            }));
          }
        }}
        placeholder="بازه  فعالیت این فراخوان"
        containerClassName={`w-100 ${styles.datePickerContainer}`}
        calendarPosition="bottom-center"
      />
      <div
        className={`${styles.dataGetterContainer} w-100 d-flex flex-row align-items-center justify-content-between `}
      >
        <TimePicker
          onChange={(data: any) =>
            setFormData((prevState) => ({ ...prevState, startTime: data }))
          }
          value={formData.startTime}
          className={`${styles.timePickerClassName} ${styles.timePickerResponsive}`}
          locale="fa"
        />
        <p>زمان شروع</p>
      </div>
      <div
        className={`${styles.dataGetterContainer} w-100 d-flex flex-row align-items-center justify-content-between `}
      >
        <TimePicker
          onChange={(data: any) =>
            setFormData((prevState) => ({ ...prevState, endTime: data }))
          }
          value={formData.endTime}
          locale="fa"
          className={`${styles.timePickerClassName} ${styles.timePickerResponsive}`}
        />
        <p>زمان پایان</p>
      </div>

      <CustomCheckbox
        isChecked={formData.active}
        onClick={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            active: !prevState.active,
          }));
        }}
        onChange={(e) => {
          setFormData((prevState) => ({
            ...prevState,
            active: !prevState.active,
          }));
        }}
        title="فعال"
      />

      <p
        className={`w-100 d-flex flex-row align-items-center justify-content-end mt-5 mb-2`}
      >
        گروه های قابل الصاق
      </p>
      <div
        className={`${styles.selectingClassNameContainer} w-100 d-flex flex-row align-items-center justify-content-between flex-wrap`}
      >
        {groupsData.isPending ? (
          <div className="w-100" style={{ textAlign: "right" }}>
            در حال بارگذاری
          </div>
        ) : (
          <>
            {groupsData.content.length === 0 ? (
              <>موردی وجود ندارد </>
            ) : (
              <>
                {groupsData.content.map((item, index) => (
                  <CustomCheckbox
                    key={item.value}
                    onClick={() => {
                      if (
                        formData.selectedGroups
                          .map((_item) => _item.groupId)
                          .includes(item.value)
                      ) {
                        setFormData((prevState) => ({
                          ...prevState,
                          selectedGroups: prevState.selectedGroups.filter(
                            (_item) => _item.groupId !== item.value
                          ),
                        }));
                        return;
                      }
                      setFormData((prevState) => ({
                        ...prevState,
                        selectedGroups: [
                          ...prevState.selectedGroups,
                          {
                            groupId: item.value,
                          },
                        ],
                      }));
                    }}
                    onChange={() => {
                      if (
                        formData.selectedGroups
                          .map((_item) => _item.groupId)
                          .includes(item.value)
                      ) {
                        setFormData((prevState) => ({
                          ...prevState,
                          selectedGroups: prevState.selectedGroups.filter(
                            (_item) => _item.groupId !== item.value
                          ),
                        }));
                        return;
                      }
                      setFormData((prevState) => ({
                        ...prevState,
                        selectedGroups: [
                          ...prevState.selectedGroups,
                          {
                            groupId: item.value,
                          },
                        ],
                      }));
                    }}
                    title={item.title}
                    isChecked={formData.selectedGroups
                      .map((_item) => _item.groupId)
                      .includes(item.value)}
                    containerClassName={`${styles.groupItem} w-48`}
                  />
                ))}
              </>
            )}
          </>
        )}
      </div>

      <Pagination
        dataForPagination={{
          currPage: paginationData.currPage,
          size: paginationData.pageSize,
          totalPages: groupsData.totalPages,
        }}
        onPageNumberChange={(pageNumber) => {
          setPaginationData((prevState) => ({
            ...prevState,
            currPage: Number(pageNumber),
          }));
        }}
        onPageNumbersClick={(pageNumber: number) => {
          setPaginationData((prevState) => ({
            ...prevState,
            currPage: Number(pageNumber),
          }));
        }}
        onPageSizeChange={(size) => {
          setPaginationData((prevState) => ({
            ...prevState,
            pageSize: Number(size),
          }));
          setPaginationData((prevState) => ({ ...prevState, currPage: 1 }));
        }}
        search={""}
      />
    </div>
  );
};

export default AddNewSingleCallOfRegistration;
