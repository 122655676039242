import { _axios } from "../Base_Axios_Config/_axios";

export const removeSingleOptionOfExistingAttributeValueService = async (_data: {
  userToken: string;
  tenant: string;
  _data: {
    attributeIdOrKey: string;
    optionId: string;
  };
}) => {
  return _axios.post(
    `/users/identity/attributes/${_data._data.attributeIdOrKey}/options/${_data._data.optionId}/delete?tenant=${_data.tenant}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${_data.userToken}`,
      },
    }
  );
};
